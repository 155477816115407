import { Page, View, Image, StyleSheet, Text, Font } from '@react-pdf/renderer'
import React from 'react'
import { ItemsPrint, PrintPage } from '../../types/user'
import HeaderPrint from './HeaderPrint'
import FooterPrint from './FooterPrint'
import { styles } from './styles'

interface Props {
    item: ItemsPrint
    printFile: PrintPage
}

const SeriePage: React.FC<Props> = (props) => {
    const item = props.item
    const printFile = props.printFile

    if (props.item) {
        return (
            <Page size="A4" style={{ display: 'flex', flexDirection: 'column' }}>
                <View style={{ width: "100%", height: "100%" }}>
                    <HeaderPrint printFile={printFile} />
                    <View style={{ paddingHorizontal: 20 }}>
                        <View>
                            <Image src={item.imgSerie} style={{ objectFit: 'cover', width: "100%", height: 250 }} />
                        </View>
                        <Text style={styles.title}>{item.name}</Text>
                        <Text style={styles.subtitle}>{item.slogan}</Text>
                        <View style={{ marginTop: 20 }}>
                            <Text style={styles.standardText}>{item.description}</Text>
                        </View>
                    </View>
                    <FooterPrint printFile={printFile} />
                </View>
            </Page>
        )
    }

    return null
}

export default SeriePage
