import React, { useEffect, useState } from 'react'
import { ReducerData, ReducerOrder, Reducers } from '../../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import TypologyListElement from '../../../components/TypologyListElement'
import Button from '../../../components/Button'
import { faArrowsAlt, faPlusSquare, faTag } from '@fortawesome/free-solid-svg-icons'
import { Switch } from '@material-ui/core'
import Select from 'react-select'
import { calculateTotalPrices, getAllItemsQuote, recalculateItems, saveCurrentOffer } from '../../../resources/requests/requests'
import { useHistory } from 'react-router'
import { setCustomersRedux, setOfferLoadedRedux } from '../../../store/actions/thunk_actions'
import { setItemsInQuote } from '../../../store/actions/order'
import { setCustomers } from '../../../store/actions/data'
import { setError, setIsLoading } from '../../../store/actions/graphic'
import FreeTypologyListElement from '../../../components/FreeTypologyListElement'
import ComposedTypologyListElement from '../../../components/composed/ComposedTypologyListElement'
import CustomOrderModal from '../../../components/modals/CustomOrderModal'
import { orderArrayPerOrder, orderArrayPerPrice, orderArrayPerTypology } from '../../../utilities/functions'
import TolleranceModal from '../../../components/modals/TolleranceModal'
import SelectInput, { Option } from '../../../components/formComponents/SelectInput'
import UpsellingModal from '../../../components/modals/UpsellingModal'
import ConfirmModal from '../../../components/modals/ConfirmModal'

interface Props {
    addItem: () => void
}

const FilterVoices = [
    {
        id: 0,
        value: 0,
        label: 'Numero ordine'
    },
    {
        id: 1,
        value: 1,
        label: 'Tipologia'
    },
    {
        id: 2,
        value: 2,
        label: 'Prezzo crescente'
    },
    {
        id: 3,
        value: 3,
        label: 'Prezzo decrescente'
    },
    {
        id: 4,
        value: 4,
        label: 'Ordine customizzato'
    }
]

const PersonalizzaTipologieView: React.FC<Props> = (props) => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [ricalcoloAuto, setRicalcoloAuto] = useState(true)
    const [showUpsell, setShowUpsell] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()
    const [selectedFilter, setSelectedFilter] = useState(FilterVoices[0])
    const [showCustomOrderModal, setShowCustomOrderModal] = useState(false)
    const [showTolleranceModal, setShowTolleranceModal] = useState(false)

    const renderTypologies = () => {
        return order.itemsInQuote.map((item) => {
            if (item.DettaglioItem.IDComposta) {
                return null
            }
            if (item.DettaglioItem.CodTipoItem == 'LIBERA') {
                return (
                    <FreeTypologyListElement
                        key={item.idRecord}
                        itemInQuote={item}
                        goPrevPhase={() => props.addItem()}
                        idRecord={item.idRecord}
                        isAutoRecalc={ricalcoloAuto}
                    />
                )
            }
            if (item.DettaglioItem.CodTipoItem == 'COMPOSTA') {
                const assignedProduct = order.itemsInQuote.filter((i) => i.DettaglioItem.IDComposta == item.idRecord)
                return (
                    <ComposedTypologyListElement
                        key={item.idRecord}
                        itemInQuote={item}
                        assignedProduct={assignedProduct}
                        goPrevPhase={() => props.addItem()}
                        idRecord={item.idRecord}
                        isAutoRecalc={ricalcoloAuto}
                    />
                )
            }
            return (
                <TypologyListElement
                    key={item.idRecord}
                    itemInQuote={item}
                    goPrevPhase={() => props.addItem()}
                    idRecord={item.idRecord}
                    isAutoRecalc={ricalcoloAuto}
                />
            )
        })
    }

    const handleSaveCurrentOffer = async () => {
        const itemsToRecalculate = order.itemsInQuote.filter((item) => item.DettaglioItem.Modificato)
        if (itemsToRecalculate.length > 0) {
            const idArray = itemsToRecalculate.map((i) => {
                return { idItem: i.idRecord }
            })
            await recalculateItems(order.newQuoteFromUser?.CodicePreventivo, order.newQuoteFromUser?.CodiceOfferta, idArray, dispatch)
        }
        await saveCurrentOffer(order.newQuoteFromUser?.CodiceOfferta, dispatch)
        const foundTax = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)?.Codice
        await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, foundTax ? foundTax : data.taxes[0].Codice, dispatch)
        dispatch(setOfferLoadedRedux(order.newQuoteFromUser?.CodiceOfferta, true))
        dispatch(setCustomersRedux())
    }

    const handleReloadRecalculatedItem = async () => {
        const items = await getAllItemsQuote(order.newQuoteFromUser?.CodicePreventivo, dispatch)
        dispatch(
            setItemsInQuote(
                order.itemsInQuote.map((item) => {
                    const itemFound = items.find((i) => i.idRecord == item.idRecord)
                    if (itemFound) {
                        return {
                            ...item,
                            DettaglioItem: itemFound
                        }
                    } else {
                        return item
                    }
                })
            )
        )
    }

    useEffect(() => {
        orderArray()
    }, [selectedFilter])

    const orderArray = () => {
        switch (selectedFilter.id) {
            case 0:
                dispatch(setItemsInQuote(orderArrayPerOrder(order.itemsInQuote)))
                return
            case 1:
                dispatch(setItemsInQuote(orderArrayPerTypology(order.itemsInQuote)))
                return
            case 2:
                dispatch(setItemsInQuote(orderArrayPerPrice(order.itemsInQuote)))
                return
            case 3:
                dispatch(setItemsInQuote(orderArrayPerPrice(order.itemsInQuote, true)))
                return
            case 4:
                setShowCustomOrderModal(true)
                return
            default:
                return
        }
    }

    const calculateNumber = () => {
        let number = 0
        order.itemsInQuote.forEach((i) => {
            number += i.DettaglioItem.Quantita
        })
        return number
    }

    return (
        <div className="formCreateOffer">
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', margin: '20px 0', alignItems: 'center' }}>
                <div style={{ flex: 1 }}>
                    {/* <Switch color="primary" value={ricalcoloAuto} onChange={() => setRicalcoloAuto(!ricalcoloAuto)} />
                    <span style={{ color: "#68696D" }}>Ricalcolo automatico</span> */}
                    <p style={{ color: '#68696D', fontSize: 18, fontStyle: 'italic' }}>Numero pezzi totali: {calculateNumber()}</p>
                </div>
                <SelectInput
                    label="Ordina per:"
                    value={selectedFilter}
                    options={FilterVoices}
                    divStyle={{ flex: 0.8, display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'flex-end', justifyContent: 'flex-end' }}
                    onChange={(e) => {
                        const val = e as Option
                        setSelectedFilter({
                            ...val,
                            id: val.id as number,
                            value: val.value as number
                        })
                    }}
                />
            </div>
            <div className="typologiesList">{renderTypologies()}</div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 20, margin: '20px 0' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                    <Button text="Aggiungi voce" buttonColor="#74777B" hasIcon={true} icon={faPlusSquare} onClick={() => props.addItem()} />
                    <Button
                        text="Tolleranza"
                        buttonColor="#74777B"
                        hasIcon={true}
                        icon={faArrowsAlt}
                        onClick={() => {
                            setShowTolleranceModal(true)
                        }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                    <Button
                        text="Salva offerta"
                        buttonColor="#52565A"
                        hasIcon={true}
                        icon={faTag}
                        onClick={async () => {
                            dispatch(setIsLoading(true))
                            try {
                                await handleSaveCurrentOffer()
                                await handleReloadRecalculatedItem()
                                setShowUpsell(true)
                            } catch (error: any) {
                                dispatch(setError(error.toString()))
                            }
                            dispatch(setIsLoading(false))
                        }}
                    />{/*
                        <Button
                        text="Salva offerta e continua"
                        hasIcon={true}
                        isVisible={false}
                        icon={faTag}
                        onClick={async () => {
                            dispatch(setIsLoading(true))
                            try {
                                await handleSaveCurrentOffer()
                                await handleReloadRecalculatedItem()
                            } catch (error: any) {
                                dispatch(setError(error.toString()))
                            }
                            dispatch(setIsLoading(false))
                        }}
                    />*/
                    }
                    
                    <CustomOrderModal isVisible={showCustomOrderModal} hideModal={() => setShowCustomOrderModal(false)} />
                    <TolleranceModal
                        codPreventivo={order.newQuoteFromUser!.CodicePreventivo}
                        codOffer={order.newQuoteFromUser!.CodiceOfferta}
                        isVisible={showTolleranceModal}
                        hideModal={() => setShowTolleranceModal(false)}
                    />
                    <UpsellingModal
                        codPreventivo={order.newQuoteFromUser!.CodicePreventivo}
                        codOffer={order.newQuoteFromUser!.CodiceOfferta}
                        isVisible={showUpsell}
                        save={async () => {
                            dispatch(setIsLoading(true))
                            await handleSaveCurrentOffer()
                            await handleReloadRecalculatedItem()
                            history.push('/')
                            dispatch(setIsLoading(false))
                        }}
                        hideModal={() => {
                            setShowUpsell(false)
                            history.push('/')
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default PersonalizzaTipologieView
