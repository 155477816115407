import React, { useEffect, useState } from 'react'
import TitlePage from '../../components/TitlePage'
import { ReducerGraphic, ReducerOrder, Reducers, ReducerUser } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../components/Button'
import { faBuilding, faCalendar, faEdit, faFileExport, faPrint, faTrash } from '@fortawesome/free-solid-svg-icons'
import CurrentOfferListElement from '../../components/CurrentOfferListElement'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { convertSale, transformToCurrency } from '../../utilities/functions'
import { createPDFToPrint, createXMLToDownload, deleteOffer, getTotalPricesOffer } from '../../resources/requests/requests'
import { saveAs } from 'file-saver'
import { useHistory } from 'react-router'
import { setOrdersRedux } from '../../store/actions/thunk_actions'
import { TotaliOfferta } from '../../types/order'
import { setPrintFile } from '../../store/actions/user'
import { setError, setIsLoading } from '../../store/actions/graphic'
import PrintSettingsModal from '../../components/modals/PrintSettingsModal'
import DeleteModal from '../../components/modals/DeleteModal'
import { PrintType } from '../../utilities/PrintType'
import ConfirmModal from '../../components/modals/ConfirmModal'
import { BASE_URL,BASE_URL_FRONTEND, SUB_PATH_URL_FRONTEND } from '../../resources/constants'
require('moment/min/locales')
moment.locale('it')

const OrderDetail: React.FC = () => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [visiblePrintSettingsModal, setVisiblePrintSettingsModal] = useState(false)
    const [isConfirmVisible, setIsConfirmVisible] = useState('')
    const [offerPrices, setOfferPrices] = useState<TotaliOfferta | null>()
    const dispatch = useDispatch()
    const history = useHistory()

    const handlePrintOffer = async (type: PrintType) => {
        dispatch(setIsLoading(true))
        try {
            const filePdf = await createPDFToPrint(user.lastOfferSeen?.CodiceOfferta, dispatch)
            dispatch(setPrintFile({ ...filePdf, printType: type }))
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                window.open('/print', '_blank')
            }else {
               // window.open(`${BASE_URL}biemme_genera/panel/print`, '_blank')
               window.open(`${BASE_URL}${SUB_PATH_URL_FRONTEND}print`, '_blank')
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handleDownloadXML = async () => {
        dispatch(setIsLoading(true))
        try {
            const fileXML = await createXMLToDownload(user.lastOfferSeen?.CodiceOfferta, dispatch)
            if (fileXML && user.lastOfferSeen) {
                saveAs(fileXML, user.lastOfferSeen.CodiceOfferta + '.zip')
                setIsConfirmVisible(user.lastOfferSeen.CodiceOfferta)
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    const getTotalPrices = async () => {
        dispatch(setIsLoading(true))
        try {
            const prices = await getTotalPricesOffer(user.lastOfferSeen?.CodiceOfferta, dispatch)
            setOfferPrices(prices)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const renderAddress = () => {
        if (user.lastOfferSeen) {
            if (user.lastOfferSeen.ComuneCantiere && user.lastOfferSeen.IndirizzoCantiere && user.lastOfferSeen.ProvinciaCantiere) {
                return (
                    <p style={{ color: '#68696D' }}>
                        Cantiere di installazione:{' '}
                        <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                            {user.lastOfferSeen.IndirizzoCantiere} - {user.lastOfferSeen.ComuneCantiere} ({user.lastOfferSeen.ProvinciaCantiere})
                        </span>
                    </p>
                )
            } else {
                return <p style={{ color: '#68696D' }}>Cantiere di installazione: </p>
            }
        }
        return <p style={{ color: '#68696D' }}>Cantiere di installazione: </p>
    }

    useEffect(() => {
        getTotalPrices()
    }, [])

    const calculatedIva = () => {
        let tax = 0
        offerPrices?.DettaglioPreventivi.forEach((p) => {
            tax += parseFloat(p.Iva)
        })
        return transformToCurrency(tax)
    }

    const handleDeleteOffer = async () => {
        dispatch(setIsLoading(true))
        try {
            if (user.lastOfferSeen) {
                await deleteOffer(user.lastOfferSeen.CodiceOfferta, dispatch)
                dispatch(setOrdersRedux())
            }
            history.push('/offers')
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <div>
            {user.lastOfferSeen ? <TitlePage text="Ordine " dynamicText={user.lastOfferSeen.CodiceOfferta} /> : <TitlePage text="Nessuna offerta presente" />}
            {user.lastOfferSeen && offerPrices && (
                <div className="scrollableDiv" style={{ maxHeight: '75vh' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 20 }}>
                        <Button
                            text="Stampa ordine"
                            hasIcon={true}
                            icon={faPrint}
                            onClick={() => {
                                setVisiblePrintSettingsModal(true)
                            }}
                            buttonColor="#68696D"
                        />
                        <Button
                            text="Scarica file specifiche"
                            hasIcon={true}
                            icon={faFileExport}
                            onClick={() => {
                                handleDownloadXML()
                            }}
                            buttonColor="#68696D"
                        />
                        {user.user?.TipoAccount == 'Amministratore' ? (
                            <Button
                                text="Elimina ordine"
                                hasIcon={true}
                                icon={faTrash}
                                onClick={async () => {
                                    setIsDeleteModalVisible(true)
                                }}
                                buttonColor="#E00000"
                            />
                        ) : null}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '20px 0' }}>
                        {user.lastOfferSeen.Preventivi.map((p) => {
                            return (
                                <CurrentOfferListElement
                                    key={p.CodicePreventivo}
                                    quote={p}
                                    isClosedOffer
                                    offerTotals={offerPrices}
                                    codOffer={user.lastOfferSeen?.CodiceOfferta}
                                    notEdit
                                    parentOffer={null}
                                    price={offerPrices?.DettaglioPreventivi.find((price) => p.CodicePreventivo == price.CodicePreventivo)?.ImportoTotale}
                                />
                            )
                        })}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 3, gap: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faCalendar} style={{ fontSize: 30, color: '#68696D' }} />
                                <p style={{ color: '#68696D' }}>
                                    Consegna richiesta entro:{' '}
                                    <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                        {user.lastOfferSeen.DataConsegna
                                            ? moment(user.lastOfferSeen.DataConsegna).format('DD MMMM YYYY')
                                            : moment().format('DD MMMM YYYY')}
                                    </span>
                                </p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faEdit} style={{ fontSize: 30, color: '#68696D' }} />
                                <p style={{ color: '#68696D' }}>Testi iniziali e finali del documento</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faBuilding} style={{ fontSize: 30, color: '#68696D' }} />
                                {renderAddress()}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 0.9, alignItems: 'flex-end', gap: 10 }}>
                            <p
                                style={{
                                    color: '#68696D',
                                    fontWeight: 500,
                                    fontSize: 22,
                                    paddingBottom: 15,
                                    borderBottom: '1px solid #ccc',
                                    padding: '0 20px',
                                    alignSelf: 'stretch',
                                    textAlign: 'right'
                                }}
                            >
                                Totale imponibile:{' '}
                                <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                    {offerPrices?.Totale && transformToCurrency(parseFloat(offerPrices?.Totale) + parseFloat(offerPrices?.ScontoApplicato))}
                                </span>
                            </p>
                            <p
                                style={{
                                    color: '#68696D',
                                    fontWeight: 500,
                                    fontSize: 22,
                                    paddingBottom: 15,
                                    borderBottom: '1px solid #ccc',
                                    padding: '0 20px',
                                    alignSelf: 'stretch',
                                    textAlign: 'right'
                                }}
                            >
                                Sconto - ({' '}
                                {offerPrices && convertSale(offerPrices!.Sconto)[1] !== '0'
                                    ? `${convertSale(offerPrices!.Sconto)[0]}+${convertSale(offerPrices!.Sconto)[1]}%`
                                    : `${offerPrices!.Sconto}%`}
                                ):{' '}
                                <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                    {offerPrices?.ScontoApplicato
                                        ? '- ' + transformToCurrency(parseFloat(offerPrices?.ScontoApplicato))
                                        : '- ' + transformToCurrency(0)}
                                </span>
                            </p>
                            <p
                                style={{
                                    color: '#68696D',
                                    fontWeight: 500,
                                    fontSize: 22,
                                    paddingBottom: 15,
                                    borderBottom: '1px solid #ccc',
                                    padding: '0 20px',
                                    alignSelf: 'stretch',
                                    textAlign: 'right'
                                }}
                            >
                                Spese interne:{' '}
                                <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                    {offerPrices?.AltreSpese ? transformToCurrency(parseFloat(offerPrices?.AltreSpese)) : transformToCurrency(0)}
                                </span>
                            </p>
                            <p
                                style={{
                                    color: '#68696D',
                                    fontWeight: 500,
                                    fontSize: 22,
                                    paddingBottom: 15,
                                    borderBottom: '1px solid #ccc',
                                    padding: '0 20px',
                                    alignSelf: 'stretch',
                                    textAlign: 'right'
                                }}
                            >
                                Spese accessorie:{' '}
                                <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                    {offerPrices?.SpeseAcc ? transformToCurrency(parseFloat(offerPrices?.SpeseAcc)) : transformToCurrency(0)}
                                </span>
                            </p>
                            <p
                                style={{
                                    color: '#68696D',
                                    fontWeight: 500,
                                    fontSize: 22,
                                    paddingBottom: 15,
                                    borderBottom: '1px solid #ccc',
                                    padding: '0 20px',
                                    alignSelf: 'stretch',
                                    textAlign: 'right'
                                }}
                            >
                                IVA: <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>{calculatedIva()}</span>
                            </p>
                            <p
                                style={{
                                    color: '#68696D',
                                    fontWeight: 500,
                                    fontSize: 22,
                                    paddingBottom: 15,
                                    padding: '0 20px',
                                    alignSelf: 'stretch',
                                    textAlign: 'right'
                                }}
                            >
                                Totale offerta:{' '}
                                <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                    {offerPrices?.TotCosti && transformToCurrency(parseFloat(offerPrices?.ImportoTotale))}
                                </span>
                            </p>
                        </div>
                    </div>
                    <DeleteModal
                        isVisible={isDeleteModalVisible}
                        hideModal={() => setIsDeleteModalVisible(false)}
                        onDelete={() => {
                            handleDeleteOffer()
                            setIsDeleteModalVisible(false)
                        }}
                        text={"Cancella l'ordine (ID " + user.lastOfferSeen?.CodiceOfferta + ')'}
                        title={'Elimina ordine'}
                    />
                    <PrintSettingsModal
                        isVisible={visiblePrintSettingsModal}
                        hideModal={() => setVisiblePrintSettingsModal(false)}
                        onConfirm={(type, order) => {
                            handlePrintOffer(type)
                        }}
                    />
                    <ConfirmModal
                        isVisible={isConfirmVisible !== ''}
                        hideModal={() => setIsConfirmVisible('')}
                        isOnlyConfirm
                        title="Sto scaricando il file specifiche"
                        text={
                            isConfirmVisible && isConfirmVisible.charAt(0) == 'P'
                                ? `Sto scaricando il file specifiche per l'offerta ${isConfirmVisible}`
                                : `Sto scaricando il file specifiche per l'ordine ${isConfirmVisible}`
                        }
                        onConfirm={() => setIsConfirmVisible('')}
                    />
                </div>
            )}
        </div>
    )
}

export default OrderDetail
