import { faPalette, faStarOfLife, faUpload } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useRef, useState } from 'react'
import Button from './Button'
import { SketchPicker } from 'react-color'
import { LOGO_DEFAULT } from '../resources/constants'


interface Props {
    isColorPicker?: boolean
    title: string
    defaultColor?: string
    defaultImage?: any
    toSetMain?: boolean
    opacityButton?: number
    disabled?: boolean
    setMain?: () => void
    editColor?: (c: string) => void
    editImage?: (i: any) => void
}

const UploadCard: React.FC<Props> = (props) => {
    const [showColorPicker, setShowColorPicker] = useState(false);
    const [color, setColor] = useState<string>(props.defaultColor ? props.defaultColor : '#000')
    const [img, setImg] = useState<any>()
    const previewImg = useRef(null)

    useEffect(() => {
        if (props.defaultColor) {
            setColor(props.defaultColor)
        }
    }, [props.defaultColor])

    useEffect(() => {
        if (props.editColor) {
            props.editColor(color)
        }
    }, [color])

    useEffect(() => {
        if (!props.defaultImage) {
            setImg(LOGO_DEFAULT)
            return
        }
        if (props.defaultImage.name) {
            const fr = new FileReader()
            fr.onload = function () {
                //@ts-ignore
                previewImg.current.src = fr.result
            }
            fr.readAsDataURL(props.defaultImage)
            return
        }
        if (props.defaultImage.includes('http')) {
            setImg(props.defaultImage)
            return
        }
    }, [props.defaultImage])

    const renderColorPicker = () => {
        if (showColorPicker) {
            return (
                <div>
                    <div style={{ position: 'fixed', width: "100vw", height: "100vh", top: 0, left: 0 }} onClick={() => setShowColorPicker(false)} />
                    <SketchPicker
                        color={props.defaultColor}
                        onChange={(colore) => {
                            console.log(colore)
                            setColor(colore.hex)
                        }}
                    />
                </div>
            )
        }
    }

    if (props.isColorPicker) {
        return (
            <div className="uploadCard">
                <div className="colorChosen" style={{ backgroundColor: props.defaultColor }} />
                <h3>{props.title}</h3>
                {renderColorPicker()}
                <Button
                    text="Scegli"
                    opacity={props.opacityButton ? props.opacityButton : 1}
                    hasIcon={true}
                    icon={faPalette}
                    onClick={() => {
                        if (props.disabled) {
                            return
                        }
                        setShowColorPicker(true)
                    }}
                />
            </div>
        )
    }

    if (props.toSetMain) {
        return (
            <div className="uploadCard">
                <img
                    ref={previewImg}
                    src={props.defaultImage ? props.defaultImage : LOGO_DEFAULT}
                />
                <h3>{props.title}</h3>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <Button
                        hasUpload={true}
                        text="Carica"
                        icon={faUpload}
                        disableUpload={props.disabled}
                        isSmall
                        onChange={(e) => {
                            if (props.editImage) {
                                props.editImage(e)
                            }
                        }}
                        opacity={props.opacityButton ? props.opacityButton : 1}
                        onClick={() => { return }}
                    />
                    <Button
                        hasIcon
                        text="Rendi principale"
                        isSmall
                        buttonColor="#52565A"
                        icon={faStarOfLife}
                        opacity={props.opacityButton ? props.opacityButton : 1}
                        onClick={() => {
                            if (props.disabled) {
                                return
                            }
                            if (props.setMain) {
                                props.setMain()
                            }
                        }}
                    />
                </div>

            </div>
        )

    }

    return (
        <div className="uploadCard">
            <img
                ref={previewImg}
                src={props.defaultImage ? props.defaultImage : LOGO_DEFAULT}
            />
            <h3>{props.title}</h3>
            <Button
                hasUpload={true}
                text="Carica"
                icon={faUpload}
                opacity={props.opacityButton ? props.opacityButton : 1}
                disableUpload={props.disabled}
                onChange={(e) => {
                    if (props.editImage) {
                        props.editImage(e)
                    }
                }}
                onClick={() => { return }} />
        </div>
    )
}

export default UploadCard