import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'
import { useDispatch } from 'react-redux'
import { applyTollerance, tolleranceList } from '../../resources/requests/quote'
import { setError, setIsLoading } from '../../store/actions/graphic'
import SelectInput, { Option } from '../formComponents/SelectInput'
import { saveCurrentOffer } from '../../resources/requests/requests'
import { setOfferLoadedRedux } from '../../store/actions/thunk_actions'
import { setCurrentOffer } from '../../store/actions/order'

interface Tollerance {
    ID: string
    Descrizione: string
    TolleranzaH: string
    TolleranzaL: string
    attivo: string
}

interface Props {
    isVisible: boolean
    codPreventivo: string
    codOffer: string
    hideModal: () => void
}

const TolleranceModal: React.FC<Props> = (props) => {
    const [tollerances, setTollerances] = useState<Tollerance[]>([])
    const [selectedTollerance, setSelectedTollerance] = useState<Tollerance | null>(null)
    const dispatch = useDispatch()

    const fetchTollerances = async () => {
        dispatch(setIsLoading(true))
        try {
            const fetchedTollerances = await tolleranceList(dispatch)
            if (fetchedTollerances.length > 0) {
                setSelectedTollerance(fetchedTollerances[0])
            }
            setTollerances(fetchedTollerances)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    useEffect(() => {
        if (props.isVisible) {
            fetchTollerances()
        }
    }, [props.isVisible])

    const saveTolerance = async () => {
        if (selectedTollerance) {
            dispatch(setIsLoading(true))
            try {
                const body = {
                    CodicePreventivo: props.codPreventivo,
                    TolleranzaL: parseInt(selectedTollerance.TolleranzaL),
                    TolleranzaH: parseInt(selectedTollerance.TolleranzaH)
                }
                await applyTollerance(body, dispatch)
                const offer = await saveCurrentOffer(props.codOffer, dispatch)
                if (offer) {
                    dispatch(setCurrentOffer(offer))
                }
                // await dispatch(setOfferLoadedRedux(props.codOffer, true))
                props.hideModal()
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    const tolleranzaValue = () => {
        if (selectedTollerance) {
            return {
                id: selectedTollerance.ID,
                value: selectedTollerance.ID,
                label: selectedTollerance.Descrizione
            }
        } else {
            return null
        }
    }

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="sm"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10, overflow: 'visible' }}>
                <TitlePage text={'Modifica tolleranza'} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Seleziona che tolleranza applicare.</p>
                <div style={{ margin: '20px 0' }}>
                    <SelectInput
                        options={tollerances.map((t) => {
                            return {
                                id: t.ID,
                                value: t.ID,
                                label: t.Descrizione
                            }
                        })}
                        value={tolleranzaValue()}
                        onChange={(e) => {
                            const val = e as Option
                            const findTollerance = tollerances.find((t) => t.ID == (val.id as string))
                            if (findTollerance) {
                                setSelectedTollerance(findTollerance)
                            }
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Annulla"
                    buttonColor="#52565A"
                    onClick={() => {
                        props.hideModal()
                    }}
                />
                <Button
                    isSmall
                    text="Salva"
                    onClick={() => {
                        saveTolerance()
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default TolleranceModal
