import React, { useEffect, useState } from 'react'
import { Categoria } from '../../../../types/data'
import UploadCard from '../../../../components/UploadCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faCog } from '@fortawesome/free-solid-svg-icons'
import Button from '../../../../components/Button'
import { useDispatch } from 'react-redux'
import { setError, setIsLoading } from '../../../../store/actions/graphic'
import { editCategory, uploadImage } from '../../../../resources/requests/requests'
import { setCategoriesRedux } from '../../../../store/actions/thunk_actions'
import { Ambito } from '../../../../utilities/Ambito'
import { ReducerGraphic, Reducers } from '../../../../types/reducers'
import { useSelector } from 'react-redux'

interface Props {
    selectedDetail: Categoria
    onBack: () => void
}

const CategoriesDetailScreen: React.FC<Props> = (props) => {
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [uploadedFile, setUploadedFile] = useState<any>(props.selectedDetail.ImgCategoria)
    const [colorPicked, setColorPicked] = useState(props.selectedDetail.ColoreFrame ? props.selectedDetail.ColoreFrame : graphic.colorAccent)
    const dispatch = useDispatch()

    useEffect(() => {
        setColorPicked(props.selectedDetail.ColoreFrame ? props.selectedDetail.ColoreFrame : graphic.colorAccent)
        setUploadedFile(props.selectedDetail.ImgCategoria)
    }, [props.selectedDetail])

    const handleSave = async () => {
        dispatch(setIsLoading(true))
        try {
            let img: any = props.selectedDetail.ImgCategoria
            if (uploadedFile && uploadedFile.name) {
                img = await uploadImage(uploadedFile, Ambito.CATEGORIA_IMG, dispatch)
            }
            const body = {
                IdCategoriaSerie: parseInt(props.selectedDetail.IdCategorSerie),
                Nome: props.selectedDetail.Nome,
                DescrizioneCategoria: props.selectedDetail.DescrizioneCategoria,
                Slogan: props.selectedDetail.Slogan,
                IconaSimbolo: props.selectedDetail.IconaSimbolo,
                ImgCategoria: img,
                ColoreFrame: colorPicked,
                IdOrd: parseInt(props.selectedDetail.IdOrd),
                Visibile: props.selectedDetail.Visibile
            }
            await editCategory(body, dispatch)
            await dispatch(setCategoriesRedux())
            props.onBack()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10, cursor: 'pointer' }} onClick={() => props.onBack()}>
                        <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 14, color: graphic.colorAccent }} />
                        <span style={{ fontSize: 14, color: '#68696D' }}>Indietro</span>
                    </div>
                    <h2 style={{ fontWeight: 300, marginTop: 0, color: '#68696D' }}>Categoria: {props.selectedDetail.Nome}</h2>
                </div>
                <Button
                    text="Salva categoria"
                    hasIcon={true}
                    icon={faCog}
                    onClick={() => {
                        handleSave()
                    }}
                />
            </div>

            <div className="categoryListContainer" style={{ margin: '20px 0', gridTemplateColumns: 'repeat(5, 1fr)' }}>
                <UploadCard title="Immagine categoria" defaultImage={uploadedFile} editImage={(e) => setUploadedFile(e)} />
                <UploadCard
                    title="Colore categoria"
                    isColorPicker={true}
                    defaultColor={colorPicked ? colorPicked : graphic.colorAccent}
                    editColor={(c) => setColorPicked(c)}
                />
            </div>
        </div>
    )
}

export default CategoriesDetailScreen
