import { faPlusSquare } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { ComposedDividerGraphic, ComposedItemStructureGraphic } from '../../types/order'
import Button from '../Button'

export enum Direction {
    Vertical = 'Verticale',
    Horizontal = 'Orizzontale'
}

interface Props {
    parents: ComposedItemStructureGraphic[]
    updateItem: (dividers: ComposedDividerGraphic[], parent: ComposedItemStructureGraphic, direction: Direction) => void
}

const AddDivisorItem: React.FC<Props> = (props) => {
    const [parent, setParent] = useState<ComposedItemStructureGraphic>(props.parents[0])
    const [dividers, setDividers] = useState<ComposedDividerGraphic[]>([])
    const [direction, setDirection] = useState<Direction>(Direction.Vertical)
    const [dividersLenght, setDividersLenght] = useState<number>(0)

    useEffect(() => {
        setParent(props.parents[0])
        setDividers([])
        setDividersLenght(0)
    }, [props.parents])

    useEffect(() => {
        if (dividersLenght === 0) {
            setDividers([])
            return
        }
        calculateDividers()
    }, [dividersLenght])

    useEffect(() => {
        calculateDividers()
    }, [direction])

    const calculateDividers = () => {
        const dimension = direction == Direction.Vertical ? parent.width : parent.height
        const size = dimension / (dividersLenght + 1)
        setDividers(
            Array.from(
                {
                    length: dividersLenght
                },
                (_, i) => {
                    return {
                        id: i,
                        offset: size * (i + 1)
                    }
                }
            )
        )
    }

    const renderDividers = () => {
        return dividers.map((d) => {
            return (
                <div
                    key={d.id}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: '#fff',
                        borderRadius: 5,
                        padding: 10,
                        width: 'calc(100% - 20px)'
                    }}
                >
                    <div style={{ flex: 1, flexDirection: 'column', textAlign: 'left' }}>
                        <label style={{ flex: 1, fontWeight: 500 }}>ID</label>
                        <p>{d.id}</p>
                    </div>
                    <div style={{ flex: 3, flexDirection: 'column', textAlign: 'right' }}>
                        <label style={{ fontWeight: 500, textAlign: 'right' }}>Quota (mm.) a partire dall{"'"}alto</label>
                        <input
                            value={d.offset}
                            style={{
                                backgroundColor: 'transparent',
                                borderBottom: '1px solid #ddd',
                                borderRadius: 0,
                                flex: 1,
                                textAlign: 'right',
                                padding: '0 5px 5px 0'
                            }}
                            onChange={(e) => {
                                setDividers(
                                    dividers.map((div) => {
                                        if (div.id == d.id) {
                                            return {
                                                ...div,
                                                offset: e.target.value ? parseFloat(e.target.value) : 0
                                            }
                                        } else {
                                            return div
                                        }
                                    })
                                )
                            }}
                        />
                    </div>
                </div>
            )
        })
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 10, width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <label>Vano</label>
                    <Select
                        value={{ id: parent.idName, value: parent.idName, label: parent.idName }}
                        options={props.parents.map((p) => {
                            return { id: p.idName, value: p.idName, label: p.idName }
                        })}
                        placeholder="Seleziona vano"
                        onChange={(e) => {
                            if (e) {
                                const parentFound = props.parents.find((p) => p.idName == e.id)
                                if (parentFound) {
                                    setParent(parentFound)
                                }
                            }
                        }}
                        styles={{
                            valueContainer: (provided) => ({
                                ...provided,
                                backgroundColor: '#fff'
                            }),
                            container: (provided) => ({
                                ...provided,
                                backgroundColor: '#fff',
                                borderRadius: 7,
                                flex: 1
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                fontSize: 18
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: 18
                            }),
                            control: (provided) => ({
                                ...provided,
                                backgroundColor: '#fff',
                                border: 0,
                                padding: '5px 10px',
                                borderRadius: 7
                            })
                        }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <label>Direzione</label>
                    <Select
                        value={{ id: direction, value: direction, label: direction }}
                        options={[
                            { id: Direction.Vertical, value: Direction.Vertical, label: Direction.Vertical },
                            { id: Direction.Horizontal, value: Direction.Horizontal, label: Direction.Horizontal }
                        ]}
                        placeholder="Seleziona direzione"
                        onChange={(e) => {
                            if (e) {
                                setDirection(e.id)
                            }
                        }}
                        styles={{
                            valueContainer: (provided) => ({
                                ...provided,
                                backgroundColor: '#fff'
                            }),
                            container: (provided) => ({
                                ...provided,
                                backgroundColor: '#fff',
                                borderRadius: 7,
                                flex: 1
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                fontSize: 18
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: 18
                            }),
                            control: (provided) => ({
                                ...provided,
                                backgroundColor: '#fff',
                                border: 0,
                                padding: '5px 10px',
                                borderRadius: 7
                            })
                        }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <label>Numero divisori</label>
                    <input
                        type="number"
                        min={0}
                        style={{ backgroundColor: '#fff' }}
                        value={dividersLenght}
                        onChange={(e) => {
                            if (!e.target.value) {
                                setDividersLenght(0)
                                return
                            }

                            setDividersLenght(parseInt(e.target.value))
                        }}
                    />
                </div>
            </div>
            <div className="gridInputOffer" style={{ margin: '20px 0', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                {renderDividers()}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10 }}>
                <Button
                    isSmall
                    text="Aggiungi"
                    hasIcon
                    icon={faPlusSquare}
                    opacity={dividers.length > 0 ? 1 : 0.5}
                    onClick={() => {
                        if (dividers.length > 0) {
                            props.updateItem(dividers, parent, direction)
                        }
                    }}
                />
            </div>
        </div>
    )
}

export default AddDivisorItem
