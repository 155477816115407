import React, { useEffect, useMemo } from 'react'
import SubtitleSection from '../../../components/SubtitleSection'
import Button from '../../../components/Button'
import { faSearch, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { ReducerData, Reducers, ReducerUser } from '../../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { setError, setIsLoading } from '../../../store/actions/graphic'
import { Company } from '../../../types/user'
import { deleteCompany } from '../../../resources/requests/requests'
import { getCompaniesListRedux, setCustomersRedux } from '../../../store/actions/thunk_actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MaterialTable from 'material-table'
import { locale_itIT } from '../../../utilities/tableLocale'
import DeleteModal from '../../../components/modals/DeleteModal'
import EditCompanyModal from '../../../components/modals/EditCompanyModal'

const AziendeScreen: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [filterSearch, setFilterSearch] = useState('')
    const [filteredCompanies, setFilteredCompanies] = useState<Company[]>([])
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null)
    const [isVisibleEditModal, setIsVisibleEditModal] = useState(false)
    const [isVisibleOfferListModal, setIsVisibleOfferListModal] = useState(false)
    const [isVisibleOrderListModal, setIsVisibleOrderListModal] = useState(false)
    const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false)
    const dispatch = useDispatch()

    const renderDatas = useMemo(() => {
        if (filterSearch) {
            return data.companies.filter((a) => a.RagioneSociale.toLowerCase().includes(filterSearch.toLowerCase()))
        } else {
            return data.companies
        }
    }, [data.companies, filterSearch])

    const renderActions = useMemo(() => {
        const actions = [
            () => ({
                icon: 'edit',
                iconProps: {
                    fontSize: 'medium'
                },
                tooltip: 'Modifica azienda',
                onClick: (event: any, rowData: Company) => {
                    setSelectedCompany(rowData)
                    setIsVisibleEditModal(true)
                }
            }),
            () => ({
                icon: 'delete',
                iconProps: {
                    color: 'error',
                    fontSize: 'medium'
                },
                tooltip: 'Elimina azienda',
                onClick: (event: any, rowData: Company) => {
                    setIsVisibleDeleteModal(true)
                    setSelectedCompany(rowData)
                }
            })
        ]

        return actions
    }, [])

    const columns = [
        {
            title: 'ID',
            field: 'ID'
        },
        {
            title: 'Codice',
            field: 'CodAZ'
        },
        {
            title: 'Ragione sociale',
            field: 'RagioneSociale'
        },
        {
            title: 'Email',
            field: 'Email'
        }
    ]

    const handleDelete = async () => {
        if (selectedCompany && selectedCompany.ID) {
            dispatch(setIsLoading(true))
            try {
                await deleteCompany(selectedCompany.ID, dispatch)
                await dispatch(getCompaniesListRedux())
                await dispatch(setCustomersRedux())
                setIsVisibleDeleteModal(false)
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    return (
        <div>
            <div className="introProductsPage">
                <SubtitleSection text="Lista aziende" />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 20 }}>
                    <div className="filterInput">
                        <FontAwesomeIcon icon={faSearch} className="icon" />
                        <input value={filterSearch} onChange={(e) => setFilterSearch(e.target.value)} placeholder="Ricerca aziende..." />
                    </div>
                    <Button
                        text="Aggiungi azienda"
                        hasIcon={true}
                        icon={faUserPlus}
                        onClick={() => {
                            setIsVisibleEditModal(true)
                            setSelectedCompany(null)
                        }}
                    />
                </div>
            </div>
            <div>
                <MaterialTable
                    title=""
                    columns={columns}
                    data={renderDatas}
                    localization={locale_itIT}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        search: false,
                        headerStyle: {
                            backgroundColor: '#ebebeb',
                            borderRadius: 20
                        }
                    }}
                    //@ts-ignore
                    actions={renderActions}
                />
            </div>
            <EditCompanyModal company={selectedCompany} isVisible={isVisibleEditModal} hideModal={() => setIsVisibleEditModal(false)} />
            <DeleteModal
                isVisible={isVisibleDeleteModal}
                hideModal={() => setIsVisibleDeleteModal(false)}
                onDelete={() => handleDelete()}
                title={'Eliminazione cliente'}
                text={'Vuoi davvero eliminare il cliente ' + selectedCompany?.RagioneSociale + '?'}
            />
        </div>
    )
}

export default AziendeScreen
