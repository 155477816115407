import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogContent } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getOfferData, saveCurrentOffer, setAdditionalDatasOffer } from '../../resources/requests/requests'
import { CurrentOffer } from '../../types/order'
import { ReducerOrder, Reducers } from '../../types/reducers'
import Button from '../Button'
import TitlePage from '../TitlePage'
import { useSelector, useDispatch } from 'react-redux'
import { setOfferLoadedRedux } from '../../store/actions/thunk_actions'
import { setLastOfferSeen } from '../../store/actions/user'
import { setError, setIsLoading } from '../../store/actions/graphic'

interface Props {
    isVisible: boolean
    hideModal: () => void
    offerData: CurrentOffer
    isClosed?: boolean
}

const TextOffersModal: React.FC<Props> = (props) => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const offerData = props.offerData
    const [offerTexts, setOfferTexts] = useState({
        startText: props.offerData.TestoIniziale,
        finalText: props.offerData.TestoFinale
    })
    const dispatch = useDispatch()

    useEffect(() => {
        setOfferTexts({
            startText: props.offerData.TestoIniziale,
            finalText: props.offerData.TestoFinale
        })
    }, [props.isVisible])

    const handleSave = async () => {
        dispatch(setIsLoading(true))
        try {
            const bodyAdditional = {
                CodiceOfferta: offerData.CodiceOfferta,
                Descrizione: offerData.Descrizione,
                CantiereInstallazione: offerData.CantiereInstallazione,
                IndirizzoCantiere: offerData.IndirizzoCantiere,
                ProvinciaCantiere: offerData.ProvinciaCantiere,
                ComuneCantiere: offerData.ComuneCantiere,
                CAPCantiere: offerData.CAPCantiere,
                TestoIniziale: offerTexts.startText,
                TestoFinale: offerTexts.finalText,
                DataConsegna: offerData.DataConsegna
            }
            await setAdditionalDatasOffer(bodyAdditional, dispatch)
            await saveCurrentOffer(offerData.CodiceOfferta, dispatch)
            if (!props.isClosed) {
                dispatch(setOfferLoadedRedux(offerData.CodiceOfferta))
            } else {
                const newOfferData = await getOfferData(offerData.CodiceOfferta, dispatch)
                dispatch(setLastOfferSeen(newOfferData))
            }
            props.hideModal()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={'Testi del documento'} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Aggiungi i testi iniziali e finali da allegare al documento</p>
                <textarea
                    style={{
                        width: 'calc(100% - 40px)',
                        borderRadius: 20,
                        border: '0px',
                        resize: 'none',
                        height: 180,
                        padding: 20,
                        fontSize: 16,
                        margin: '20px 0',
                        fontFamily: 'Roboto',
                        outline: 'none'
                    }}
                    placeholder="Testi iniziali documento"
                    value={offerTexts.startText}
                    onChange={(e) => {
                        setOfferTexts({
                            ...offerTexts,
                            startText: e.target.value
                        })
                    }}
                />
                <textarea
                    style={{
                        width: 'calc(100% - 40px)',
                        borderRadius: 20,
                        border: '0px',
                        resize: 'none',
                        height: 180,
                        padding: 20,
                        fontSize: 16,
                        margin: '20px 0',
                        fontFamily: 'Roboto',
                        outline: 'none'
                    }}
                    placeholder="Testi finali documento"
                    value={offerTexts.finalText}
                    onChange={(e) => {
                        setOfferTexts({
                            ...offerTexts,
                            finalText: e.target.value
                        })
                    }}
                />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: '100%' }}>
                    <Button
                        isSmall
                        text="Salva"
                        hasIcon={true}
                        icon={faEdit}
                        onClick={() => {
                            handleSave()
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default TextOffersModal
