import React from 'react'
import SubtitleSection from '../../../components/SubtitleSection'
import UploadCard from '../../../components/UploadCard'
import Button from '../../../components/Button'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import Select from 'react-select'
import { convertArrayToSelect } from '../../../utilities/functions'
import { Editor } from '@tinymce/tinymce-react'
import { ReducerData, Reducers, ReducerUser } from '../../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { Company } from '../../../types/user'
import { setError, setIsLoading } from '../../../store/actions/graphic'
import { addEditAzienda, uploadImage } from '../../../resources/requests/requests'
import { Ambito } from '../../../utilities/Ambito'
import { getCompaniesListRedux, setMyCompany } from '../../../store/actions/thunk_actions'
import TextArea from '../../../components/formComponents/TextArea'

const TipologieClienti = ['Premium', 'Silver', 'Gold']

const Lingue = ['Italiano', 'Inglese', 'Francese']

const StampaScreen: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [currentCompany, setCurrentCompany] = useState<Company | null>(user.company)
    const dispatch = useDispatch()

    //console.log(introduttivoOfferta)

    const handleSave = async () => {
        dispatch(setIsLoading(true))
        try {
            if (currentCompany && user.company) {
                let img = user.company.Logo
                let imgFronte = user.company.CopertinaFronte
                let imgRetro = user.company.CopertinaRetro
                if (currentCompany.Logo.name) {
                    img = await uploadImage(currentCompany.Logo, Ambito.LOGO_AZIENDA, dispatch, undefined, currentCompany.CodAZ)
                }
                if (currentCompany.CopertinaFronte.name) {
                    imgFronte = await uploadImage(currentCompany.CopertinaFronte, Ambito.COPERTINA_AZIENDA_STAMPA, dispatch, undefined, currentCompany.CodAZ)
                }
                if (currentCompany.CopertinaRetro.name) {
                    imgRetro = await uploadImage(currentCompany.CopertinaRetro, Ambito.RETRO_AZIENDA_STAMPA, dispatch, undefined, currentCompany.CodAZ)
                }
                const newCurrentCompany: Company = {
                    ...currentCompany,
                    Logo: img,
                    CopertinaFronte: imgFronte,
                    CopertinaRetro: imgRetro
                }
                await addEditAzienda(newCurrentCompany, dispatch)
                dispatch(setMyCompany())
                dispatch(getCompaniesListRedux())
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <div>
            <div className="introProductsPage">
                <SubtitleSection text="Materiale grafico per stampa" />
                <Button
                    text="Salva i dati"
                    hasIcon={true}
                    icon={faCog}
                    onClick={() => {
                        handleSave()
                    }}
                />
            </div>
            <div className="customCards">
                <UploadCard
                    title="Logo aziendale"
                    defaultImage={currentCompany?.Logo}
                    editImage={(e: any) => {
                        if (currentCompany) {
                            setCurrentCompany({
                                ...currentCompany,
                                Logo: e
                            })
                        }
                    }}
                />
                <UploadCard
                    title="Copertina"
                    defaultImage={currentCompany?.CopertinaFronte}
                    editImage={(e: any) => {
                        if (currentCompany) {
                            setCurrentCompany({
                                ...currentCompany,
                                CopertinaFronte: e
                            })
                        }
                    }}
                />
                <UploadCard
                    title="Retro"
                    defaultImage={currentCompany?.CopertinaRetro}
                    editImage={(e: any) => {
                        if (currentCompany) {
                            setCurrentCompany({
                                ...currentCompany,
                                CopertinaRetro: e
                            })
                        }
                    }}
                />
                <UploadCard
                    title="Colore principale"
                    defaultColor={currentCompany?.color && currentCompany.color.includes('#') ? currentCompany.color : '#005AAB'}
                    editColor={(c) => {
                        if (currentCompany) {
                            setCurrentCompany({
                                ...currentCompany,
                                color: c
                            })
                        }
                    }}
                    isColorPicker={true}
                />
            </div>
            <SubtitleSection text="Testi standard offerte" />

            {/* <div className="selectColumn">
                    <div className="select">
                        <label>Categoria cliente</label>
                        <Select options={convertArrayToSelect(TipologieClienti)} defaultValue={convertArrayToSelect(TipologieClienti)[0]} />
                    </div>
                    <div className="select">
                        <label>Lingua</label>
                        <Select options={convertArrayToSelect(Lingue)} defaultValue={convertArrayToSelect(Lingue)[0]} />
                    </div>
                </div> */}
            {currentCompany ? (
                <div className="textToPrintContainer">
                    <TextArea
                        label="Testo introduttivo offerta"
                        placeholder="Testo introduttivo offerta"
                        onChange={(e) => setCurrentCompany({ ...currentCompany, TestoIntroduttivo: e })}
                        value={currentCompany.TestoIntroduttivo}
                    />
                    <TextArea
                        label="Testo conclusivo offerta"
                        placeholder="Testo conclusivo offerta"
                        onChange={(e) => setCurrentCompany({ ...currentCompany, TestoFinale: e })}
                        value={currentCompany.TestoFinale}
                    />
                </div>
            ) : null}
        </div>
    )
}

export default StampaScreen
