import React, { useEffect, useState } from 'react'
import { CurrentOffer, CurrentOfferQuoteItem, TotaliOfferta, TotaliPreventivo } from '../types/order'
import { ReducerData, ReducerGraphic, ReducerOrder, Reducers, ReducerUser } from '../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { Serie } from '../types/data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faFileExport, faPencilAlt, faRetweet, faTrash } from '@fortawesome/free-solid-svg-icons'
import { convertSale, transformToCurrency } from '../utilities/functions'
import { useHistory } from 'react-router'
import { setLastOfferSeen, setLastProduct } from '../store/actions/user'
import { deleteAllQuotes, deleteOffer, deleteQuoteFromOffer, getOfferData, saveCurrentOffer, switchSeries } from '../resources/requests/requests'
import { setCurrentOffer, setIsPendingOffer } from '../store/actions/order'
import { setError, setIsLoading } from '../store/actions/graphic'
import DeleteModal from '../components/modals/DeleteModal'
import { setOfferLoadedRedux, setOffersRedux } from '../store/actions/thunk_actions'
import TotalPriceModal from './modals/TotalPriceModal'
import SwitchSeriesModal from './modals/SwitchSeriesModal'

interface Props {
    quote: CurrentOfferQuoteItem
    parentOffer: CurrentOffer | null
    offerTotals: TotaliOfferta | null | undefined
    isCurrentOffer?: boolean
    codOffer: string | undefined
    isClosedOffer?: boolean
    setCurrentSerie?: () => void
    recalc?: () => void
    price?: string
    notEdit?: boolean
}

const CurrentOfferListElement: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const quote = props.quote
    const [currentSerie, setCurrentSerie] = useState<Serie>()
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [showTotalPriceModal, setShowTotalPriceModal] = useState(false)
    const [showSwitchSeriesModal, setShowSwitchSeriesModal] = useState(false)
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        const seriesFound = data.series.find((s) => s.CodSerie == quote.ItemsList[0].CodiceSerie)
        setCurrentSerie(seriesFound)
    }, [props.quote])

    const renderFirstColumn = () => {
        return (
            <div>
                <img src={currentSerie?.ImgMain} style={{ width: '10vw', height: '10vw', borderRadius: 10 }} />
            </div>
        )
    }

    const renderSecondColumn = () => {
        let totalQuantity = 0
        const nameTipologies = quote.ItemsList.filter((c) => c.IDComposta == null).map((i) => {
            totalQuantity += i.Quantita
            return i.DescrizioneInterneDB
        })
        const uniqueTipologies = nameTipologies.filter((c, index) => {
            return nameTipologies.indexOf(c) === index
        })
        return (
            <div style={{ flex: 5 }}>
                <h2 style={{ color: '#68696D', fontWeight: 500, fontSize: 28 }}>
                    Prodotto: <b>{currentSerie?.NomeSerie}</b>
                </h2>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'space-between' }}>
                    <p style={{ color: '#68696D' }}>Tipologie: {uniqueTipologies.toString().replace(',', ', ')}</p>
                    <p style={{ color: '#68696D' }}>Quantità: {totalQuantity}</p>
                </div>
            </div>
        )
    }

    const deleteItem = async () => {
        dispatch(setIsLoading(true))
        try {
            if (props.parentOffer && props.parentOffer.Preventivi.length > 1) {
                await deleteQuoteFromOffer(quote.CodicePreventivo, dispatch)
                await saveCurrentOffer(props.parentOffer.CodiceOfferta, dispatch)
                dispatch(
                    setCurrentOffer({
                        ...props.parentOffer,
                        Preventivi: props.parentOffer.Preventivi.filter((p) => p.CodicePreventivo !== quote.CodicePreventivo)
                    })
                )
            } else {
                await deleteAllQuotes(dispatch)
                dispatch(setCurrentOffer(null))
                dispatch(setIsPendingOffer(false))
                if (props.parentOffer) {
                    await deleteOffer(props.parentOffer.CodiceOfferta, dispatch)
                    await saveCurrentOffer(props.parentOffer.CodiceOfferta, dispatch)
                    //dispatch(setOffersRedux())
                }
                history.push('/')
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    const renderThirdColumn = () => {
        if (props.offerTotals) {
            const quoteFound = props.offerTotals.DettaglioPreventivi.find((p) => p.CodicePreventivo == quote.CodicePreventivo)
            if (quoteFound) {
                return (
                    <div style={{ flex: 2, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                        {!props.notEdit && (
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 30 }}>
                                {props.parentOffer?.statoPreventivo == 'In corso' || props.parentOffer?.statoPreventivo == 'in uso' || props.isCurrentOffer ? (
                                    <FontAwesomeIcon
                                        icon={faRetweet}
                                        style={{ fontSize: 28, color: graphic.colorAccent, cursor: 'pointer' }}
                                        onClick={() => {
                                            setShowSwitchSeriesModal(true)
                                        }}
                                    />
                                ) : null}
                                {props.parentOffer?.statoPreventivo == 'In corso' || props.parentOffer?.statoPreventivo == 'in uso' || props.isCurrentOffer ? (
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        style={{ fontSize: 28, color: graphic.colorAccent, cursor: 'pointer' }}
                                        onClick={() => {
                                            if (currentSerie) {
                                                dispatch(setLastProduct(currentSerie))
                                                if (props.isClosedOffer && props.setCurrentSerie) {
                                                    props.setCurrentSerie()
                                                    return
                                                }
                                                history.push('/quote')
                                            }
                                        }}
                                    />
                                ) : null}
                                {props.parentOffer?.statoPreventivo == 'In corso' || props.parentOffer?.statoPreventivo == 'in uso' || props.isCurrentOffer ? (
                                    <FontAwesomeIcon
                                        icon={faTrash}
                                        style={{ fontSize: 24, color: '#E00000', cursor: 'pointer' }}
                                        onClick={() => {
                                            setIsDeleteModalVisible(true)
                                        }}
                                    />
                                ) : null}
                            </div>
                        )}
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, marginTop: 10, justifyContent: 'flex-start', alignItems: 'flex-end' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center' }}>
                                <h3 style={{ color: '#68696D', fontSize: 28 }}>
                                    Totale: <span style={{ color: graphic.colorAccent }}>{transformToCurrency(parseFloat(quoteFound!.ImportoTotale))}</span>
                                </h3>
                                {!props.notEdit && (props.parentOffer?.statoPreventivo == 'In corso' || props.parentOffer?.statoPreventivo == 'in uso') && (
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        style={{ fontSize: 28, color: graphic.colorAccent, cursor: 'pointer' }}
                                        onClick={() => {
                                            setShowTotalPriceModal(true)
                                        }}
                                    />
                                )}
                            </div>
                            <span style={{ color: '#68696D' }}>
                                Sconto - ({' '}
                                {convertSale(quoteFound!.Sconto)[1] !== '0'
                                    ? `${convertSale(quoteFound!.Sconto)[0]}+${convertSale(quoteFound!.Sconto)[1]}%`
                                    : `${quoteFound!.Sconto}%`}
                                ): <b>{transformToCurrency(parseFloat(quoteFound!.ScontoApplicato))}</b>
                            </span>
                            <span style={{ color: '#68696D' }}>
                                Spese interne: <b>{transformToCurrency(parseFloat(quoteFound!.AltreSpese))}</b>
                            </span>
                            <span style={{ color: '#68696D' }}>
                                Spese accessorie: <b>{transformToCurrency(parseFloat(quoteFound!.SpeseAcc))}</b>
                            </span>
                            <span style={{ color: '#68696D' }}>IVA esclusa</span>
                        </div>
                    </div>
                )
            }
        }
    }

    const handleSwitchSerie = async (codSerie: string) => {
        dispatch(setIsLoading(true))
        try {
            if (props.parentOffer) {
                const dataReturn = await switchSeries(props.parentOffer.CodiceOfferta, quote.CodicePreventivo, codSerie, dispatch)
                if (dataReturn && dataReturn > 0) {
                    await saveCurrentOffer(props.parentOffer.CodiceOfferta, dispatch)
                    if (order.pricesTotalOffer?.CodiceOfferta == props.parentOffer?.CodiceOfferta) {
                        await dispatch(setOfferLoadedRedux(props.parentOffer.CodiceOfferta))
                    } else {
                        const offer = await getOfferData(props.parentOffer.CodiceOfferta, dispatch)
                        await dispatch(setLastOfferSeen(offer))
                    }
                    if (props.recalc) {
                        props.recalc()
                    }
                    history.push('/current-offer')
                    setShowSwitchSeriesModal(false)
                    //dispatch(setOffersRedux())
                } else {
                    dispatch(setError('Impossibile effettuare questo cambio di serie.'))
                }
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <div className="cardCustomTypology" style={{ gap: 20, alignItems: 'center' }}>
            {renderFirstColumn()}
            {renderSecondColumn()}
            {renderThirdColumn()}
            <DeleteModal
                isVisible={isDeleteModalVisible}
                hideModal={() => setIsDeleteModalVisible(false)}
                onDelete={() => {
                    deleteItem()
                    setIsDeleteModalVisible(false)
                }}
                text={'Cancella il preventivo corrente (ID ' + quote.CodicePreventivo + ')'}
                title={'Elimina preventivo'}
            />
            <SwitchSeriesModal
                isVisible={showSwitchSeriesModal}
                codPreventivo={quote.CodicePreventivo}
                codSerie={currentSerie?.CodSerie}
                onSave={(codSerie) => {
                    handleSwitchSerie(codSerie)
                }}
                hideModal={() => setShowSwitchSeriesModal(false)}
            />
            {!props.notEdit && (
                <TotalPriceModal
                    isVisible={showTotalPriceModal}
                    codicePreventivo={quote.CodicePreventivo}
                    currentSerie={currentSerie ? currentSerie : null}
                    currentOffer={props.parentOffer}
                    hideModal={async (changed) => {
                        setShowTotalPriceModal(false)
                        if (changed) {
                            dispatch(setOfferLoadedRedux(props.parentOffer?.CodiceOfferta))
                            if (props.recalc) {
                                props.recalc()
                            }
                        }
                    }}
                    currentQuote={props.offerTotals && props.offerTotals.DettaglioPreventivi.find((p) => p.CodicePreventivo == quote.CodicePreventivo)}
                />
            )}
        </div>
    )
}

export default CurrentOfferListElement
