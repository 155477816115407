import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Agente, Cliente, OfferArchive, OrderArchive } from '../../types/data'
import { Dialog, DialogContent, } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import TitlePage from '../TitlePage'
import { ReducerData, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import OrdersListItem from '../OrdersListItem'

interface Props {
    orders: OrderArchive[]
    customer?: Cliente | null
    agent?: Agente | null
    isVisible: boolean
    hideModal: () => void
}

const OrdersListModal: React.FC<Props> = (props) => {

    const renderList = () => {
        return props.orders.map((o) => {
            return (
                <OrdersListItem
                    key={o.ID}
                    item={o}
                    backgroundColor='#fff'
                />
            )
        })
    }

    const renderTitle = () => {
        if (props.customer) {
            return (
                <div>
                    <TitlePage text={'Ordini di ' + props.customer?.nominativo} style={{ margin: 0 }} />
                    <p style={{ fontSize: 24, color: "#68696D", margin: 0 }}>Gli ordini relative al cliente {props.customer?.nominativo}</p>
                </div>
            )
        }
        if (props.agent) {
            return (
                <div>
                    <TitlePage text={'Ordini di ' + props.agent?.DenAg} style={{ margin: 0 }} />
                    <p style={{ fontSize: 24, color: "#68696D", margin: 0 }}>Gli ordini relativi al cliente {props.agent?.DenAg}</p>
                </div>
            )
        }
        return null
    }

    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="lg" className="overflowVisible" onBackdropClick={() => props.hideModal()} >
            <DialogContent style={{ padding: 20, backgroundColor: "#ebebeb", position: 'relative', borderRadius: 10 }}>
                <FontAwesomeIcon icon={faTimes} style={{ position: 'absolute', top: 10, right: 10, color: "#68696D", fontSize: 17, cursor: 'pointer' }} onClick={() => props.hideModal()} />
                {renderTitle()}
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: "20px 0", maxHeight: '50vh' }}>
                    {props.orders.length > 0 ? renderList() : (
                        <p style={{ fontSize: 20, fontStyle: 'italic', color: "#68696D" }}>Non sono presenti ordini collegati a questo nominativo</p>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default OrdersListModal