import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Reducers, ReducerUser } from '../types/reducers'
import { useSelector } from 'react-redux'

interface Props {
    text: string
    hasIcon?: boolean
    icon?: IconProp
    buttonColor?: string
    opacity?: number
    onClick: () => void
    hasUpload?: boolean
    disableUpload?: boolean
    onChange?: (e: File) => void
    accept?: string
    isSmall?: boolean
    disabled?: boolean
}

const Button: React.FC<Props> = (props) => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [color, setCurrentColor] = useState('#005AAB')

    useEffect(() => {
        if (user.company && user.company.color && user.company.color.includes('#')) {
            setCurrentColor(user.company.color)
        } else {
            setCurrentColor('#005AAB')
        }
    }, [user.company])

    if (props.hasUpload) {
        return (
            <div
                className="button"
                style={{
                    backgroundColor: props.buttonColor ? props.buttonColor : color,
                    opacity: props.opacity ? props.opacity : 1,
                    padding: props.isSmall ? '10px 20px' : '15px 25px'
                }}
            >
                {!props.disableUpload && (
                    <input
                        type="file"
                        onChange={(event) => {
                            if (props.onChange && event?.target && event.target.files && event.target.files.length > 0) {
                                props?.onChange(event.target.files[0])
                            }
                        }}
                        accept={props.accept ? props.accept : 'image/jpeg, image/jpg'}
                    />
                )}
                <FontAwesomeIcon icon={props?.icon ? props.icon : faCheck} className="icon" style={{ fontSize: props.isSmall ? 18 : 24 }} />
                <span style={{ fontSize: props.isSmall ? 15 : 20 }}>{props.text}</span>
            </div>
        )
    }

    if (props.hasIcon) {
        return (
            <div
                className="button"
                style={{
                    backgroundColor: props.buttonColor ? props.buttonColor : color,
                    opacity: props.opacity ? props.opacity : 1,
                    padding: props.isSmall ? '10px 20px' : '15px 25px'
                }}
                onClick={() => {
                    if (!props.disabled) {
                        props.onClick()
                    }
                }}
            >
                <FontAwesomeIcon icon={props?.icon ? props.icon : faCheck} className="icon" style={{ fontSize: props.isSmall ? 18 : 24 }} />
                {props.text ? <span style={{ fontSize: props.isSmall ? 15 : 20 }}>{props.text}</span> : null}
            </div>
        )
    }

    return (
        <div
            className="button"
            style={{
                backgroundColor: props.buttonColor ? props.buttonColor : color,
                opacity: props.opacity ? props.opacity : 1,
                padding: props.isSmall ? '10px 20px' : '15px 25px'
            }}
            onClick={() => {
                if (!props.disabled) {
                    props.onClick()
                }
            }}
        >
            <span style={{ fontSize: props.isSmall ? 15 : 20 }}>{props.text}</span>
        </div>
    )
}

export default Button
