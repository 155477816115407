import { Page, View, Text, Font } from '@react-pdf/renderer'
import React from 'react'
import { PrintPage } from '../../types/user'
import { transformToCurrency } from '../../utilities/functions'
import { PrintType } from '../../utilities/PrintType'
import FooterPrint from './FooterPrint'
import HeaderPrint from './HeaderPrint'
import { styles } from './styles'

interface Props {
    printFile: PrintPage
}

const PricesPage: React.FC<Props> = (props) => {
    const printFile = props.printFile

    Font.register({
        family: 'Roboto',
        fonts: [
            { src: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/Roboto-Regular.ttf' },
            { src: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/Roboto-Bold.ttf', fontStyle: 'bold', fontWeight: 700 }
        ]
    })

    const renderPricesProdutcs = () => {
        return printFile.items.map((i) => {
            return (
                <View key={i.codSerie} wrap={false} style={{ backgroundColor: '#ebebeb', padding: 10, borderRadius: 5, marginTop: 10, height: 90 }}>
                    <View
                        style={{
                            backgroundColor: printFile.color ? printFile.color : '#005AAB',
                            width: '100%',
                            padding: 5,
                            flexDirection: 'row',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Text style={{ color: '#fff', fontSize: 12, fontFamily: 'Roboto', margin: 0, padding: 0 }}>
                            {i.name} - {i.TotalPz} pezzi (Totale {i.TotalSurfaceMQ} mq.)
                        </Text>
                        {printFile.printType !== PrintType.ONLY_FINAL && printFile.printType !== PrintType.NO_PRICES ? (
                            <Text style={{ color: '#fff', fontSize: 12, fontFamily: 'Roboto', margin: 0, padding: 0 }}>
                                Prezzo: {transformToCurrency(i.totalNoSale)}
                            </Text>
                        ) : null}
                    </View>
                    {i.saleEuro && printFile.printType !== PrintType.ONLY_FINAL && printFile.printType !== PrintType.NO_PRICES ? (
                        <View style={{ width: '100%', padding: 5, flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <Text style={{ color: '#68696D', fontSize: 12, fontFamily: 'Roboto', marginBottom: 0 }}>
                                Sconto Serie - ( {i.saleformula}%): {i.price ? transformToCurrency(i.saleEuro) : ''}
                            </Text>
                        </View>
                    ) : null}
                    {printFile.printType !== PrintType.NO_PRICES ? (
                        <View style={{ width: '100%', padding: 5, flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <Text style={{ color: '#68696D', fontSize: 12, fontFamily: 'Roboto', marginTop: 0 }}>
                                Prezzo totale Serie: {i.price ? transformToCurrency(i.price) : ''}
                            </Text>
                        </View>
                    ) : null}
                </View>
            )
        })
    }

    const renderCostsProduct = () => {
        let totalAdditionalCosts = 0
        return (
            <View style={{ backgroundColor: '#ebebeb', padding: 10, borderRadius: 5 }}>
                {printFile.additionalCosts.map((c, i) => {
                    totalAdditionalCosts += parseFloat(c.price)
                    return (
                        <View
                            key={c.name + i}
                            style={{
                                backgroundColor: '#68696d',
                                width: '100%',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                paddingHorizontal: 3,
                                paddingVertical: 3,
                                marginVertical: 5,
                                height: 25,
                                alignItems: 'center'
                            }}
                        >
                            <Text style={{ color: '#fff', fontSize: 12, fontFamily: 'Roboto', margin: 0, padding: 0 }}>{c.name}</Text>
                            <Text style={{ color: '#fff', fontSize: 12, fontFamily: 'Roboto', margin: 0, padding: 0 }}>
                                Prezzo: {transformToCurrency(parseFloat(c.price))}
                            </Text>
                        </View>
                    )
                })}
                <View style={{ width: '100%', padding: 5, flexDirection: 'row', justifyContent: 'flex-end' }}>
                    <Text style={{ color: '#68696D', fontSize: 12, fontFamily: 'Roboto', marginTop: 0, marginBottom: 10 }}>
                        Prezzo totale Spese addizionali: {transformToCurrency(totalAdditionalCosts)}
                    </Text>
                </View>
            </View>
        )
    }

    if (printFile) {
        return (
            <Page size="A4" style={{ display: 'flex', flexDirection: 'column' }}>
                <HeaderPrint printFile={printFile} />
                <View style={{ width: '100%' }}>
                    <View style={{ paddingHorizontal: 20, paddingVertical: 10 }}>
                        <Text style={{ ...styles.title }}>Prezzi</Text>
                        <View>
                            <View wrap={false} style={{ marginVertical: 10, display: 'flex', flexDirection: 'column' }}>
                                {renderPricesProdutcs()}
                            </View>
                            <View
                                wrap={false}
                                style={{
                                    height: printFile.additionalCosts.length * 35 + 40
                                }}
                            >
                                {renderCostsProduct()}
                            </View>
                            <View wrap={false} style={{ width: '100%', padding: 5, flexDirection: 'row', justifyContent: 'flex-end', height: 100 }}>
                                <Text style={styles.subtitle}>
                                    Prezzo imponibile offerta: {transformToCurrency(parseFloat(printFile.price) - parseFloat(printFile.tax[0].price))}
                                </Text>
                            </View>
                            <View
                                wrap={false}
                                break={printFile.items.length > 3 || printFile.additionalCosts.length > 4 ? true : false}
                                style={{
                                    backgroundColor: '#68696d',
                                    width: '100%',
                                    height: 30,
                                    padding: 5,
                                    marginTop: 3,
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Text style={{ color: '#fff', fontSize: 12, fontFamily: 'Roboto', margin: 0, padding: 0 }}>{printFile.tax[0].name}:</Text>
                                <Text style={{ color: '#fff', fontSize: 12, fontFamily: 'Roboto', margin: 0, padding: 0 }}>
                                    Prezzo: {transformToCurrency(parseFloat(printFile.tax[0].price))}
                                </Text>
                            </View>
                            <View wrap={false} style={{ width: '100%', padding: 5, marginTop: 10, flexDirection: 'row', justifyContent: 'flex-end' }}>
                                <Text style={styles.subtitle}>Prezzo finale offerta: {transformToCurrency(parseFloat(printFile.price))}</Text>
                            </View>
                        </View>
                    </View>
                </View>
                <FooterPrint printFile={printFile} />
            </Page>
        )
    }

    return null
}

export default PricesPage
