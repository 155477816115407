import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'
import { Database } from '../../types/data'
import Input from '../formComponents/Input'
import { ReducerGraphic, Reducers } from '../../types/reducers'
import { useSelector } from 'react-redux'

interface Props {
    backup: Database
    isVisible: boolean
    hideModal: () => void
    onReset: (codOffer: string) => void
}

const ResetOfferFromBackup: React.FC<Props> = (props) => {
    const [codOffer, setCodOffer] = useState('')
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)

    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md" onBackdropClick={() => props.hideModal()}>
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <TitlePage text={`Reset offerta da backup ${props.backup.NomeBackup}`} style={{ margin: 0 }} />
                <Input
                    divStyle={{ flexDirection: 'column', display: 'flex', margin: '20px 0' }}
                    value={codOffer}
                    onChange={(e) => setCodOffer(e)}
                    placeholder="Inserisci codice offerta"
                    label="Codice offerta"
                />
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Annulla"
                    buttonColor="#52565A"
                    onClick={() => {
                        props.hideModal()
                    }}
                />
                <Button
                    isSmall
                    text="Ripristina"
                    buttonColor={graphic.colorAccent}
                    onClick={() => {
                        props.onReset(codOffer)
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default ResetOfferFromBackup
