import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'
import { Serie } from '../../types/data'
import { useDispatch, useSelector } from 'react-redux'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { getUpsellingElements, saveUpsellingSelected } from '../../resources/requests/quote'
import ProductCard from '../ProductCard'
import { ReducerGraphic, Reducers } from '../../types/reducers'
import ConfirmModal from './ConfirmModal'

interface Messages {
    message: string
    detailMessage: string[]
}

interface Props {
    codOffer: string
    codPreventivo: string
    isVisible: boolean
    hideModal: () => void
    save: () => void
}

const UpsellingModal: React.FC<Props> = (props) => {
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [elements, setElements] = useState<Serie[]>([])
    const [selectedSerie, setSelectedSerie] = useState<Serie | null>(null)
    const [messages, setMessages] = useState<Messages | null>(null)
    const dispatch = useDispatch()

    const fetchSeries = async () => {
        dispatch(setIsLoading(true))
        try {
            const series = await getUpsellingElements(props.codPreventivo, dispatch)
            setElements(series)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    console.log(messages)

    const addSerieToOffer = async () => {
        if (selectedSerie) {
            dispatch(setIsLoading(true))
            try {
                const messagesFetch = await saveUpsellingSelected(props.codOffer, props.codPreventivo, selectedSerie.CodSerie, dispatch)
                if (messagesFetch) {
                    setMessages(messagesFetch)
                }
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    useEffect(() => {
        if (props.isVisible) {
            fetchSeries()
        }
    }, [props.isVisible])

    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="lg" onBackdropClick={() => props.hideModal()}>
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <TitlePage text="Prodotti correlati" />
                <div className="variantGrid" style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))' }}>
                    {elements.map((p) => {
                        return (
                            <ProductCard
                                key={p.ID}
                                isSelectable
                                isSelected={selectedSerie !== null && selectedSerie.CodSerie == p.CodSerie}
                                product={p}
                                onClick={() => setSelectedSerie(p)}
                            />
                        )
                    })}
                </div>
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Non sono interessato"
                    buttonColor="#52565A"
                    onClick={() => {
                        props.hideModal()
                    }}
                />
                <Button
                    isSmall
                    text="Aggiungi"
                    opacity={selectedSerie ? 1 : 0.6}
                    buttonColor={graphic.colorAccent}
                    onClick={() => {
                        if (selectedSerie) {
                            addSerieToOffer()
                        }
                    }}
                />
            </DialogActions>
            <ConfirmModal
                isOnlyConfirm
                onConfirm={() => {
                    setMessages(null)
                    props.save()
                }}
                hideModal={() => {
                    setMessages(null)
                    props.save()
                }}
                title={messages ? messages.message : ''}
                isVisible={messages !== null}
                text={messages && messages.detailMessage.length > 0 ? messages.detailMessage.toString() : ''}
            />
        </Dialog>
    )
}

export default UpsellingModal
