import React, { useEffect, useState } from 'react'
import TitlePage from '../../components/TitlePage'
import { ReducerData, ReducerGraphic, ReducerOrder, Reducers, ReducerUser } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../components/Button'
import { faBuilding, faCalendar, faEdit, faFileExport, faPencilAlt, faPrint, faTag, faTrash } from '@fortawesome/free-solid-svg-icons'
import CurrentOfferListElement from '../../components/CurrentOfferListElement'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { convertSale, transformToCurrency } from '../../utilities/functions'
import { createPDFToPrint, createXMLToDownload, deleteAllQuotes, deleteOffer, getTotalPricesOffer, saveCurrentOffer } from '../../resources/requests/requests'
import { saveAs } from 'file-saver'
import { setCurrentOffer } from '../../store/actions/order'
import { useHistory } from 'react-router'
import { setOffersRedux } from '../../store/actions/thunk_actions'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { TotaliOfferta } from '../../types/order'
import { setPrintFile } from '../../store/actions/user'
import TextOffersModal from '../../components/modals/TextOffersModal'
import WorksiteAddressModal from '../../components/modals/WorksiteAddressModal'
import { setError, setIsLoading } from '../../store/actions/graphic'
import DeleteModal from '../../components/modals/DeleteModal'
import PrintSettingsModal from '../../components/modals/PrintSettingsModal'
import { PrintType } from '../../utilities/PrintType'
import DeliveryDateSelectModal from '../../components/modals/DeliveryDateSelectModal'
import ConfirmModal from '../../components/modals/ConfirmModal'
import { BASE_URL, BASE_URL_FRONTEND, SUB_PATH_URL_FRONTEND } from '../../resources/constants'
require('moment/min/locales')
moment.locale('it')

const OfferDetail: React.FC = () => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [modalCurrentOfferVisible, setModalCurrentOfferVisible] = useState(false)
    const [isOffersTextModalVisible, setIsOffersTextModalVisible] = useState(false)
    const [isWorksiteModalVisible, setIsWorksiteModalVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [visiblePrintSettingsModal, setVisiblePrintSettingsModal] = useState(false)
    const [isSelectDateVisible, setIsSelectDateVisible] = useState(false)
    const [isConfirmVisible, setIsConfirmVisible] = useState('')
    const [offerPrices, setOfferPrices] = useState<TotaliOfferta | null>()
    const dispatch = useDispatch()
    const history = useHistory()
    const offerFind = data.offers.find((o) => o.CodiceOfferta == user.lastOfferSeen?.CodiceOfferta)

    const handlePrintOffer = async (type: PrintType) => {
        dispatch(setIsLoading(true))
        try {
            const filePdf = await createPDFToPrint(user.lastOfferSeen?.CodiceOfferta, dispatch)
            dispatch(setPrintFile({ ...filePdf, printType: type }))
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                window.open('/print', '_blank')
            }
             else {
                //window.open(`${BASE_URL}biemme_genera/panel/print`, '_blank')
                //window.open(`${BASE_URL}${SUB_PATH_URL_FRONTEND}print`, '_blank')
                window.open(
                    `${process.env.REACT_APP_RELEASE_ENV == 'production' ? BASE_URL_FRONTEND : BASE_URL_FRONTEND + SUB_PATH_URL_FRONTEND}print`,
                            '_blank'
                )
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    const handleDownloadXML = async () => {
        dispatch(setIsLoading(true))
        try {
            const fileXML = await createXMLToDownload(user.lastOfferSeen?.CodiceOfferta, dispatch)
            if (fileXML && user.lastOfferSeen) {
                saveAs(fileXML, user.lastOfferSeen.CodiceOfferta + '.zip')
                setIsConfirmVisible(user.lastOfferSeen.CodiceOfferta)
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handleDeleteOffer = async () => {
        dispatch(setIsLoading(true))
        try {
            if (user.lastOfferSeen) {
                await deleteOffer(user.lastOfferSeen.CodiceOfferta, dispatch)
                //dispatch(setOffersRedux())
            }
            history.push('/offers')
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const getTotalPrices = async () => {
        dispatch(setIsLoading(true))
        try {
            const prices = await getTotalPricesOffer(user.lastOfferSeen?.CodiceOfferta, dispatch)
            setOfferPrices(prices)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    useEffect(() => {
        //getTotalPrices()
    }, [])

    const renderModalCurrentOfferVisible = () => {
        return (
            <Dialog open={modalCurrentOfferVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md">
                <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                    <TitlePage text={'Offerta in sospeso'} style={{ margin: 0 }} />
                    <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>
                        Hai un{"'"}offerta in sospeso.
                        <br />
                        Prima di proseguire con la visualizzazione del dettaglio dell{"'"}offerta {user.lastOfferSeen?.CodiceOfferta} cosa vorresti fare?
                    </p>
                </DialogContent>
                <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                    <Button
                        isSmall
                        text="Annulla operazione"
                        buttonColor="#52565A"
                        onClick={() => {
                            setModalCurrentOfferVisible(false)
                        }}
                    />
                    <Button
                        isSmall
                        text="Elimina offerta corrente"
                        buttonColor="#E00000"
                        onClick={async () => {
                            dispatch(setIsLoading(true))
                            try {
                                await deleteAllQuotes(dispatch)
                                dispatch(setCurrentOffer(user.lastOfferSeen))
                                history.push('/quote')
                            } catch (error: any) {
                                dispatch(setError(error.toString()))
                            }
                            dispatch(setIsLoading(false))
                        }}
                    />
                    <Button
                        isSmall
                        text="Salva offerta corrente"
                        onClick={async () => {
                            dispatch(setIsLoading(true))
                            try {
                                await saveCurrentOffer(order.currentOffer?.CodiceOfferta, dispatch)
                                //await dispatch(setOffersRedux())
                                dispatch(setCurrentOffer(user.lastOfferSeen))
                                history.push('/quote')
                            } catch (error: any) {
                                dispatch(setError(error.toString()))
                            }

                            dispatch(setIsLoading(false))
                        }}
                    />
                </DialogActions>
            </Dialog>
        )
    }

    const renderAddress = () => {
        if (user.lastOfferSeen) {
            if (user.lastOfferSeen.ComuneCantiere && user.lastOfferSeen.IndirizzoCantiere && user.lastOfferSeen.ProvinciaCantiere) {
                return (
                    <p style={{ color: '#68696D' }}>
                        Cantiere di installazione:{' '}
                        <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                            {user.lastOfferSeen.IndirizzoCantiere} - {user.lastOfferSeen.ComuneCantiere} ({user.lastOfferSeen.ProvinciaCantiere})
                        </span>
                    </p>
                )
            } else {
                return <p style={{ color: '#68696D' }}>Cantiere di installazione: </p>
            }
        }
        return <p style={{ color: '#68696D' }}>Cantiere di installazione: </p>
    }

    const calculatedIva = () => {
        let tax = 0
        offerPrices?.DettaglioPreventivi.forEach((p) => {
            tax += parseFloat(p.Iva)
        })
        return transformToCurrency(tax)
    }

    //console.log(offerPrices)

    return (
        <div>
            {renderModalCurrentOfferVisible()}
            {user.lastOfferSeen ? <TitlePage text="Offerta " dynamicText={user.lastOfferSeen.CodiceOfferta} /> : <TitlePage text="Nessuna offerta presente" />}
            {user.lastOfferSeen && (
                <div className="scrollableDiv" style={{ maxHeight: '75vh' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 20 }}>
                        <Button
                            text="Stampa offerta"
                            hasIcon={true}
                            icon={faPrint}
                            onClick={() => {
                                setVisiblePrintSettingsModal(true)
                            }}
                            buttonColor="#68696D"
                        />
                        <Button
                            text="Scarica file specifiche"
                            hasIcon={true}
                            icon={faFileExport}
                            onClick={() => {
                                handleDownloadXML()
                            }}
                            buttonColor="#68696D"
                        />
                        <Button
                            text="Elimina offerta"
                            hasIcon={true}
                            icon={faTrash}
                            onClick={async () => {
                                setIsDeleteModalVisible(true)
                            }}
                            buttonColor="#E00000"
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '20px 0' }}>
                        {user.lastOfferSeen.Preventivi.map((p) => {
                            return (
                                <CurrentOfferListElement
                                    key={p.CodicePreventivo}
                                    quote={p}
                                    parentOffer={user.lastOfferSeen}
                                    offerTotals={order.pricesTotalOffer}
                                    codOffer={user.lastOfferSeen?.CodiceOfferta}
                                    isClosedOffer
                                    recalc={() => {
                                        getTotalPrices()
                                    }}
                                    price={order.pricesTotalOffer?.DettaglioPreventivi.find((price) => p.CodicePreventivo == price.CodicePreventivo)?.Totale}
                                    setCurrentSerie={() => {
                                        dispatch(setCurrentOffer(user.lastOfferSeen))
                                        history.push('/quote')
                                    }}
                                    notEdit={false}
                                />
                            )
                        })}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 3, gap: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faCalendar} style={{ fontSize: 30, color: '#68696D' }} />
                                <p style={{ color: '#68696D' }}>
                                    Consegna richiesta entro:{' '}
                                    <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                        {user.lastOfferSeen.DataConsegna
                                            ? moment(user.lastOfferSeen.DataConsegna).format('DD MMMM YYYY')
                                            : moment().format('DD MMMM YYYY')}
                                    </span>
                                </p>
                                <FontAwesomeIcon
                                    icon={faPencilAlt}
                                    style={{ fontSize: 20, color: graphic.colorAccent, cursor: 'pointer' }}
                                    onClick={() => setIsSelectDateVisible(true)}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faEdit} style={{ fontSize: 30, color: '#68696D' }} />
                                <p style={{ color: '#68696D' }}>Testi iniziali e finali del documento</p>
                                {offerFind?.statoPreventivo == 'In corso' && (
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        style={{ fontSize: 20, color: graphic.colorAccent, cursor: 'pointer' }}
                                        onClick={() => setIsOffersTextModalVisible(true)}
                                    />
                                )}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faBuilding} style={{ fontSize: 30, color: '#68696D' }} />
                                {renderAddress()}
                                {offerFind?.statoPreventivo == 'In corso' && (
                                    <FontAwesomeIcon
                                        icon={faPencilAlt}
                                        style={{ fontSize: 20, color: graphic.colorAccent, cursor: 'pointer' }}
                                        onClick={() => setIsWorksiteModalVisible(true)}
                                    />
                                )}
                            </div>
                        </div>
                        {order.pricesTotalOffer && order.pricesTotalOffer.CodiceOfferta == user?.lastOfferSeen.CodiceOfferta ? (
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 0.9, alignItems: 'flex-end', gap: 10 }}>
                                <p
                                    style={{
                                        color: '#68696D',
                                        fontWeight: 500,
                                        fontSize: 22,
                                        paddingBottom: 15,
                                        borderBottom: '1px solid #ccc',
                                        padding: '0 20px',
                                        alignSelf: 'stretch',
                                        textAlign: 'right'
                                    }}
                                >
                                    Totale imponibile:{' '}
                                    <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                        {order.pricesTotalOffer?.Totale &&
                                            transformToCurrency(
                                                parseFloat(order.pricesTotalOffer?.Totale) + parseFloat(order.pricesTotalOffer.ScontoApplicato)
                                            )}
                                    </span>
                                </p>
                                <p
                                    style={{
                                        color: '#68696D',
                                        fontWeight: 500,
                                        fontSize: 22,
                                        paddingBottom: 15,
                                        borderBottom: '1px solid #ccc',
                                        padding: '0 20px',
                                        alignSelf: 'stretch',
                                        textAlign: 'right'
                                    }}
                                >
                                    Sconto - ({' '}
                                    {convertSale(order.pricesTotalOffer!.Sconto)[1] !== '0'
                                        ? `${order.pricesTotalOffer && convertSale(order.pricesTotalOffer!.Sconto)[0]}+${
                                              convertSale(order.pricesTotalOffer!.Sconto)[1]
                                          }%`
                                        : `${order.pricesTotalOffer!.Sconto}%`}
                                    ):{' '}
                                    <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                        {order.pricesTotalOffer?.ScontoApplicato
                                            ? '- ' + transformToCurrency(parseFloat(order.pricesTotalOffer?.ScontoApplicato))
                                            : '- ' + transformToCurrency(0)}
                                    </span>
                                </p>
                                <p
                                    style={{
                                        color: '#68696D',
                                        fontWeight: 500,
                                        fontSize: 22,
                                        paddingBottom: 15,
                                        borderBottom: '1px solid #ccc',
                                        padding: '0 20px',
                                        alignSelf: 'stretch',
                                        textAlign: 'right'
                                    }}
                                >
                                    Spese interne:{' '}
                                    <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                        {order.pricesTotalOffer?.AltreSpese
                                            ? transformToCurrency(parseFloat(order.pricesTotalOffer?.AltreSpese))
                                            : transformToCurrency(0)}
                                    </span>
                                </p>
                                <p
                                    style={{
                                        color: '#68696D',
                                        fontWeight: 500,
                                        fontSize: 22,
                                        paddingBottom: 15,
                                        borderBottom: '1px solid #ccc',
                                        padding: '0 20px',
                                        alignSelf: 'stretch',
                                        textAlign: 'right'
                                    }}
                                >
                                    Spese accessorie:{' '}
                                    <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                        {order.pricesTotalOffer?.SpeseAcc
                                            ? transformToCurrency(parseFloat(order.pricesTotalOffer?.SpeseAcc))
                                            : transformToCurrency(0)}
                                    </span>
                                </p>
                                <p
                                    style={{
                                        color: '#68696D',
                                        fontWeight: 500,
                                        fontSize: 22,
                                        paddingBottom: 15,
                                        borderBottom: '1px solid #ccc',
                                        padding: '0 20px',
                                        alignSelf: 'stretch',
                                        textAlign: 'right'
                                    }}
                                >
                                    IVA: <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>{calculatedIva()}</span>
                                </p>
                                <p
                                    style={{
                                        color: '#68696D',
                                        fontWeight: 500,
                                        fontSize: 22,
                                        paddingBottom: 15,
                                        padding: '0 20px',
                                        alignSelf: 'stretch',
                                        textAlign: 'right'
                                    }}
                                >
                                    Totale offerta:{' '}
                                    <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                        {order.pricesTotalOffer?.TotCosti && transformToCurrency(parseFloat(order.pricesTotalOffer?.ImportoTotale))}
                                    </span>
                                </p>
                            </div>
                        ) : null}
                    </div>
                    <TextOffersModal
                        isVisible={isOffersTextModalVisible}
                        hideModal={() => setIsOffersTextModalVisible(false)}
                        offerData={user.lastOfferSeen}
                        isClosed
                    />
                    <WorksiteAddressModal
                        isVisible={isWorksiteModalVisible}
                        hideModal={() => setIsWorksiteModalVisible(false)}
                        offerData={user.lastOfferSeen}
                        isClosed
                    />
                    <DeliveryDateSelectModal
                        isVisible={isSelectDateVisible}
                        hideModal={() => setIsSelectDateVisible(false)}
                        offerData={user.lastOfferSeen}
                        isClosed
                    />
                    <DeleteModal
                        isVisible={isDeleteModalVisible}
                        hideModal={() => setIsDeleteModalVisible(false)}
                        onDelete={() => {
                            handleDeleteOffer()
                            setIsDeleteModalVisible(false)
                        }}
                        text={"Cancella l'offerta (ID " + user.lastOfferSeen?.CodiceOfferta + ')'}
                        title={'Elimina offerta'}
                    />
                    <PrintSettingsModal
                        isVisible={visiblePrintSettingsModal}
                        hideModal={() => setVisiblePrintSettingsModal(false)}
                        onConfirm={(type, order) => {
                            handlePrintOffer(type)
                        }}
                    />
                    <ConfirmModal
                        isVisible={isConfirmVisible !== ''}
                        hideModal={() => setIsConfirmVisible('')}
                        isOnlyConfirm
                        title="Sto scaricando il file specifiche"
                        text={
                            isConfirmVisible && isConfirmVisible.charAt(0) == 'P'
                                ? `Sto scaricando il file specifiche per l'offerta ${isConfirmVisible}`
                                : `Sto scaricando il file specifiche per l'ordine ${isConfirmVisible}`
                        }
                        onConfirm={() => setIsConfirmVisible('')}
                    />
                </div>
            )}
        </div>
    )
}

export default OfferDetail
