import { GRAPHIC_ACTIONS } from './../actions/graphic'
import { ReducerGraphic, ReduxActionData } from './../../types/reducers'

const defaultValue: ReducerGraphic = {
    categorySelected: null,
    currentUserOnline: '',
    isLoading: false,
    error: null,
    errorVariants: [],
    listino: null,
    colorAccent: '#005aab',
    showContacts: false
}

const graphicState: (state: ReducerGraphic, action: ReduxActionData<any>) => ReducerGraphic = (state = defaultValue, action: ReduxActionData<any>) => {
    switch (action.type) {
        case GRAPHIC_ACTIONS.SET_SELECTED_CATEGORY:
            return {
                ...state,
                categorySelected: action.payload
            }
        case GRAPHIC_ACTIONS.SET_CURRENT_USER_ONLINE:
            return {
                ...state,
                currentUserOnline: action.payload
            }
        case GRAPHIC_ACTIONS.SET_IS_LOADING:
            return {
                ...state,
                isLoading: action.payload
            }
        case GRAPHIC_ACTIONS.SET_COLOR:
            return {
                ...state,
                colorAccent: action.payload
            }
        case GRAPHIC_ACTIONS.SET_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case GRAPHIC_ACTIONS.SET_LISTINO:
            return {
                ...state,
                listino: action.payload
            }
        case GRAPHIC_ACTIONS.SET_ERROR_VARIANTS:
            return {
                ...state,
                errorVariants: action.payload
            }
        case GRAPHIC_ACTIONS.SET_SHOW_CONTACTS:
            return {
                ...state,
                showContacts: action.payload
            }
        default:
            return state
    }
}

export default graphicState
