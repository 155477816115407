import MaterialTable from 'material-table'
import React, { useMemo } from 'react'
import { DetailStat } from '../../types/data'
import { ReducerData, Reducers } from '../../types/reducers'
import { locale_itIT } from '../../utilities/tableLocale'
import { useSelector } from 'react-redux'
import { transformToCurrency } from '../../utilities/functions'

interface CompleteStat extends DetailStat {
    company: string
    agent: string
    serie: string
}

interface Props {
    stats: DetailStat[]
}

const StatDetailTable: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)

    const fullStats = useMemo(() => {
        if (props.stats && props.stats.length > 0) {
            const arr: CompleteStat[] = props.stats.map((s) => {
                const foundCompany = data.companies.find((c) => c.CodAZ == s.CodAZ)
                const foundAgent = data.agents.find((a) => a.CodAg == s.CodAg)
                const foundSerie = data.series.find((serie) => s.CodiceSerie == serie.CodSerie)
                return {
                    ...s,
                    company: foundCompany ? foundCompany.RagioneSociale : '',
                    agent: foundAgent ? foundAgent.DenAg : '',
                    serie: foundSerie ? foundSerie.NomeSerie : ''
                }
            })
            return arr
        } else {
            return []
        }
    }, [props.stats])

    const columns = [
        {
            title: 'Codice offerta',
            field: 'CodiceOfferta'
        },
        {
            title: 'Codice Listino',
            field: 'CodListino'
        },
        {
            title: 'Codice azienda',
            field: 'company',
            render: (rowData: CompleteStat) => {
                return (
                    <p>
                        {rowData.company} {'('}
                        {rowData.CodAZ}
                        {')'}
                    </p>
                )
            }
        },
        {
            title: 'Codice agente',
            field: 'agent',
            render: (rowData: CompleteStat) => {
                return (
                    <p>
                        {rowData.agent} {'('}
                        {rowData.CodAg}
                        {')'}
                    </p>
                )
            }
        },
        {
            title: 'Codice serie',
            field: 'serie',
            render: (rowData: CompleteStat) => {
                return (
                    <p>
                        {rowData.serie} {'('}
                        {rowData.CodiceSerie}
                        {')'}
                    </p>
                )
            }
        },
        {
            title: 'Totale pezzi',
            field: 'TotPezzi'
        },
        {
            title: 'Totale',
            field: 'Totale',
            render: (rowData: CompleteStat) => {
                return <p>{transformToCurrency(rowData.Totale)}</p>
            }
        },
        {
            title: 'Totale a listino',
            field: 'TotaleAListino',
            render: (rowData: CompleteStat) => {
                return <p>{transformToCurrency(rowData.TotaleAListino)}</p>
            }
        }
    ]

    return (
        <div>
            <MaterialTable
                title="Statistiche"
                columns={columns}
                data={fullStats}
                localization={locale_itIT}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    search: false,
                    exportButton: true,
                    exportAllData: true,
                    headerStyle: {
                        backgroundColor: '#ebebeb',
                        borderRadius: 20
                    }
                }}
            />
        </div>
    )
}

export default StatDetailTable
