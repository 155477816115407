import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Agente } from '../../types/data'
import { Dialog, DialogContent } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import TitlePage from '../TitlePage'
import { ReducerData, Reducers, ReducerUser } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { provincePerSelect, citiesPerSelect, companiesPerSelect } from '../../utilities/functions'
import Button from '../Button'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { createAgent, editAgent } from '../../resources/requests/requests'
import { setAgentsRedux, setMyCompany } from '../../store/actions/thunk_actions'
import { AgentUpdateData } from '../../types/user'
import Input from '../formComponents/Input'
import SelectInput, { Option } from '../formComponents/SelectInput'

interface Props {
    agent: Agente | null
    isVisible: boolean
    hideModal: () => void
}

const EditAgentModal: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const voidAgent: AgentUpdateData = {
        CodAg: '',
        DenAg: '',
        CfiAg: '',
        PivAg: '',
        AliqProvv: '',
        email: '',
        TelAg: '',
        FaxAg: '',
        Agente: '',
        Parent: '',
        Tipo: '',
        ProvAg: '',
        CitAg: '',
        CapAg: '',
        IndAg: '',
        IdQual: ''
    }
    const [currentAgent, setCurrentAgent] = useState<AgentUpdateData>(voidAgent)
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.isVisible) {
            let agent
            if (props.agent) {
                agent = {
                    CodAg: props.agent.CodAg,
                    DenAg: props.agent.DenAg,
                    CfiAg: props.agent.CfiAg,
                    PivAg: props.agent.PivAg,
                    AliqProvv: props.agent.AliqProvv,
                    email: props.agent.email,
                    TelAg: props.agent.TelAg,
                    FaxAg: props.agent.FaxAg,
                    Agente: props.agent.Agente,
                    Parent: props.agent.CodAZ,
                    Tipo: props.agent.Tipo,
                    ProvAg: props.agent.ProvAg,
                    CitAg: props.agent.CitAg,
                    CapAg: props.agent.CapAg,
                    IndAg: props.agent.IndAg,
                    IdQual: props.agent.IdQual
                }
            } else {
                agent = voidAgent
            }
            setCurrentAgent(agent)
        }
    }, [props.isVisible])

    const provinceValue = () => {
        if (currentAgent && currentAgent.ProvAg) {
            const cityFound = data.provinces.find((c) => c.Sigla == currentAgent.ProvAg)
            if (cityFound) {
                return {
                    id: cityFound.Sigla,
                    value: cityFound.Sigla,
                    label: cityFound.Provincia
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const cityValue = () => {
        if (currentAgent && currentAgent.CapAg) {
            const cityFound = data.cities.find((c) => c.CAP == currentAgent.CapAg && c.Comune == currentAgent.CitAg)
            if (cityFound) {
                return {
                    id: cityFound.CAP,
                    value: cityFound.IDComune,
                    label: cityFound.Comune
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const levelValue = () => {
        if (currentAgent.Agente) {
            const levelFound = data.levelsAgent.find((l) => l.livello == currentAgent.Agente)
            if (levelFound) {
                return {
                    id: levelFound.livello,
                    value: levelFound.livello,
                    label: levelFound.categoria
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const companyValue = () => {
        if (currentAgent.Parent) {
            const companyFound = data.companies.find((c) => c.CodAZ == currentAgent.Parent)
            if (companyFound) {
                return {
                    id: companyFound.CodAZ,
                    value: companyFound.CodAZ,
                    label: `${companyFound.RagioneSociale} (Cod. ${companyFound.CodAZ})`
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const handleSave = async () => {
        dispatch(setIsLoading(true))
        try {
            await editAgent(currentAgent, dispatch)
            await dispatch(setAgentsRedux())
            if (user.user && currentAgent.CodAg == user.user.CodAg) {
                await dispatch(setMyCompany())
            }
            props.hideModal()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    const handleCreate = async () => {
        dispatch(setIsLoading(true))
        try {
            const newBody = {
                ...currentAgent,
                AliqProvv: parseFloat(currentAgent.AliqProvv),
                Agente: parseInt(currentAgent.Agente)
            }
            await createAgent(newBody, dispatch)
            await dispatch(setAgentsRedux())
            props.hideModal()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={props.agent ? 'Dati operatore: ' + currentAgent.DenAg : 'Aggiungi operatore'} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Aggiungi / modifica dell{"'"}operatore</p>
                <div className="gridInputOffer" style={{ margin: '20px 0', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                    <Input
                        label="Codice operatore"
                        placeholder="Codice operatore"
                        disabled={props.agent ? true : false}
                        value={currentAgent.CodAg}
                        onChange={(e) => {
                            setCurrentAgent({
                                ...currentAgent,
                                CodAg: e
                            })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                    />
                    <Input
                        label="Nominativo"
                        placeholder="Nominativo"
                        value={currentAgent.DenAg}
                        onChange={(e) => {
                            setCurrentAgent({
                                ...currentAgent,
                                DenAg: e
                            })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                    />
                    <Input
                        label="Codice fiscale"
                        placeholder="Codice fiscale"
                        value={currentAgent.CfiAg}
                        onChange={(e) => {
                            setCurrentAgent({
                                ...currentAgent,
                                CfiAg: e
                            })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                    />
                    <Input
                        label="Partita IVA"
                        placeholder="Partita IVA"
                        value={currentAgent.PivAg}
                        onChange={(e) => {
                            setCurrentAgent({
                                ...currentAgent,
                                PivAg: e
                            })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                    />
                    <Input
                        label="Email"
                        placeholder="Email"
                        value={currentAgent.email}
                        onChange={(e) => {
                            setCurrentAgent({
                                ...currentAgent,
                                email: e
                            })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                    />
                    <Input
                        type="tel"
                        label="Telefono"
                        placeholder="Telefono"
                        value={currentAgent.TelAg}
                        onChange={(e) => {
                            setCurrentAgent({
                                ...currentAgent,
                                TelAg: e
                            })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                    />
                    <Input
                        type="tel"
                        label="Fax"
                        placeholder="Fax"
                        value={currentAgent.FaxAg}
                        onChange={(e) => {
                            setCurrentAgent({
                                ...currentAgent,
                                FaxAg: e
                            })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                    />
                    <Input
                        label="Indirizzo"
                        placeholder="Indirizzo"
                        value={currentAgent.IndAg}
                        onChange={(e) => {
                            setCurrentAgent({
                                ...currentAgent,
                                IndAg: e
                            })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                    />
                    <SelectInput
                        label="Provincia"
                        placeholder="Provincia"
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        options={provincePerSelect(data.provinces)}
                        onChange={(e) => {
                            const val = e as Option
                            if (val) {
                                setCurrentAgent({
                                    ...currentAgent,
                                    ProvAg: val.id as string
                                })
                            }
                        }}
                        value={provinceValue()}
                    />
                    <SelectInput
                        label="Città"
                        placeholder="Città"
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        options={citiesPerSelect(data.cities)}
                        onChange={(e) => {
                            const val = e as Option
                            if (val) {
                                setCurrentAgent({
                                    ...currentAgent,
                                    CapAg: val.id as string,
                                    CitAg: val.label
                                })
                            }
                        }}
                        value={cityValue()}
                    />
                    <SelectInput
                        label="Livello operatore"
                        placeholder="Livello operatore"
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        options={data.levelsAgent.map((l) => {
                            return { id: l.livello, value: l.livello, label: l.categoria }
                        })}
                        onChange={(e) => {
                            const val = e as Option
                            if (val) {
                                setCurrentAgent({
                                    ...currentAgent,
                                    Agente: val.id as string
                                })
                            }
                        }}
                        value={levelValue()}
                    />
                    <SelectInput
                        label="Azienda collegata"
                        placeholder="Azienda collegata"
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        options={companiesPerSelect(data.companies)}
                        onChange={(e) => {
                            const val = e as Option
                            if (val) {
                                setCurrentAgent({
                                    ...currentAgent,
                                    Parent: val.id as string
                                })
                            }
                        }}
                        value={companyValue()}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: '100%' }}>
                    <Button
                        isSmall
                        text="Salva"
                        hasIcon={true}
                        icon={faEdit}
                        opacity={currentAgent.CodAg && currentAgent.DenAg && currentAgent.Parent && currentAgent.Agente ? 1 : 0.5}
                        onClick={() => {
                            if (currentAgent.CodAg && currentAgent.DenAg && currentAgent.Parent && currentAgent.Agente) {
                                if (props.agent) {
                                    handleSave()
                                } else {
                                    handleCreate()
                                }
                            }
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default EditAgentModal
