import moment from 'moment'
import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { version, RELASED_AT, LINK_CONDIZIONI_VENDITA } from '../resources/constants'
import { useDispatch, useSelector } from 'react-redux'
import { setShowContacts } from '../store/actions/graphic'
import { ReducerUser, Reducers } from '../types/reducers'

const Footer: React.FC = () => {
    const history = useHistory()
    const dispatch = useDispatch()
    const user: ReducerUser = useSelector((store: Reducers) => store.user)

    return (
        <footer>
            <div className="container-fluid">
                <p className="text-center versionFooter">
                    © {RELASED_AT} - {moment().format('YYYY')} - v. {version} -{' '}
                    <span style={{ color: '#000', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => dispatch(setShowContacts(true))}>
                        Contatti
                    </span>{' '}
                    -{' '}
                    <NavLink to={'/docs'}>
                        <span style={{ color: '#000', textDecoration: 'underline', cursor: 'pointer' }}>Documenti Utili</span>
                    </NavLink>{' '}
                    -{' '}
                    <NavLink to={'/docs-commesse'}>
                        <span style={{ color: '#000', textDecoration: 'underline', cursor: 'pointer' }}>Commesse </span>
                    </NavLink>{' '}
                    -{' '}
                    {user.user?.TipoAccount == 'Amministratore' || user.user?.TipoAccount == 'Supervisore' ? (
                        <>
                            <NavLink to={'/docs-xml'}>
                                <span style={{ color: '#000', textDecoration: 'underline', cursor: 'pointer' }}>Documenti XML</span>
                            </NavLink>{' '}
                            -{' '}
                        </>
                    ) : null}
                    {user.user?.TipoAccount == 'Amministratore' || user.user?.TipoAccount == 'Supervisore' ? (
                        <>
                            <NavLink to={'/docs-json'}>
                                <span style={{ color: '#000', textDecoration: 'underline', cursor: 'pointer' }}>Archivio</span>
                            </NavLink>{' '}
                            -{' '}
                        </>
                    ) : null}
                    <a
                        href={LINK_CONDIZIONI_VENDITA}
                        target="_blank"
                        rel="noreferrer"
                        style={{ color: '#000', textDecoration: 'underline', cursor: 'pointer' }}
                    >
                        Condizioni di vendita
                    </a>{' '}
                    {/* -
                    <a href={LINK_PRIVACY} 
                     target="_blank" rel="noreferrer" style={{ color: '#000', textDecoration: 'underline', cursor: 'pointer' }}>
                        Privacy Policy
                    </a>{' '} */}
                    - powered by{' '}
                    <a href="https://22hbg.com" target="_blank" rel="noreferrer" style={{ color: '#000' }}>
                        22HBG
                    </a>
                </p>
            </div>
        </footer>
    )
}

export default Footer
