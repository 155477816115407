export const ALPHABET = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

//export const BLUE_DEFAULT = '#7DCE23'//'#005aab'
//export const LOGO_DEFAULT = 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/logoBiemme.png'
//export const LOGO_PRODUCT = 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/genera-logo.svg'
export let BLUE_DEFAULT = '#005aab'
export let LOGO_DEFAULT = 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/logoBiemme.png'
export let LOGO_PRODUCT = 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/genera-logo.svg'

export const version = '0.1.5'

export const TOKEN_ERROR_STRING = 'TOKEN ERROR'

/* SERVER TEST */
//export const BASE_URL = 'http://54.228.96.60:3001/'
//export const BASE_URL = 'http://54.228.96.60:3004/'

/*SERVER  */
export let BASE_URL = ''
export let BASE_URL_FRONTEND = ''
export let SUB_PATH_URL_FRONTEND = '' // WL aggiunto 14/12/2022
// WL aggiunto 26/01/2023
// CONTATTI PRODUCT  aggiunto 26/01/2023
export let NAME_PRODUCT = 'Genera'
export let EMAIL_ADMIN_PRODUCT =    'amministratore.genera@biemmefinestre.it' 
export let EMAIL_INFO_PRODUCT =     'info.genera@biemmefinestre.it' 
export let EMAIL_CUSTOMER_PRODUCT = 'segnalazionerrori.genera@biemmefinestre.it' 
export let RELASED_AT = 'Biemme Finestre'
export let ALL_FUNCTION_ENABLED = true
export let BANNER_LOGIN_ENABLED = false
 
export let LINK_CONDIZIONI_VENDITA = 'https://www.biemmefinestre.it/condizioni-di-vendita/ '
export let LINK_PRIVACY = ''

export let EVIDENCE_NOTE = ''

switch (process.env.REACT_APP_RELEASE_ENV) {
    case 'development':
        SUB_PATH_URL_FRONTEND = 'biemme_genera/panel/'
        /* default start Sviluppo */
        //BASE_URL = 'http://35.176.154.210:3005/'
        //BASE_URL_FRONTEND = 'http://35.176.154.210:3005/'
        /*
        //  API  su con frontend ufficiale pilota
        BASE_URL = 'http://35.176.154.210:3001/'
        BASE_URL_FRONTEND = 'http://35.176.154.210:3001/'
        */
        /*
        //  API  su con frontend ufficiale in produzione
        BASE_URL = 'http://35.176.154.210:3003/'
        BASE_URL_FRONTEND = 'http://35.176.154.210:3003/'
        */

        // API in manutenzione su con frontend in manutenzione
        BASE_URL = 'http://genera.biemmefinestre.it:3004/'
        BASE_URL_FRONTEND = 'http://genera.biemmefinestre.it:3004/'
        BANNER_LOGIN_ENABLED = true
        EVIDENCE_NOTE =       'localhost su Manutenzione v.'+version+'-3004_dev '
        break
    case 'staging':
        /* Versione Manutenzione per Test Nuovi listini */
        // BASE_URL = 'http://35.176.154.210:3004/'
        // BASE_URL_FRONTEND = 'http://35.176.154.210:3004/'
        BASE_URL = 'http://genera.biemmefinestre.it:3004/'
        BASE_URL_FRONTEND = 'http://genera.biemmefinestre.it:3004/'
        SUB_PATH_URL_FRONTEND = 'biemme_genera/panel/'
        BANNER_LOGIN_ENABLED = true
        EVIDENCE_NOTE = '  Manutenzione  v.' + version + '_3004-Staging'
        break
    case 'testing':
        /* Versione release Pilota last or new */
        //BASE_URL = 'http://35.176.154.210:3001/'
        //BASE_URL_FRONTEND = 'http://35.176.154.210:3001/'
        BASE_URL = 'http://genera.biemmefinestre.it:3005/'
        BASE_URL_FRONTEND = 'http://genera.biemmefinestre.it:3005/'
        SUB_PATH_URL_FRONTEND = 'biemme_genera/panel/'
        BANNER_LOGIN_ENABLED = true
        EVIDENCE_NOTE = ' Pilot  v.' + version + '-3005'
        break
    case 'production':
        /* Versione release Produzione*/
        //BASE_URL = 'http://35.176.154.210:3003/'
        BASE_URL = 'http://genera.biemmefinestre.it:3003/'
        SUB_PATH_URL_FRONTEND = ''
        BASE_URL_FRONTEND = 'http://genera.biemmefinestre.it/'
        BANNER_LOGIN_ENABLED = false
        EVIDENCE_NOTE = ' Production      v.' + version + '_3003'
        break
    case 'developmentWiteLabel22HBG':
        /* default start Sviluppo WHITE LABEL  WL aggiunto 26/01/2023 */
        // Da cambiare il nome della pagina  nell' inde.html
        // EVIDENCE_NOTE = ''
        NAME_PRODUCT = 'Sales22HBG'
        BASE_URL = 'http://54.228.96.60:3004/'
        BASE_URL_FRONTEND = 'http://54.228.96.60:3004/'
        SUB_PATH_URL_FRONTEND = NAME_PRODUCT + '/panel/'
        BLUE_DEFAULT = '#7DCE23'
        RELASED_AT = '22HBG S.R.L'
        ALL_FUNCTION_ENABLED = false
        BANNER_LOGIN_ENABLED = false
        EMAIL_ADMIN_PRODUCT = 'amministratore@22hbg.it'
        EMAIL_INFO_PRODUCT = 'info@22hbg.it'
        EMAIL_CUSTOMER_PRODUCT = 'segnalazionerrori@22hbg.it'
        LOGO_DEFAULT = 'https://22hbg.com/wp-content/uploads/2022/02/logo-white-22hbg-footer.svg'
        LOGO_PRODUCT = 'https://22hbgtest.s3.eu-west-2.amazonaws.com/sales22hbg/sales22bg-logo1.png'
        LINK_CONDIZIONI_VENDITA = 'https://22hbg.com/ricerca-e-sviluppo/'
        LINK_PRIVACY = 'https://22hbg.com/privacy-policy/'

        break
    case 'productionWiteLabel22HBG':
        /* default start production WHITE LABEL  WL aggiunto 26/01/2023 */
        // Da cambiare il nome della pagina  nell' inde.html
        EVIDENCE_NOTE = ''
        NAME_PRODUCT = 'Sales22HBG'
        BASE_URL = 'http://54.228.96.60:3004/'
        BASE_URL_FRONTEND = 'http://54.228.96.60:3004/'
        SUB_PATH_URL_FRONTEND = NAME_PRODUCT + '/panel/'
        BLUE_DEFAULT = '#7DCE23'
        RELASED_AT = '22HBG S.R.L'
        ALL_FUNCTION_ENABLED = false
        BANNER_LOGIN_ENABLED = false
        EMAIL_ADMIN_PRODUCT = 'amministratore@22hbg.it'
        EMAIL_INFO_PRODUCT = 'info@22hbg.it'
        EMAIL_CUSTOMER_PRODUCT = 'segnalazionerrori@22hbg.it'
        LOGO_DEFAULT = 'https://22hbg.com/wp-content/uploads/2022/02/logo-white-22hbg-footer.svg'
        LOGO_PRODUCT = 'https://22hbgtest.s3.eu-west-2.amazonaws.com/sales22hbg/sales22bg-logo1.png'

        break
}

//export const BASE_URL = 'http://35.176.154.210:3004/'

export const MONTHS = ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre']
