import React from 'react'
import { Dialog, DialogContent, } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'
import { ReducerGraphic, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { setError, setErrorVariants } from '../../store/actions/graphic'

const ErrorModal: React.FC = () => {
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const dispatch = useDispatch()

    if (graphic.errorVariants.length > 0) {
        return (
            <Dialog open={graphic.errorVariants.length > 0} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md" className="overflowVisible" >
                <DialogContent style={{ padding: 20, backgroundColor: "#EBEBEB", position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                    <TitlePage text={"Errore"} style={{ margin: 0 }} />
                    <p style={{ fontSize: 24, color: "#68696D", margin: 0 }}>Non è stato possibile aggiungere le seguenti varianti: </p>
                    <ul>
                        {graphic.errorVariants.map((e, i) => {
                            return (
                                <li key={i.toString()}>{e}</li>
                            )
                        })}
                    </ul>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: "100%", }}>
                        <Button
                            isSmall
                            text="Ok"
                            onClick={() => {
                                dispatch(setErrorVariants([]))
                            }}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        )

    }

    return (
        <Dialog open={graphic.error !== null} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md" className="overflowVisible" >
            <DialogContent style={{ padding: 20, backgroundColor: "#EBEBEB", position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <TitlePage text={"Errore"} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: "#68696D", margin: 0 }}>Si è verificato il seguente errore</p>
                <p style={{ fontStyle: 'italic', margin: "20px 0", color: '#666', fontWeight: 700, fontSize: 18 }}>{graphic.error}</p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: "100%", }}>
                    <Button
                        isSmall
                        text="Ok"
                        onClick={() => {
                            dispatch(setError(null))
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ErrorModal