import React, { useEffect, useState } from 'react'
import { addItemToPreventivo, createDefaultVariants, saveCurrentOffer } from '../../../resources/requests/requests'
import { AddItemsToPreventivoBody, Serie, Typology, TypologyPushObject } from '../../../types/data'
import TypologyCard from '../../../components/TypologyCard'
import Button from '../../../components/Button'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { ReducerOrder, Reducers } from '../../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { ItemInQuote } from '../../../types/order'
import { setCurrentOffer, setItemsInQuote } from '../../../store/actions/order'
import { setOfferLoadedRedux } from '../../../store/actions/thunk_actions'
import { setError, setIsLoading } from '../../../store/actions/graphic'
import UniformSizeModal from '../../../components/modals/UniformSizeModal'
//import { ALL_FUNCTION_ENABLED } from '../../resources/constants'

//if (ALL_FUNCTION_ENABLED === false) non deve essere mostrata

interface Props {
    serieSelected: Serie
    typologiesSelected: Typology[]
    nextPhase: () => void
    edited: (toAdd: AddItemsToPreventivoBody) => void
}

const SelezionaTipologieView: React.FC<Props> = (props) => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const [selectedTypologies, setSelectedTypologies] = useState<AddItemsToPreventivoBody>({
        CodicePreventivo: order.newQuoteFromUser?.CodicePreventivo,
        CodiceSerie: props.serieSelected.CodSerie,
        UniformaMisure: 0,
        Tipologia: []
    })
    const [visibleUniform, setVisibleUniform] = useState(false)
    const dispatch = useDispatch()

    
    //console.log(order.newQuoteFromUser)

    useEffect(() => {
        setSelectedTypologies({
            ...selectedTypologies,
            CodicePreventivo: order.newQuoteFromUser?.CodicePreventivo
        })
    }, [order.newQuoteFromUser])

    const renderTypologiesGrid = () => {
        return props.typologiesSelected.map((t) => {
            return (
                <TypologyCard
                    setSelectedTypologies={(typology: TypologyPushObject) => {
                        AddEditTypology(typology)
                    }}
                    key={t.CodTip}
                    typology={t}
                />
            )
        })
    }

    const AddEditTypology = (t: TypologyPushObject) => {
        const currentIndex = selectedTypologies.Tipologia.findIndex((typology) => typology.CodTipoItem == t.CodTipoItem)
        if (currentIndex !== -1) {
            if (t.Items.length == 0) {
                setSelectedTypologies({
                    ...selectedTypologies,
                    Tipologia: selectedTypologies.Tipologia.filter((typology) => typology.CodTipoItem !== t.CodTipoItem)
                })
            } else {
                setSelectedTypologies({
                    ...selectedTypologies,
                    Tipologia: selectedTypologies.Tipologia.map((typology) => {
                        if (typology.CodTipoItem == t.CodTipoItem) {
                            return t
                        } else {
                            return typology
                        }
                    })
                })
            }
        } else {
            setSelectedTypologies({
                ...selectedTypologies,
                Tipologia: [...selectedTypologies.Tipologia, t]
            })
        }
    }

    useEffect(() => {
        props.edited(selectedTypologies)
    }, [selectedTypologies])

    const handleAddItemToPreventivo = async (uniform: 0 | 1) => {
        dispatch(setIsLoading(true))
        try {
            const idArray: any = await addItemToPreventivo({ ...selectedTypologies, UniformaMisure: uniform }, dispatch)
            const defaultVariants: ItemInQuote[] = await createDefaultVariants({ Items: idArray }, dispatch)
            dispatch(setItemsInQuote([...order.itemsInQuote, ...defaultVariants]))
            const offer = await saveCurrentOffer(order.newQuoteFromUser?.CodiceOfferta, dispatch)
            if (offer) {
                dispatch(setCurrentOffer(offer))
            }
            // await dispatch(setOfferLoadedRedux(order.newQuoteFromUser?.CodiceOfferta, true))
            props.nextPhase()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }
     
    return (
        <div className="formCreateOffer">
            <div className="typologiesGrid">{renderTypologiesGrid()}</div>
            <div className="nextPhaseButtonBar">
                <Button
                    text="Prossimo step"
                    hasIcon={true}
                    icon={faArrowRight}
                    opacity={selectedTypologies.Tipologia.length > 0 ? 1 : 0.5}
                    onClick={() => {
                        if (selectedTypologies.Tipologia.length > 0) {
                            const onlyFullItems = selectedTypologies.Tipologia.filter((t) => t.Items.length > 0)
                            if (onlyFullItems.length > 0) {
                                // WL modifica  nasconde funzione uniforma misura
                                //setVisibleUniform(true)  
                                handleAddItemToPreventivo(0)
                                // fine 
                                
                            } else {
                                props.nextPhase()
                            }
                        }
                    }}
                />
            </div>
            <UniformSizeModal
           
                isVisible={visibleUniform}
                onClick={(v) => {
                    setVisibleUniform(false)
                    handleAddItemToPreventivo(v)
                }}
            />
        </div>
    )
}

export default SelezionaTipologieView
