import React, { useEffect } from 'react'
import SubtitleSection from '../../../components/SubtitleSection'
import Button from '../../../components/Button'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { ReducerData, Reducers, ReducerUser } from '../../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import { citiesPerSelect, provincePerSelect } from '../../../utilities/functions'
import { setError, setIsLoading } from '../../../store/actions/graphic'
import { AgentUpdateData } from '../../../types/user'
import { editAgent } from '../../../resources/requests/requests'
import { setUserRedux } from '../../../store/actions/thunk_actions'
import Input from '../../../components/formComponents/Input'
import SelectInput, { Option } from '../../../components/formComponents/SelectInput'

const ProfiloOperatoreScreen: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [currentAgent, setCurrentAgent] = useState<AgentUpdateData>()
    const dispatch = useDispatch()

    useEffect(() => {
        if (user.user) {
            setCurrentAgent({
                CodAg: user.user?.CodAg,
                DenAg: user.user.DenAg,
                CfiAg: user.user.CfiAg,
                PivAg: user.user.PivAg,
                AliqProvv: user.user.AliqProvv,
                email: user.user.mail,
                TelAg: user.user.TelAg,
                FaxAg: user.user.FaxAg,
                Agente: user.user.Agente,
                Parent: user.user.Parent,
                Tipo: user.user.TipoAccount,
                ProvAg: user.user.ProvAg,
                CitAg: user.user.CitAg,
                CapAg: user.user.CapAg,
                IndAg: user.user.IndAg,
                IdQual: user.user.CodAg
            })
        }
    }, [user.user])

    const currentProvince = () => {
        if (currentAgent?.ProvAg) {
            const provFound = data.provinces.find((p) => p.Sigla == currentAgent.ProvAg)
            if (provFound) {
                return {
                    id: provFound.Sigla,
                    value: provFound.Sigla,
                    label: provFound.Provincia
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }
/*
    const currentCity = () => {
       
       console.log('currentCity   ')
        if (currentAgent?.CapAg && currentAgent.CitAg) {
            const cityFound = data.cities.find((c) => c.Comune == currentAgent.CitAg && currentAgent.CapAg == c.CAP)
                console.log('cityFound   ', cityFound)
            if (cityFound) {
                return {
                    id: cityFound.CAP,
                    value: cityFound.IDComune,
                    label: cityFound.Comune
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }
*/

      const currentCity = () => {
        console.log('currentCity   ')  
        if (currentAgent && currentAgent?.CapAg) {
              const cityFound = data.cities.find((c) => c.CAP == currentAgent.CapAg && c.Comune == currentAgent.CitAg)
              if (cityFound) {
                  return {
                      id: cityFound.CAP,
                      value: cityFound.IDComune,
                      label: cityFound.Comune
                  }
              } else {
                  return null
              }
          } else {
              return null
          }
      }

    const saveData = async () => {
        if (currentAgent) {
            dispatch(setIsLoading(true))
            try {
                await editAgent(currentAgent, dispatch)
                await dispatch(setUserRedux())
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    if (currentAgent) {
        return (
            <div>
                <div className="introProductsPage">
                    <SubtitleSection text="Riepilogo dati operatore" />
                    <Button
                        text="Salva i dati"
                        hasIcon={true}
                        icon={faCog}
                        onClick={() => {
                            saveData()
                        }}
                    />
                </div>
                <div className="formSettings">
                    <Input
                        value={currentAgent?.DenAg}
                        onChange={(e) => {
                            setCurrentAgent({ ...currentAgent, DenAg: e })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        label="Nominativo"
                        placeholder="Nominativo"
                    />
                    <Input
                        value={currentAgent?.CfiAg}
                        onChange={(e) => {
                            setCurrentAgent({ ...currentAgent, CfiAg: e })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        label="Codice fiscale"
                        placeholder="Codice fiscale"
                    />
                    <Input
                        value={currentAgent?.PivAg}
                        onChange={(e) => {
                            setCurrentAgent({ ...currentAgent, PivAg: e })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        label="Partita IVA"
                        placeholder="Partita IVA"
                    />
                    <Input
                        value={currentAgent?.email}
                        onChange={(e) => {
                            setCurrentAgent({ ...currentAgent, email: e })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        disabled={user.user?.TipoAccount !== 'Amministratore'}
                        label="Email"
                        type="email"
                        placeholder="Email"
                    />
                    <Input
                        value={currentAgent?.TelAg}
                        onChange={(e) => {
                            setCurrentAgent({ ...currentAgent, TelAg: e })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        label="Telefono"
                        type="tel"
                        placeholder="Telefono"
                    />
                    <Input
                        value={currentAgent?.FaxAg}
                        onChange={(e) => {
                            setCurrentAgent({ ...currentAgent, FaxAg: e })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        label="Fax"
                        type="tel"
                        placeholder="Fax"
                    />
                    <Input
                        value={currentAgent?.IndAg}
                        onChange={(e) => {
                            setCurrentAgent({ ...currentAgent, IndAg: e })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        label="Indirizzo"
                        placeholder="Indirizzo"
                    />
                    <SelectInput
                        options={provincePerSelect(data.provinces)}
                        value={currentProvince()}
                        onChange={(e) => {
                            const value = e as Option
                            setCurrentAgent({
                                ...currentAgent,
                                ProvAg: value.id as string
                            })
                        }}
                        label="Provincia"
                        placeholder="Provincia"
                    />
                    {/*
                    <SelectInput
                        options={citiesPerSelect(data.cities)}
                        value={currentCity()}
                        onChange={(e) => {
                            const value = e as Option
                            setCurrentAgent({
                                ...currentAgent,
                                CitAg: value.id as string
                            })
                        }}
                        label="Città**"
                        placeholder="Città"
                    />
                    */}
                    <SelectInput
                        label="Città"
                        placeholder="Città"
                        options={citiesPerSelect(currentAgent?.ProvAg !== '' ? data.cities.filter((c) => c.Sigla == currentAgent?.ProvAg) : data.cities)}
                        onChange={(e) => {
                            const value = e as Option
                            setCurrentAgent({
                                ...currentAgent,
                                CitAg: value.value as string,
                                CapAg: value.id as string
                            })
                        }}
                        value={currentCity()}
                    />
                </div>
            </div>
        )
    }
    return null
}

export default ProfiloOperatoreScreen
