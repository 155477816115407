import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
    title: {
        fontFamily: 'Roboto',
        fontSize: 30,
        fontWeight: 900,
        marginVertical: 10
    },
    subtitle: {
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: 900,
        marginVertical: 10
    },
    standardText: {
        fontSize: 12,
        color: '#68696D',
        marginBottom: 5,
        fontFamily: 'Roboto'
    },
    boldText: {
        fontSize: 12,
        color: '#000',
        marginBottom: 5,
        fontWeight: 700,
        fontStyle: 'bold',
        fontFamily: 'Roboto'
    },
    standardSmallText: {
        fontSize: 12,
        color: '#68696D',
        fontFamily: 'Roboto'
    },
    boldSmallText: {
        fontSize: 12,
        color: '#000',
        fontFamily: 'Roboto',
        fontWeight: 700
    }
});