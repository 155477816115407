export enum Ambito {
    CATEGORIA_IMG = 'CATEGORIA_IMG',
    CATEGORIA_ICO = 'CATEGORIA_ICO',
    SERIE_MAIN = 'SERIE_MAIN',
    SERIE_BANNER = 'SERIE_BANNER',
    SERIE_ANIMATED = 'SERIE_ANIMATED',
    SERIE_GALLERY1 = 'SERIE_GALLERY1',
    SERIE_GALLERY2 = 'SERIE_GALLERY2',
    SERIE_GALLERY3 = 'SERIE_GALLERY3',
    SERIE_GALLERY4 = 'SERIE_GALLERY4',
    SERIE_GALLERY5 = 'SERIE_GALLERY5',
    LOGO_AZIENDA = 'LOGO_AZIENDA',
    LOGO_AZIENDA_STAMPA = 'LOGO_AZIENDA_STAMPA',
    COPERTINA_AZIENDA_STAMPA = 'COPERTINA_AZIENDA_STAMPA',
    RETRO_AZIENDA_STAMPA = 'RETRO_AZIENDA_STAMPA',
    LIBERA = 'LIBERA'
}
