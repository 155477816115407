import React from 'react'
import { ReducerGraphic, Reducers } from '../types/reducers'
import { useSelector } from 'react-redux'

interface Props {
    text: string | undefined
    style?: any
    dynamicText?: string
}

const TitlePage: React.FC<Props> = (props) => {
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)

    return (
        <h1 className="titlePage" style={{ ...props.style, borderLeft: `3px solid ${graphic.colorAccent}` }}>{props.text} {props.dynamicText && <b>{props.dynamicText}</b>}</h1>
    )
}

export default TitlePage