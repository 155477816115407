import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Dialog, DialogContent } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import TitlePage from '../TitlePage'
import { ReducerData, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { provincePerSelect, citiesPerSelect, salePerSelect } from '../../utilities/functions'
import Button from '../Button'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { addEditAzienda } from '../../resources/requests/requests'
import { getCompaniesListRedux, setMyCompany } from '../../store/actions/thunk_actions'
import { Company } from '../../types/user'

interface Props {
    company: Company | null
    isVisible: boolean
    hideModal: () => void
}

const EditCompanyModal: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const voidCompany: Company = {
        Attivo: '1',
        CAP: '',
        CF: '',
        Citta: '',
        CodAZ: '',
        CopertinaFronte: '',
        CopertinaRetro: '',
        Email: '',
        ID: null,
        Indirizzo: '',
        Lingua: '',
        Logo: '',
        Nazione: '',
        PIva: '',
        Provincia: '',
        RagioneSociale: '',
        SitoWeb: '',
        Telefono: '',
        TestoFinale: '',
        TestoIntroduttivo: '',
        color: '',
        ZonaVendita: '',
        categoria: ''
    }
    const [currentCompany, setCurrentCompany] = useState<Company>(props.company ? props.company : voidCompany)
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.isVisible) {
            setCurrentCompany(props.company ? props.company : voidCompany)
        }
    }, [props.isVisible])

    const provinceValue = () => {
        if (currentCompany && currentCompany.Provincia) {
            const cityFound = data.provinces.find((c) => c.Sigla == currentCompany.Provincia)
            if (cityFound) {
                return {
                    id: cityFound.Sigla,
                    value: cityFound.Sigla,
                    label: cityFound.Provincia
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const cityValue = () => {
        if (currentCompany && currentCompany.CAP) {
            const cityFound = data.cities.find((c) => c.CAP == currentCompany.CAP && c.Comune == currentCompany.Citta)
            if (cityFound) {
                return {
                    id: cityFound.CAP,
                    value: cityFound.IDComune,
                    label: cityFound.Comune
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const saleValue = () => {
        if (currentCompany && currentCompany.categoria) {
            const saleFound = data.saleCategories.find((s) => s.categoria == currentCompany.categoria)
            if (saleFound) {
                return {
                    id: saleFound.ID,
                    value: saleFound.ID,
                    label: saleFound.categoria
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const handleSave = async () => {
        dispatch(setIsLoading(true))
        try {
            await addEditAzienda(currentCompany, dispatch)
            dispatch(setMyCompany())
            dispatch(getCompaniesListRedux())
            props.hideModal()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={props.company ? 'Dati azienda: ' + currentCompany.RagioneSociale : 'Aggiungi azienda'} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Aggiungi / modifica dell{"'"}azienda</p>
                <div className="gridInputOffer" style={{ margin: '20px 0', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Codice Aziendale</label>
                        <input
                            type="text"
                            disabled={currentCompany.ID ? true : false}
                            value={currentCompany?.CodAZ}
                            onChange={(e) => {
                                if (currentCompany) {
                                    setCurrentCompany({
                                        ...currentCompany,
                                        CodAZ: e.target.value
                                    })
                                }
                            }}
                            required
                            placeholder="Codice Azienda*"
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Ragione sociale</label>
                        <input
                            type="text"
                            value={currentCompany?.RagioneSociale}
                            onChange={(e) => {
                                if (currentCompany) {
                                    setCurrentCompany({
                                        ...currentCompany,
                                        RagioneSociale: e.target.value
                                    })
                                }
                            }}
                            required
                            placeholder="Ragione Sociale*"
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Partita IVA</label>
                        <input
                            type="text"
                            value={currentCompany?.PIva}
                            onChange={(e) => {
                                if (currentCompany) {
                                    setCurrentCompany({
                                        ...currentCompany,
                                        PIva: e.target.value
                                    })
                                }
                            }}
                            required
                            placeholder="Partita IVA"
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Codice Fiscale</label>
                        <input
                            type="text"
                            value={currentCompany?.CF}
                            onChange={(e) => {
                                if (currentCompany) {
                                    setCurrentCompany({
                                        ...currentCompany,
                                        CF: e.target.value
                                    })
                                }
                            }}
                            required
                            placeholder="Codice Fiscale"
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Indirizzo</label>
                        <input
                            type="text"
                            value={currentCompany?.Indirizzo}
                            onChange={(e) => {
                                if (currentCompany) {
                                    setCurrentCompany({
                                        ...currentCompany,
                                        Indirizzo: e.target.value
                                    })
                                }
                            }}
                            required
                            placeholder="Indirizzo"
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Provincia</label>
                        <Select
                            styles={{
                                valueContainer: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#f4f4f4'
                                }),
                                container: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#f4f4f4',
                                    borderRadius: 7
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#f4f4f4',
                                    border: 0,
                                    padding: '5px 10px',
                                    borderRadius: 7
                                })
                            }}
                            placeholder="Provincia"
                            options={provincePerSelect(data.provinces)}
                            onChange={(e) => {
                                if (e && currentCompany) {
                                    setCurrentCompany({
                                        ...currentCompany,
                                        Provincia: e.id
                                    })
                                }
                            }}
                            value={provinceValue()}
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Comune</label>
                        <Select
                            styles={{
                                singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                option: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#f4f4f4',
                                    border: 0,
                                    padding: '5px 10px',
                                    borderRadius: 7
                                })
                            }}
                            placeholder="Comune"
                            options={citiesPerSelect(
                                currentCompany?.Provincia !== '' ? data.cities.filter((c) => c.Sigla == currentCompany?.Provincia) : data.cities
                            )}
                            onChange={(e) => {
                                if (e && currentCompany) {
                                    setCurrentCompany({
                                        ...currentCompany,
                                        Citta: e.label,
                                        CAP: e.id
                                    })
                                }
                            }}
                            value={cityValue()}
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Nazione</label>
                        <input
                            type="text"
                            value={currentCompany?.Nazione}
                            onChange={(e) => {
                                if (currentCompany) {
                                    setCurrentCompany({
                                        ...currentCompany,
                                        Nazione: e.target.value
                                    })
                                }
                            }}
                            required
                            placeholder="Nazione"
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Sito web</label>
                        <input
                            type="text"
                            value={currentCompany?.SitoWeb}
                            onChange={(e) => {
                                if (currentCompany) {
                                    setCurrentCompany({
                                        ...currentCompany,
                                        SitoWeb: e.target.value
                                    })
                                }
                            }}
                            required
                            placeholder="Sito Web"
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Email</label>
                        <input
                            type="email"
                            value={currentCompany?.Email}
                            onChange={(e) => {
                                if (currentCompany) {
                                    setCurrentCompany({
                                        ...currentCompany,
                                        Email: e.target.value
                                    })
                                }
                            }}
                            required
                            placeholder="Email"
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Telefono</label>
                        <input
                            type="tel"
                            value={currentCompany?.Telefono}
                            onChange={(e) => {
                                if (currentCompany) {
                                    setCurrentCompany({
                                        ...currentCompany,
                                        Telefono: e.target.value
                                    })
                                }
                            }}
                            required
                            placeholder="Telefono"
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Categoria</label>
                        <Select
                            styles={{
                                singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                option: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#f4f4f4',
                                    border: 0,
                                    padding: '5px 10px',
                                    borderRadius: 7
                                })
                            }}
                            placeholder="Categoria"
                            options={salePerSelect(data.saleCategories.filter((c) => !c.CodSerie))}
                            onChange={(e) => {
                                if (e && currentCompany) {
                                    console.log(e.label)
                                    setCurrentCompany({
                                        ...currentCompany,
                                        categoria: e.label
                                    })
                                }
                            }}
                            value={saleValue()}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: '100%' }}>
                    <Button
                        isSmall
                        text="Salva"
                        hasIcon={true}
                        icon={faEdit}
                        opacity={currentCompany.CodAZ && currentCompany.RagioneSociale ? 1 : 0.5}
                        onClick={() => {
                            if (currentCompany.CodAZ && currentCompany) {
                                handleSave()
                            }
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default EditCompanyModal
