import { faTag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MaterialTable from 'material-table'
import moment from 'moment'
import React, { useMemo } from 'react'
import { Database } from '../../types/data'
import { ReducerGraphic, Reducers } from '../../types/reducers'
import { locale_itIT } from '../../utilities/tableLocale'
import { useSelector } from 'react-redux'

interface Props {
    backups: Database[]
    search: string
    onSelect: (d: Database) => void
    onDelete: (d: Database) => void
}

const BackupTable: React.FC<Props> = (props) => {
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)

    const datas = useMemo(() => {
        if (props.search) {
            return props.backups.filter((b) => b.NomeBackup.toLowerCase().includes(props.search.toLowerCase()))
        }
        return props.backups
    }, [props.backups, props.search])

    const columns = [
        {
            title: 'Nome',
            field: 'NomeBackup'
        },
        {
            title: 'Data creazione',
            field: 'DataCreazione',
            render: (b: Database) => {
                return <p>{moment(b.DataCreazione).format('DD MMMM YYYY')}</p>
            }
        },
        {
            title: 'Note',
            field: 'Note'
        },
        {
            title: 'Tipo',
            field: 'tipo'
        }
    ]

    const renderActions = useMemo(() => {
        const actions = [
            () => ({
                // eslint-disable-next-line react/display-name
                icon: () => <FontAwesomeIcon icon={faTag} style={{ fontSize: 20, color: graphic.colorAccent }} />,
                tooltip: 'Recupera offerta',
                onClick: (event: any, rowData: Database) => {
                    props.onSelect(rowData)
                }
            }),
            () => ({
                icon: 'delete',
                iconProps: {
                    color: 'error',
                    fontSize: 'medium'
                },
                tooltip: 'Elimina backup',
                onClick: (event: any, rowData: Database) => {
                    props.onDelete(rowData)
                }
            })
        ]

        return actions
    }, [])

    return (
        <MaterialTable
            title={''}
            columns={columns}
            data={datas}
            localization={locale_itIT}
            options={{
                actionsColumnIndex: -1,
                pageSize: 10,
                pageSizeOptions: [10, 20, 50],
                search: false,
                headerStyle: {
                    backgroundColor: '#ebebeb',
                    borderRadius: 20
                }
            }}
            // @ts-ignore
            actions={renderActions}
        />
    )
}

export default BackupTable
