import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import Select from 'react-select'
import { Categoria, Serie } from '../../../../types/data'
import CategoriesListScreen from './CategoriesListScreen'
import CategoriesDetailScreen from './CategoriesDetailScreen'
import SeriesListScreen from './SeriesListScreen'
import SeriesDetailScreen from './SeriesDetailScreen'
import SelectInput, { Option } from '../../../../components/formComponents/SelectInput'

enum Type {
    CATEGORIES = 'Categorie',
    SERIES = 'Serie'
}

const CategorieSerieScreen: React.FC = () => {
    const [selectedType, setSelectedType] = useState(Type.CATEGORIES)
    const [isDetail, setIsDetail] = useState(false)
    const [selectedCategory, setSelectedCateogy] = useState<Categoria>()
    const [selectedSerie, setSelectedSerie] = useState<Serie>()

    const renderSelectedType = () => {
        if (selectedType == Type.CATEGORIES) {
            if (isDetail && selectedCategory) {
                return <CategoriesDetailScreen selectedDetail={selectedCategory} onBack={() => setIsDetail(false)} />
            } else {
                return (
                    <CategoriesListScreen
                        selectDetail={(c) => {
                            setSelectedCateogy(c)
                            setIsDetail(true)
                        }}
                    />
                )
            }
        }
        if (selectedType == Type.SERIES) {
            if (isDetail && selectedSerie) {
                return <SeriesDetailScreen selectedDetail={selectedSerie} onBack={() => setIsDetail(false)} />
            } else {
                return (
                    <SeriesListScreen
                        selectDetail={(p) => {
                            setSelectedSerie(p)
                            setIsDetail(true)
                        }}
                    />
                )
            }
        }
        return null
    }

    return (
        <div>
            {!isDetail && (
                <div style={{ display: 'flex', maxWidth: '32vh' }}>
                    <SelectInput
                        value={{ id: selectedType, value: selectedType, label: selectedType }}
                        options={[
                            { id: Type.CATEGORIES, value: Type.CATEGORIES, label: Type.CATEGORIES },
                            { id: Type.SERIES, value: Type.SERIES, label: Type.SERIES }
                        ]}
                        placeholder="Seleziona tipo"
                        onChange={(e) => {
                            const val = e as Option
                            setSelectedType(val.id as any)
                        }}
                    />
                </div>
            )}

            {renderSelectedType()}
        </div>
    )
}

export default CategorieSerieScreen
