import React from 'react'
import { ReducerOrder, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'
import { setCurrentOffer, setIsPendingOffer } from '../../store/actions/order'
import { deleteAllQuotes } from '../../resources/requests/requests'
import { useHistory } from 'react-router'
import { setError, setIsLoading } from '../../store/actions/graphic'

const PendingOfferModal: React.FC = () => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const dispatch = useDispatch()
    const history = useHistory()

    return (
        <Dialog open={order.pendingOffer} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md">
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <TitlePage text={'Offerta in sospeso'} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Hai un{"'"}offerta in sospeso</p>
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Chiudi offerta"
                    buttonColor="#E00000"
                    onClick={async () => {
                        dispatch(setIsLoading(true))
                        try {
                            await deleteAllQuotes(dispatch)
                            dispatch(setCurrentOffer(null))
                            dispatch(setIsPendingOffer(false))
                        } catch (error: any) {
                            dispatch(setError(error.toString()))
                        }
                        dispatch(setIsLoading(false))
                    }}
                />
                <Button
                    isSmall
                    text="Continua navigazione"
                    buttonColor="#52565A"
                    onClick={() => {
                        dispatch(setIsPendingOffer(false))
                    }}
                />
                <Button
                    isSmall
                    text="Visualizza offerta"
                    onClick={() => {
                        dispatch(setIsPendingOffer(false))
                        history.push('/current-offer')
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default PendingOfferModal
