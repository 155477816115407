import React, { useEffect, useState } from 'react'
import SubtitleSection from '../../../components/SubtitleSection'
import UploadCard from '../../../components/UploadCard'
import Button from '../../../components/Button'
import { faCog } from '@fortawesome/free-solid-svg-icons'
import { ReducerData, ReducerGraphic, Reducers, ReducerUser } from '../../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { Company } from '../../../types/user'
import Select from 'react-select'
import { citiesPerSelect, provincePerSelect } from '../../../utilities/functions'
import { addEditAzienda, uploadImage } from '../../../resources/requests/requests'
import { Ambito } from '../../../utilities/Ambito'
import { getCompaniesListRedux, setMyCompany } from '../../../store/actions/thunk_actions'
import { setError, setIsLoading } from '../../../store/actions/graphic'
import Input from '../../../components/formComponents/Input'
import SelectInput, { Option } from '../../../components/formComponents/SelectInput'

const DatiAziendaliScreen: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [currentCompany, setCurrentCompany] = useState<Company | null>(user.company)
    const [isDisabled, setIsDisabled] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        setCurrentCompany(user.company)
        setIsDisabled(user.user?.TipoAccount == 'Agente')
    }, [user.company])

    const provinceValue = () => {
        if (currentCompany && currentCompany.Provincia) {
            const cityFound = data.provinces.find((c) => c.Sigla == currentCompany.Provincia)
            if (cityFound) {
                return {
                    id: cityFound.Sigla,
                    value: cityFound.Sigla,
                    label: cityFound.Provincia
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const cityValue = () => {
        if (currentCompany && currentCompany.CAP) {
            const cityFound = data.cities.find((c) => c.CAP == currentCompany.CAP && c.Comune == currentCompany.Citta)
            if (cityFound) {
                return {
                    id: cityFound.CAP,
                    value: cityFound.IDComune,
                    label: cityFound.Comune
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const handleSave = async () => {
        dispatch(setIsLoading(true))
        try {
            if (currentCompany && user.company) {
                let img = user.company.Logo
                if (currentCompany.Logo.name) {
                    img = await uploadImage(currentCompany.Logo, Ambito.LOGO_AZIENDA, dispatch, undefined, currentCompany.CodAZ)
                }
                const newCurrentCompany: Company = {
                    ...currentCompany,
                    Logo: img
                }
                await addEditAzienda(newCurrentCompany, dispatch)
                dispatch(setMyCompany())
                dispatch(getCompaniesListRedux())
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <div>
            <div className="introProductsPage">
                <SubtitleSection text="Riepilogo dati aziendali" />
                <Button
                    text="Salva i dati"
                    hasIcon={true}
                    icon={faCog}
                    onClick={() => {
                        handleSave()
                    }}
                />
            </div>
            {currentCompany ? (
                <div className="formSettings">
                    <Input
                        value={currentCompany?.RagioneSociale}
                        onChange={(e) => {
                            setCurrentCompany({ ...currentCompany, RagioneSociale: e })
                        }}
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        label="Ragione sociale"
                        placeholder="Ragione sociale*"
                        disabled={isDisabled || user.user?.TipoAccount == 'Rivenditore'}
                        required
                    />
                    <Input
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        value={currentCompany?.PIva}
                        onChange={(e) => {
                            setCurrentCompany({ ...currentCompany, PIva: e })
                        }}
                        label="Partita IVA"
                        placeholder="Partita IVA*"
                        disabled={isDisabled || user.user?.TipoAccount == 'Rivenditore'}
                        required
                    />
                    <Input
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        value={currentCompany?.CF}
                        onChange={(e) => {
                            setCurrentCompany({ ...currentCompany, CF: e })
                        }}
                        label="Codice Fiscale"
                        placeholder="Codice Fiscale*"
                        disabled={isDisabled || user.user?.TipoAccount == 'Rivenditore'}
                        required
                    />
                    <Input
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        value={currentCompany?.Indirizzo}
                        onChange={(e) => {
                            setCurrentCompany({ ...currentCompany, Indirizzo: e })
                        }}
                        label="Indirizzo"
                        placeholder="Indirizzo*"
                        disabled={isDisabled || user.user?.TipoAccount == 'Rivenditore'}
                        required
                    />
                    <SelectInput
                        label="Provincia"
                        placeholder="Provincia"
                        options={provincePerSelect(data.provinces)}
                        onChange={(e) => {
                            const value = e as Option
                            setCurrentCompany({
                                ...currentCompany,
                                Provincia: value.id as string
                            })
                        }}
                        value={provinceValue()}
                    />
                    <SelectInput
                        label="Comune"
                        placeholder="Comune"
                        options={citiesPerSelect(
                            currentCompany?.Provincia !== '' ? data.cities.filter((c) => c.Sigla == currentCompany?.Provincia) : data.cities
                        )}
                        onChange={(e) => {
                            const value = e as Option
                            setCurrentCompany({
                                ...currentCompany,
                                Citta: value.value as string,
                                CAP: value.id as string
                            })
                        }}
                        value={cityValue()}
                    />
                    <Input
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        value={currentCompany?.Nazione}
                        onChange={(e) => {
                            setCurrentCompany({ ...currentCompany, Nazione: e })
                        }}
                        label="Nazione"
                        placeholder="Nazione*"
                        disabled={isDisabled || user.user?.TipoAccount == 'Rivenditore'}
                        required
                    />
                    <Input
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        value={currentCompany?.SitoWeb}
                        onChange={(e) => {
                            setCurrentCompany({ ...currentCompany, SitoWeb: e })
                        }}
                        label="Sito Web"
                        placeholder="Sito Web*"
                        disabled={isDisabled || user.user?.TipoAccount == 'Rivenditore'}
                        required
                    />
                    <Input
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        value={currentCompany?.Email}
                        onChange={(e) => {
                            setCurrentCompany({ ...currentCompany, Email: e })
                        }}
                        type="email"
                        label="Email"
                        placeholder="Email*"
                        disabled={isDisabled || user.user?.TipoAccount == 'Rivenditore'}
                        required
                    />
                    <Input
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        value={currentCompany?.Telefono}
                        onChange={(e) => {
                            setCurrentCompany({ ...currentCompany, Telefono: e })
                        }}
                        type="tel"
                        label="Telefono"
                        placeholder="Telefono*"
                        disabled={isDisabled || user.user?.TipoAccount == 'Rivenditore'}
                        required
                    />
                </div>
            ) : null}
            <SubtitleSection text="Personalizzazione pannello" />
            <div className="customCards">
                <UploadCard
                    disabled={isDisabled}
                    title="Logo aziendale"
                    defaultImage={currentCompany?.Logo}
                    opacityButton={isDisabled ? 0.5 : 1}
                    editImage={(e: any) => {
                        if (currentCompany) {
                            setCurrentCompany({
                                ...currentCompany,
                                Logo: e
                            })
                        }
                    }}
                />
                <UploadCard
                    title="Colore principale"
                    opacityButton={isDisabled ? 0.5 : 1}
                    disabled={isDisabled}
                    defaultColor={currentCompany?.color && currentCompany.color.includes('#') ? currentCompany.color : '#005AAB'}
                    editColor={(c) => {
                        if (currentCompany) {
                            setCurrentCompany({
                                ...currentCompany,
                                color: c
                            })
                        }
                    }}
                    isColorPicker={true}
                />
            </div>
        </div>
    )
}

export default DatiAziendaliScreen
