import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { Typology, TypologyItemQuote, TypologyPushObject } from '../types/data'
import TypologyFastVariantModal from '../components/modals/TypologyFastVariantModal'
import { usePrevious } from '../utilities/functions';
import { ReducerOrder, Reducers } from '../types/reducers';
import { useSelector } from 'react-redux'
import { LOGO_DEFAULT } from '../resources/constants';

interface Props {
    typology: Typology
    setSelectedTypologies: (t: TypologyPushObject) => void
}

const TypologyCard: React.FC<Props> = (props) => {
    const typology = props.typology;
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [selectedVariants, setSelectedVariants] = useState<TypologyItemQuote[]>([{
        Htip: parseInt(props.typology.HPred),
        Ltip: parseInt(props.typology.LPred),
        HManiglia: props.typology.HManiglia ? parseInt(props.typology.HManiglia) : null,
        HTraverso1: props.typology.HTraverso1 ? parseInt(props.typology.HTraverso1) : null,
        HTraverso2: props.typology.HTraverso2 ? parseInt(props.typology.HTraverso2) : null,
        HTraverso3: props.typology.HTraverso3 ? parseInt(props.typology.HTraverso3) : null,
        HTraverso4: props.typology.HTraverso4 ? parseInt(props.typology.HTraverso4) : null,
        Quantita: 0,
        Ordinamento: 1,
        Etichetta: "",
        PRM: null,
        Prezzo: null,
        Sconto: null,
        Annotazioniutente: "",
        DescrizioneInterneDB: props.typology.DescrizTip
    }])
    const previousSelectedVariants = usePrevious(selectedVariants)

    useEffect(() => {
        const filteredArray = order.itemsInQuote.filter((i) => i.DettaglioItem.CodTipoItem == typology.CodTip)
        if (order.itemsInQuote.length > 0 && filteredArray.length > 0) {
            setSelectedVariants(filteredArray.map((f) => {
                return {
                    Htip: parseInt(f.DettaglioItem.Htip),
                    Ltip: parseInt(f.DettaglioItem.Ltip),
                    HManiglia: f.DettaglioItem.HManiglia ? parseInt(f.DettaglioItem.HManiglia) : null,
                    HTraverso1: f.DettaglioItem.HTraverso1 ? parseInt(f.DettaglioItem.HTraverso1) : null,
                    HTraverso2: f.DettaglioItem.HTraverso2 ? parseInt(f.DettaglioItem.HTraverso2) : null,
                    HTraverso3: f.DettaglioItem.HTraverso3 ? parseInt(f.DettaglioItem.HTraverso3) : null,
                    HTraverso4: f.DettaglioItem.HTraverso4 ? parseInt(f.DettaglioItem.HTraverso4) : null,
                    Quantita: f.DettaglioItem.Quantita,
                    Ordinamento: parseInt(f.DettaglioItem.Ordinamento),
                    Etichetta: f.DettaglioItem.Etichetta,
                    PRM: f.DettaglioItem.PRM,
                    Prezzo: f.DettaglioItem.Prezzo,
                    Sconto: null,
                    Annotazioniutente: f.DettaglioItem.Annotazioniutente,
                    DescrizioneInterneDB: f.DettaglioItem.DescrizioneInterneDB
                }
            }))
        }
    }, [order.itemsInQuote])

    const getTotalQuantity = () => {
        let quantity = 0
        selectedVariants.forEach((v) => quantity = quantity + v.Quantita)
        return quantity
    }

    const isQuantityDisabled = () => {
        if (selectedVariants.length > 1) {
            return true
        }
        if (selectedVariants.length == 1 && selectedVariants[0].Prezzo !== null) {
            return true
        }
        return false
    }

    useEffect(() => {
        if (previousSelectedVariants) {
            props.setSelectedTypologies({
                CodTipoItem: props.typology.CodTip,
                Items: selectedVariants.filter((variant) => variant.Quantita > 0 && variant.Prezzo == null)
            })
        }
    }, [selectedVariants])

    return (
        <div className="typologyCard">
            <TypologyFastVariantModal variants={selectedVariants} isVisible={isModalVisible} typology={typology} hideModal={() => setIsModalVisible(false)} setSelectedVariants={(v) => setSelectedVariants(v)} />
            <FontAwesomeIcon icon={faInfoCircle} onClick={() => setIsModalVisible(true)} className="icon" />
            <img src={typology.Figura !== "" ? typology.Figura : LOGO_DEFAULT} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, justifyContent: 'space-between' }}>
                <h3>{typology.DescrizTip}</h3>
                <div className="quantityBox">
                    <span>Quantità</span>
                    <input
                        min={0}
                        value={selectedVariants.length == 1 ? selectedVariants[0].Quantita : getTotalQuantity()}
                        onChange={(e) => {
                            if (selectedVariants.length == 1) {
                                setSelectedVariants(selectedVariants.map((v) => {
                                    return {
                                        ...v,
                                        Quantita: parseInt(e.target.value)
                                    }
                                }))
                            }
                        }}
                        disabled={isQuantityDisabled()}
                        defaultValue={0}
                        type="number"
                    />
                </div>
            </div>

        </div>
    )
}

export default TypologyCard