import React from 'react'
import './App.css'
import { useSelector, useDispatch } from 'react-redux'
import { ReducerGraphic, ReducerOrder, Reducers, ReducerUser } from './types/reducers'
import { BrowserRouter, Route, useHistory } from 'react-router-dom'
import LoginPage from './pages/User/LoginPage'
import ForgotPasswordPage from './pages/User/ForgotPasswordPage'
import LoggedRoute from './pages/LoggedRoute'
import { useEffect } from 'react'
import { setOffersRedux, setPricesRedux, setStartingData, setTokenRedux, setUserRedux } from './store/actions/thunk_actions'
import { LogoutUser } from './resources/requests/requests'
import { setColor, setShowContacts } from './store/actions/graphic'
import { BLUE_DEFAULT, SUB_PATH_URL_FRONTEND } from './resources/constants'
import ContactsModal from './components/modals/ContactsModal'

const App: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (user.token && user.token !== 'ERROR') {
            dispatch(setTokenRedux(user.token))
            dispatch(setUserRedux())
        }
    }, [user.token])

    useEffect(() => {
        if (user.company && user.company.color && user.company.color.includes('#')) {
            dispatch(setColor(user.company.color))
        } else {
            dispatch(setColor(BLUE_DEFAULT))
        }
    }, [user.company])

    //console.log(user.token)

    useEffect(() => {
        if (order.currentOffer) {
            dispatch(setPricesRedux(order.currentOffer.CodiceOfferta))
            //dispatch(setOffersRedux())
        }
    }, [order.currentOffer])

    useEffect(() => {
        return () => {
            LogoutUser()
        }
    }, [])

    document.addEventListener('wheel', function (event) {
        //@ts-ignore
        if (document.activeElement && document.activeElement.type === 'number') {
            //@ts-ignore
            document.activeElement.blur()
        }
    })

    return (
        /* manca Production ?
         basename={
                process.env.REACT_APP_RELEASE_ENV === 'development' || process.env.REACT_APP_RELEASE_ENV === 'testing'
                    ? process.env.PUBLIC_URL
                    : '/biemme_genera/panel'
            }

        SUB_PATH_URL_FRONTEND
        */
        <BrowserRouter
            basename={
                process.env.REACT_APP_RELEASE_ENV === 'development' //|| process.env.REACT_APP_RELEASE_ENV === 'testing'
                    ? process.env.PUBLIC_URL
                    : process.env.REACT_APP_RELEASE_ENV === 'production' || process.env.REACT_APP_RELEASE_ENV === 'testing' || process.env.REACT_APP_RELEASE_ENV === 'staging'
                    ? SUB_PATH_URL_FRONTEND
                    : SUB_PATH_URL_FRONTEND
            }
        >
            {!user.token || user.token == 'ERROR' ? (
                <div className="container">
                    <Route path="/" component={LoginPage} />
                    <Route exact path="/forgot-password" component={ForgotPasswordPage} />
                </div>
            ) : (
                <LoggedRoute />
            )}
            <ContactsModal isVisible={graphic.showContacts} hideModal={() => dispatch(setShowContacts(false))} />
        </BrowserRouter>
    )
}

export default App
