import React, { useEffect, useMemo, useState } from 'react'
import TitlePage from '../../components/TitlePage'
import { useDispatch, useSelector } from 'react-redux'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { faFile, faSearch } from '@fortawesome/free-solid-svg-icons'
import { ReducerUser, Reducers, ReducerGraphic } from '../../types/reducers'
import Button from '../../components/Button'
import { deleteDoc, getDocs, getXMLDocs } from '../../resources/requests/others'
import { Doc, DocXML } from '../../types/others'
import MaterialTable from 'material-table'
import moment from 'moment'
import { locale_itIT } from '../../utilities/tableLocale'
import DeleteModal from '../../components/modals/DeleteModal'
import AddDocModal from '../../components/modals/AddDocModal'
import Input from '../../components/formComponents/Input'

const DocumentsXML: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [documents, setDocuments] = useState<DocXML[]>([])
    const [search, setSearch] = useState('')
    const dispatch = useDispatch()

    const fetchDocuments = async () => {
        dispatch(setIsLoading(true))
        try {
            const docs = await getXMLDocs(dispatch)
            setDocuments(docs)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    useEffect(() => {
        if (user.user) {
            fetchDocuments()
        }
    }, [user.user])

    const renderColumns = useMemo(() => {
        const columns = [
            {
                title: 'Nome',
                field: 'name',
                render: (rowData: DocXML) => {
                    return <p>{rowData.name}</p>
                }
            },
            {
                title: 'Link',
                field: 'link',
                render: (rowData: DocXML) => {
                    return (
                        <a style={{ color: graphic.colorAccent, textDecoration: 'none' }} href={rowData.link} rel="noreferrer" target="_blank">
                            {rowData.link}
                        </a>
                    )
                }
            }
        ]
        if (user.user?.TipoAccount == 'Amministratore') {
            columns.push({
                title: 'Data creazione',
                field: 'dataCreazione',
                render: (rowData: DocXML) => {
                    return <p>{moment(rowData.dataCreazione, 'DD-MM-YYYY HH:mm:ss A').format('DD MMMM YYYY HH:mm')}</p>
                }
            })
        }
        return columns
    }, [])

    const renderDatas = useMemo(() => {
        if (search) {
            return documents.filter((d) => d.name.toLowerCase().includes(search.toLowerCase()) || d.link.toLowerCase().includes(search.toLowerCase()))
        }
        return documents
    }, [documents, search])

    const renderActions = useMemo(() => {
        const actions = [
            (rowData: DocXML) => ({
                icon: 'download',
                iconProps: {
                    fontSize: 'medium'
                },
                tooltip: 'Scarica',
                onClick: () => {
                    window.open(rowData.link, '_blank')
                }
            })
        ]

        return actions
    }, [])

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TitlePage text="Documenti XML" />
                <Input
                    icon={faSearch}
                    isFilterInput
                    placeholder="Ricerca documento"
                    value={search}
                    onChange={(e) => {
                        if (e) {
                            setSearch(e)
                        } else {
                            setSearch('')
                        }
                    }}
                />
            </div>
            <MaterialTable
                title=""
                columns={renderColumns}
                data={renderDatas}
                localization={locale_itIT}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    search: false,
                    headerStyle: {
                        backgroundColor: '#ebebeb',
                        borderRadius: 20
                    }
                }}
                //@ts-ignore
                actions={renderActions}
            />
        </div>
    )
}

export default DocumentsXML
