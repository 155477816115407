import { faCheck, faCopy, faEdit, faImage, faInfoCircle, faTag, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import { CurrentOffer, ItemInQuote, ItemToEdit, VariantItem } from '../types/order'
import { transformToCurrency } from '../utilities/functions'
import Button from './Button'
import {
    calculateTotalPrices,
    deleteItemFromQuote,
    duplicateItemInQuote,
    editItemPreventivo,
    getSingleItemQuote,
    recalculateItems,
    saveCurrentOffer,
    uploadImage
} from '../resources/requests/requests'
import { ReducerData, ReducerGraphic, ReducerOrder, Reducers } from '../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentOffer, setItemsInQuote, setPricesTotalOffer } from '../store/actions/order'
import { setOfferLoadedRedux } from '../store/actions/thunk_actions'
import { setError, setIsLoading } from '../store/actions/graphic'
import DeleteModal from './modals/DeleteModal'
import DuplicateModal from './modals/DuplicateModal'
import FreeEditDimensionModal from './modals/FreeEditDimensionModal'
import FreePriceModal from './modals/FreePriceModal'
import { Ambito } from '../utilities/Ambito'

interface Props {
    itemInQuote: ItemInQuote
    idRecord: string
    goPrevPhase: () => void
    isAutoRecalc?: boolean
}

enum VARIANT_TAB {
    DEFAULT,
    ADDITIONAL
}

const FreeTypologyListElement: React.FC<Props> = (props) => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [currentItemInQuote, setCurrentItemInQuote] = useState(props.itemInQuote)
    const [additionalVariants, setAdditionalVariants] = useState<VariantItem[]>([])
    const [currentVariantTab, setCurrentVariantTab] = useState(VARIANT_TAB.DEFAULT)
    const [visibleModalGeometria, setVisibleModalGeometria] = useState(false)
    const [visibleModalPrice, setVisibleModalPrice] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false)
    const previewImg = useRef(null)
    const dispatch = useDispatch()

    const renderFirstColumn = () => {
        return (
            <div
                style={{
                    borderRight: '1px solid #D3D3D3',
                    paddingRight: 20,
                    flex: 2,
                    textAlign: 'center',
                    color: '#68696D',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <p style={{ fontSize: 16, fontWeight: 500 }}>N° ord.: {currentItemInQuote.DettaglioItem.Ordinamento}</p>
                <h2 style={{ color: '#000', fontSize: 22, fontWeight: 900 }}>{currentItemInQuote.DettaglioItem.Etichetta}</h2>
                <p style={{ fontSize: 18 }}>{currentItemInQuote.DettaglioItem.DescrizioneInterneDB}</p>
                <div style={{ margin: '20px 0', position: 'relative' }}>
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        onClick={() => setVisibleModalGeometria(true)}
                        style={{
                            top: -10,
                            right: 0,
                            position: 'absolute',
                            fontSize: 24,
                            color: graphic.colorAccent,
                            cursor: 'pointer',
                            backgroundColor: '#fff',
                            borderRadius: '50%'
                        }}
                    />
                    <img
                        ref={previewImg}
                        src={currentItemInQuote.DettaglioItem.FiguraRiferimento}
                        style={{
                            maxWidth: '100%',
                            borderRadius: 10,
                            height: '20vh',
                            width: '20vh',
                            backgroundColor: '#fff',
                            objectFit: 'contain',
                            padding: 5,
                            marginBottom: 10
                        }}
                    />
                    <Button
                        isSmall
                        hasUpload={true}
                        text="Carica"
                        icon={faImage}
                        onChange={(e) => {
                            setCurrentItemInQuote({
                                ...currentItemInQuote,
                                DettaglioItem: {
                                    ...currentItemInQuote.DettaglioItem,
                                    FiguraRiferimento: e
                                }
                            })
                        }}
                        onClick={() => {
                            return
                        }}
                    />
                    {currentItemInQuote.DettaglioItem.FiguraRiferimento.name && (
                        <div style={{ marginTop: 10 }}>
                            <Button
                                isSmall
                                text="Salva immmagine"
                                hasIcon
                                icon={faCopy}
                                onClick={() => {
                                    handleUpdateItem()
                                }}
                            />
                        </div>
                    )}
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 20, justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span style={{ color: '#000', fontSize: 14 }}>L (mm.)</span>
                        <span>{currentItemInQuote.DettaglioItem.Ltip}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span style={{ color: '#000', fontSize: 14 }}>H (mm.)</span>
                        <span>{currentItemInQuote.DettaglioItem.Htip}</span>
                    </div>
                </div>
            </div>
        )
    }

    const renderSecondColumn = () => {
        return (
            <div style={{ padding: '0 20px', flex: 6 }}>
                <textarea
                    style={{
                        width: 'calc(100% - 20px)',
                        height: '80%',
                        resize: 'none',
                        border: 0,
                        borderRadius: 15,
                        padding: 10,
                        fontSize: 18,
                        fontFamily: 'Raleway'
                    }}
                    placeholder="Inserisci descrizione tipologia..."
                    value={currentItemInQuote.DettaglioItem.Annotazioniutente}
                    onChange={(e) => {
                        setCurrentItemInQuote({
                            ...currentItemInQuote,
                            DettaglioItem: {
                                ...currentItemInQuote.DettaglioItem,
                                Annotazioniutente: e.target.value
                            }
                        })
                    }}
                />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                        isSmall
                        text="Salva note"
                        hasIcon={true}
                        icon={faEdit}
                        onClick={() => {
                            handleUpdateItem()
                        }}
                    />
                </div>
            </div>
        )
    }

    useEffect(() => {
        setCurrentItemInQuote(props.itemInQuote)
    }, [props.itemInQuote])

    useEffect(() => {
        if (currentItemInQuote.DettaglioItem.FiguraRiferimento.name) {
            const fr = new FileReader()
            fr.onload = function () {
                //@ts-ignore
                previewImg.current.src = fr.result
            }
            fr.readAsDataURL(currentItemInQuote.DettaglioItem.FiguraRiferimento)
            return
        }
    }, [currentItemInQuote.DettaglioItem.FiguraRiferimento])

    const handleUpdateItem = async () => {
        dispatch(setIsLoading(true))
        try {
            let img = currentItemInQuote.DettaglioItem.FiguraRiferimento
            if (currentItemInQuote.DettaglioItem.FiguraRiferimento.name) {
                img = await uploadImage(currentItemInQuote.DettaglioItem.FiguraRiferimento, Ambito.LIBERA, dispatch)
            }
            const body: ItemToEdit = {
                idRecord: currentItemInQuote.idRecord,
                CodicePreventivo: order.newQuoteFromUser?.CodicePreventivo,
                CodiceSerie: currentItemInQuote.DettaglioItem.CodiceSerie,
                CodTipoItem: currentItemInQuote.DettaglioItem.CodTipoItem,
                Htip: currentItemInQuote.DettaglioItem.Htip,
                Ltip: currentItemInQuote.DettaglioItem.Ltip,
                Quantita: currentItemInQuote.DettaglioItem.Quantita,
                Etichetta: currentItemInQuote.DettaglioItem.Etichetta,
                Ordinamento: currentItemInQuote.DettaglioItem.Ordinamento,
                HManiglia: currentItemInQuote.DettaglioItem.HManiglia ? currentItemInQuote.DettaglioItem.HManiglia : null,
                HTraverso1: currentItemInQuote.DettaglioItem.HTraverso1 ? currentItemInQuote.DettaglioItem.HTraverso1 : null,
                HTraverso2: currentItemInQuote.DettaglioItem.HTraverso2 ? currentItemInQuote.DettaglioItem.HTraverso2 : null,
                HTraverso3: currentItemInQuote.DettaglioItem.HTraverso3 ? currentItemInQuote.DettaglioItem.HTraverso3 : null,
                HTraverso4: currentItemInQuote.DettaglioItem.HTraverso4 ? currentItemInQuote.DettaglioItem.HTraverso4 : null,
                PRM: currentItemInQuote.DettaglioItem.PRM ? currentItemInQuote.DettaglioItem.PRM.toString() : null,
                Prezzo: currentItemInQuote.DettaglioItem.Prezzo.toString(),
                Sconto: currentItemInQuote.DettaglioItem.Sconto.toString(),
                Annotazioniutente: currentItemInQuote.DettaglioItem.Annotazioniutente,
                FiguraRiferimento: img
            }
            await editItemPreventivo(body, dispatch)
            await recalculateItems(
                order.newQuoteFromUser?.CodicePreventivo,
                order.newQuoteFromUser?.CodiceOfferta,
                [{ idItem: currentItemInQuote.idRecord }],
                dispatch
            )
            const updatedItem = await getSingleItemQuote(currentItemInQuote.idRecord, order.newQuoteFromUser?.CodicePreventivo, dispatch)
            dispatch(
                setItemsInQuote(
                    order.itemsInQuote.map((item) => {
                        if (item.idRecord == currentItemInQuote.idRecord) {
                            return {
                                ...item,
                                DettaglioItem: updatedItem
                            }
                        } else {
                            return item
                        }
                    })
                )
            )
            const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
            const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
            dispatch(setPricesTotalOffer(prices))
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    console.log(currentItemInQuote)

    const renderThirdColumn = () => {
        return (
            <div
                style={{
                    borderLeft: '1px solid #D3D3D3',
                    paddingLeft: 20,
                    flex: 1,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#68696D'
                }}
            >
                <div style={{ paddingBottom: 20, borderBottom: '1px solid #D3D3D3', display: 'flex', flexDirection: 'column' }}>
                    <span>Prezzo</span>
                    <span style={{ color: '#000', fontWeight: 900, fontSize: 22 }}>
                        {transformToCurrency(currentItemInQuote.DettaglioItem.Prezzo * currentItemInQuote.DettaglioItem.Quantita)}
                    </span>
                    <span style={{ fontSize: 12, margin: '15px 0' }}>
                        Sconto applicato {currentItemInQuote.DettaglioItem.Sconto ? currentItemInQuote.DettaglioItem.Sconto.toFixed(2) : 0}%
                    </span>
                    <span style={{ fontSize: 12 }}>
                        {transformToCurrency(currentItemInQuote.DettaglioItem.Prezzo)} x {currentItemInQuote.DettaglioItem.Quantita}
                    </span>
                    <span style={{ fontSize: 12, padding: '5px 0' }}>IVA esclusa</span>
                </div>
                <div
                    style={{
                        paddingBottom: 20,
                        borderBottom: '1px solid #D3D3D3',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        padding: '20px 0',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        gap: 20
                    }}
                >
                    <div className="quantityBox" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span>Quantità</span>
                        <input
                            min={0}
                            value={currentItemInQuote.DettaglioItem.Quantita}
                            onChange={(e) => {
                                setCurrentItemInQuote({
                                    ...currentItemInQuote,
                                    DettaglioItem: {
                                        ...currentItemInQuote.DettaglioItem,
                                        Quantita: parseInt(e.target.value)
                                    }
                                })
                            }}
                            defaultValue={0}
                            type="number"
                        />
                    </div>
                    {currentItemInQuote.DettaglioItem.Quantita !== props.itemInQuote.DettaglioItem.Quantita && (
                        <FontAwesomeIcon
                            icon={faCheck}
                            onClick={() => {
                                handleUpdateItem()
                            }}
                            style={{ backgroundColor: graphic.colorAccent, fontSize: 15, color: '#fff', cursor: 'pointer', padding: 8, borderRadius: 5 }}
                        />
                    )}
                </div>
                <div style={{ paddingBottom: 20, display: 'flex', flexDirection: 'column', width: '100%', padding: '20px 0', gap: 10 }}>
                    <Button
                        isSmall
                        text="Prezzo"
                        hasIcon={true}
                        icon={faTag}
                        onClick={() => {
                            setVisibleModalPrice(true)
                        }}
                    />
                    <Button
                        isSmall
                        text="Duplica"
                        hasIcon={true}
                        buttonColor="#52565A"
                        icon={faCopy}
                        onClick={() => {
                            setIsDuplicateModalVisible(true)
                        }}
                    />
                    <Button
                        isSmall
                        text="Elimina"
                        hasIcon={true}
                        buttonColor="#FF0000"
                        icon={faTrash}
                        onClick={() => {
                            setIsDeleteModalVisible(true)
                        }}
                    />
                </div>
            </div>
        )
    }

    const handleAddItemToPreventivo = async () => {
        dispatch(setIsLoading(true))
        try {
            if (order.newQuoteFromUser) {
                const item: any = await duplicateItemInQuote(order.newQuoteFromUser?.CodiceOfferta, currentItemInQuote.idRecord, dispatch)
                const newItem: ItemInQuote = {
                    idRecord: item.idItem.toString,
                    Message: '',
                    DettaglioItem: item
                }
                dispatch(setItemsInQuote([...order.itemsInQuote, newItem]))
                const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
                const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
                dispatch(setPricesTotalOffer(prices))
                if (order.currentOffer) {
                    const offer = await saveCurrentOffer(order.newQuoteFromUser?.CodiceOfferta, dispatch)
                    if (offer) {
                        dispatch(setCurrentOffer(offer))
                    }
                    // dispatch(setOfferLoadedRedux(order.newQuoteFromUser?.CodiceOfferta, true))
                }
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    const handleDeleteItemToPreventivo = async () => {
        dispatch(setIsLoading(true))
        try {
            await deleteItemFromQuote(currentItemInQuote.idRecord, dispatch)
            const filteredArray = order.itemsInQuote.filter((i) => i.idRecord !== currentItemInQuote.idRecord)
            const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
            const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
            dispatch(setPricesTotalOffer(prices))
            if (filteredArray.length > 0) {
                dispatch(setItemsInQuote(filteredArray))
            } else {
                dispatch(setItemsInQuote([]))
                props.goPrevPhase()
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    return (
        <div className="cardCustomTypology">
            <FreePriceModal
                isVisible={visibleModalPrice}
                itemInQuote={currentItemInQuote}
                hideModal={() => setVisibleModalPrice(false)}
                updatedItem={(item) => {
                    setCurrentItemInQuote(item)
                }}
            />
            <FreeEditDimensionModal
                updatedItem={(item) => {
                    setCurrentItemInQuote({
                        ...currentItemInQuote,
                        DettaglioItem: item
                    })
                }}
                isVisible={visibleModalGeometria}
                hideModal={() => setVisibleModalGeometria(false)}
                itemInQuote={currentItemInQuote}
            />
            <DeleteModal
                isVisible={isDeleteModalVisible}
                hideModal={() => setIsDeleteModalVisible(false)}
                onDelete={() => {
                    handleDeleteItemToPreventivo()
                    setIsDeleteModalVisible(false)
                }}
                text={"Cancella l'elemento (ID " + currentItemInQuote.idRecord + ')'}
                title={'Elimina elemento'}
            />
            <DuplicateModal
                isVisible={isDuplicateModalVisible}
                hideModal={() => setIsDuplicateModalVisible(false)}
                onDuplicate={() => {
                    handleAddItemToPreventivo()
                    setIsDuplicateModalVisible(false)
                }}
                text={"Duplica l'elemento (ID " + currentItemInQuote.idRecord + ')'}
                title={'Duplicazione elemento'}
            />
            {renderFirstColumn()}
            {renderSecondColumn()}
            {renderThirdColumn()}
        </div>
    )
}

export default FreeTypologyListElement
