import { faCheckCircle, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { Serie } from '../types/data'
import { ReducerGraphic, Reducers } from '../types/reducers';
import { useSelector } from 'react-redux'
import { LOGO_DEFAULT } from '../resources/constants';

interface Props {
    product: Serie,
    isEdit?: boolean
    isSelectable?: boolean
    isSelected?: boolean
    onClick: () => void
}

const ProductCard: React.FC<Props> = (props) => {
    const product = props.product;
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)

    if (props.isSelectable) {
        return (
            <div className="productCard" style={{ backgroundColor: '#fff', cursor: 'initial' }} >
                <div style={{ border: "1px solid #ebebeb", backgroundColor: '#e9e9e9', position: 'absolute', top: 5, right: 5, width: 30, height: 30, borderRadius: "50%", display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => props.onClick()}>
                    {props.isSelected && (
                        <FontAwesomeIcon icon={faCheckCircle} style={{ position: 'relative', top: 0, right: 0, color: graphic.colorAccent, fontSize: 24 }} />
                    )}
                </div>
                <img src={product.ImgMain !== "" ? product.ImgMain : LOGO_DEFAULT} />
                <div>
                    <h3>{product.NomeSerie}</h3>
                    <p>{product.FamigliaProdotto}</p>
                </div>

            </div>
        )
    }

    if (props.isEdit) {
        return (
            <div className="productCard" style={{ cursor: 'initial' }}>
                <div style={{ position: 'relative' }}>
                    <FontAwesomeIcon
                        icon={faPencilAlt}
                        onClick={() => props.onClick()}
                        style={{ fontSize: 20, position: 'absolute', backgroundColor: graphic.colorAccent, top: -5, right: -5, color: '#fff', padding: 10, borderRadius: "50%", boxShadow: '0 3px 6px rgba(0,0,0,0.3)', cursor: 'pointer' }}
                    />
                    <img src={product.ImgMain !== "" ? product.ImgMain : LOGO_DEFAULT} />
                </div>
                <div>
                    <h3>{product.NomeSerie}</h3>
                    <p>{product.FamigliaProdotto}</p>
                </div>

            </div>
        )
    }

    return (
        <div className="productCard" onClick={() => props.onClick()}>
            <img src={product.ImgMain !== "" ? product.ImgMain : LOGO_DEFAULT} />
            <div>
                <h3>{product.NomeSerie}</h3>
                <p>{product.FamigliaProdotto}</p>
            </div>

        </div>
    )
}

export default ProductCard