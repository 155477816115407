import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { DataVar } from '../../pages/Settings/screens/ListinoScreen'
import Button from '../Button'
import Input from '../formComponents/Input'
import TitlePage from '../TitlePage'

interface Props {
    dataVar: DataVar | null
    isVisible: boolean
    hideModal: () => void
    onSave: (v: DataVar) => void
}

const editableKeys = [
    'PrioritaCalcoloPredefinita',
    'PrioritaCalcoloAmmessa',
    'FormulaValiditaPredefinita',
    'FormulaValiditaAmmessa',
    'FormulaCalcoloAmmessa',
    'Prezzo'
]

const EditDataVarModal: React.FC<Props> = (props) => {
    const [dataVar, setDataVar] = useState<DataVar | null>(props.dataVar)

    useEffect(() => {
        if (props.dataVar) {
            setDataVar(props.dataVar)
        }
    }, [props.isVisible])

    console.log(dataVar)

    if (dataVar) {
        return (
            <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="lg" onBackdropClick={() => props.hideModal()}>
                <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                    <TitlePage text={`Modifica variante ${dataVar?.DescrizVar}`} style={{ margin: 0 }} />
                    <div className="gridInputOffer" style={{ margin: '20px 0', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                        {Object.keys(dataVar).map((k: string) => {
                            if (k == 'tableData' || k == 'ID') {
                                return null
                            }
                            return (
                                <Input
                                    key={k}
                                    value={dataVar[k as keyof DataVar].toString()}
                                    label={k.replace(/([A-Z])/g, ' $1')}
                                    disabled={!editableKeys.includes(k)}
                                    onChange={(e) => {
                                        if (editableKeys.includes(k)) {
                                            setDataVar({
                                                ...dataVar,
                                                [k]: e
                                            })
                                        }
                                    }}
                                    divStyle={{ flexDirection: 'column', display: 'flex' }}
                                    labelStyle={{ textTransform: 'capitalize' }}
                                />
                            )
                        })}
                    </div>
                </DialogContent>
                <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                    <Button
                        isSmall
                        text={'Annulla'}
                        buttonColor="#52565A"
                        onClick={() => {
                            props.hideModal()
                        }}
                    />
                    <Button
                        isSmall
                        text={'Salva'}
                        onClick={() => {
                            props.onSave(dataVar)
                        }}
                    />
                </DialogActions>
            </Dialog>
        )
    }
    return null
}

export default EditDataVarModal
