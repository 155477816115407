import React, { useEffect, useMemo, useState } from 'react'
import TitlePage from '../../components/TitlePage'
import { useDispatch, useSelector } from 'react-redux'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { faFile } from '@fortawesome/free-solid-svg-icons'
import { ReducerUser, Reducers } from '../../types/reducers'
import Button from '../../components/Button'
import { deleteDoc, getDocs } from '../../resources/requests/others'
import { Doc } from '../../types/others'
import MaterialTable from 'material-table'
import moment from 'moment'
import { locale_itIT } from '../../utilities/tableLocale'
import DeleteModal from '../../components/modals/DeleteModal'
import AddDocModal from '../../components/modals/AddDocModal'

const Documents: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [documents, setDocuments] = useState<Doc[]>([])
    const [docToDelete, setDocToDelete] = useState<Doc | null>(null)
    const [visibleAddDoc, setVisibleAddDoc] = useState(false)
    const dispatch = useDispatch()

    const fetchDocuments = async () => {
        dispatch(setIsLoading(true))
        try {
            const docs = await getDocs(dispatch)
            setDocuments(docs)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    useEffect(() => {
        fetchDocuments()
    }, [])

    const columns = [
        {
            title: 'Agente',
            field: 'Agente',
            render: (rowData: Doc) => {
                return <p>{rowData.Agente}</p>
            }
        },
        {
            title: 'Data Creazione',
            field: 'DataCreazione',
            render: (rowData: Doc) => {
                return <p>{moment(rowData.DataCreazione).format('DD/MM/YYYY HH:mm')}</p>
            }
        },
        {
            title: 'Titolo',
            field: 'Titolo',
            render: (rowData: Doc) => {
                return <p>{rowData.Titolo}</p>
            }
        },
        {
            title: 'Descrizione',
            field: 'Descrizione',
            render: (rowData: Doc) => {
                return <p>{rowData.Descrizione.length > 150 ? `${rowData.Descrizione.substring(0, 150)}...` : rowData.Descrizione}</p>
            }
        }
    ]

    const renderDatas = useMemo(() => {
        return documents
    }, [documents])

    const renderActions = useMemo(() => {
        const actions = [
            (rowData: Doc) => ({
                icon: 'download',
                iconProps: {
                    fontSize: 'medium'
                },
                tooltip: 'Scarica',
                onClick: () => {
                    window.open(rowData.Link, '_blank')
                }
            })
        ]

        if (user.user?.TipoAccount == 'Amministratore') {
            actions.push((rowData: Doc) => ({
                icon: 'delete',
                iconProps: {
                    color: 'error',
                    fontSize: 'medium'
                },
                tooltip: 'Elimina',
                onClick: () => {
                    setDocToDelete(rowData)
                }
            }))
        }

        return actions
    }, [])

    const handleDeleteDoc = async () => {
        if (docToDelete) {
            dispatch(setIsLoading(true))
            try {
                await deleteDoc(docToDelete?.ID, dispatch)
                setDocToDelete(null)
                fetchDocuments()
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TitlePage text="Documenti utili" />
                {user.user?.TipoAccount == 'Amministratore' ? (
                    <div style={{ display: 'flex', margin: '20px 0', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <Button
                            text="Aggiungi documento"
                            hasIcon={true}
                            icon={faFile}
                            onClick={() => {
                                setVisibleAddDoc(true)
                            }}
                        />
                    </div>
                ) : null}
            </div>
            <MaterialTable
                title=""
                columns={columns}
                data={renderDatas}
                localization={locale_itIT}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    search: false,
                    headerStyle: {
                        backgroundColor: '#ebebeb',
                        borderRadius: 20
                    }
                }}
                //@ts-ignore
                actions={renderActions}
            />
            <AddDocModal
                isVisible={visibleAddDoc}
                onSave={() => {
                    setVisibleAddDoc(false)
                    fetchDocuments()
                }}
                hideModal={() => setVisibleAddDoc(false)}
            />
            {docToDelete ? (
                <DeleteModal
                    isVisible={docToDelete ? true : false}
                    hideModal={() => setDocToDelete(null)}
                    onDelete={() => {
                        handleDeleteDoc()
                    }}
                    text={`Vuoi cancellare il messaggio ${docToDelete.Titolo}?`}
                    title={'Elimina messaggio'}
                />
            ) : null}
        </div>
    )
}

export default Documents
