import { CurrentOffer, OfferData } from './../../types/order'
import { Serie } from '../../types/data'
import { ReduxAction } from '../../types/reducers'
import { Company, PrintPage, User } from '../../types/user'

export enum USER_ACTIONS {
    SET_USER = 'userAction/setUser',
    SET_TOKEN = 'userAction/setToken',
    SET_ID_OFFER_PENDING = 'userAction/setIdOfferPending',
    SET_LAST_PRODUCT = 'userActions/setLastProduct',
    SET_LAST_OFFER_SEEN = 'userActions/setLastOfferSeen',
    SET_PRINT_FILE = 'userActions/setPrintFile',
    SET_COMPANY = 'userActions/setCompany',
    LOGOUT_USER = 'userAction/logoutUser'
}

export const setUser: ReduxAction<User> = (user: User) => {
    return {
        type: USER_ACTIONS.SET_USER,
        payload: user
    }
}

export const setToken: ReduxAction<string> = (token: string) => {
    return {
        type: USER_ACTIONS.SET_TOKEN,
        payload: token
    }
}

export const setIdOfferPending: ReduxAction<string> = (idOffer: string) => {
    return {
        type: USER_ACTIONS.SET_ID_OFFER_PENDING,
        payload: idOffer
    }
}

export const setLastProduct: ReduxAction<Serie> = (product: Serie) => {
    return {
        type: USER_ACTIONS.SET_LAST_PRODUCT,
        payload: product
    }
}

export const setLastOfferSeen: ReduxAction<CurrentOffer> = (offer: CurrentOffer) => {
    return {
        type: USER_ACTIONS.SET_LAST_OFFER_SEEN,
        payload: offer
    }
}

export const setPrintFile: ReduxAction<PrintPage> = (printPage: PrintPage) => {
    return {
        type: USER_ACTIONS.SET_PRINT_FILE,
        payload: printPage
    }
}

export const setCompany: ReduxAction<Company> = (company: Company) => {
    return {
        type: USER_ACTIONS.SET_COMPANY,
        payload: company
    }
}

export const logoutUser = () => {
    return {
        type: USER_ACTIONS.LOGOUT_USER
    }
}
