import React, { useEffect, useMemo, useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'
import { SaleCategory } from '../../types/data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlusSquare, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { ReducerData, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { createSaleCategorySeries, deleteCategorySale, editSaleCategorySeries } from '../../resources/requests/requests'
import { setSaleCategoriesRedux } from '../../store/actions/thunk_actions'
import { setError, setIsLoading } from '../../store/actions/graphic'
import Input from '../formComponents/Input'
import SelectInput, { Option } from '../formComponents/SelectInput'

interface Props {
    isVisible: boolean
    hideModal: () => void
    category: SaleCategory | null
    childCategory: SaleCategory[]
}

const SalePerSeriesModal: React.FC<Props> = (props) => {
    const voidCategory: SaleCategory = {
        ID: null,
        abilitato: props.category ? props.category.abilitato : '1',
        aggiuntivo: props.category ? props.category.aggiuntivo : '0',
        categoria: props.category ? props.category.categoria : '',
        livello: props.category ? props.category.livello : '',
        predefinito: props.category ? props.category.predefinito : '0',
        personalizzato: props.category ? props.category.personalizzato : '0',
        visibile: props.category ? props.category.visibile : '0',
        CodSerie: null,
        NomeSerie: null,
        CodAg: props.category ? props.category.CodAg : ''
    }
    const [categorySales, setCategorySales] = useState(props.childCategory && props.childCategory.length > 0 ? props.childCategory : [])
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.isVisible) {
            setCategorySales(props.childCategory && props.childCategory.length > 0 ? props.childCategory : [])
        }
    }, [props.isVisible, data.saleCategories])

    const saleValue = (category: SaleCategory) => {
        if (category.CodSerie) {
            const seriesFound = data.series.find((s) => s.CodSerie == category.CodSerie)
            if (seriesFound) {
                return {
                    id: seriesFound.CodSerie,
                    value: seriesFound.CodSerie,
                    label: seriesFound.NomeSerie
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const handleCreateSale = async (category: SaleCategory) => {
        dispatch(setIsLoading(true))
        try {
            const body = {
                CodAg: category.CodAg,
                categoria: category.categoria,
                NomeSerie: category.NomeSerie ? category.NomeSerie : '',
                CodSerie: category.CodSerie ? category.CodSerie : '',
                predefinito: parseFloat(category.predefinito),
                aggiuntivo: parseFloat(category.aggiuntivo)
            }
            await createSaleCategorySeries(body, dispatch)
            dispatch(setSaleCategoriesRedux())
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    console.log(categorySales)

    const handleEditSale = async (category: SaleCategory) => {
        dispatch(setIsLoading(true))
        try {
            if (category.ID) {
                const body = {
                    ID: parseInt(category.ID),
                    CodAg: category.CodAg,
                    categoria: category.categoria,
                    NomeSerie: category.NomeSerie ? category.NomeSerie : '',
                    CodSerie: category.CodSerie ? category.CodSerie : '',
                    predefinito: parseFloat(category.predefinito),
                    aggiuntivo: parseFloat(category.aggiuntivo)
                }
                await editSaleCategorySeries(body, dispatch)
                dispatch(setSaleCategoriesRedux())
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handleDeleteSale = async (category: SaleCategory) => {
        if (category.ID) {
            dispatch(setIsLoading(true))
            try {
                await deleteCategorySale(category.ID, dispatch)
                dispatch(setSaleCategoriesRedux())
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    const renderCategory = useMemo(() => {
        if (categorySales.length > 0) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 15, overflow: 'visible' }}>
                    {categorySales.map((c) => {
                        return (
                            <div
                                key={c.ID}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    backgroundColor: '#fff',
                                    flex: 1,
                                    width: 'calc(100% - 40px)',
                                    gap: 15,
                                    padding: 20,
                                    borderRadius: 20
                                }}
                            >
                                <SelectInput
                                    label="Serie"
                                    divStyle={{ display: 'flex', flexDirection: 'column', flex: 1 }}
                                    value={saleValue(c)}
                                    options={data.series
                                        .filter((s) => categorySales.find((cs) => cs.CodSerie == s.CodSerie) == undefined)
                                        .map((s) => {
                                            return { id: s.CodSerie, value: s.CodSerie, label: s.NomeSerie }
                                        })}
                                    placeholder="Seleziona aliquota"
                                    onChange={(e) => {
                                        const val = e as Option
                                        if (val) {
                                            setCategorySales(
                                                categorySales.map((cat) => {
                                                    if (cat.ID == c.ID) {
                                                        return {
                                                            ...cat,
                                                            NomeSerie: val.label,
                                                            CodSerie: val.id as string
                                                        }
                                                    } else {
                                                        return cat
                                                    }
                                                })
                                            )
                                        }
                                    }}
                                />
                                <Input
                                    label="Sconto principale"
                                    type="number"
                                    value={parseInt(c.predefinito)}
                                    style={{ display: 'flex', flexDirection: 'column', flex: 1, width: 'calc(100% - 34px)' }}
                                    min={0}
                                    max={props.category ? parseInt(props.category?.predefinito) : 50}
                                    onChange={(e) => {
                                        setCategorySales(
                                            categorySales.map((cat) => {
                                                if (cat.ID == c.ID) {
                                                    return {
                                                        ...cat,
                                                        predefinito:
                                                            props.category && parseInt(props.category.predefinito) < parseInt(e)
                                                                ? props.category.predefinito
                                                                : e
                                                    }
                                                } else {
                                                    return cat
                                                }
                                            })
                                        )
                                    }}
                                />
                                <Input
                                    label="Sconto aggiuntivo"
                                    type="number"
                                    value={parseInt(c.aggiuntivo)}
                                    style={{ display: 'flex', flexDirection: 'column', flex: 1, width: 'calc(100% - 34px)' }}
                                    min={0}
                                    max={props.category ? parseInt(props.category?.aggiuntivo) : 50}
                                    onChange={(e) => {
                                        setCategorySales(
                                            categorySales.map((cat) => {
                                                if (cat.ID == c.ID) {
                                                    return {
                                                        ...cat,
                                                        aggiuntivo:
                                                            props.category && parseInt(props.category.aggiuntivo) < parseInt(e) ? props.category.aggiuntivo : e
                                                    }
                                                } else {
                                                    return cat
                                                }
                                            })
                                        )
                                    }}
                                />
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, alignItems: 'flex-end', gap: 15 }}>
                                    <Button
                                        text="Salva"
                                        hasIcon
                                        icon={faCheck}
                                        opacity={c.CodSerie && parseFloat(c.aggiuntivo) >= 0 && parseFloat(c.predefinito) >= 0 ? 1 : 0.5}
                                        onClick={() => {
                                            if (!c.CodSerie && parseFloat(c.aggiuntivo) < 0 && parseFloat(c.predefinito) < 0) {
                                                return
                                            }
                                            if (!c.ID) {
                                                handleCreateSale(c)
                                            } else {
                                                handleEditSale(c)
                                            }
                                        }}
                                    />
                                    {c.ID && (
                                        <Button
                                            text="Elimina"
                                            hasIcon
                                            icon={faTrash}
                                            buttonColor="#E00000"
                                            opacity={c.CodSerie && parseFloat(c.aggiuntivo) >= 0 && parseFloat(c.predefinito) >= 0 ? 1 : 0.5}
                                            onClick={() => {
                                                handleDeleteSale(c)
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        )
                    })}
                    {categorySales.length > 0 && categorySales[categorySales.length - 1].ID && (
                        <div style={{ width: 'calc(100% - 40px)', padding: 20, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                text="Aggiungi serie"
                                hasIcon
                                icon={faPlusSquare}
                                onClick={() => {
                                    setCategorySales([...categorySales, voidCategory])
                                }}
                            />
                        </div>
                    )}
                </div>
            )
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 20 }}>
                <p style={{ fontSize: 20, color: '#68696D', margin: 0, textAlign: 'center' }}>Nessuna categoria di sconto assegnata alle serie.</p>
                <Button
                    text="Aggiungi serie"
                    hasIcon
                    icon={faPlusSquare}
                    onClick={() => {
                        setCategorySales([...categorySales, voidCategory])
                    }}
                />
            </div>
        )
    }, [categorySales])

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            className={categorySales.length > 3 ? '' : 'overflowVisible'}
            maxWidth="lg"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10, overflow: 'visible', maxHeight: '70vh' }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={`Assegnazione sconti a serie per categoria ${props.category?.categoria}`} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Assegna sconti alla categoria selezionata</p>
                <div style={{ margin: '30px 0', maxHeight: '70vh' }}>{renderCategory}</div>
            </DialogContent>
        </Dialog>
    )
}

export default SalePerSeriesModal
