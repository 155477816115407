import React, { useEffect, useMemo, useState } from 'react'
import { AdditionalCost, AdditionalCostItem, AdditionalCostOffer, ClienteScontoItem, Serie, Tax } from '../../types/data'
import { Dialog, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCopy, faPlusSquare, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'
import {
    calculateTotalPrices,
    getAdditionalCostList,
    getAdditionalCostsOffer,
    getAllItemsQuote,
    saveCurrentOffer,
    setQuotePrice,
    switchCosts
} from '../../resources/requests/requests'
import { ReducerData, ReducerGraphic, ReducerOrder, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { CurrentOffer, TotaliPreventivo } from '../../types/order'
import { convertSale, transformToCurrency, usePrevious } from '../../utilities/functions'
import Select from 'react-select'
import { setItemsInQuote, setPricesTotalOffer } from '../../store/actions/order'
import { setError, setIsLoading } from '../../store/actions/graphic'
import CostItem from '../CostItem'

interface Props {
    isVisible: boolean
    currentSerie: Serie | null
    codicePreventivo?: string
    codiceOfferta?: string
    currentQuote?: TotaliPreventivo | undefined | null
    currentOffer?: CurrentOffer | null
    hideModal: (changed: boolean) => void
}

interface SaleObject {
    salePercent: string
    salePercentAdd: string | null
    saleValue: string
}

enum SaleType {
    PERCENT,
    VALUE
}

const TotalPriceModal: React.FC<Props> = (props) => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [currentQuote, setCurrentQuote] = useState(props.currentQuote ? props.currentQuote : null)
    const [sale, setSale] = useState<SaleObject>({
        saleValue: props.currentQuote ? props.currentQuote.ScontoApplicato : '0',
        salePercent: '0',
        salePercentAdd: '0'
    })
    const [currentSaleType, setCurrentSaleType] = useState<SaleType>()
    const [oldAdditionalCosts, setOldAdditionalCosts] = useState<AdditionalCostItem[]>([])
    const [currentAdditionalCosts, setCurrentAdditionalCosts] = useState<AdditionalCostItem[]>([])
    const [taxSelected, setTaxSelected] = useState<Tax>()
    const [additionalCosts, setAdditionalCosts] = useState<AdditionalCost[]>([])
    const [taxValue, setTaxValue] = useState(0)
    const [currentTotal, setCurrentTotal] = useState(0)
    const dispatch = useDispatch()
    const previousSale = usePrevious(sale)
    const [maxCustomerSale, setCustomerSale] = useState<ClienteScontoItem | null>(null)
    const [isOverSale, setIsOverSale] = useState(false)

    const fetchAdditionalCosts = async () => {
        dispatch(setIsLoading(true))
        try {
            const body = {
                CodiceOfferta: props.codiceOfferta ? props.codiceOfferta : null,
                CodicePreventivo: props.codicePreventivo ? props.codicePreventivo : null
            }

            const costs = await getAdditionalCostsOffer(body, dispatch)
            if (costs) {
                setOldAdditionalCosts(costs?.Spese)
                setCurrentAdditionalCosts(costs?.Spese)
                if (costs.Aliquota.length > 0) {
                    setTaxSelected(data.taxes.find((t) => t.descrizione == costs.Aliquota[0].Descrizione))
                    setTaxValue(parseFloat(costs.Aliquota[0].Totale))
                }
                if (props.currentQuote) {
                    setCurrentTotal(parseFloat(props.currentQuote?.ImportoTotale as string))
                }
            }
            const costsList = await getAdditionalCostList(props.codicePreventivo, dispatch)

            setAdditionalCosts(costsList)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    useEffect(() => {
        if (props.isVisible) {
            setCurrentQuote(props.currentQuote ? props.currentQuote : null)
            fetchAdditionalCosts()
            let sale = ['0', '0']
            if (props.currentQuote) {
                sale = convertSale(
                    ((parseFloat(props.currentQuote.ScontoApplicato) * 100) / parseFloat(props.currentQuote?.TotaleListino)).toFixed(2).toString()
                )
            }
            setSale({
                salePercent: sale[0],
                salePercentAdd: sale[1],
                saleValue: props.currentQuote ? parseFloat(props.currentQuote.ScontoApplicato).toFixed(2).toString() : '0'
            })
            foundMaxSale()
        }
    }, [props.isVisible])

    useEffect(() => {
        let costValuesSum = 0
        currentAdditionalCosts.forEach((c) => {
            if (c.Totale) {
                costValuesSum = costValuesSum + parseFloat(c.Totale)
            }
        })
        if (currentQuote && taxSelected) {
            const calculatedTax =
                (parseFloat(currentQuote.TotaleListino) - parseFloat(sale.saleValue ? sale.saleValue : '0') + costValuesSum) * parseFloat(taxSelected?.aliquota)
            const calculateTotal = parseFloat(currentQuote.TotaleListino) - parseFloat(sale.saleValue ? sale.saleValue : '0') + costValuesSum + calculatedTax
            setCurrentTotal(calculateTotal)
        }
    }, [taxValue, currentAdditionalCosts, sale.saleValue])

    const foundMaxSale = () => {
        const foundCustomer = data.customers.find((c) => c.idContatto == order.idUserKnown)
        if (foundCustomer && foundCustomer.Sconti && foundCustomer.categoria && props.currentSerie) {
            const foundSale = foundCustomer.Sconti.find((s) => s.CodSerie == props.currentSerie?.CodSerie)
            if (foundSale) {
                setCustomerSale({ ...foundSale, categoria: foundCustomer.categoria })
            } else {
                const foundDefault = foundCustomer.Sconti.find((s) => s.CodSerie == 'Predefinito')
                if (foundDefault) {
                    setCustomerSale({ ...foundDefault, categoria: foundCustomer.categoria })
                } else {
                    setCustomerSale(null)
                }
            }
        } else {
            setCustomerSale(null)
        }
    }

    const renderSale = () => {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderBottom: '1px solid #BCBCBC',
                    paddingBottom: 10,
                    marginTop: 20
                }}
            >
                <p style={{ color: '#68696D', fontSize: 20 }}>Sconti</p>
                <div style={{ display: 'flex', justifyContent: 'flex-end', flex: 1, alignItems: 'flex-end', gap: 20 }}>
                    <div style={{ textAlign: 'right' }}>
                        <label style={{ fontSize: 12, fontWeight: 400, color: isOverSale ? '#E00000' : '#68696D' }}>Sconto percentuale</label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ flex: 3, fontWeight: 900, color: isOverSale ? '#E00000' : '#68696D' }}>- </span>
                            <input
                                max={maxCustomerSale ? parseFloat(maxCustomerSale.predefinito) : 100}
                                style={{
                                    backgroundColor: 'transparent',
                                    borderBottom: '1px solid #BCBCBC',
                                    textAlign: 'right',
                                    borderRadius: 0,
                                    flex: 1,
                                    maxWidth: 100,
                                    color: isOverSale ? '#E00000' : '#68696D'
                                }}
                                step=".01"
                                type="number"
                                value={sale.salePercent ? sale.salePercent : 0}
                                onFocus={() => setCurrentSaleType(SaleType.PERCENT)}
                                onChange={(e) =>
                                    setSale({
                                        ...sale,
                                        salePercent: e.target.value.replace(',', '.')
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <label style={{ fontSize: 12, fontWeight: 400, color: isOverSale ? '#E00000' : '#68696D' }}>Sconto aggiuntivo (%)</label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ flex: 3, fontWeight: 900, color: isOverSale ? '#E00000' : '#68696D' }}>- </span>
                            <input
                                style={{
                                    backgroundColor: 'transparent',
                                    borderBottom: '1px solid #BCBCBC',
                                    textAlign: 'right',
                                    borderRadius: 0,
                                    flex: 1,
                                    maxWidth: 100,
                                    color: isOverSale ? '#E00000' : '#68696D'
                                }}
                                placeholder="Sconto aggiuntivo"
                                max={maxCustomerSale ? parseFloat(maxCustomerSale.aggiuntivo) : 100}
                                step=".01"
                                type="number"
                                disabled={parseFloat(sale.salePercent) > 0 ? false : true}
                                value={sale.salePercentAdd ? sale.salePercentAdd : '0'}
                                onFocus={() => setCurrentSaleType(SaleType.PERCENT)}
                                onChange={(e) =>
                                    setSale({
                                        ...sale,
                                        salePercentAdd: e.target.value.replace(',', '.')
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        <label style={{ fontSize: 12, fontWeight: 400, color: isOverSale ? '#E00000' : '#68696D' }}>Sconto in valuta</label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ flex: 3, fontWeight: 900, color: isOverSale ? '#E00000' : '#68696D' }}>- </span>
                            <input
                                style={{
                                    backgroundColor: 'transparent',
                                    borderBottom: '1px solid #BCBCBC',
                                    textAlign: 'right',
                                    borderRadius: 0,
                                    flex: 1,
                                    maxWidth: 100,
                                    color: isOverSale ? '#E00000' : '#68696D'
                                }}
                                step=".01"
                                type="number"
                                value={sale.saleValue ? sale.saleValue : 0}
                                onFocus={() => setCurrentSaleType(SaleType.VALUE)}
                                onChange={(e) =>
                                    setSale({
                                        ...sale,
                                        saleValue: parseFloat(e.target.value).toFixed(2).toString().replace(',', '.')
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const renderListPrices = () => {
        if (currentQuote) {
            return (
                <div style={{ margin: '20px 0', backgroundColor: '#fff', padding: 15, borderRadius: 20 }}>
                    <div
                        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: '1px solid #BCBCBC', paddingBottom: 10 }}
                    >
                        <p style={{ color: '#68696D', fontSize: 20 }}>Totale {props.currentSerie?.NomeSerie}</p>
                        <span style={{ color: '#68696D', fontSize: 20, fontWeight: 900 }}>{transformToCurrency(parseFloat(currentQuote?.TotaleListino))}</span>
                    </div>
                    {renderSale()}
                    {renderCosts()}
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            borderBottom: '1px solid #BCBCBC',
                            paddingBottom: 10,
                            marginTop: 20,
                            alignItems: 'center'
                        }}
                    >
                        {renderIvaSelect()}
                        <span style={{ color: '#68696D', fontSize: 20, fontWeight: 900 }}>{transformToCurrency(taxValue)}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 10, marginTop: 50 }}>
                        <p style={{ color: '#68696D', fontSize: 24, fontWeight: 900 }}>Totale preventivo:</p>
                        <span style={{ color: graphic.colorAccent, fontSize: 24, fontWeight: 900 }}>{transformToCurrency(currentTotal)}</span>
                    </div>
                </div>
            )
        }
    }

    const renderIvaSelect = () => {
        return (
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                <div style={{ flex: 1, marginRight: 40 }}>
                    <Select
                        value={taxSelected ? { id: taxSelected.Codice, value: taxSelected.Codice, label: taxSelected.descrizione } : null}
                        options={data.taxes.map((t) => {
                            return { id: t.Codice, value: t.Codice, label: t.descrizione }
                        })}
                        placeholder="Seleziona aliquota"
                        onChange={(e) => {
                            const foundTax = data.taxes.find((s) => s.descrizione == e?.label)
                            if (foundTax && currentQuote) {
                                setTaxSelected(foundTax)
                                const calculated =
                                    (parseFloat(currentQuote.TotaleListino) - parseFloat(sale.saleValue ? sale.saleValue : '0')) * parseFloat(foundTax.aliquota)
                                setTaxValue(calculated)
                            }
                        }}
                        styles={{
                            valueContainer: (provided) => ({
                                ...provided,
                                backgroundColor: '#f4f4f4'
                            }),
                            container: (provided) => ({
                                ...provided,
                                backgroundColor: '#f4f4f4',
                                borderRadius: 7
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                fontSize: 18
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: 18
                            }),
                            control: (provided) => ({
                                ...provided,
                                backgroundColor: '#f4f4f4',
                                border: 0,
                                padding: '5px 10px',
                                borderRadius: 7
                            })
                        }}
                    />
                </div>
            </div>
        )
    }

    const renderCosts = () => {
        return currentAdditionalCosts.map((s, i) => {
            return (
                <CostItem
                    key={s.ID + i}
                    costSelected={s}
                    costList={additionalCosts}
                    onDelete={() => {
                        setCurrentAdditionalCosts(currentAdditionalCosts.filter((p) => p.ID !== s.ID))
                    }}
                    setCurrentAdditionalCost={(c) => {
                        setCurrentAdditionalCosts(
                            currentAdditionalCosts.map((a) => {
                                if (a.ID == s.ID) {
                                    return c
                                } else {
                                    return a
                                }
                            })
                        )
                    }}
                />
            )
        })
    }

    const handleSave = async () => {
        dispatch(setIsLoading(true))
        try {
            await handleCalculateSales()
            await handleSwitchCosts()
            const prices = await calculateTotalPrices(
                props.currentOffer ? props.currentOffer.CodiceOfferta : order.newQuoteFromUser?.CodiceOfferta,
                taxSelected ? taxSelected.Codice : null,
                dispatch
            )
            await saveCurrentOffer(props.currentOffer?.CodiceOfferta, dispatch)
            dispatch(setPricesTotalOffer(prices))
            const items = await getAllItemsQuote(props.codicePreventivo, dispatch)
            dispatch(
                setItemsInQuote(
                    order.itemsInQuote.map((item) => {
                        const itemFound = items.find((i) => i.idRecord == item.idRecord)
                        if (itemFound) {
                            return {
                                ...item,
                                DettaglioItem: itemFound
                            }
                        } else {
                            return item
                        }
                    })
                )
            )
            props.hideModal(true)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    const handleSwitchCosts = async () => {
        const body = {
            CodiceOfferta: props.currentOffer ? props.currentOffer.CodiceOfferta : order.newQuoteFromUser!.CodiceOfferta,
            CodicePreventivo: props?.codicePreventivo ? props?.codicePreventivo : null,
            AddSpese:
                currentAdditionalCosts.length > 0
                    ? currentAdditionalCosts
                          .filter((s) => s.TipoSpesa !== 'INTERNA')
                          .map((c) => {
                              return {
                                  Totale: parseFloat(c.Totale),
                                  Descrizione: c.Descrizione
                              }
                          })
                    : [],
            RemoveSpese:
                oldAdditionalCosts.length > 0
                    ? oldAdditionalCosts
                          .filter((s) => s.TipoSpesa !== 'INTERNA')
                          .map((c) => {
                              return {
                                  ID: parseFloat(c.ID)
                              }
                          })
                    : []
        }
        await switchCosts(body, dispatch)
    }

    const handleCalculateSales = async () => {
        if (currentQuote && (order.newQuoteFromUser || props.currentOffer)) {
            const body = {
                CodiceOfferta: props.currentOffer ? props.currentOffer.CodiceOfferta : order.newQuoteFromUser!.CodiceOfferta,
                CodicePreventivo: currentQuote?.CodicePreventivo,
                Sconto: null,
                PrezzoTotale: (parseFloat(currentQuote.TotaleListino) - parseFloat(sale.saleValue ? sale.saleValue : '0')).toFixed(2).toString()
            }
            await setQuotePrice(body, dispatch)
        }
    }

    useEffect(() => {
        if (previousSale !== undefined && JSON.stringify(sale) == JSON.stringify(previousSale)) {
            return
        }
        if (currentQuote) {
            if (currentSaleType == SaleType.VALUE) {
                const salePercent = (parseFloat(sale.saleValue) * 100) / parseFloat(currentQuote?.TotaleListino)
                setSale({
                    ...sale,
                    salePercent: parseFloat(salePercent.toFixed(2)).toString(),
                    salePercentAdd: null
                })
            }
            if (currentSaleType == SaleType.PERCENT) {
                if (sale.salePercentAdd !== null && parseFloat(sale.salePercentAdd) > 0) {
                    const firstSale = (parseFloat(currentQuote?.TotaleListino) * parseFloat(sale.salePercent)) / 100
                    const secondSale = ((parseFloat(currentQuote?.TotaleListino) - firstSale) * parseFloat(sale.salePercentAdd)) / 100
                    setSale({
                        ...sale,
                        saleValue: (firstSale + secondSale).toFixed(2).toString()
                    })
                } else {
                    const saleValue = (parseFloat(currentQuote?.TotaleListino) * parseFloat(sale.salePercent)) / 100
                    setSale({
                        ...sale,
                        saleValue: saleValue.toFixed(2).toString()
                    })
                }
            }
        }
        if (
            maxCustomerSale &&
            (parseFloat(sale.salePercent) > parseFloat(maxCustomerSale?.predefinito) ||
                (sale.salePercentAdd && parseFloat(sale.salePercentAdd) > parseFloat(maxCustomerSale.aggiuntivo)))
        ) {
            setIsOverSale(true)
        } else {
            setIsOverSale(false)
        }
    }, [sale])

    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md" onBackdropClick={() => props.hideModal(false)}>
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal(false)}
                />
                <TitlePage text={props.currentSerie?.NomeSerie} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Dettaglio prezzo</p>
                {renderListPrices()}
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1, width: '100%' }}>
                    <Button
                        isSmall
                        text="Aggiungi spesa accessoria"
                        buttonColor="#52565A"
                        hasIcon={true}
                        icon={faPlusSquare}
                        onClick={() => {
                            setCurrentAdditionalCosts([
                                ...currentAdditionalCosts,
                                {
                                    ID: currentAdditionalCosts.length > 0 ? (currentAdditionalCosts[currentAdditionalCosts.length - 1].ID + 1).toString() : '0',
                                    TipoSpesa: '',
                                    Descrizione: '',
                                    Totale: '0'
                                }
                            ])
                        }}
                    />
                    <Button
                        isSmall
                        text="Salva"
                        hasIcon={true}
                        icon={faCopy}
                        opacity={isOverSale ? 0.5 : 1}
                        onClick={() => {
                            if (isOverSale) {
                                return
                            }
                            handleSave()
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default TotalPriceModal
