import React from 'react'
import { useEffect } from 'react'
import TitlePage from '../components/TitlePage'
import ProductCard from '../components/ProductCard'
import CategoryCard from '../components/CategoryCard'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerData, Reducers, ReducerUser } from '../types/reducers'
import { setTokenRedux } from '../store/actions/thunk_actions'
import { NavLink, useHistory } from 'react-router-dom'
import { setSelectedCategory } from '../store/actions/graphic'
import { setLastProduct } from '../store/actions/user'

const HomePage: React.FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const dispatch = useDispatch()
    const history = useHistory()

    const renderProducts = () => {
        const newArray = data.series.slice(0, 6)
        return (
            newArray.map((s) => {
                return (
                    <ProductCard
                        product={s}
                        key={s.ID}
                        onClick={() => {
                            dispatch(setLastProduct(s))
                            history.push('/quote', { product: s })
                            const categoryProduct = data.categories.find((c) => c.IdCategorSerie == s.IdCategoriaSerie)
                            if (categoryProduct) {
                                dispatch(setSelectedCategory(categoryProduct))
                            }
                        }}
                    />
                )
            })
        )
    }

    const renderCategories = () => {
        const newArray = data.categories.filter((c) => !c.Nome.includes("Tutt"))
        return (
            newArray.map((c) => {
                return (
                    <CategoryCard category={c} key={c.IdCategorSerie} onClick={() => { return }} />
                )
            })
        )
    }

    return (
        <div className="homePageContainer">
            <TitlePage text="Prodotti più venduti" />
            <div className="columnsHome">
                <div className="columnProduct" id="columnProduct">
                    {renderProducts()}
                </div>
                <div className="columnCategories">
                    <NavLink
                        to="/products"
                        className="seeAllProducts"
                        id="seeAllProducts"
                        onClick={() => dispatch(setSelectedCategory(null))}
                    >
                        <h2>Visualizza tutti i prodotti</h2>
                        <div className="imgSpace">
                            <img src="https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/img_serie/img-prodotti-home.png" />
                        </div>
                    </NavLink>
                    <div className="categoryListContainer">
                        {renderCategories()}
                    </div>
                    <NavLink
                        to="/products"
                        className="promoProducts"
                        id="promoProducts"
                        onClick={() => dispatch(setSelectedCategory(null))}
                    >
                        <h2>Le nostre promozioni</h2>
                        <div className="imgSpace">
                            <img src="https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/img_serie/promo-logo.png" />
                        </div>
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default HomePage