import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'

interface Props {
    isVisible: boolean
    dontSave: () => void
    saveAndContinue: () => void
}

const AlertChangePhaseModal: React.FC<Props> = (props) => {
    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md">
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <TitlePage text="Modifiche non salvate." style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Vuoi salvare le modifiche prima di proseguire con lo step selezionato?</p>
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Prosegui senza salvare"
                    buttonColor="#52565A"
                    onClick={() => {
                        props.dontSave()
                    }}
                />
                <Button
                    isSmall
                    text="Salva e continua"
                    onClick={() => {
                        props.saveAndContinue()
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default AlertChangePhaseModal
