import React, { useEffect, useState } from 'react'
import { ReducerData, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'
import { acceptMessage, createMessage } from '../../resources/requests/others'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { setMessagesToAccept } from '../../store/actions/thunk_actions'
import { Message } from '../../types/others'
import Select from 'react-select'
import { Editor } from '@tinymce/tinymce-react'

interface Props {
    isNew?: boolean
    selectedMessage?: Message | null
    onClose?: () => void
}

const MessageToReadModal: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const voidMessage = {
        Titolo: '',
        Descrizione: '',
        Link: '',
        Visibile: 1,
        RichestaAccettazione: 1,
        Gruppo: 1,
        Colore: ''
    }
    const [newMessage, setNewMessage] = useState(voidMessage)
    const dispatch = useDispatch()

    useEffect(() => {
        setNewMessage(voidMessage)
    }, [props.isNew])

    const handleAcceptMessage = async () => {
        if (data.messagesToRead.length > 0) {
            dispatch(setIsLoading(true))
            try {
                await acceptMessage(data.messagesToRead[0].ID, dispatch)
                dispatch(setMessagesToAccept())
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    const renderAccountGroups = () => {
        const foundGroup = data.accountGroups.find((g) => parseInt(g.ID) == newMessage.Gruppo)
        if (foundGroup) {
            return {
                id: parseInt(foundGroup.ID),
                value: parseInt(foundGroup.ID),
                label: foundGroup.Descrizione
            }
        } else {
            return null
        }
    }

    const handleSendMessage = async () => {
        dispatch(setIsLoading(true))
        try {
            await createMessage(newMessage, dispatch)
            dispatch(setMessagesToAccept())
            if (props.onClose) {
                props.onClose()
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    if (props.isNew) {
        return (
            <Dialog open={props.isNew} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md">
                <DialogContent style={{ padding: 20, backgroundColor: '#fff', position: 'relative', borderRadius: 10, overflow: 'visible' }}>
                    <TitlePage text={'Messaggio'} style={{ margin: 0 }} />
                    <div style={{ margin: '20px 0' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <label>Titolo</label>
                            <input
                                placeholder="Inserisci titolo"
                                value={newMessage.Titolo}
                                onChange={(e) => setNewMessage({ ...newMessage, Titolo: e.target.value })}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: 10 }}>
                            <label>Descrizione</label>
                            <Editor
                                apiKey="011x4lbalj6an5p39pj1vg0kos85106cnui9ai4mmf4aez2q"
                                value={newMessage.Descrizione}
                                onEditorChange={(text) => setNewMessage({ ...newMessage, Descrizione: text })}
                            />
                            {/* <textarea
                                style={{ border: '0' }}
                                value={newMessage.Descrizione}
                                onChange={(e) => setNewMessage({ ...newMessage, Descrizione: e.target.value })}
                                placeholder="Inserisci descrizione"
                            /> */}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: 10 }}>
                            <label>Gruppo finale di destinazione</label>
                            <Select
                                value={renderAccountGroups()}
                                options={data.accountGroups.map((c) => {
                                    return { id: parseInt(c.ID), value: parseInt(c.ID), label: c.Descrizione }
                                })}
                                placeholder="Seleziona gruppo"
                                onChange={(e) => {
                                    if (e) {
                                        setNewMessage({ ...newMessage, Gruppo: e?.id })
                                    }
                                }}
                                styles={{
                                    valueContainer: (provided) => ({
                                        ...provided,
                                        backgroundColor: '#f4f4f4'
                                    }),
                                    container: (provided) => ({
                                        ...provided,
                                        backgroundColor: '#f4f4f4',
                                        borderRadius: 7,
                                        width: '100%'
                                    }),
                                    singleValue: (provided) => ({
                                        ...provided,
                                        fontSize: 18
                                    }),
                                    placeholder: (provided) => ({
                                        ...provided,
                                        fontSize: 18
                                    }),
                                    control: (provided) => ({
                                        ...provided,
                                        backgroundColor: '#f4f4f4',
                                        border: 0,
                                        padding: '5px 10px',
                                        borderRadius: 7
                                    })
                                }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center', marginTop: 30 }}>
                            <span>Richiesta accettazione</span>
                            <input
                                type="checkbox"
                                style={{ width: 25, height: 25, border: '0' }}
                                checked={newMessage.RichestaAccettazione == 1 ? true : false}
                                onChange={() => {
                                    if (newMessage.RichestaAccettazione == 1) {
                                        setNewMessage({
                                            ...newMessage,
                                            RichestaAccettazione: 0
                                        })
                                    } else {
                                        setNewMessage({
                                            ...newMessage,
                                            RichestaAccettazione: 1
                                        })
                                    }
                                }}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions style={{ padding: 20, backgroundColor: '#fff' }}>
                    <Button
                        isSmall
                        text="Annulla"
                        buttonColor="#52565A"
                        onClick={() => {
                            if (props.onClose) {
                                props.onClose()
                            }
                        }}
                    />
                    <Button
                        isSmall
                        text="Invia"
                        opacity={newMessage.Titolo && newMessage.Descrizione ? 1 : 0.5}
                        onClick={() => {
                            if (newMessage.Titolo && newMessage.Descrizione) {
                                handleSendMessage()
                            }
                        }}
                    />
                </DialogActions>
            </Dialog>
        )
    }

    if (props.selectedMessage) {
        return (
            <Dialog open={props.selectedMessage ? true : false} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md">
                <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                    <TitlePage text={'Messaggio'} style={{ margin: 0 }} />
                    {props.selectedMessage ? (
                        <div style={{ margin: '20px 0' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                <label>Titolo</label>
                                <p style={{ background: '#fff', padding: 10, borderRadius: 10 }}>{props.selectedMessage.Titolo}</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: 10 }}>
                                <label>Descrizione</label>
                                <div style={{ background: '#fff', padding: 10, borderRadius: 10 }}>
                                    <Editor
                                        apiKey="011x4lbalj6an5p39pj1vg0kos85106cnui9ai4mmf4aez2q"
                                        inline
                                        value={props.selectedMessage.Descrizione}
                                        disabled
                                    />
                                </div>
                                {/* <div style={{ background: '#fff', padding: 10, borderRadius: 10, minHeight: 200, maxHeight: 200, overflowY: 'scroll' }}>
                                    {props.selectedMessage.Descrizione}
                                </div> */}
                            </div>
                        </div>
                    ) : null}
                </DialogContent>
                <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                    <Button
                        isSmall
                        text="Chiudi"
                        onClick={() => {
                            if (props.onClose) {
                                props.onClose()
                            }
                        }}
                    />
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Dialog open={data.messagesToRead.length > 0 ? true : false} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md">
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <TitlePage text={'Messaggio'} style={{ margin: 0 }} />
                {data.messagesToRead.length > 0 ? (
                    <div style={{ margin: '20px 0' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <label>Titolo</label>
                            <p style={{ background: '#fff', padding: 10, borderRadius: 10 }}>{data.messagesToRead[0].Titolo}</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, marginTop: 10 }}>
                            <label>Descrizione</label>
                            <div style={{ background: '#fff', padding: 10, borderRadius: 10 }}>
                                <Editor apiKey="011x4lbalj6an5p39pj1vg0kos85106cnui9ai4mmf4aez2q" inline value={data.messagesToRead[0].Descrizione} disabled />
                            </div>
                            {/* <div style={{ background: '#fff', padding: 10, borderRadius: 10, minHeight: 200, maxHeight: 200, overflowY: 'scroll' }}>
                                {data.messagesToRead[0].Descrizione}
                            </div> */}
                        </div>
                    </div>
                ) : null}
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Conferma lettura"
                    onClick={async () => {
                        handleAcceptMessage()
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default MessageToReadModal
