import { faSearch, faCalendar, faFilter, faChartArea } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import React, { useRef, useState } from 'react'
import Button from '../../../components/Button'
import Input from '../../../components/formComponents/Input'
import { FilterStats, getStats } from '../../../resources/requests/stats'
import { useDispatch, useSelector } from 'react-redux'
import { Database, Stats } from '../../../types/data'
import StatCard from '../../../components/StatCard'
import { setError, setIsLoading } from '../../../store/actions/graphic'
import { ReducerData, ReducerGraphic, Reducers } from '../../../types/reducers'
import { transformToCurrency } from '../../../utilities/functions'
import StatDetailTable from '../../../components/tables/StatDetailTable'
import StatChart from '../../../components/StatChart'
import SelectInput, { Option } from '../../../components/formComponents/SelectInput'
import AziendaStatChart from '../../../components/AziendeStatChart'

interface Props {
    currentStats: Stats | null
    lastFilter: FilterStats
    noOrders: boolean
    databases: Database[]
    subtitle: string
    timeExec: string
    notes: string
    onGetStats: (filter: FilterStats) => void
}

const OrdiniScreen: React.FC<Props> = (props) => {
    const statChartRef = useRef(null)
    const aziendaStatChartRef = useRef(null)
    const startDate = moment().subtract(2, 'weeks').format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')
    const [filterBody, setFilterBody] = useState<FilterStats>(props.lastFilter)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const data: ReducerData = useSelector((store: Reducers) => store.data)

    const calculateSale = (stats: Stats) => {
        const saleEuro = stats.totaleOrdiniAlistinoEuro - stats.totaleOrdiniEuro
        const salePercent = (saleEuro * 100) / stats.totaleOrdiniEuro
        // const salePercent = (stats.totaleOrdiniEuro * 100) / stats.totaleOrdiniAlistinoEuro
        return `${salePercent.toFixed(2)}%`
    }

    const calculateTotalPieces = (stats: Stats) => {
        let pieces = 0
        stats.Dettaglio.forEach((d) => {
            pieces += d.TotPezzi
        })
        return pieces.toString()
    }

    const serieOptions = () => {
        const arrayOptions = data.series.map((s) => {
            return {
                id: s.CodSerie,
                value: s.CodSerie,
                label: s.NomeSerie
            }
        })
        arrayOptions.push({ id: 'all', value: 'all', label: '--TUTTE--' })
        return arrayOptions
    }

    const serieValue = () => {
        if (filterBody.CodiceSerie) {
            const serieFound = data.series.find((s) => s.CodSerie == filterBody.CodiceSerie)
            if (serieFound) {
                return {
                    id: serieFound.CodSerie,
                    value: serieFound.CodSerie,
                    label: serieFound.NomeSerie
                }
            } else {
                return {
                    id: 'all',
                    value: 'all',
                    label: '--TUTTE--'
                }
            }
        } else {
            return {
                id: 'all',
                value: 'all',
                label: '--TUTTE--'
            }
        }
    }

    const aziendaOptions = () => {
        const arrayOptions = data.companies.map((c) => {
            return {
                id: c.CodAZ,
                value: c.CodAZ,
                label: c.RagioneSociale
            }
        })
        arrayOptions.push({ id: 'all', value: 'all', label: '--TUTTE--' })
        return arrayOptions
    }

    const aziendaValue = () => {
        if (filterBody.CodAz) {
            const companyFound = data.companies.find((c) => c.CodAZ == filterBody.CodAz?.toString())
            if (companyFound) {
                return {
                    id: companyFound.CodAZ,
                    value: companyFound.CodAZ,
                    label: companyFound.RagioneSociale
                }
            } else {
                return {
                    id: 'all',
                    value: 'all',
                    label: '--TUTTE--'
                }
            }
        } else {
            return {
                id: 'all',
                value: 'all',
                label: '--TUTTE--'
            }
        }
    }

    const agentOptions = () => {
        const arrayOptions = data.agents
            .filter((a) => a.CodAZ == filterBody.CodAz?.toString())
            .map((a) => {
                return {
                    id: a.CodAg,
                    value: a.CodAg,
                    label: a.DenAg
                }
            })
        arrayOptions.push({ id: 'all', value: 'all', label: '--TUTTI--' })
        return arrayOptions
    }

    const agentValue = () => {
        if (filterBody.CodAg) {
            const agentFound = data.agents.find((c) => c.CodAg == filterBody.CodAg?.toString())
            if (agentFound) {
                return {
                    id: agentFound.CodAg,
                    value: agentFound.CodAg,
                    label: agentFound.DenAg
                }
            } else {
                return {
                    id: 'all',
                    value: 'all',
                    label: '--TUTTI--'
                }
            }
        } else {
            return {
                id: 'all',
                value: 'all',
                label: '--TUTTI--'
            }
        }
    }

    const databaseOptions = () => {
        const arrayOptions = props.databases.map((a) => {
            return {
                id: a.ID,
                value: a.ID,
                label: a.NomeBackup
            }
        })
        arrayOptions.push({ id: 'current', value: 'current', label: '--CORRENTE--' })
        return arrayOptions
    }

    const databaseValue = () => {
        if (filterBody.DBSourceName) {
            const dbFound = props.databases.find((c) => c.NomeBackup == filterBody.DBSourceName)
            if (dbFound) {
                return {
                    id: dbFound.ID,
                    value: dbFound.ID,
                    label: dbFound.NomeBackup
                }
            } else {
                return {
                    id: 'current',
                    value: 'current',
                    label: '--CORRENTE--'
                }
            }
        } else {
            return {
                id: 'current',
                value: 'current',
                label: '--CORRENTE--'
            }
        }
    }

    return (
        <div style={{ minHeight: '50vh', display: 'flex', flexDirection: 'column' }}>
            <div style={{ display: 'flex', gap: 20, flexDirection: 'row', marginBottom: 20 }}>
                <div className="gridFilter">
                    <Input
                        value={filterBody.DataStart !== null ? filterBody.DataStart : startDate}
                        type="date"
                        onChange={(e) => setFilterBody({ ...filterBody, DataStart: e })}
                        placeholder="Data inizio periodo riferimento..."
                        label="Data inizio periodo riferimento"
                        style={{ padding: '12.5px 20px 12.5px 40px', width: 'calc(100% - 60px)' }}
                        icon={faCalendar}
                        labelStyle={{ fontWeight: 400, color: '#68696d' }}
                        isFilterInput
                    />
                    <Input
                        value={filterBody.DataEnd !== null ? filterBody.DataEnd : endDate}
                        type="date"
                        onChange={(e) => setFilterBody({ ...filterBody, DataEnd: e })}
                        placeholder="Data fine periodo riferimento..."
                        label="Data fine periodo riferimento"
                        style={{ padding: '12.5px 20px 12.5px 40px', width: 'calc(100% - 60px)' }}
                        icon={faCalendar}
                        labelStyle={{ fontWeight: 400, color: '#68696d' }}
                        isFilterInput
                    />
                    <SelectInput
                        label="Serie"
                        options={serieOptions()}
                        value={serieValue()}
                        width={'18vw'}
                        onChange={(e) => {
                            const val = e as Option
                            if (val.id !== 'all') {
                                setFilterBody({
                                    ...filterBody,
                                    CodiceSerie: val.id as string
                                })
                            } else {
                                setFilterBody({
                                    ...filterBody,
                                    CodiceSerie: null
                                })
                            }
                        }}
                    />
                    <SelectInput
                        label="Azienda"
                        options={aziendaOptions()}
                        value={aziendaValue()}
                        width={'18vw'}
                        onChange={(e) => {
                            const val = e as Option
                            if (val.id !== 'all') {
                                setFilterBody({
                                    ...filterBody,
                                    CodAz: val.id as number,
                                    CodAg: null
                                })
                            } else {
                                setFilterBody({
                                    ...filterBody,
                                    CodAz: null,
                                    CodAg: null
                                })
                            }
                        }}
                    />
                    <SelectInput
                        label="Agente"
                        disabled={filterBody.CodAz ? false : true}
                        options={agentOptions()}
                        value={agentValue()}
                        width={'18vw'}
                        onChange={(e) => {
                            const val = e as Option
                            if (val.id !== 'all') {
                                setFilterBody({
                                    ...filterBody,
                                    CodAg: val.id as string
                                })
                            } else {
                                setFilterBody({
                                    ...filterBody,
                                    CodAg: null
                                })
                            }
                        }}
                    />
                    <SelectInput
                        label="Database"
                        options={databaseOptions()}
                        value={databaseValue()}
                        width={'18vw'}
                        onChange={(e) => {
                            const val = e as Option
                            if (val.id !== 'current') {
                                setFilterBody({
                                    ...filterBody,
                                    DBSourceName: val.label as string
                                })
                            } else {
                                setFilterBody({
                                    ...filterBody,
                                    DBSourceName: null
                                })
                            }
                        }}
                    />
                    <div style={{ marginTop: 18 }}>
                        <Button
                            text="Risultato"
                            hasIcon
                            icon={faChartArea}
                            onClick={() => {
                                props.onGetStats(filterBody)
                                //@ts-ignore
                                statChartRef.current?.resetData()
                                //@ts-ignore
                                aziendaStatChartRef.current?.resetData()
                            }}
                        />
                    </div>
                </div>
            </div>
            {props.currentStats ? (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', color: '#68696d', fontStyle: 'italic' }}>
                        <div>
                            <p>{props.subtitle}</p>
                            <p>{props.notes}</p>
                        </div>
                        <p style={{ width: '30%', textAlign: 'right' }}>Tempo di esecuzione: {props.timeExec}</p>
                    </div>
                    <div className="gridStats order">
                        <StatCard title={'Totale ordini'} value={props.currentStats.totaleOrdiniNumero.toString()} />
                        <StatCard title={'Totale fatturato'} value={transformToCurrency(props.currentStats.totaleOrdiniEuro)} />
                        <StatCard title={'Totale listino'} value={transformToCurrency(props.currentStats.totaleOrdiniAlistinoEuro)} />
                        <StatCard title={'Totale sconto'} value={calculateSale(props.currentStats)} />
                        <StatCard title={'Totale pezzi'} value={calculateTotalPieces(props.currentStats)} />
                        <StatCard title={'Ordini confermati in percentuale'} value={`${props.currentStats.PercentualeConferme}%`} />
                        <StatCard title={'Ordini fatturati in percentuale'} value={`${props.currentStats.PercentualeFatturato}%`} />
                    </div>
                    <StatDetailTable stats={props.currentStats.Dettaglio} />
                </div>
            ) : (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1, margin: 30 }}>
                    {props.noOrders ? (
                        <p style={{ fontSize: 28, color: graphic.colorAccent, textAlign: 'center', margin: 10 }}>
                            Non ho trovato nessun risultato con questi criteri.
                        </p>
                    ) : null}
                    <p style={{ textAlign: 'center', fontSize: 24, color: '#68696d', fontStyle: 'italic', opacity: 0.6 }}>
                        Imposta i filtri e premi sul pulsante {'"'}Risultato{'"'} per consultare le statistiche
                    </p>
                </div>
            )}
            <StatChart filterBody={filterBody} differentData={JSON.stringify(filterBody) !== JSON.stringify(props.lastFilter)} ref={statChartRef} />
            <AziendaStatChart
                filterBody={filterBody}
                differentData={JSON.stringify(filterBody) !== JSON.stringify(props.lastFilter)}
                ref={aziendaStatChartRef}
            />
        </div>
    )
}

export default OrdiniScreen
