import React from 'react'
import { ReducerGraphic, Reducers } from '../types/reducers'
import { useSelector } from 'react-redux'

interface Props {
    title: string,
    number: number,
    isActive: boolean
    onClick: () => void
}

const PhaseQuote: React.FC<Props> = (props) => {
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)

    return (
        <div className="phaseQuote" onClick={() => props.onClick()}>
            <div className="number" style={{ color: props.isActive ? graphic.colorAccent : '#68696d', borderColor: props.isActive ? graphic.colorAccent : '#68696d' }}><span>{props.number}</span></div>
            <div className="title" style={{ color: props.isActive ? graphic.colorAccent : '#68696d' }}>{props.title}</div>
        </div>
    )
}

export default PhaseQuote