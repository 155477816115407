import MaterialTable from 'material-table'
import React, { useMemo, useState } from 'react'
import TitlePage from '../../components/TitlePage'
import { Message } from '../../types/others'
import { locale_itIT } from '../../utilities/tableLocale'
import { useSelector } from 'react-redux'
import { ReducerData, Reducers, ReducerUser } from '../../types/reducers'
import MessageToReadModal from '../../components/modals/MessageToReadModal'
import Button from '../../components/Button'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import DeleteModal from '../../components/modals/DeleteModal'
import { useDispatch } from 'react-redux'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { acceptMessage, deleteMessage } from '../../resources/requests/others'
import { setMessagesToAccept } from '../../store/actions/thunk_actions'
import moment from 'moment'

const MessagesPage: React.FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [selectedMessage, setSelectedMessage] = useState<Message | null>(null)
    const [messageToDelete, setMessageToDelete] = useState<Message | null>(null)
    const [isNewMessage, setIsNewMessage] = useState(false)
    const dispatch = useDispatch()

    const columns = [
        {
            title: 'Autore',
            field: 'Autore',
            render: (rowData: Message) => {
                return <p style={{ fontWeight: rowData.Letto == '0' ? 'bold' : 'normal' }}>{rowData.Autore}</p>
            }
        },
        {
            title: 'Data invio',
            field: 'DataCreazione',
            render: (rowData: Message) => {
                return <p style={{ fontWeight: rowData.Letto == '0' ? 'bold' : 'normal' }}>{moment(rowData.DataCreazione).format('DD/MM/YYYY HH:mm')}</p>
            }
        },
        {
            title: 'Titolo',
            field: 'Titolo',
            render: (rowData: Message) => {
                return <p style={{ fontWeight: rowData.Letto == '0' ? 'bold' : 'normal' }}>{rowData.Titolo}</p>
            }
        },
        {
            title: 'Descrizione',
            field: 'Descrizione',
            render: (rowData: Message) => {
                return (
                    <p style={{ fontWeight: rowData.Letto == '0' ? 'bold' : 'normal' }}>
                        {rowData.Descrizione.length > 150 ? `${rowData.Descrizione.substring(0, 150)}...` : rowData.Descrizione}
                    </p>
                )
            }
        }
    ]

    const renderDatas = useMemo(() => {
        return data.messages
    }, [data.messages])

    const renderActions = useMemo(() => {
        const actions = [
            (rowData: Message) => ({
                icon: 'visibility',
                iconProps: {
                    fontSize: 'medium'
                },
                tooltip: 'Visualizza',
                onClick: () => {
                    setSelectedMessage(rowData)
                }
            })
        ]

        if (user.user?.TipoAccount == 'Amministratore') {
            actions.push((rowData: Message) => ({
                icon: 'delete',
                iconProps: {
                    color: 'error',
                    fontSize: 'medium'
                },
                tooltip: 'Elimina',
                onClick: () => {
                    setMessageToDelete(rowData)
                }
            }))
        }

        return actions
    }, [])

    const handleDeleteMessage = async () => {
        if (messageToDelete) {
            dispatch(setIsLoading(true))
            try {
                await deleteMessage(messageToDelete?.ID, dispatch)
                dispatch(setMessagesToAccept())
                setMessageToDelete(null)
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    const handleReadCloseMessage = async () => {
        dispatch(setIsLoading(true))
        try {
            if (selectedMessage?.Letto == '0') {
                await acceptMessage(selectedMessage.ID, dispatch)
                await dispatch(setMessagesToAccept())
            }
            setSelectedMessage(null)
            setIsNewMessage(false)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TitlePage text="Bacheca comunicazioni" />
                {user.user?.TipoAccount == 'Amministratore' ? (
                    <div style={{ display: 'flex', margin: '20px 0', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                        <Button
                            text="Invia messaggio"
                            hasIcon={true}
                            icon={faEnvelope}
                            onClick={() => {
                                setIsNewMessage(true)
                            }}
                        />
                    </div>
                ) : null}
            </div>

            <div>
                <MaterialTable
                    title=""
                    columns={columns}
                    data={renderDatas}
                    localization={locale_itIT}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        search: false,
                        headerStyle: {
                            backgroundColor: '#ebebeb',
                            borderRadius: 20
                        }
                    }}
                    //@ts-ignore
                    actions={renderActions}
                />
            </div>
            <MessageToReadModal
                isNew={isNewMessage}
                selectedMessage={selectedMessage}
                onClose={() => {
                    if (selectedMessage) {
                        handleReadCloseMessage()
                    } else {
                        setSelectedMessage(null)
                        setIsNewMessage(false)
                    }
                }}
            />
            {messageToDelete ? (
                <DeleteModal
                    isVisible={messageToDelete ? true : false}
                    hideModal={() => setMessageToDelete(null)}
                    onDelete={() => {
                        handleDeleteMessage()
                    }}
                    text={`Vuoi cancellare il messaggio ${messageToDelete.Titolo}?`}
                    title={'Elimina messaggio'}
                />
            ) : null}
        </div>
    )
}

export default MessagesPage
