import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Cliente } from '../../types/data'
import { Dialog, DialogContent } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import TitlePage from '../TitlePage'
import { ReducerData, Reducers, ReducerUser } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { provincePerSelect, citiesPerSelect, agentsPerSelect, companiesPerSelect } from '../../utilities/functions'
import Button from '../Button'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { createAccount, editAccount } from '../../resources/requests/requests'
import { setAccountsRedux, setCustomersRedux } from '../../store/actions/thunk_actions'
import { User } from '../../types/user'
import SelectInput from '../formComponents/SelectInput'
import Input from '../formComponents/Input'

interface Props {
    account: User | null
    isVisible: boolean
    hideModal: () => void
}

const EditAccountModal: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const voidAccount: User = {
        AliqProvv: '',
        CapAg: '',
        CfiAg: '',
        CodAg: '',
        DenAg: '',
        FaxAg: '',
        IDComune: '',
        IndAg: '',
        Parent: '',
        PivAg: '',
        ProvAg: '',
        CitAg: '',
        TelAg: '',
        TipoAccount: '',
        mail: '',
        username: '',
        Agente: '',
        ID: '',
        attivo: '1',
        passExpire: ''
    }
    const [currentAccount, setCurrentAccount] = useState<User>(props.account ? props.account : voidAccount)
    const [password, setPassword] = useState<string>('')
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.isVisible) {
            setCurrentAccount(props.account ? props.account : voidAccount)
            setPassword('')
        }
    }, [props.isVisible])

    const agentValue = () => {
        if (currentAccount.CodAg) {
            const agentFound = data.agents.find((a) => a.CodAg == currentAccount.CodAg)
            if (agentFound) {
                return {
                    id: agentFound.CodAg,
                    value: agentFound.CodAg,
                    label: agentFound.DenAg
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const groupValue = () => {
        if (currentAccount.TipoAccount) {
            const groupFound = data.accountGroups.find((g) => g.Descrizione == currentAccount.TipoAccount)
            if (groupFound) {
                return {
                    id: groupFound.ID,
                    value: groupFound.ID,
                    label: groupFound.Descrizione
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const companyValue = () => {
        if (currentAccount.Parent) {
            const companyFound = data.companies.find((c) => c.CodAZ == currentAccount.Parent)
            if (companyFound) {
                return {
                    id: companyFound.CodAZ,
                    value: companyFound.CodAZ,
                    label: `${companyFound.RagioneSociale} (Cod. ${companyFound.CodAZ})`
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const handleSave = async () => {
        dispatch(setIsLoading(true))
        try {
            const foundGroup = data.accountGroups.find((a) => a.Descrizione == currentAccount.TipoAccount)
            const body = {
                ID: parseInt(currentAccount.ID),
                CodAg: currentAccount.CodAg,
                username: currentAccount.username,
                ABL: 0,
                password: password ? password : null,
                mail: currentAccount.mail,
                idgruppo: foundGroup ? parseInt(foundGroup.ID) : 1,
                attivo: currentAccount.attivo == '1' ? true : false
            }
            await editAccount(body, dispatch)
            await dispatch(setAccountsRedux())
            props.hideModal()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    const handleCreate = async () => {
        dispatch(setIsLoading(true))
        try {
            const foundGroup = data.accountGroups.find((a) => a.Descrizione == currentAccount.TipoAccount)
            const body = {
                CodAg: currentAccount.CodAg,
                username: currentAccount.username,
                password: password,
                mail: currentAccount.mail,
                idgruppo: foundGroup ? parseInt(foundGroup.ID) : 1
            }
            await createAccount(body, dispatch)
            await dispatch(setAccountsRedux())
            props.hideModal()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={props.account ? 'Dati account: ' + currentAccount.username : 'Aggiungi account'} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Aggiungi / modifica dell{"'"}account</p>
                <div className="gridInputOffer" style={{ margin: '20px 0', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                    <Input
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        type="text"
                        placeholder="Username"
                        label="Username"
                        disabled={user.user?.TipoAccount !== 'Amministratore'}
                        value={currentAccount.username}
                        onChange={(e) => {
                            setCurrentAccount({
                                ...currentAccount,
                                username: e
                            })
                        }}
                    />
                    <Input
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        type="email"
                        disabled={user.user?.TipoAccount !== 'Amministratore'}
                        placeholder="Email"
                        label="Email"
                        value={currentAccount.mail}
                        onChange={(e) => {
                            setCurrentAccount({
                                ...currentAccount,
                                mail: e
                            })
                        }}
                    />
                    <Input
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        type="password"
                        disabled={user.user?.TipoAccount !== 'Amministratore'}
                        value={password}
                        placeholder="Password"
                        label="Password"
                        onChange={(e) => {
                            setPassword(e)
                        }}
                    />
                    <SelectInput
                        placeholder="Operatore collegato"
                        label="Operatore collegato"
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        options={agentsPerSelect(data.agents)}
                        value={agentValue()}
                        onChange={(e: any) => {
                            if (e) {
                                setCurrentAccount({
                                    ...currentAccount,
                                    CodAg: e.id
                                })
                            }
                        }}
                    />
                    <SelectInput
                        placeholder="Gruppo account"
                        label="Gruppo account"
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        options={data.accountGroups.map((g) => {
                            return { id: g.ID, value: g.ID, label: g.Descrizione }
                        })}
                        value={groupValue()}
                        onChange={(e: any) => {
                            if (e) {
                                setCurrentAccount({
                                    ...currentAccount,
                                    TipoAccount: e.label
                                })
                            }
                        }}
                    />
                    <SelectInput
                        placeholder="Azienda collegata"
                        label="Azienda collegata"
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        options={companiesPerSelect(data.companies)}
                        value={companyValue()}
                        onChange={(e: any) => {
                            if (e) {
                                setCurrentAccount({
                                    ...currentAccount,
                                    Parent: e.id
                                })
                            }
                        }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: '100%' }}>
                    <Button
                        isSmall
                        text="Salva"
                        hasIcon={true}
                        icon={faEdit}
                        opacity={
                            (currentAccount.ID == '' && currentAccount.username && currentAccount.mail && password) ||
                            (currentAccount.ID !== '' && currentAccount.username && currentAccount.mail)
                                ? 1
                                : 0.5
                        }
                        onClick={() => {
                            if (currentAccount.ID == '' && currentAccount.username && currentAccount.mail && password) {
                                handleCreate()
                            } else if (currentAccount.ID !== '' && currentAccount.username && currentAccount.mail) {
                                handleSave()
                            } else {
                                return
                            }
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default EditAccountModal
