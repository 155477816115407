import { faCheck, faEdit, faThLarge, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { ComposedItem, ItemInQuote, ItemToEdit } from '../../types/order'
import { orderComposedPerLabel, transformToCurrency,convertSale } from '../../utilities/functions'
import Button from '../Button'
import NotesModal from '../modals/NotesModal'
import ComposedSaleModal from './ComposedSaleModal'
import {
    calculateTotalPrices,
    deleteItemFromQuote,
    duplicateItemInQuote,
    editItemPreventivo,
    getAllItemsQuote,
    getComposedItem,
    getSingleItemQuote,
    recalculateItems,
    saveCurrentOffer
} from '../../resources/requests/requests'
import { ReducerData, ReducerGraphic, ReducerOrder, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentOffer, setItemsInQuote, setPricesTotalOffer } from '../../store/actions/order'
import { setOfferLoadedRedux } from '../../store/actions/thunk_actions'
import { setError, setIsLoading } from '../../store/actions/graphic'
import DeleteModal from '../modals/DeleteModal'
import CreateStructureModal from './CreateStructureModal'
import DuplicateModal from '../modals/DuplicateModal'
import VariantListComposed from './VariantListComposed'

interface Props {
    itemInQuote: ItemInQuote
    idRecord: string
    assignedProduct: ItemInQuote[]
    goPrevPhase: () => void
    isAutoRecalc?: boolean
}

const ComposedTypologyListElement: React.FC<Props> = (props) => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [currentItemInQuote, setCurrentItemInQuote] = useState(props.itemInQuote)
    const [currentComposedItem, setCurrentComposedItem] = useState<ComposedItem | null>(null)
    const [visibleModalStructure, setVisibleModalStructure] = useState(false)
    const [visibleModalNotes, setVisibleModalNotes] = useState(false)
    const [visibleModalSale, setVisibleModalSale] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false)
    const [selectedIdRecord, setSelectedIdRecord] = useState('')
    const dispatch = useDispatch()

    useEffect(() => {
        const foundInItems = props.assignedProduct.find((p) => p.idRecord == selectedIdRecord)
        if ((selectedIdRecord == '' || !foundInItems) && props.assignedProduct.length > 0) {
            setSelectedIdRecord(props.assignedProduct[0].idRecord)
        }
    }, [currentComposedItem, props.assignedProduct])

    const renderFirstColumn = () => {
        return (
            <div
                style={{
                    borderRight: '1px solid #D3D3D3',
                    paddingRight: 20,
                    flex: 2,
                    textAlign: 'center',
                    color: '#68696D',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <p style={{ fontSize: 16, fontWeight: 500 }}>N° Ord.: {currentItemInQuote.DettaglioItem.Ordinamento}</p>
                <h2 style={{ color: '#000', fontSize: 22, fontWeight: 900 }}>{currentItemInQuote.DettaglioItem.Etichetta}</h2>
                <p style={{ fontSize: 18 }}>{currentItemInQuote.DettaglioItem.DescrizioneInterneDB}</p>
                {currentItemInQuote.DettaglioItem.Trasmittanza && currentItemInQuote.DettaglioItem.Trasmittanza > 0 ? (
                    <div style={{ display: 'flex', flexDirection: 'column', fontSize: 14 }}>
                        <span>trasmittanza termica da normativa:</span>
                        <span style={{ fontWeight: 900, color: '#68696D' }}>{currentItemInQuote.DettaglioItem.Trasmittanza} W/m2K</span>
                    </div>
                ) : null}
                {currentItemInQuote.DettaglioItem.Peso && currentItemInQuote.DettaglioItem.Peso > 0 ? (
                    <p style={{ fontWeight: 900, color: '#68696D' }}>{currentItemInQuote.DettaglioItem.Peso} Kg</p>
                ) : null}
                <div style={{ margin: '20px 0', position: 'relative' }}>
                    <FontAwesomeIcon
                        icon={faThLarge}
                        onClick={() => setVisibleModalStructure(true)}
                        style={{
                            top: -10,
                            right: -8,
                            position: 'absolute',
                            fontSize: 14,
                            color: '#fff',
                            cursor: 'pointer',
                            backgroundColor: graphic.colorAccent,
                            borderRadius: '50%',
                            padding: 6
                        }}
                    />
                    <img
                        src={currentItemInQuote.DettaglioItem.FiguraRiferimento}
                        style={{ maxWidth: '100%', borderRadius: 10, height: '25vh', width: '25vh', backgroundColor: '#fff', objectFit: 'contain', padding: 5 }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 20, justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span style={{ color: '#000', fontSize: 14 }}>L (mm.)</span>
                        <span>{currentItemInQuote.DettaglioItem.Ltip}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span style={{ color: '#000', fontSize: 14 }}>H (mm.)</span>
                        <span>{currentItemInQuote.DettaglioItem.Htip}</span>
                    </div>
                    {currentItemInQuote.DettaglioItem.HManiglia && (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <span style={{ color: '#000', fontSize: 14 }}>HMan.</span>
                            <span>{currentItemInQuote.DettaglioItem.HManiglia}</span>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const renderVariantList = () => {
        if (selectedIdRecord && props.assignedProduct.length > 0) {
            const foundItem = props.assignedProduct.find((p) => p.idRecord == selectedIdRecord)
            if (foundItem) {
                return <VariantListComposed variants={foundItem.DettaglioItem.elencoVarianti} idRecord={selectedIdRecord} />
            }
        }
        return null
    }

    const renderSecondColumn = () => {
        const sortedArray = orderComposedPerLabel(props.assignedProduct)
        return (
            <div style={{ padding: '0 20px', flex: 6 }}>
                {props.assignedProduct.length > 0 ? (
                    <div>
                        <div className="settingsSubMenu" style={{ justifyContent: 'flex-start' }}>
                            {sortedArray.map((p) => {
                                return (
                                    <div
                                        key={p.idRecord}
                                        className="menuVoice"
                                        style={{
                                            backgroundColor: p.idRecord == selectedIdRecord ? graphic.colorAccent : '#fff',
                                            color: p.idRecord == selectedIdRecord ? '#fff' : graphic.colorAccent,
                                            padding: '8px 15px',
                                            borderRadius: 10,
                                            border: `3px solid ${graphic.colorAccent}`,
                                            fontSize: 16
                                        }}
                                        onClick={() => {
                                            setSelectedIdRecord(p.idRecord)
                                        }}
                                    >
                                        {p.DettaglioItem.Etichetta} (L. {p.DettaglioItem.Ltip} x H. {p.DettaglioItem.Htip} mm.)
                                    </div>
                                )
                            })}
                        </div>
                        {renderVariantList()}
                    </div>
                ) : (
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 10, height: '100%' }}>
                        <p style={{ color: '#68696f', fontSize: 24 }}>Nessuna tipologia associata</p>
                        <p style={{ color: '#68696f', fontSize: 20 }}>Configura ora questa finestra composta</p>
                        <Button
                            text="Configura ora"
                            onClick={() => {
                                setVisibleModalStructure(true)
                            }}
                        />
                    </div>
                )}
            </div>
        )
    }

    useEffect(() => {
        if (props.isAutoRecalc) {
            handleRecalculateItem()
        }
    }, [currentItemInQuote])

    useEffect(() => {
        setCurrentItemInQuote(props.itemInQuote)
    }, [props.itemInQuote])

    const handleUpdateQuantity = async () => {
        dispatch(setIsLoading(true))
        try {
            const body: ItemToEdit = {
                idRecord: currentItemInQuote.idRecord,
                CodicePreventivo: order.newQuoteFromUser?.CodicePreventivo,
                CodiceSerie: currentItemInQuote.DettaglioItem.CodiceSerie,
                CodTipoItem: currentItemInQuote.DettaglioItem.CodTipoItem,
                Htip: currentItemInQuote.DettaglioItem.Htip,
                Ltip: currentItemInQuote.DettaglioItem.Ltip,
                Quantita: currentItemInQuote.DettaglioItem.Quantita,
                Etichetta: currentItemInQuote.DettaglioItem.Etichetta,
                Ordinamento: currentItemInQuote.DettaglioItem.Ordinamento,
                HManiglia: currentItemInQuote.DettaglioItem.HManiglia ? currentItemInQuote.DettaglioItem.HManiglia : null,
                HTraverso1: currentItemInQuote.DettaglioItem.HTraverso1 ? currentItemInQuote.DettaglioItem.HTraverso1 : null,
                HTraverso2: currentItemInQuote.DettaglioItem.HTraverso2 ? currentItemInQuote.DettaglioItem.HTraverso2 : null,
                HTraverso3: currentItemInQuote.DettaglioItem.HTraverso3 ? currentItemInQuote.DettaglioItem.HTraverso3 : null,
                HTraverso4: currentItemInQuote.DettaglioItem.HTraverso4 ? currentItemInQuote.DettaglioItem.HTraverso4 : null,
                PRM: currentItemInQuote.DettaglioItem.PRM ? currentItemInQuote.DettaglioItem.PRM.toString() : null,
                Prezzo: currentItemInQuote.DettaglioItem.Prezzo.toString(),
                Sconto: currentItemInQuote.DettaglioItem.Sconto.toString(),
                Annotazioniutente: currentItemInQuote.DettaglioItem.Annotazioniutente
            }
            await editItemPreventivo(body, dispatch)
            for (let i = 0; i < props.assignedProduct.length; i++) {
                const body: ItemToEdit = {
                    idRecord: props.assignedProduct[i].idRecord,
                    CodicePreventivo: order.newQuoteFromUser?.CodicePreventivo,
                    CodiceSerie: props.assignedProduct[i].DettaglioItem.CodiceSerie,
                    CodTipoItem: props.assignedProduct[i].DettaglioItem.CodTipoItem,
                    Htip: props.assignedProduct[i].DettaglioItem.Htip,
                    Ltip: props.assignedProduct[i].DettaglioItem.Ltip,
                    Quantita: currentItemInQuote.DettaglioItem.Quantita,
                    Etichetta: props.assignedProduct[i].DettaglioItem.Etichetta,
                    Ordinamento: props.assignedProduct[i].DettaglioItem.Ordinamento,
                    HManiglia: props.assignedProduct[i].DettaglioItem.HManiglia ? props.assignedProduct[i].DettaglioItem.HManiglia : null,
                    HTraverso1: props.assignedProduct[i].DettaglioItem.HTraverso1 ? props.assignedProduct[i].DettaglioItem.HTraverso1 : null,
                    HTraverso2: props.assignedProduct[i].DettaglioItem.HTraverso2 ? props.assignedProduct[i].DettaglioItem.HTraverso2 : null,
                    HTraverso3: props.assignedProduct[i].DettaglioItem.HTraverso3 ? props.assignedProduct[i].DettaglioItem.HTraverso3 : null,
                    HTraverso4: props.assignedProduct[i].DettaglioItem.HTraverso4 ? props.assignedProduct[i].DettaglioItem.HTraverso4 : null,
                    PRM: props.assignedProduct[i].DettaglioItem.PRM ? props.assignedProduct[i].DettaglioItem.PRM : null,
                    Prezzo: props.assignedProduct[i].DettaglioItem.Prezzo.toString(),
                    Sconto: props.assignedProduct[i].DettaglioItem.Sconto.toString(),
                    Annotazioniutente: props.assignedProduct[i].DettaglioItem.Annotazioniutente
                }
                await editItemPreventivo(body, dispatch)
            }
            const updatedItems = await getAllItemsQuote(order.newQuoteFromUser?.CodicePreventivo, dispatch)
            dispatch(
                setItemsInQuote(
                    order.itemsInQuote.map((item) => {
                        const itemFound = updatedItems.find((i) => i.idRecord == item.idRecord)
                        if (itemFound) {
                            return {
                                ...item,
                                DettaglioItem: itemFound
                            }
                        } else {
                            return item
                        }
                    })
                )
            )
            const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
            const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
            dispatch(setPricesTotalOffer(prices))
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handleRecalculateItem = async () => {
        if (currentItemInQuote.DettaglioItem.Modificato) {
            dispatch(setIsLoading(true))
            try {
                await recalculateItems(
                    order.newQuoteFromUser?.CodicePreventivo,
                    order.newQuoteFromUser?.CodiceOfferta,
                    [{ idItem: currentItemInQuote.idRecord }],
                    dispatch
                )
                const updatedItem = await getSingleItemQuote(currentItemInQuote.idRecord, order.newQuoteFromUser?.CodicePreventivo, dispatch)
                dispatch(
                    setItemsInQuote(
                        order.itemsInQuote.map((item) => {
                            if (item.idRecord == currentItemInQuote.idRecord) {
                                return {
                                    ...item,
                                    DettaglioItem: updatedItem
                                }
                            } else {
                                return item
                            }
                        })
                    )
                )
                const foundTax = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)?.Codice
                const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, foundTax ? foundTax : data.taxes[0].Codice, dispatch)
                dispatch(setPricesTotalOffer(prices))
                if (order.currentOffer) {
                    const offer = await saveCurrentOffer(order.newQuoteFromUser?.CodiceOfferta, dispatch)
                    if (offer) {
                        dispatch(setCurrentOffer(offer))
                    }
                    // dispatch(setOfferLoadedRedux(order.newQuoteFromUser?.CodiceOfferta, true))
                }
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    const renderThirdColumn = () => {
        return (
            <div
                style={{
                    borderLeft: '1px solid #D3D3D3',
                    paddingLeft: 20,
                    flex: 1,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#68696D'
                }}
            >
                <div style={{ paddingBottom: 20, borderBottom: '1px solid #D3D3D3', display: 'flex', flexDirection: 'column' }}>
                    <span>Prezzo</span>
                    <span style={{ color: '#000', fontWeight: 900, fontSize: 22 }}>
                        {transformToCurrency(renderPrices() * currentItemInQuote.DettaglioItem.Quantita)}
                    </span>
                    <span style={{ fontSize: 12, margin: '15px 0' }}>Sconto applicato {renderSalePercent().toFixed(2)}%</span>
                    <span style={{ fontSize: 12 }}>
                        {transformToCurrency(renderPrices())} x {currentItemInQuote.DettaglioItem.Quantita}
                    </span>
                    <span style={{ fontSize: 12, padding: '5px 0' }}>IVA esclusa</span>
                </div>
                <div
                    style={{
                        paddingBottom: 20,
                        borderBottom: '1px solid #D3D3D3',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        padding: '20px 0',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        gap: 20
                    }}
                >
                    <div className="quantityBox" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span>Quantità</span>
                        <input
                            min={0}
                            value={currentItemInQuote.DettaglioItem.Quantita}
                            onChange={(e) => {
                                setCurrentItemInQuote({
                                    ...currentItemInQuote,
                                    DettaglioItem: {
                                        ...currentItemInQuote.DettaglioItem,
                                        Quantita: parseInt(e.target.value)
                                    }
                                })
                            }}
                            defaultValue={0}
                            type="number"
                        />
                    </div>
                    {currentItemInQuote.DettaglioItem.Quantita !== props.itemInQuote.DettaglioItem.Quantita && (
                        <FontAwesomeIcon
                            icon={faCheck}
                            onClick={() => {
                                handleUpdateQuantity()
                            }}
                            style={{ backgroundColor: graphic.colorAccent, fontSize: 15, color: '#fff', cursor: 'pointer', padding: 8, borderRadius: 5 }}
                        />
                    )}
                </div>
                <div style={{ paddingBottom: 20, display: 'flex', flexDirection: 'column', width: '100%', padding: '20px 0', gap: 10 }}>
                    {/* <Button
                        isSmall
                        text="Ricalcola"
                        hasIcon={true}
                        icon={faSyncAlt}
                        onClick={() => {
                            handleRecalculateItem()
                        }}
                        opacity={currentItemInQuote.DettaglioItem.Modificato ? 1 : 0.5}
                    /> */}
                    <Button
                        isSmall
                        text="Modifica prezzo"
                        onClick={() => {
                            setVisibleModalSale(true)
                        }}
                    />
                    <Button
                        isSmall
                        text="Nota"
                        hasIcon={true}
                        icon={faEdit}
                        onClick={() => {
                            setVisibleModalNotes(true)
                        }}
                    />
                    {/* <Button
                        isSmall
                        text="Duplica"
                        hasIcon={true}
                        buttonColor="#52565A"
                        icon={faCopy}
                        onClick={() => {
                            setIsDuplicateModalVisible(true)
                        }}
                    /> */}
                    <Button
                        isSmall
                        text="Elimina"
                        hasIcon={true}
                        buttonColor="#FF0000"
                        icon={faTrash}
                        onClick={() => {
                            setIsDeleteModalVisible(true)
                        }}
                    />
                </div>
            </div>
        )
    }

    const handleAddItemToPreventivo = async () => {
        dispatch(setIsLoading(true))
        try {
            if (order.newQuoteFromUser) {
                const item: any = await duplicateItemInQuote(order.newQuoteFromUser?.CodiceOfferta, currentItemInQuote.idRecord, dispatch)
                const newItem: ItemInQuote = {
                    idRecord: item.idItem.toString,
                    Message: '',
                    DettaglioItem: item
                }
                dispatch(setItemsInQuote([...order.itemsInQuote, newItem]))
                const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
                const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
                dispatch(setPricesTotalOffer(prices))
                if (order.currentOffer) {
                    const offer = await saveCurrentOffer(order.newQuoteFromUser?.CodiceOfferta, dispatch)
                    if (offer) {
                        dispatch(setCurrentOffer(offer))
                    }
                    // dispatch(setOfferLoadedRedux(order.newQuoteFromUser?.CodiceOfferta, true))
                }
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handleDeleteItemToPreventivo = async () => {
        dispatch(setIsLoading(true))
        try {
            await deleteItemFromQuote(currentItemInQuote.idRecord, dispatch)
            const arrayId = [currentItemInQuote.idRecord]
            for (let i = 0; i < props.assignedProduct.length; i++) {
                await deleteItemFromQuote(props.assignedProduct[i].idRecord, dispatch)
                arrayId.push(props.assignedProduct[i].idRecord)
            }
            const filteredArray = order.itemsInQuote.filter((i) => !arrayId.includes(i.idRecord))
            const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
            const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
            dispatch(setPricesTotalOffer(prices))
            if (filteredArray.length == 0) {
                dispatch(setItemsInQuote([]))
                props.goPrevPhase()
            } else {
                dispatch(setItemsInQuote(filteredArray))
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    const getComposedData = async () => {
        const item = await getComposedItem(parseInt(currentItemInQuote.idRecord), dispatch)
        setCurrentComposedItem(item)
    }

    useEffect(() => {
        getComposedData()
    }, [])

    const renderPrices = () => {
        let price = 0
        props.assignedProduct.forEach((p) => {
            price = price + p.DettaglioItem.Prezzo
        })
        return price
    }

    const renderSalePercent = () => {
        let sale = 0
        let totalPrices = 0
        let finalPrices = 0
        props.assignedProduct.forEach((p) => {
            totalPrices = totalPrices + parseFloat(p.DettaglioItem.PrezzoListino)
        })
        props.assignedProduct.forEach((p) => {
            finalPrices = finalPrices + p.DettaglioItem.Prezzo
        })
        sale = ((totalPrices - finalPrices) * 100) / totalPrices
        const newsale = convertSale(sale.toString()) // WL da fare modifica ricostruzione sconto in formula
        console.log(sale + ' <-- old sconto composta nuovo --> ' + newsale)
        if (sale) {
            return sale
        } else {
            return 0
        }
    }

    //console.log(currentItemInQuote)

    return (
        <div className="cardCustomTypology">
            <CreateStructureModal
                isVisible={visibleModalStructure}
                hideModal={() => setVisibleModalStructure(false)}
                composedItem={currentComposedItem}
                itemInQuote={currentItemInQuote}
                updateItem={(i) => {
                    console.log('QUI', i)
                    setCurrentComposedItem(i)
                }}
            />
            <NotesModal
                isVisible={visibleModalNotes}
                item={currentItemInQuote}
                hideModal={() => setVisibleModalNotes(false)}
                updatedItem={(item) => {
                    setCurrentItemInQuote({
                        ...currentItemInQuote,
                        DettaglioItem: item
                    })
                }}
            />
            <ComposedSaleModal
                isVisible={visibleModalSale}
                item={currentItemInQuote}
                assignedItems={props.assignedProduct}
                hideModal={() => setVisibleModalSale(false)}
            />
            <DeleteModal
                isVisible={isDeleteModalVisible}
                hideModal={() => setIsDeleteModalVisible(false)}
                onDelete={() => {
                    handleDeleteItemToPreventivo()
                    setIsDeleteModalVisible(false)
                }}
                text={'Cancella la finestra composta (ID ' + currentItemInQuote.idRecord + ') e le sue finestre associate'}
                title={'Elimina elemento'}
            />
            <DuplicateModal
                isVisible={isDuplicateModalVisible}
                hideModal={() => setIsDuplicateModalVisible(false)}
                onDuplicate={() => {
                    handleAddItemToPreventivo()
                    setIsDuplicateModalVisible(false)
                }}
                text={"Duplica l'elemento (ID " + currentItemInQuote.idRecord + ')'}
                title={'Duplicazione elemento'}
            />
            {renderFirstColumn()}
            {renderSecondColumn()}
            {renderThirdColumn()}
        </div>
    )
}

export default ComposedTypologyListElement
