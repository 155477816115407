import React, { useEffect, useMemo } from 'react'
import { PDFViewer, Page, Image, Document, Text, View, StyleSheet, Font, PDFDownloadLink } from '@react-pdf/renderer'
import { Reducers, ReducerUser } from '../types/reducers'
import { useSelector } from 'react-redux'
import HeaderPrint from '../components/print/HeaderPrint'
import FooterPrint from '../components/print/FooterPrint'
import SeriePage from '../components/print/SeriePage'
import TipologyPage from '../components/print/TipologyPage'
import FreeTipologyPage from '../components/print/FreeTipologyPage'
import ComposedTypologyPage from '../components/print/ComposedTypologyPage'
import PricesPage from '../components/print/PricesPage'
import moment from 'moment'
import { styles } from '../components/print/styles'
import { PrintType } from '../utilities/PrintType'
import { isMobile, isTablet } from 'react-device-detect'
import { BLUE_DEFAULT } from '../resources/constants'

const PrintPage: React.FC = React.memo(() => {
    const { printFile } = useSelector((store: Reducers) => store.user)

    useEffect(() => {
        Font.register({
            family: 'Roboto',
            fonts: [
                { src: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/Roboto-Regular.ttf' },
                { src: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/Roboto-Bold.ttf', fontStyle: 'bold', fontWeight: 700 }
            ]
        })
    }, [])

    const renderFrontPage = useMemo(() => {
        if (!printFile) {
            return null
        }
        return (
            <Page size="A4" style={{ display: 'flex', flexDirection: 'column' }}>
                <View style={{ width: '100%', height: '100%' }}>
                    <Image source={printFile.frontImage} style={{ width: '100%', height: '100%' }} />
                </View>
            </Page>
        )
    }, [printFile])

    const renderCustomerDataPage = useMemo(() => {
        if (!printFile) {
            return null
        }
        const renderAddress = () => {
            let address = ''
            if (printFile.worksite) {
                address += `${printFile.worksite} - `
            }
            if (printFile.worksiteAddress) {
                address += `${printFile.worksiteAddress}, `
            }
            if (printFile.worksiteCap) {
                address += `${printFile.worksiteCap}  - `
            }
            if (printFile.worksiteCity) {
                address += printFile.worksiteCity
            }
            if (printFile.worksiteProvince) {
                address += `(${printFile.worksiteProvince})`
            }
            return address
        }

        return (
            <Page size="A4" style={{ display: 'flex', flexDirection: 'column' }}>
                <View style={{ width: '100%', height: '100%' }}>
                    <HeaderPrint printFile={printFile} />
                    <View style={{ paddingHorizontal: 20, paddingVertical: 10 }}>
                        <Text style={styles.title}>Introduzione offerta</Text>
                        <View>
                            <Text style={styles.subtitle}>Spettabile {printFile.header.customer.nominativo}</Text>
                            <Text style={styles.standardText}>
                                Indirizzo: {printFile.header.customer.address} - {printFile.header.customer.cap} {printFile.header.customer.city} (
                                {printFile.header.customer.province})
                            </Text>
                            <Text style={styles.standardText}>Telefono: {printFile.header.customer.mobile}</Text>
                            <Text style={styles.standardText}>Email: {printFile.header.customer.email}</Text>
                            {printFile.header.customer.partitaIVA ? (
                                <Text style={styles.standardText}>Partita IVA: {printFile.header.customer.partitaIVA}</Text>
                            ) : null}
                            {printFile.header.customer.codFiscale ? (
                                <Text style={styles.standardText}>Codice Fiscale: {printFile.header.customer.codFiscale}</Text>
                            ) : null}
                            {printFile.header.customer.sdi ? <Text style={styles.standardText}>Codice SDI: {printFile.header.customer.sdi}</Text> : null}
                            {renderAddress() !== '' ? <Text style={styles.standardText}>Indirizzo cantiere: {renderAddress()}</Text> : null}
                            {printFile.header.deliveryDate ? (
                                <Text style={styles.standardText}>Data di consegna prevista: {moment(printFile.header.deliveryDate).format('DD/MM/YYYY')}</Text>
                            ) : null}
                        </View>
                        <View>
                            <Text style={[styles.subtitle, { fontSize: 18 }]}>Dati operatore</Text>
                            <Text style={styles.standardText}>
                                {printFile.agent.name} {printFile.agent.surname}
                            </Text>
                            <Text style={styles.standardText}>Email: {printFile.agent.email}</Text>
                            <Text style={styles.standardText}>
                                Telefono: {printFile.agent.phone} - Cellulare: {printFile.agent.mobile}
                            </Text>
                        </View>
                        <View>
                            <Text style={[styles.subtitle, { fontSize: 18 }]}>Dati azienda</Text>
                            <Text style={styles.standardText}>{printFile.footer.ragioneSociale}</Text>
                            <Text style={styles.standardText}>
                                Indirizzo: {printFile.footer.address}, {printFile.footer.cap} - {printFile.footer.city} ({printFile.footer.province})
                            </Text>
                            <Text style={styles.standardText}>Telefono: {printFile.footer.phone}</Text>
                        </View>
                        <View style={{ marginTop: 20 }}>
                            <Text style={styles.standardText}>{printFile.textStartCurrentOffer}</Text>
                        </View>
                    </View>
                    <FooterPrint printFile={printFile} />
                </View>
            </Page>
        )
    }, [printFile])

    const renderCompanyPage = useMemo(() => {
        if (!printFile) {
            return null
        }
        return (
            <Page size="A4" style={{ display: 'flex', flexDirection: 'column' }}>
                <View style={{ width: '100%', height: '100%' }}>
                    <HeaderPrint printFile={printFile} />
                    <View style={{ paddingHorizontal: 20, paddingVertical: 10 }}>
                        <Text style={styles.title}>{printFile.footer.ragioneSociale}</Text>
                        <View style={{ marginTop: 20 }}>
                            <Text style={styles.standardText}>{printFile.printIntroText}</Text>
                        </View>
                    </View>
                    <FooterPrint printFile={printFile} />
                </View>
            </Page>
        )
    }, [printFile])

    const renderProductsPages = useMemo(() => {
        if (!printFile) {
            return null
        }
        return printFile?.items.map((p) => {
            return (
                <>
                    <SeriePage key={p.codSerie} item={p} printFile={printFile} />
                    {p.tipologies
                        .filter((t) => t.IDComposta == null)
                        .map((t, i) => {
                            if (t.description == 'Composta') {
                                return <ComposedTypologyPage key={t.id} currentItem={p} tipology={t} printFile={printFile} index={i + 1} />
                            }
                            if (t.description == 'Libera') {
                                return <FreeTipologyPage key={t.id} currentItem={p} tipology={t} printFile={printFile} index={i + 1} />
                            }
                            return <TipologyPage key={t.id} currentItem={p} tipology={t} printFile={printFile} index={i + 1} />
                        })}
                </>
            )
        })
    }, [printFile])

    const renderPricesPage = useMemo(() => {
        if (!printFile || printFile.printType == PrintType.NO_PRICES) {
            return null
        }
        return <PricesPage printFile={printFile} />
    }, [printFile])

    const renderFinalNotes = useMemo(() => {
        if (!printFile) {
            return null
        }
        return (
            <Page size="A4" style={{ display: 'flex', flexDirection: 'column' }}>
                <View style={{ width: '100%', height: '100%' }}>
                    <HeaderPrint printFile={printFile} />
                    <View style={{ paddingHorizontal: 20, paddingVertical: 10 }}>
                        {printFile.printOutroText ? (
                            <>
                                <Text style={styles.title}>Note finali</Text>
                                <View style={{ marginTop: 20 }}>
                                    <Text style={styles.standardText}>{printFile?.printOutroText}</Text>
                                </View>
                            </>
                        ) : null}
                        {printFile.textEndCurrentOffer ? (
                            <>
                                <Text style={styles.title}>Note finali dell{"'"}offerta</Text>
                                <View style={{ marginTop: 20 }}>
                                    <Text style={styles.standardText}>{printFile?.textEndCurrentOffer}</Text>
                                </View>
                            </>
                        ) : null}
                    </View>
                    <FooterPrint printFile={printFile} />
                </View>
            </Page>
        )
    }, [printFile])

    const renderRetroPage = useMemo(() => {
        if (!printFile) {
            return null
        }
        return (
            <Page size="A4" style={{ display: 'flex', flexDirection: 'column' }}>
                <View style={{ width: '100%', height: '100%' }}>
                    <Image source={printFile.retroImage} style={{ width: '100%', height: '100%' }} />
                </View>
            </Page>
        )
    }, [printFile])

    console.log(printFile)

    const renderMainDocument = useMemo(() => {
        if (!printFile) {
            return null
        }
        return (
            <PDFViewer width="100%" height="100%" style={{ height: '100vh' }}>
                <Document>
                    {printFile.printType !== PrintType.NORMAL ? renderFrontPage : null}
                    {renderCustomerDataPage}
                    {printFile.printType !== PrintType.NORMAL ? renderCompanyPage : null}
                    {renderProductsPages}
                    {renderPricesPage}
                    {printFile.printType !== PrintType.NORMAL ? renderFinalNotes : null}
                    {printFile.printType !== PrintType.NORMAL ? renderRetroPage : null}
                </Document>
            </PDFViewer>
        )
    }, [printFile])

    if (renderMainDocument && (isMobile || isTablet)) {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', flexDirection: 'column', gap: 30 }}>
                <img src={printFile?.logo} style={{ width: '20vw', height: 'auto' }} />
                <PDFDownloadLink
                    document={renderMainDocument}
                    fileName={printFile?.header.offer}
                    style={{
                        textDecoration: 'none',
                        backgroundColor: printFile?.color ? printFile.color : BLUE_DEFAULT,
                        color: '#fff',
                        padding: '10px 20px',
                        borderRadius: 5
                    }}
                >
                    Scarica il pdf
                </PDFDownloadLink>
            </div>
        )
    }

    return renderMainDocument
})
PrintPage.displayName = 'PrintPage'

export default PrintPage
