import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Input from '../../../components/formComponents/Input'
import SubtitleSection from '../../../components/SubtitleSection'
import { getEmailConfig } from '../../../resources/requests/settings'
import { setError, setIsLoading } from '../../../store/actions/graphic'

interface EmailConfig {
    MAILCONFIG: string
    MAILFROM: string
    MAILFROMNAME: string
    MAILHOST: string
    MAILPASSWORD: string
    MAILPORT: number
    MAILREPLY: string
    MAILREPLYNAME: string
    MAILSECUREAUTH: boolean
    MAILSECURETYPE: string
    MAILUSERNAME: string
}

const EmailConfigScreen: React.FC = () => {
    const [emailConfig, setEmailConfig] = useState<EmailConfig | null>(null)
    const dispatch = useDispatch()

    const fetchConfig = async () => {
        dispatch(setIsLoading(true))
        try {
            const data = await getEmailConfig(dispatch)
            setEmailConfig(data)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    useEffect(() => {
        fetchConfig()
    }, [])

    return (
        <div>
            <div className="introProductsPage">
                <SubtitleSection text="Configurazione casella Email" />
            </div>
            {emailConfig ? (
                <div className="formSettings">
                    {Object.keys(emailConfig).map((k: string) => {
                        return (
                            <Input
                                key={k}
                                value={emailConfig[k as keyof EmailConfig].toString()}
                                label={k.toLowerCase()}
                                disabled
                                onChange={() => {
                                    return
                                }}
                                divStyle={{ flexDirection: 'column', display: 'flex' }}
                                labelStyle={{ textTransform: 'capitalize' }}
                            />
                        )
                    })}
                </div>
            ) : null}
        </div>
    )
}

export default EmailConfigScreen
