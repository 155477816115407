import React, { useEffect, useRef, useState } from 'react'
import TitlePage from '../../components/TitlePage'
import { AddItemsToPreventivoBody, Serie, Typology } from '../../types/data'
import PhaseQuote from '../../components/PhaseQuote'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight, faInfoCircle, faPercent } from '@fortawesome/free-solid-svg-icons'
import CreaOffertaView from './screen/CreaOffertaView'
import SelezionaTipologieView from './screen/SelezionaTipologieView'
import PersonalizzaTipologieView from './screen/PersonalizzaTipologieView'
import {
    addItemToPreventivo,
    addQuoteToOffer,
    calculateTotalPrices,
    createDefaultVariants,
    deleteAllQuotes,
    getTotalPricesOffer,
    getTypologies,
    saveCurrentOffer,
    setAdditionalDatasOffer,
    setQuotePrice
} from '../../resources/requests/requests'
import { ItemInQuote, OfferData, TotaliOfferta, TotaliPreventivo } from '../../types/order'
import { ReducerData, ReducerGraphic, ReducerOrder, Reducers, ReducerUser } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import {
    setcurrentCustomerSale,
    setCurrentOffer,
    setIdUserKnown,
    setItemsInQuote,
    setNewOrderFromUser,
    setOfferData,
    setPricesTotalOffer
} from '../../store/actions/order'
import { transformToCurrency, useInterval, usePrevious } from '../../utilities/functions'
import CurrentOffer from '../Offers/CurrentOffer'
import TotalPriceModal from '../../components/modals/TotalPriceModal'
import { setOfferLoadedRedux } from '../../store/actions/thunk_actions'
import { setError, setIsLoading } from '../../store/actions/graphic'
import AlertChangePhaseModal from '../../components/modals/AlertChangePhaseModal'
import moment from 'moment'

interface Props {
    location: any
}

enum Phase {
    CREA_OFFERTA,
    SELEZIONA_TIPOLOGIE,
    PERSONALIZZA_TIPOLOGIE
}

const arrayPhases = ['Crea offerta', 'Seleziona tipologie', 'Personalizza tipologie']

const NewQuotePage: React.FC<Props> = (props) => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [selectedImage, setSelectedImage] = useState(user.lastProduct?.ImgMain)
    const [imgGallery, setImgGallery] = useState(1)
    const [currentPhase, setCurrentPhase] = useState(Phase.CREA_OFFERTA)
    const [typologies, setTypologies] = useState<Typology[]>([])
    const [currentQuoteTotal, setCurrentQuoteTotal] = useState<string>('')
    const [showTotalPriceModal, setShowTotalPriceModal] = useState(false)
    const voidOfferData: OfferData = {
        offerTitle: '',
        customer: {
            name: '',
            address: '',
            province: '',
            city: '',
            email: '',
            number: '',
            category: '',
            agent: ''
        },
        worksite: {
            description: '',
            address: '',
            province: '',
            city: ''
        }
    }
    const [currentClientOffer, setCurrentClientOffer] = useState(order.offerData ? order.offerData : voidOfferData)
    const [isEdit, setIsEdit] = useState(false)
    const [editTypologies, setEditTypologies] = useState(false)
    const [nextPhase, setNextPhase] = useState<Phase>(currentPhase)
    const [tempItemsToAdd, setTempItemsToAdd] = useState<AddItemsToPreventivoBody | null>(null)
    const previousOrder = usePrevious(order.currentOffer)
    const dispatch = useDispatch()

    useInterval(() => {
        const newKey = `ImageGallery${imgGallery + 1}`
        //@ts-ignore
        if (user.lastProduct[newKey]) {
            setImgGallery(imgGallery + 1)
        } else {
            setImgGallery(1)
        }
    }, 10000)

    useEffect(() => {
        const key = `ImageGallery${imgGallery}`
        //@ts-ignore
        setSelectedImage(user.lastProduct[key])
    }, [imgGallery])

    useEffect(() => {
        //console.log(order.currentOffer)
        if (order.currentOffer && order.currentOffer.Contatto.length > 0 && order.currentOffer.Preventivi.length > 0) {
            dispatch(setIdUserKnown(order.currentOffer.Contatto[0].idContatto))
            const cityFound = data.cities.find((c) => c.Comune == order.currentOffer?.ComuneCantiere)
            dispatch(
                setOfferData({
                    offerTitle: order.currentOffer.Descrizione,
                    customer: {
                        name: order.currentOffer.Contatto[0].nominativo,
                        address: order.currentOffer.Contatto[0].indirizzo,
                        province: order.currentOffer.Contatto[0].provincia,
                        city: order.currentOffer.Contatto[0].citta,
                        email: order.currentOffer.Contatto[0].email,
                        number: order.currentOffer.Contatto[0].cellulare,
                        category: order.currentOffer.Contatto[0].idCategoria,
                        agent: order.currentOffer.Contatto[0].CodAg
                    },
                    worksite: {
                        description: order.currentOffer.CantiereInstallazione,
                        address: order.currentOffer.IndirizzoCantiere,
                        province: order.currentOffer.ProvinciaCantiere,
                        city: cityFound ? cityFound.IDComune : ''
                    }
                })
            )
            const seriesFound = order.currentOffer.Preventivi.find((p) => p.ItemsList[0].CodiceSerie == user.lastProduct?.CodSerie)
            if (seriesFound) {
                dispatch(
                    setNewOrderFromUser({
                        IdRecord: '',
                        CodiceOfferta: order.currentOffer.CodiceOfferta,
                        CodicePreventivo: seriesFound.CodicePreventivo,
                        IdContattoTemporaneo: order.currentOffer.Contatto[0].ID
                    })
                )
                dispatch(
                    setItemsInQuote(
                        seriesFound.ItemsList.map((i) => {
                            return {
                                idRecord: i.idRecord,
                                Message: '',
                                DettaglioItem: {
                                    AnnotazioniDB: i.AnnotazioniDB,
                                    Annotazioniutente: i.Annotazioniutente,
                                    CodGrigliaListino: i.CodGrigliaListino,
                                    CodStruttura: i.CodStruttura,
                                    CodTipoItem: i.CodTipoItem,
                                    CodicePreventivo: i.CodicePreventivo,
                                    CodiceSerie: i.CodiceSerie,
                                    DescrizioneInterneDB: i.DescrizioneInterneDB,
                                    Etichetta: i.Etichetta,
                                    FiguraRiferimento: i.FiguraRiferimento,
                                    HManiglia: i.HManiglia ? i.HManiglia.toString() : null,
                                    HTraverso1: i.HTraverso1 ? i.HTraverso1.toString() : null,
                                    HTraverso2: i.HTraverso2 ? i.HTraverso2.toString() : null,
                                    HTraverso3: i.HTraverso3 ? i.HTraverso3.toString() : null,
                                    HTraverso4: i.HTraverso4 ? i.HTraverso4.toString() : null,
                                    IDComposta: i.IDComposta,
                                    Htip: i.Htip.toString(),
                                    Ltip: i.Ltip.toString(),
                                    Modificato: i.Modificato,
                                    Ordinamento: i.Ordinamento.toString(),
                                    PRM: i.PRM,
                                    Peso: i.Peso,
                                    Prezzo: i.Prezzo,
                                    PrezzoListino: i.PrezzoListino.toString(),
                                    Prif: i.Prif.toString(),
                                    Quantita: i.Quantita,
                                    Ricalcola: i.Ricalcola,
                                    Sconto: i.Sconto,
                                    Trasmittanza: i.Trasmittanza,
                                    elencoVarianti: i.elencoVarianti.map((v) => {
                                        return {
                                            CALCAMMVAR: v.CALC_AMM_VAR,
                                            CALCVAR: v.CALC_VAR,
                                            Calcolo: v.Calcolo,
                                            CodVar: v.CodVar,
                                            Costo: v.Costo,
                                            DecrizioneCategoria: v.DecrizioneCategoria,
                                            DescrizVar: v.DescrizVar,
                                            FiguraAp: v.FiguraAp,
                                            IdCatVar: v.IdCatVar,
                                            IdGenereVar: v.IdGenereVar,
                                            NascondiInDoc: v.NascondiInDoc,
                                            Necess: v.Necess,
                                            PRM: v.PRM,
                                            Prezzo: v.Prezzo.toString(),
                                            PrezzoVariante: v.PrezzoVariante,
                                            Priorita: v.Priorita,
                                            PrioritaCalcolo: v.PrioritaCalcolo,
                                            StampaOrd: v.StampaOrd,
                                            StampaPrev: v.StampaPrev,
                                            StampaPrezzi: v.StampaPrezzi,
                                            StampaQta: v.StampaQta,
                                            Um: v.Um,
                                            Univoco: v.Univoco,
                                            VALIDAMMTIPLIS: v.VALID_AMM_TIPLIS,
                                            VALIDAMMVAR: v.VALID_AMM_VAR,
                                            inserita: 1
                                        }
                                    })
                                }
                            }
                        })
                    )
                )
            } else {
                dispatch(setItemsInQuote([]))
                createNewOffer()
            }
            if (currentPhase == Phase.CREA_OFFERTA && !previousOrder) {
                setCurrentPhase(Phase.SELEZIONA_TIPOLOGIE)
            }
        } else {
            deleteAllQuotes(dispatch)
            dispatch(setIdUserKnown(''))
            dispatch(setOfferData(voidOfferData))
            dispatch(setItemsInQuote([]))
            dispatch(setPricesTotalOffer(null))
        }
    }, [order.currentOffer])

    const createNewOffer = async () => {
        if (order.currentOffer && user.lastProduct) {
            dispatch(setIsLoading(true))
            try {
                const newOrder = await addQuoteToOffer(
                    parseInt(order.currentOffer.Contatto[0].idContatto),
                    user.lastProduct?.CodSerie,
                    order.currentOffer.CodiceOfferta,
                    dispatch
                )
                dispatch(setNewOrderFromUser(newOrder))
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    useEffect(() => {
        const scrollableDiv = document.getElementById('scrollableDiv')
        scrollableDiv?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
        setNextPhase(currentPhase)
        if (currentPhase == Phase.PERSONALIZZA_TIPOLOGIE) {
            getPrices()
        }
        if (currentPhase == Phase.SELEZIONA_TIPOLOGIE) {
            getNewTypologies()
        }
    }, [currentPhase])

    useEffect(() => {
        setCurrentClientOffer(order.offerData ? order.offerData : voidOfferData)
    }, [order.offerData])

    const getPrices = async () => {
        dispatch(setIsLoading(true))
        try {
            const foundTax = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)?.Codice
            const pricesWithoutSale = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, foundTax ? foundTax : data.taxes[0].Codice, dispatch)
            let prices = pricesWithoutSale
            console.log(order.pricesTotalOffer)
            const foundPreventivo = order.pricesTotalOffer
                ? order.pricesTotalOffer.DettaglioPreventivi.find((p) => p.CodicePreventivo == order.newQuoteFromUser?.CodicePreventivo)
                : undefined
            // console.log(order.pricesTotalOffer, order.currentCustomerSale)
            if (pricesWithoutSale && order.currentCustomerSale && order.newQuoteFromUser) {
                if (!foundPreventivo) {
                    prices = await handleSales(pricesWithoutSale, order.newQuoteFromUser.CodicePreventivo)
                } else {
                    const prev = foundPreventivo as TotaliPreventivo
                    prices = await handleSales(pricesWithoutSale, order.newQuoteFromUser.CodicePreventivo, prev.Sconto as string)
                }
            }
            dispatch(setPricesTotalOffer(prices))
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handleSales = async (pricesOffer: TotaliOfferta, codPreventivo: string, customSale?: string) => {
        let prices = pricesOffer
        const foundPreventivo = pricesOffer.DettaglioPreventivi.find((p) => p.CodicePreventivo == codPreventivo)
        if (order.currentCustomerSale && foundPreventivo) {
            const saleFirst = customSale ? parseFloat(customSale) : parseFloat(order.currentCustomerSale.predefinito)
            const saleAdd = customSale ? 0 : parseFloat(order.currentCustomerSale.aggiuntivo)
            const predSale = (parseFloat(foundPreventivo.TotaleListino) * saleFirst) / 100
            const addSale = ((parseFloat(foundPreventivo.TotaleListino) - predSale) * saleAdd) / 100
            const totalToSet = parseFloat(foundPreventivo.TotaleListino) - predSale - addSale
            const body = {
                CodiceOfferta: order.newQuoteFromUser ? order.newQuoteFromUser?.CodiceOfferta : null,
                CodicePreventivo: order.newQuoteFromUser ? order.newQuoteFromUser?.CodicePreventivo : null,
                Sconto: null,
                PrezzoTotale: totalToSet.toString()
            }
            await setQuotePrice(body, dispatch)
            const foundTax = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)?.Codice
            prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, foundTax ? foundTax : data.taxes[0].Codice, dispatch)
            const offer = await saveCurrentOffer(order.newQuoteFromUser?.CodiceOfferta, dispatch)
            if (offer) {
                dispatch(setCurrentOffer(offer))
            }
            // await dispatch(setOfferLoadedRedux(order.newQuoteFromUser?.CodiceOfferta, true))
        }
        return prices
    }

    useEffect(() => {
        if (order.pricesTotalOffer && order.newQuoteFromUser) {
            const quoteFound = order.pricesTotalOffer.DettaglioPreventivi.find(
                (p) => p.CodicePreventivo == order.newQuoteFromUser?.CodicePreventivo
            )?.ImportoTotale
            if (quoteFound) {
                setCurrentQuoteTotal(quoteFound)
            }
        }
    }, [order.currentOffer, order.pricesTotalOffer])

    useEffect(() => {
        if (order.idUserKnown && currentClientOffer.customer.name) {
            const foundCustomer = data.customers.find((c) => c.idContatto == order.idUserKnown)
            if (foundCustomer && foundCustomer.Sconti && foundCustomer.categoria && user.lastProduct) {
                const foundSale = foundCustomer.Sconti.find((s) => s.CodSerie == user.lastProduct?.CodSerie)
                if (foundSale) {
                    dispatch(setcurrentCustomerSale({ ...foundSale, categoria: foundCustomer.categoria }))
                } else {
                    const foundDefault = foundCustomer.Sconti.find((s) => s.CodSerie == 'Predefinito')
                    if (foundDefault) {
                        dispatch(setcurrentCustomerSale({ ...foundDefault, categoria: foundCustomer.categoria }))
                    } else {
                        dispatch(setcurrentCustomerSale(null))
                    }
                }
            } else {
                dispatch(setcurrentCustomerSale(null))
            }
        } else {
            dispatch(setcurrentCustomerSale(null))
        }
    }, [currentClientOffer, order.idUserKnown])

    const renderCurrentPhase = () => {
        if (user.lastProduct) {
            switch (currentPhase) {
                case Phase.CREA_OFFERTA:
                    return (
                        <CreaOffertaView
                            serieSelected={user.lastProduct}
                            currentClientOffer={currentClientOffer}
                            setCurrentClientOffer={(d) => {
                                setCurrentClientOffer(d)
                                setIsEdit(true)
                            }}
                            nextPhase={() => setCurrentPhase(Phase.SELEZIONA_TIPOLOGIE)}
                        />
                    )
                case Phase.SELEZIONA_TIPOLOGIE:
                    return (
                        <SelezionaTipologieView
                            serieSelected={user.lastProduct}
                            typologiesSelected={typologies}
                            nextPhase={() => setCurrentPhase(Phase.PERSONALIZZA_TIPOLOGIE)}
                            edited={(i) => {
                                setEditTypologies(true)
                                setTempItemsToAdd(i)
                            }}
                        />
                    )
                case Phase.PERSONALIZZA_TIPOLOGIE:
                    return <PersonalizzaTipologieView addItem={() => setCurrentPhase(Phase.SELEZIONA_TIPOLOGIE)} />
                default:
                    return (
                        <CreaOffertaView
                            serieSelected={user.lastProduct}
                            currentClientOffer={currentClientOffer}
                            setCurrentClientOffer={(d) => {
                                setCurrentClientOffer(d)
                                setIsEdit(true)
                            }}
                            nextPhase={() => setCurrentPhase(Phase.SELEZIONA_TIPOLOGIE)}
                        />
                    )
            }
        }
    }

    const getNewTypologies = async () => {
        if (user.lastProduct) {
            dispatch(setIsLoading(true))
            try {
                const typologiesFetched = await getTypologies(user.lastProduct?.CodSerie, dispatch)
                setTypologies(typologiesFetched)
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    const handleSetAdditionalDatas = async () => {
        if (order.currentOffer) {
            dispatch(setIsLoading(true))
            try {
                const cityFound = data.cities.find((c) => c.IDComune == currentClientOffer.worksite.city)
                const bodyAdditional = {
                    CodiceOfferta: order.currentOffer?.CodiceOfferta,
                    Descrizione: currentClientOffer.offerTitle,
                    CantiereInstallazione: currentClientOffer.worksite.description,
                    IndirizzoCantiere: currentClientOffer.worksite.address,
                    ProvinciaCantiere: currentClientOffer.worksite.province,
                    ComuneCantiere: cityFound ? cityFound.Comune : '',
                    CAPCantiere: cityFound ? cityFound.CAP : '',
                    TestoIniziale: '',
                    TestoFinale: '',
                    DataConsegna: moment(order.currentOffer?.DataConsegna).format('YYYY-MM-DD')
                }
                await setAdditionalDatasOffer(bodyAdditional, dispatch)
                const offer = await saveCurrentOffer(order.newQuoteFromUser?.CodiceOfferta, dispatch)
                if (offer) {
                    dispatch(setCurrentOffer(offer))
                }
                // await dispatch(setOfferLoadedRedux(order.currentOffer.CodiceOfferta))
            } catch (error: any) {
                dispatch(setError(error))
            }
            dispatch(setIsLoading(false))
        }
    }

    const handleAddItemToPreventivo = async () => {
        if (tempItemsToAdd && tempItemsToAdd.Tipologia.length > 0) {
            dispatch(setIsLoading(true))
            try {
                const idArray: any = await addItemToPreventivo(tempItemsToAdd, dispatch)
                const defaultVariants: ItemInQuote[] = await createDefaultVariants({ Items: idArray }, dispatch)
                dispatch(setItemsInQuote([...order.itemsInQuote, ...defaultVariants]))
                const offer = await saveCurrentOffer(order.newQuoteFromUser?.CodiceOfferta, dispatch)
                if (offer) {
                    dispatch(setCurrentOffer(offer))
                }
                // await dispatch(setOfferLoadedRedux(order.newQuoteFromUser?.CodiceOfferta, true))
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    return (
        <div className="columnsSerie">
            <div className="columnDataSerie">
                <span className="famigliaSerie">{user.lastProduct?.FamigliaProdotto.toUpperCase()}</span>
                <TitlePage text={user.lastProduct?.NomeSerie} style={{ marginBottom: 0 }} />
                <p className="sloganSerie">{user.lastProduct?.Slogan}</p>
                <img src={selectedImage} className="imgMainSerie" />
                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(5, 1fr)', gap: 10, height: 90 }}>
                    {user.lastProduct?.ImageGallery1 && (
                        <img
                            src={user.lastProduct?.ImageGallery1}
                            onClick={() => {
                                setImgGallery(1)
                            }}
                            style={{
                                maxWidth: '100%',
                                borderRadius: 3,
                                border: selectedImage == user.lastProduct?.ImageGallery1 ? `2px solid ${graphic.colorAccent}` : '0',
                                cursor: 'pointer'
                            }}
                        />
                    )}
                    {user.lastProduct?.ImageGallery2 && (
                        <img
                            src={user.lastProduct?.ImageGallery2}
                            onClick={() => {
                                setImgGallery(2)
                            }}
                            style={{
                                maxWidth: '100%',
                                borderRadius: 3,
                                border: selectedImage == user.lastProduct?.ImageGallery2 ? `2px solid ${graphic.colorAccent}` : '0',
                                cursor: 'pointer'
                            }}
                        />
                    )}
                    {user.lastProduct?.ImageGallery3 && (
                        <img
                            src={user.lastProduct?.ImageGallery3}
                            onClick={() => {
                                setImgGallery(3)
                            }}
                            style={{
                                maxWidth: '100%',
                                borderRadius: 3,
                                border: selectedImage == user.lastProduct?.ImageGallery3 ? `2px solid ${graphic.colorAccent}` : '0',
                                cursor: 'pointer'
                            }}
                        />
                    )}
                    {user.lastProduct?.ImageGallery4 && (
                        <img
                            src={user.lastProduct?.ImageGallery4}
                            onClick={() => {
                                setImgGallery(4)
                            }}
                            style={{
                                maxWidth: '100%',
                                borderRadius: 3,
                                border: selectedImage == user.lastProduct?.ImageGallery4 ? `2px solid ${graphic.colorAccent}` : '0',
                                cursor: 'pointer'
                            }}
                        />
                    )}
                    {user.lastProduct?.ImageGallery5 && (
                        <img
                            src={user.lastProduct?.ImageGallery5}
                            onClick={() => {
                                setImgGallery(5)
                            }}
                            style={{
                                maxWidth: '100%',
                                borderRadius: 3,
                                border: selectedImage == user.lastProduct?.ImageGallery5 ? `2px solid ${graphic.colorAccent}` : '0',
                                cursor: 'pointer'
                            }}
                        />
                    )}
                </div>
                <p style={{ margin: '15px 0', maxHeight: 100, overflowY: 'scroll' }}>{user.lastProduct?.AdditionalText}</p>
                {order.currentOffer ? (
                    <p style={{ margin: '15px 0', fontStyle: 'italic', color: '#68696D' }}>Codice offerta: {order.currentOffer.CodiceOfferta}</p>
                ) : null}
                {order.pricesTotalOffer && currentPhase == Phase.PERSONALIZZA_TIPOLOGIE && (
                    <div style={{ display: 'flex', flexDirection: 'row', position: 'relative', gap: 8, alignItems: 'flex-end' }}>
                        <span style={{ color: '#000', fontSize: 27, fontWeight: 500 }}>Totale:</span>
                        <span style={{ color: graphic.colorAccent, fontWeight: 900, fontSize: 32 }}>{transformToCurrency(parseFloat(currentQuoteTotal))}</span>
                        <FontAwesomeIcon
                            icon={faPercent}
                            style={{
                                color: '#fff',
                                cursor: 'pointer',
                                marginBottom: 15,
                                backgroundColor: graphic.colorAccent,
                                padding: 5,
                                width: 14,
                                height: 14,
                                borderRadius: 25
                            }}
                            onClick={() => setShowTotalPriceModal(true)}
                        />
                    </div>
                )}
            </div>
            <div className="columnQuoteSerie">
                <div className="phaseSelector">
                    <PhaseQuote
                        onClick={() => {
                            if (editTypologies) {
                                setNextPhase(Phase.CREA_OFFERTA)
                                return
                            }
                            setCurrentPhase(Phase.CREA_OFFERTA)
                        }}
                        title={arrayPhases[0]}
                        number={1}
                        isActive={currentPhase == Phase.CREA_OFFERTA}
                    />
                    <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 29, color: '#68696D', opacity: 0.6 }} />
                    <PhaseQuote
                        onClick={() => {
                            if (!order.currentOffer || !order.newQuoteFromUser) {
                                return
                            }
                            if (
                                isEdit &&
                                order.currentOffer &&
                                (order.currentOffer.Descrizione !== currentClientOffer.offerTitle ||
                                    order.currentOffer.CantiereInstallazione !== currentClientOffer.worksite.description ||
                                    order.currentOffer.IndirizzoCantiere !== currentClientOffer.worksite.address ||
                                    order.currentOffer.ProvinciaCantiere !== currentClientOffer.worksite.province ||
                                    order.currentOffer.CAPCantiere !== currentClientOffer.worksite.city)
                            ) {
                                setNextPhase(Phase.SELEZIONA_TIPOLOGIE)
                                return
                            }
                            setCurrentPhase(Phase.SELEZIONA_TIPOLOGIE)
                        }}
                        title={arrayPhases[1]}
                        number={2}
                        isActive={currentPhase == Phase.SELEZIONA_TIPOLOGIE}
                    />
                    <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: 29, color: '#68696D', opacity: 0.6 }} />
                    <PhaseQuote
                        onClick={() => {
                            if (!order.currentOffer || !order.newQuoteFromUser) {
                                return
                            }
                            if (order.currentOffer) {
                                const foundInOffer = order.currentOffer.Preventivi.find(
                                    (p) => p.ItemsList.find((i) => typologies.find((t) => t.CodTip == i.CodTipoItem) !== undefined) !== undefined
                                )
                                if (!foundInOffer) {
                                    return
                                }
                                if (
                                    isEdit &&
                                    order.currentOffer &&
                                    (order.currentOffer.Descrizione !== currentClientOffer.offerTitle ||
                                        order.currentOffer.CantiereInstallazione !== currentClientOffer.worksite.description ||
                                        order.currentOffer.IndirizzoCantiere !== currentClientOffer.worksite.address ||
                                        order.currentOffer.ProvinciaCantiere !== currentClientOffer.worksite.province ||
                                        order.currentOffer.CAPCantiere !== currentClientOffer.worksite.city)
                                ) {
                                    setNextPhase(Phase.PERSONALIZZA_TIPOLOGIE)
                                    return
                                }
                                if (editTypologies) {
                                    setNextPhase(Phase.PERSONALIZZA_TIPOLOGIE)
                                    return
                                }
                                if (typologies.length > 0) {
                                    setCurrentPhase(Phase.PERSONALIZZA_TIPOLOGIE)
                                }
                            }
                        }}
                        title={arrayPhases[2]}
                        number={3}
                        isActive={currentPhase == Phase.PERSONALIZZA_TIPOLOGIE}
                    />
                </div>
                <div className="scrollableDiv" id="scrollableDiv">
                    {renderCurrentPhase()}
                </div>
            </div>
            <AlertChangePhaseModal
                isVisible={nextPhase !== currentPhase}
                saveAndContinue={async () => {
                    if (currentPhase == Phase.CREA_OFFERTA) {
                        await handleSetAdditionalDatas()
                        setCurrentPhase(nextPhase)
                        setIsEdit(false)
                    }
                    if (currentPhase == Phase.SELEZIONA_TIPOLOGIE) {
                        await handleAddItemToPreventivo()
                        setCurrentPhase(nextPhase)
                        setEditTypologies(false)
                    }
                }}
                dontSave={() => {
                    if (order.currentOffer) {
                        setCurrentClientOffer({
                            ...currentClientOffer,
                            offerTitle: order.currentOffer?.Descrizione,
                            worksite: {
                                description: order.currentOffer.CantiereInstallazione,
                                address: order.currentOffer.IndirizzoCantiere,
                                province: order.currentOffer.ProvinciaCantiere,
                                city: order.currentOffer.CAPCantiere
                            }
                        })
                    }
                    setCurrentPhase(nextPhase)
                }}
            />
            <TotalPriceModal
                isVisible={showTotalPriceModal}
                codicePreventivo={order?.newQuoteFromUser?.CodicePreventivo}
                currentSerie={user.lastProduct}
                hideModal={() => setShowTotalPriceModal(false)}
                currentQuote={
                    order.pricesTotalOffer
                        ? order.pricesTotalOffer.DettaglioPreventivi.find((p) => p.CodicePreventivo == order.newQuoteFromUser?.CodicePreventivo)
                        : null
                }
            />
        </div>
    )
}

export default NewQuotePage
