import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogContent } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { ItemInQuote } from '../../types/order'
import { ReducerOrder, Reducers } from '../../types/reducers'
import Button from '../Button'
import TitlePage from '../TitlePage'
import { useSelector, useDispatch } from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { setItemsInQuote } from '../../store/actions/order'

interface Props {
    isVisible: boolean
    hideModal: () => void
}

const CustomOrderModal: React.FC<Props> = (props) => {
    const [orderedArray, setOrderedArray] = useState<ItemInQuote[]>([])
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const dispatch = useDispatch()

    useEffect(() => {
        setOrderedArray(order.itemsInQuote)
    }, [props.isVisible])

    const getItemStyle = (isDragging: any, draggableStyle: any) => ({
        // some basic styles to make the items look a bit nicer
        userSelect: 'none',
        padding: 10,
        borderRadius: 10,
        display: 'flex',
        gap: 30,
        // change background colour if dragging
        background: isDragging ? '#cecece' : '#fff',

        // styles we need to apply on draggables
        ...draggableStyle
    })

    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list)
        const [removed] = result.splice(startIndex, 1)
        result.splice(endIndex, 0, removed)
        return result
    }

    const onDragEnd = (result: any) => {
        // dropped outside the list
        if (!result.destination) {
            return
        }

        const items = reorder(orderedArray, result.source.index, result.destination.index)

        setOrderedArray(items)
    }

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text="Ordina tipolgie" style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0, marginBottom: 20 }}>Trascina i componenti per ordinare la lista delle tipologie</p>
                <DragDropContext
                    onDragEnd={(result) => {
                        onDragEnd(result)
                    }}
                >
                    <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div {...provided.droppableProps} ref={provided.innerRef} style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                                {orderedArray.map((o, i) => {
                                    return (
                                        <Draggable key={o.idRecord} draggableId={o.idRecord} index={i}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                                >
                                                    <span>ID: {o.idRecord}</span>
                                                    <span>
                                                        {o.DettaglioItem.Etichetta} ({o.DettaglioItem.DescrizioneInterneDB})
                                                    </span>
                                                </div>
                                            )}
                                        </Draggable>
                                    )
                                })}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: '100%', marginTop: 20 }}>
                    <Button
                        isSmall
                        text="Salva"
                        hasIcon={true}
                        icon={faEdit}
                        onClick={() => {
                            dispatch(setItemsInQuote(orderedArray))
                            props.hideModal()
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default CustomOrderModal
