import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import React, { useState } from 'react'
import { ReducerGraphic, Reducers } from '../../types/reducers'
import Button from '../Button'
import TitlePage from '../TitlePage'
import { useSelector } from 'react-redux'
import TextArea from '../formComponents/TextArea'

interface Props {
    isVisible: boolean
    message: string
    detail?: string
    hideModal: () => void
}

const FinalMessageModal: React.FC<Props> = (props) => {
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [showDetail, setShowDetail] = useState(false)

    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md" onBackdropClick={() => props.hideModal()}>
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <TitlePage text={'Operazione completata'} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>{props.message}</p>
                {props.detail ? (
                    <div>
                        <p style={{ color: graphic.colorAccent }} onClick={() => setShowDetail(true)}>
                            Visualizza maggiori dettagli
                        </p>
                        {showDetail ? (
                            <TextArea
                                disabled
                                onChange={() => {
                                    return
                                }}
                                value={props.detail}
                            />
                        ) : null}
                    </div>
                ) : null}
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Chiudi"
                    onClick={() => {
                        props.hideModal()
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default FinalMessageModal
