import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'
import SubtitleSection from '../SubtitleSection'
// WL MODIFICATO 26/01/2023
import { BLUE_DEFAULT, EMAIL_ADMIN_PRODUCT, EMAIL_INFO_PRODUCT, EMAIL_CUSTOMER_PRODUCT,NAME_PRODUCT } from '../../resources/constants'


interface Props {
    isVisible: boolean
    hideModal: () => void
}

const ContactsModal: React.FC<Props> = (props) => {
    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="lg" onBackdropClick={() => props.hideModal()}>
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <TitlePage text="Contatti" />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                        <SubtitleSection text='Richiedi le credenziali' />
                        <a href="mailto:info.genera@biemmefinestre.it " style={{ color: BLUE_DEFAULT }}>
                            {EMAIL_ADMIN_PRODUCT}
                        </a>
                    </div>
                    <div>
                        <SubtitleSection text= "Per informazioni su Genera" />
                        <a href="mailto:info.genera@biemmefinestre.it " style={{ color: BLUE_DEFAULT }}>
                            {EMAIL_INFO_PRODUCT}
                        </a>
                    </div>
                    <div>
                        <SubtitleSection text="Per segnalazioni errori" />
                        <a href="mailto:segnalazionerrori.genera@biemmefinestre.it " style={{ color: BLUE_DEFAULT }}>
                            {EMAIL_CUSTOMER_PRODUCT}
                        </a>
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Chiudi"
                    buttonColor="#52565A"
                    onClick={() => {
                        props.hideModal()
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default ContactsModal
