import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import React from 'react'
import Button from '../Button'
import TitlePage from '../TitlePage'

interface Props {
    isVisible: boolean
    onClick: (v: 0 | 1) => void
}

const UniformSizeModal: React.FC<Props> = (props) => {
    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md">
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <TitlePage text={'Uniformare le misure?'} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>
                    Se uniformi, tutte le misure inserite verranno adattate a quella più piccola nel range dei 5mm. Vuoi uniformare le misure?
                </p>
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Non uniformare"
                    buttonColor="#52565A"
                    onClick={() => {
                        props.onClick(0)
                    }}
                />
                <Button
                    isSmall
                    text="Uniforma"
                    onClick={() => {
                        props.onClick(1)
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default UniformSizeModal
