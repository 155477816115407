import React, { useEffect, useMemo } from 'react'
import SubtitleSection from '../../../components/SubtitleSection'
import Button from '../../../components/Button'
import { faReceipt, faSearch, faTag, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { ReducerData, Reducers, ReducerUser } from '../../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { orderClientiPerName } from '../../../utilities/functions'
import { setError, setIsLoading } from '../../../store/actions/graphic'
import { deleteCliente } from '../../../resources/requests/requests'
import { setCustomersRedux } from '../../../store/actions/thunk_actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Cliente } from '../../../types/data'
import MaterialTable from 'material-table'
import { locale_itIT } from '../../../utilities/tableLocale'
import EditCustomerModal from '../../../components/modals/EditCustomerModal'
import OffersListModal from '../../../components/modals/OffersListModal'
import OrdersListModal from '../../../components/modals/OrdersListModal'
import DeleteModal from '../../../components/modals/DeleteModal'
import Input from '../../../components/formComponents/Input'
import CheckboxInput from '../../../components/formComponents/CheckboxInput'

interface CustomerForTable extends Cliente {
    nameAgent: string
}

const AnagraficaClientiScreen: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [filterSearch, setFilterSearch] = useState('')
    const [filterType, setFilterType] = useState<'PRIVATO' | 'AZIENDA' | ''>('')
    const [filterDirectContacts, setFilterDirectContacts] = useState<boolean>(false)
    const [selectedCustomer, setSelectedCustomer] = useState<Cliente | null>(null)
    const [isVisibleEditModal, setIsVisibleEditModal] = useState(false)
    const [isVisibleOfferListModal, setIsVisibleOfferListModal] = useState(false)
    const [isVisibleOrderListModal, setIsVisibleOrderListModal] = useState(false)
    const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false)
    const dispatch = useDispatch()

    const renderDatas = useMemo(() => {
        const array = data.customers.map((c) => {
            const agentFound = data.agents.find((a) => a.CodAg == c.CodAg)
            return {
                ...c,
                nameAgent: agentFound ? agentFound.DenAg : ''
            }
        })
        const filteredArray = array.filter((i) => {
            if (filterSearch) {
                const exist = i.nominativo.toLowerCase().includes(filterSearch.toLowerCase()) || i.nameAgent.toLowerCase().includes(filterSearch.toLowerCase())
                if (!exist) {
                    return false
                }
            }
            if (filterType && i.tipo !== filterType) {
                return false
            }
            if (filterDirectContacts && i.ContattoDiretto !== 1) {
                return false
            }
            return true
        })
        return orderClientiPerName(filteredArray)
    }, [data.customers, filterSearch, filterType, filterDirectContacts])

    const renderActions = useMemo(() => {
        const actions = [
            () => ({
                icon: 'edit',
                iconProps: {
                    fontSize: 'medium'
                },
                tooltip: 'Modifica cliente',
                onClick: (event: any, rowData: Cliente) => {
                    setSelectedCustomer(rowData)
                    setIsVisibleEditModal(true)
                }
            }),
            () => ({
                // eslint-disable-next-line react/display-name
                icon: () => <FontAwesomeIcon icon={faTag} style={{ fontSize: 20, color: '#68696D' }} />,
                tooltip: 'Lista offerte',
                onClick: (event: any, rowData: any) => {
                    
                       // console.log('aaaa')
                    
                    setSelectedCustomer(rowData)
                    setIsVisibleOfferListModal(true)
                }
            }),
            () => ({
                // eslint-disable-next-line react/display-name
                icon: () => <FontAwesomeIcon icon={faReceipt} style={{ fontSize: 20, color: '#68696D' }} />,
                tooltip: 'Lista ordini',
                onClick: (event: any, rowData: any) => {
                    setSelectedCustomer(rowData)
                    setIsVisibleOrderListModal(true)
                }
            }),
            () => ({
                icon: 'delete',
                iconProps: {
                    color: 'error',
                    fontSize: 'medium'
                },
                tooltip: 'Elimina cliente',
                onClick: (event: any, rowData: any) => {
                    setIsVisibleDeleteModal(true)
                    setSelectedCustomer(rowData)
                }
            })
        ]

        return actions
    }, [])

    const columns = [
        {
            title: 'Nominativo',
            field: 'nominativo'
        },
        {
            title: 'Email',
            field: 'email'
        },
        {
            title: 'Telefono',
            field: 'cellulare'
        },
        {
            title: 'Agente',
            field: 'nameAgent'
        }
    ]

    const handleDelete = async () => {
        if (selectedCustomer) {
            dispatch(setIsLoading(true))
            try {
                await deleteCliente(selectedCustomer?.idContatto, dispatch)
                await dispatch(setCustomersRedux())
                setIsVisibleDeleteModal(false)
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    return (
        <div>
            <div className="introProductsPage">
                <SubtitleSection text="Lista clienti" />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', gap: 40, marginBottom: 20 }}>
                    <Input
                        isFilterInput
                        icon={faSearch}
                        value={filterSearch}
                        onChange={(e) => setFilterSearch(e)}
                        placeholder={'Ricerca cliente...'}
                        label={'Ricerca cliente'}
                    />
                    <CheckboxInput
                        label="Tipologia cliente"
                        values={[
                            {
                                label: 'Privato',
                                status: filterType == 'PRIVATO' ? true : false
                            },
                            {
                                label: 'Azienda',
                                status: filterType == 'AZIENDA' ? true : false
                            }
                        ]}
                        onClick={(v) => {
                            if (v.label == 'Privato') {
                                if (filterType == 'PRIVATO') {
                                    setFilterType('')
                                } else {
                                    setFilterType('PRIVATO')
                                }
                            } else {
                                if (filterType == 'AZIENDA') {
                                    setFilterType('')
                                } else {
                                    setFilterType('AZIENDA')
                                }
                            }
                        }}
                    />
                    {user.user?.TipoAccount == 'Amministratore' ? (
                        <CheckboxInput
                            label="Mostra contatti"
                            values={[
                                {
                                    label: 'Solo contatti diretti?',
                                    status: filterDirectContacts
                                }
                            ]}
                            onClick={() => {
                                setFilterDirectContacts(!filterDirectContacts)
                            }}
                        />
                    ) : null}

                    {user.user?.TipoAccount !== 'Agente' ? (
                        <Button
                            text="Aggiungi cliente"
                            hasIcon={true}
                            icon={faUserPlus}
                            onClick={() => {
                                setIsVisibleEditModal(true)
                                setSelectedCustomer(null)
                            }}
                        />
                    ) : null}
                </div>
            </div>
            <div>
                <MaterialTable
                    title=""
                    columns={columns}
                    data={renderDatas}
                    localization={locale_itIT}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        search: false,
                        headerStyle: {
                            backgroundColor: '#ebebeb',
                            borderRadius: 20
                        }
                    }}
                    //@ts-ignore
                    actions={renderActions}
                />
            </div>
            <EditCustomerModal customer={selectedCustomer} isVisible={isVisibleEditModal} hideModal={() => setIsVisibleEditModal(false)} />
            <OffersListModal
                
                offers={selectedCustomer ? data.offers.filter((o) => o.IdContattoTemporaneo == selectedCustomer.idContatto) : []}
                customer={selectedCustomer}
                isVisible={isVisibleOfferListModal}
                hideModal={() => setIsVisibleOfferListModal(false)}
            />
            <OrdersListModal
                orders={selectedCustomer ? data.orders.filter((o) => o.IdContattoTemporaneo == selectedCustomer.idContatto) : []}
                customer={selectedCustomer}
                isVisible={isVisibleOrderListModal}
                hideModal={() => setIsVisibleOrderListModal(false)}
            />
            <DeleteModal
                isVisible={isVisibleDeleteModal}
                hideModal={() => setIsVisibleDeleteModal(false)}
                onDelete={() => handleDelete()}
                title={'Eliminazione cliente'}
                text={
                    'ATTENZIONE! Se elimini questo cliente le offerte collegate ad esso non mostreranno i dati anagrafici. Vuoi davvero eliminare il cliente ' +
                    selectedCustomer?.nominativo +
                    '?'
                }
            />
        </div>
    )
}

export default AnagraficaClientiScreen
