import React, { useEffect, useMemo, useState } from 'react'
import TitlePage from '../../components/TitlePage'
import { ReducerData, Reducers } from '../../types/reducers'
import { useSelector } from 'react-redux'
import OffersListItem, { OfferStatus } from '../../components/OffersListItem'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faChevronLeft, faChevronRight, faFilter, faSearch } from '@fortawesome/free-solid-svg-icons'
import { getOrders, headersJson } from '../../resources/requests/requests'
import moment from 'moment'
import { OfferArchive } from '../../types/data'
import CustomAxios from '../../customCompontents/CustomAxios'
import { useDispatch } from 'react-redux'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { usePrevious } from '../../utilities/functions'
import Button from '../../components/Button'
import Input from '../../components/formComponents/Input'
import { BASE_URL } from '../../resources/constants'
import SelectInput, { Option } from '../../components/formComponents/SelectInput'

const StatusFilterOptions = [
    {
        id: OfferStatus.InCorso,
        value: OfferStatus.InCorso,
        label: OfferStatus.InCorso
    },
    {
        id: OfferStatus.InUso,
        value: OfferStatus.InUso,
        label: OfferStatus.InUso
    },
    {
        id: OfferStatus.ConfermatoCliente,
        value: OfferStatus.ConfermatoCliente,
        label: OfferStatus.ConfermatoCliente
    },
    {
        id: OfferStatus.RicevutoBiemme,
        value: OfferStatus.RicevutoBiemme,
        label: OfferStatus.RicevutoBiemme
    },
    {
        id: OfferStatus.Confermato,
        value: OfferStatus.Confermato,
        label: OfferStatus.Confermato
    },
    {
        id: OfferStatus.Rifiutato,
        value: OfferStatus.Rifiutato,
        label: OfferStatus.Rifiutato
    }
]

const OffersList: React.FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const startDate = moment().subtract(2, 'weeks').format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')
    const [filterSearch, setFilterSearch] = useState('')
    const [filteredStartDate, setFilteredStartDate] = useState<string>(startDate)
    const [filteredEndDate, setFilteredEndDate] = useState<string>(endDate)
    const [statusFilter, setStatusFilter] = useState('')
    const [allOffers, setAllOffers] = useState<OfferArchive[]>([])
    const [renderedOffers, setRenderedOffers] = useState<OfferArchive[]>([])
    const dispatch = useDispatch()
    const pageDimension = 10
    const [currentPage, setCurrentPage] = useState(1)
    const previousSearch = usePrevious(filterSearch)
    const previousStart = usePrevious(filteredStartDate)
    const previousEnd = usePrevious(filteredEndDate)

    const renderStatusFilterValue = () => {
        if (statusFilter) {
            const statusFind = StatusFilterOptions.find((s) => s.id == statusFilter)
            if (statusFind) {
                return statusFind
            }
        }
        return null
    }

    const renderList = () => {
        return renderedOffers.map((o, i) => {
            return (
                <OffersListItem
                    key={o.ID}
                    item={o}
                    index={i}
                    onDelete={() => {
                        fetchOffers()
                    }}
                />
            )
        })
    }

    useEffect(() => {
        fetchOffers()
    }, [])

    useEffect(() => {
        const newArray = allOffers.slice((currentPage - 1) * pageDimension, pageDimension * currentPage)
        setRenderedOffers(newArray)
    }, [allOffers, currentPage])

    const fetchOffers = async () => {
        dispatch(setIsLoading(true))
        try {
            const body = {
                filter: {
                    DataStart: moment(filteredStartDate).format('YYYY-MM-DD'),
                    DataEnd: moment(filteredEndDate).format('YYYY-MM-DD'),
                    Query: filterSearch.length > 3 ? filterSearch : '',
                    Stato: statusFilter ? statusFilter : ''
                }
            }
            const offers = await CustomAxios.post(BASE_URL + 'bmapi/Contratto/ElencoPreventivi', body, headersJson).then((data) => {
                if (data.data.Data) {
                    return data.data.Data
                } else {
                    return []
                }
            })
            setAllOffers(offers)
            //setRenderedOffers(offers.slice(0, pageDimension))
            setCurrentPage(1)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <div>
            <TitlePage text="Offerte" />
            <div style={{ display: 'flex', gap: 20, flexDirection: 'row', marginBottom: 20 }}>
                <div style={{ display: 'flex', gap: 20, flexDirection: 'row' }}>
                    <Input
                        value={filterSearch}
                        onChange={(e) => setFilterSearch(e)}
                        placeholder="Ricerca ordine..."
                        label="Ricerca ordine..."
                        icon={faSearch}
                        labelStyle={{ fontWeight: 400, color: '#68696d' }}
                        isFilterInput
                    />
                    <Input
                        value={filteredStartDate}
                        type="date"
                        onChange={(e) => setFilteredStartDate(e)}
                        placeholder="Data inizio periodo riferimento..."
                        label="Data inizio periodo riferimento"
                        style={{ padding: '12.5px 20px 12.5px 40px' }}
                        icon={faCalendar}
                        labelStyle={{ fontWeight: 400, color: '#68696d' }}
                        isFilterInput
                    />
                    <Input
                        value={filteredEndDate}
                        type="date"
                        onChange={(e) => setFilteredEndDate(e)}
                        placeholder="Data fine periodo riferimento..."
                        label="Data fine periodo riferimento"
                        style={{ padding: '12.5px 20px 12.5px 40px' }}
                        icon={faCalendar}
                        labelStyle={{ fontWeight: 400, color: '#68696d' }}
                        isFilterInput
                    />
                    <SelectInput
                        options={StatusFilterOptions}
                        value={renderStatusFilterValue()}
                        onChange={(v) => {
                            const val = v as Option
                            setStatusFilter(val.id as string)
                        }}
                        label="Stato dell'offerta"
                        labelStyle={{ fontWeight: 400, color: '#68696d' }}
                        placeholder="Stato ordine"
                    />
                    <div style={{ marginTop: 28 }}>
                        <Button text="Filtra" hasIcon icon={faFilter} onClick={() => fetchOffers()} />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                <p style={{ fontWeight: 400, color: '#68696d', fontSize: 18, fontStyle: 'italic', flex: 1 }}>
                    Stai visualizzando: {allOffers.length} offerte - Pagina {currentPage} di {Math.ceil(allOffers.length / pageDimension)}
                </p>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 10 }}>
                    <Button
                        text=""
                        onClick={() => {
                            if (currentPage > 1) {
                                setCurrentPage(currentPage - 1)
                            }
                        }}
                        opacity={currentPage > 1 ? 1 : 0.5}
                        hasIcon
                        isSmall
                        icon={faChevronLeft}
                    />
                    Pagina {currentPage}
                    <Button
                        text=""
                        onClick={() => {
                            if (currentPage !== Math.ceil(allOffers.length / pageDimension)) {
                                setCurrentPage(currentPage + 1)
                            }
                        }}
                        opacity={currentPage !== Math.ceil(allOffers.length / pageDimension) ? 1 : 0.5}
                        hasIcon
                        isSmall
                        icon={faChevronRight}
                    />
                </div>
            </div>

            <div style={{ maxHeight: '68vh', display: 'flex', flexDirection: 'column', gap: 20 }} className="scrollableDiv">
                {renderList()}
            </div>
        </div>
    )
}

export default OffersList
