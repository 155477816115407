import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { CSSProperties } from 'react'

interface Props {
    value: string | number
    onChange: (e: string) => void
    label?: string
    icon?: IconProp
    placeholder?: string
    type?: 'date' | 'email' | 'text' | 'tel' | 'number' | 'password'
    style?: CSSProperties
    labelStyle?: CSSProperties
    divStyle?: CSSProperties
    disabled?: boolean
    required?: boolean
    isFilterInput?: boolean
    min?: number
    max?: number
}

const Input: React.FC<Props> = (props) => {
    return (
        <div style={{ ...props.divStyle }}>
            {props.label ? <label style={{ ...props.labelStyle }}>{props.label}</label> : null}
            {props.icon ? (
                <div className={props.isFilterInput ? 'filterInput' : ''}>
                    <FontAwesomeIcon icon={props.icon} className="icon" />
                    <input
                        disabled={props?.disabled}
                        style={{ ...props.style }}
                        type={props.type ? props.type : 'text'}
                        value={props.value}
                        placeholder={props.placeholder ? props.placeholder : ''}
                        onChange={(e) => {
                            props.onChange(e.target.value)
                        }}
                        required={props.required}
                        min={props.min}
                        max={props.max}
                    />
                </div>
            ) : (
                <input
                    disabled={props?.disabled}
                    style={{ ...props.style }}
                    type={props.type ? props.type : 'text'}
                    value={props.value}
                    placeholder={props.placeholder ? props.placeholder : ''}
                    onChange={(e) => {
                        props.onChange(e.target.value)
                    }}
                    required={props.required}
                    min={props.min}
                    max={props.max}
                />
            )}
        </div>
    )
}

export default Input
