import React from 'react'
import PendingOfferModal from '../components/modals/PendingOfferModal'
import Sidebar from '../components/sidebar/Sidebar'
import Header from '../components/Header'
import Footer from '../components/Footer'
import HomePage from './HomePage'
import ProductsPage from './ProductsPage'
import SettingsPage from './Settings/SettingsPage'
import CurrentOffer from './Offers/CurrentOffer'
import OffersList from './Offers/OffersList'
import OfferDetail from './Offers/OfferDetail'
import OrdersList from './Orders/OrdersList'
import OrderDetail from './Orders/OrderDetail'
import NewQuotePage from './NewQuote/NewQuotePage'
import { Route, useLocation } from 'react-router-dom'
import PrintPage from './PrintPage'
import Loader from '../components/Loader'
import { ReducerGraphic, Reducers, ReducerUser } from '../types/reducers'
import { useSelector } from 'react-redux'
import ErrorModal from '../components/modals/ErrorModal'
import MessageToReadModal from '../components/modals/MessageToReadModal'
import MessagesPage from './Others/MessagesPage'
import StatsPage from './Stats/StatsPage'
import Documents from './Others/Documents'
import DocumentsXML from './Others/DocumentsXML'
import DocumentsCommesse from './Others/DocumentsCommesse'
import DocumentsJSON from './Others/DocumentsJSON'


const LoggedRoute: React.FC = () => {
    const location = useLocation()
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)

    if (location.pathname == '/print') {
        return <PrintPage />
    }

    return (
        <div className="container">
            <Loader isVisible={graphic.isLoading} />
            <ErrorModal />
            <PendingOfferModal />
            <MessageToReadModal />
            <Sidebar />
            <Header />
            <div className="pageContainer">
                <Route exact path="/" component={HomePage} />
                <Route exact path="/products" component={ProductsPage} />
                <Route exact path="/settings" component={SettingsPage} />
                <Route exact path="/stats" component={StatsPage} />
                <Route exact path="/messages" component={MessagesPage} />
                <Route exact path="/quote" component={NewQuotePage} />
                <Route exact path="/current-offer" component={CurrentOffer} />
                <Route exact path="/offers" component={OffersList} />
                <Route exact path="/offers/detail" component={OfferDetail} />
                <Route exact path="/orders" component={OrdersList} />
                <Route exact path="/orders/detail" component={OrderDetail} />
                <Route exact path="/docs" component={Documents} />
                <Route exact path="/docs-xml" component={DocumentsXML} />
                <Route exact path="/docs-commesse" component={DocumentsCommesse} />
                <Route exact path="/docs-json" component={DocumentsJSON} />
            </div>
            <Footer />
        </div>
    )
}

export default LoggedRoute
