import { faChartArea } from '@fortawesome/free-solid-svg-icons'
import React, { forwardRef, useImperativeHandle, useMemo, useState } from 'react'
import { ReducerData, ReducerGraphic, Reducers } from '../types/reducers'
import Button from './Button'
import SelectInput, { Option } from './formComponents/SelectInput'
import { useSelector, useDispatch } from 'react-redux'
import { FilterStats, getStatsAndamento, getStatsFatturatoAziende } from '../resources/requests/stats'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import { setError, setIsLoading } from '../store/actions/graphic'
import { MONTHS } from '../resources/constants'
import ConfirmModal from './modals/ConfirmModal'

interface Props {
    filterBody: FilterStats
    differentData: boolean
}

interface FatturatoObject {
    CodAZ: string
    mese: string
    Fatturato: string
}

const AziendaStatChart: any = forwardRef((props: Props, ref: any) => {
    const [results, setResults] = useState<FatturatoObject[]>([])
    const [nothingFound, setNothingFound] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const dispatch = useDispatch()

    useImperativeHandle(ref, () => ({
        resetData: () => {
            setResults([])
        }
    }))

    const fetchStats = async () => {
        dispatch(setIsLoading(true))
        try {
            const andamento = await getStatsFatturatoAziende({ ...props.filterBody }, dispatch)
            if (!andamento) {
                setNothingFound(true)
            } else {
                setNothingFound(false)
            }
            setResults(andamento ? andamento : [])
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    console.log(results)

    const chartOption = useMemo(() => {
        if (results && results.length > 0) {
            const arrayData: any[] = []
            results.forEach((p) => {
                const aziendaFound = data.companies.find((c) => c.CodAZ == p.CodAZ)
                if (aziendaFound) {
                    const arrToPush = [aziendaFound.RagioneSociale, parseFloat(p.Fatturato)]
                    arrayData.push(arrToPush)
                }
            })
            return {
                type: 'column',
                title: {
                    text: ''
                },
                series: [
                    {
                        name: 'Fatturato',
                        color: graphic.colorAccent,
                        type: 'column',
                        data: arrayData
                    }
                ],
                xAxis: {
                    type: 'category',
                    title: {
                        text: 'Aziende'
                    },
                    min: 0,
                    scrollbar: {
                        enabled: true
                    },
                    labels: {
                        rotation: -45
                    },
                    tickLength: 0
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Fatturato'
                    }
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            enabled: true
                        }
                    }
                },
                legend: {
                    enabled: false
                },
                credits: {
                    enabled: false
                },
                tooltip: {
                    valueSuffix: '€'
                }
            }
        } else {
            return null
        }
    }, [results])

    return (
        <div
            style={{
                backgroundColor: '#ebebeb',
                padding: 20,
                borderRadius: 15,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                margin: '25px 0'
            }}
        >
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1, width: '100%', alignItems: 'center' }}>
                <p style={{ color: '#68696d', fontSize: 22, flex: 1 }}>Fatturati aziende</p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, alignItems: 'center', gap: 20 }}>
                    <Button
                        text="Calcola fatturati aziende"
                        hasIcon
                        icon={faChartArea}
                        onClick={() => {
                            setShowAlert(props.differentData)
                            fetchStats()
                        }}
                    />
                </div>
            </div>
            {results.length > 0 ? (
                <div style={{ minWidth: '100%', margin: '20px 0' }}>
                    <HighchartsReact constructorType={'chart'} highcharts={Highcharts} options={chartOption} />
                </div>
            ) : (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1, margin: 30, width: '100%' }}>
                    {nothingFound ? (
                        <p style={{ fontSize: 28, color: graphic.colorAccent, textAlign: 'center', margin: 10 }}>
                            Non ho trovato nessun risultato con questi criteri.
                        </p>
                    ) : null}
                    <p style={{ textAlign: 'center', fontSize: 24, color: '#68696d', fontStyle: 'italic', opacity: 0.6 }}>
                        Imposta il range e premi sul pulsante {'"'}Calcola fatturati aziende{'"'} per consultare il grafico
                    </p>
                </div>
            )}
            <ConfirmModal
                isVisible={showAlert}
                title="Scostamento dati"
                text="Attenzione! L'andamento che andrai a visualizzare sarà differente da quanto riportato sopra in quanto sono cambiati i parametri di analisi. In ogni momento potrai allineare i dati cliccando sul pulsante Risultato"
                isOnlyConfirm
                onConfirm={() => {
                    setShowAlert(false)
                }}
                hideModal={() => {
                    setShowAlert(false)
                }}
            />
        </div>
    )
})
AziendaStatChart.displayName = 'AziendaStatChart'
export default AziendaStatChart
