import axios from 'axios'
import { BASE_URL } from '../resources/constants'

const CustomAxios = axios.create({
    baseURL: BASE_URL,
    //baseURL: 'http://34.252.73.234:3001/',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
})

export const NewCustomAxios = axios.create({
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    }
})

export const toCamelCase: any = (object: any) => {
    let transformedObject = object
    if (typeof object === 'object' && object !== null) {
        if (object instanceof Array) {
            transformedObject = object.map(toCamelCase)
        } else {
            transformedObject = {}
            for (const key in object) {
                // @ts-ignore
                if (object[key] !== undefined) {
                    const newKey = key.replace(/(_\w)|(-\w)/g, (k) => k[1].toUpperCase())
                    transformedObject[newKey] = toCamelCase(object[key])
                }
            }
        }
    }
    return transformedObject
}

// export const toSnackCase: any = (object: any) => {
//     let transformedObject = object
//     if (typeof object === 'object' && object !== null) {
//         if (object instanceof Array) {
//             transformedObject = object.map(toSnackCase)
//         } else {
//             transformedObject = {}
//             for (const key in object) {
//                 // @ts-ignore
//                 if (object[key] !== undefined) {
//                     const newKey = key
//                         .replace(/\.?([A-Z]+)/g, function (x, y) {
//                             return '_' + y.toLowerCase()
//                         })
//                         .replace(/^_/, '')
//                     transformedObject[newKey] = toSnackCase(object[key])
//                 }
//             }
//         }
//     }
//     return transformedObject
// }

CustomAxios.interceptors.response.use(
    (response) => {
        response.data = toCamelCase(response.data)
        return response
    },
    (error) => {
        // Do something with response error
        return Promise.reject(error)
    }
)

CustomAxios.interceptors.request.use(
    (config) => {
        let newObject = config.data
        if (config.headers['Content-Type'] === 'application/x-www-form-urlencoded') {
            newObject = new URLSearchParams(newObject).toString()
        }
        config.data = newObject
        return config
    },
    (error) => {
        // Do something with response error
        return Promise.reject(error)
    }
)

export default CustomAxios
