import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogContent } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { editItemPreventivo, getSingleItemQuote } from '../../resources/requests/requests'
import { DettaglioItemInQuote, ItemInQuote, ItemToEdit } from '../../types/order'
import { ReducerOrder, Reducers } from '../../types/reducers'
import Button from '../Button'
import TitlePage from '../TitlePage'
import { useSelector, useDispatch } from 'react-redux'
import { setError, setIsLoading } from '../../store/actions/graphic'

interface Props {
    isVisible: boolean
    item: ItemInQuote
    hideModal: () => void
    updatedItem: (item: DettaglioItemInQuote) => void
}

const NotesModal: React.FC<Props> = (props) => {
    const [currentItem, setCurrentItem] = useState(props.item)
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.isVisible) {
            setCurrentItem(props.item)
        }
    }, [props.isVisible])

    const handleSave = async () => {
        dispatch(setIsLoading(true))
        try {
            if (currentItem.DettaglioItem.Annotazioniutente !== props.item.DettaglioItem.Annotazioniutente) {
                const body: ItemToEdit = {
                    idRecord: currentItem.idRecord,
                    CodicePreventivo: order.newQuoteFromUser?.CodicePreventivo,
                    CodiceSerie: currentItem.DettaglioItem.CodiceSerie,
                    CodTipoItem: currentItem.DettaglioItem.CodTipoItem,
                    Htip: currentItem.DettaglioItem.Htip,
                    Ltip: currentItem.DettaglioItem.Ltip,
                    Quantita: currentItem.DettaglioItem.Quantita,
                    Etichetta: currentItem.DettaglioItem.Etichetta,
                    Ordinamento: currentItem.DettaglioItem.Ordinamento,
                    HManiglia: currentItem.DettaglioItem.HManiglia ? currentItem.DettaglioItem.HManiglia : null,
                    HTraverso1: currentItem.DettaglioItem?.HTraverso1 ? currentItem.DettaglioItem.HTraverso1 : null,
                    HTraverso2: currentItem.DettaglioItem?.HTraverso2 ? currentItem.DettaglioItem.HTraverso2 : null,
                    HTraverso3: currentItem.DettaglioItem?.HTraverso3 ? currentItem.DettaglioItem.HTraverso3 : null,
                    HTraverso4: currentItem.DettaglioItem?.HTraverso4 ? currentItem.DettaglioItem.HTraverso4 : null,
                    PRM: currentItem.DettaglioItem.PRM ? currentItem.DettaglioItem.PRM.toString() : null,
                    Prezzo: currentItem.DettaglioItem.Prezzo.toString(),
                    Sconto: currentItem.DettaglioItem.Sconto.toString(),
                    Annotazioniutente: currentItem.DettaglioItem.Annotazioniutente
                }
                await editItemPreventivo(body, dispatch)
                const updatedItem = await getSingleItemQuote(currentItem.idRecord, order.newQuoteFromUser?.CodicePreventivo, dispatch)
                props.updatedItem(updatedItem)
                props.hideModal()
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={currentItem.DettaglioItem.DescrizioneInterneDB} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Aggiungi una nota</p>
                <textarea
                    style={{
                        width: 'calc(100% - 40px)',
                        borderRadius: 20,
                        border: '0px',
                        resize: 'none',
                        height: 180,
                        padding: 20,
                        fontSize: 16,
                        margin: '20px 0',
                        fontFamily: 'Raleway',
                        outline: 'none'
                    }}
                    placeholder="Nota..."
                    value={currentItem.DettaglioItem.Annotazioniutente}
                    onChange={(e) => {
                        setCurrentItem({
                            ...currentItem,
                            DettaglioItem: {
                                ...currentItem.DettaglioItem,
                                Annotazioniutente: e.target.value
                            }
                        })
                    }}
                />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: '100%' }}>
                    <Button
                        isSmall
                        text="Salva"
                        hasIcon={true}
                        icon={faEdit}
                        onClick={() => {
                            handleSave()
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default NotesModal
