import React, { useEffect } from 'react'
import { useState } from 'react'
import TitlePage from '../../components/TitlePage'
import DatiAziendaliScreen from './screens/DatiAziendaliScreen'
import ProfiloOperatoreScreen from './screens/ProfiloOperatoreScreen'
import StampaScreen from './screens/StampaScreen'
import AnagraficaClientiScreen from './screens/AnagraficaClientiScreen'
import GestioneAccountScreen from './screens/GestioneAccountScreen'
import GestioneAgentiScreen from './screens/GestioneAgentiScreen'
import CategorieSerieScreen from './screens/CategorieSerie/CategorieSerieScreen'
import AziendeScreen from './screens/AziendeScreen'
import ScontiScreen from './screens/ScontiScreen'
import { ReducerGraphic, Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import EmailConfigScreen from './screens/EmailConfigScreen'
import ListinoScreen from './screens/ListinoScreen'
import BackupScreen from './screens/BackupScreen'

enum SettingsSubMenu {
    DatiAziendali = 'Dati Aziendali',
    ProfiloOperatore = 'Profilo Operatore',
    Stampa = 'Stampa',
    CategorieSerie = 'Categorie & Serie',
    AnagraficaClienti = 'Anagrafica Clienti',
    Sconti = 'Sconti',
    Aziende = 'Aziende',
    GestioneAgenti = 'Gestione operatori',
    GestioneAccount = 'Gestione licenze',
    CasellaEmail = 'Configurazione email',
    Listino = 'Listino',
    Backup = 'Backup'
}

const settingsSubMenuVoices = [
    SettingsSubMenu.DatiAziendali,
    SettingsSubMenu.ProfiloOperatore,
    SettingsSubMenu.Stampa,
    SettingsSubMenu.CategorieSerie,
    SettingsSubMenu.AnagraficaClienti,
    SettingsSubMenu.Sconti,
    SettingsSubMenu.Aziende,
    SettingsSubMenu.GestioneAgenti,
    SettingsSubMenu.GestioneAccount,
    SettingsSubMenu.CasellaEmail,
    SettingsSubMenu.Listino,
    SettingsSubMenu.Backup
]

const settingsSubMenuVoicesSupervisore = [
    SettingsSubMenu.DatiAziendali,
    SettingsSubMenu.ProfiloOperatore,
    SettingsSubMenu.Stampa,
    SettingsSubMenu.AnagraficaClienti,
    SettingsSubMenu.Sconti,
    SettingsSubMenu.Aziende
]

const settingsSubMenuVoicesAgente = [
    SettingsSubMenu.DatiAziendali,
    SettingsSubMenu.ProfiloOperatore,
    SettingsSubMenu.AnagraficaClienti,
    SettingsSubMenu.Sconti,
    SettingsSubMenu.Aziende
]

const settingsSubMenuVoicesRivenditore = [
    SettingsSubMenu.DatiAziendali,
    SettingsSubMenu.ProfiloOperatore,
    SettingsSubMenu.Stampa,
    SettingsSubMenu.AnagraficaClienti,
    SettingsSubMenu.Sconti
]

const SettingsPage: React.FC = () => {
    const [selectedVoice, setSelectedVoice] = useState(SettingsSubMenu.DatiAziendali)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)

    const renderMenuVoices = () => {
        if (user.user?.TipoAccount == 'Rivenditore') {
            return settingsSubMenuVoicesRivenditore.map((m) => {
                return (
                    <span
                        key={m}
                        className="menuVoice"
                        style={{
                            borderBottomWidth: selectedVoice == m ? 3 : 0,
                            color: selectedVoice == m ? graphic.colorAccent : '#68696D'
                        }}
                        onClick={() => setSelectedVoice(m)}
                    >
                        {m}
                    </span>
                )
            })
        }
        if (user.user?.TipoAccount == 'Agente') {
            return settingsSubMenuVoicesAgente.map((m) => {
                return (
                    <span
                        key={m}
                        className="menuVoice"
                        style={{
                            borderBottomWidth: selectedVoice == m ? 3 : 0,
                            color: selectedVoice == m ? graphic.colorAccent : '#68696D'
                        }}
                        onClick={() => setSelectedVoice(m)}
                    >
                        {m}
                    </span>
                )
            })
        }
        if (user.user?.TipoAccount == 'Supervisore') {
            return settingsSubMenuVoicesSupervisore.map((m) => {
                return (
                    <span
                        key={m}
                        className="menuVoice"
                        style={{
                            borderBottomWidth: selectedVoice == m ? 3 : 0,
                            color: selectedVoice == m ? graphic.colorAccent : '#68696D'
                        }}
                        onClick={() => setSelectedVoice(m)}
                    >
                        {m}
                    </span>
                )
            })
        }
        return settingsSubMenuVoices.map((m, i) => {
            return (
                <span
                    key={m}
                    className="menuVoice"
                    style={{
                        borderBottomWidth: selectedVoice == m ? 3 : 0,
                        color: selectedVoice == m ? graphic.colorAccent : '#68696D'
                    }}
                    onClick={() => setSelectedVoice(m)}
                >
                    {m}
                </span>
            )
        })
    }

    const renderCurrentScreen = () => {
        switch (selectedVoice) {
            case SettingsSubMenu.DatiAziendali:
                return <DatiAziendaliScreen />
            case SettingsSubMenu.ProfiloOperatore:
                return <ProfiloOperatoreScreen />
            case SettingsSubMenu.Stampa:
                return <StampaScreen />
            case SettingsSubMenu.CategorieSerie:
                return <CategorieSerieScreen />
            case SettingsSubMenu.AnagraficaClienti:
                return <AnagraficaClientiScreen />
            case SettingsSubMenu.Sconti:
                return <ScontiScreen />
            case SettingsSubMenu.Aziende:
                return <AziendeScreen />
            case SettingsSubMenu.GestioneAgenti:
                return <GestioneAgentiScreen />
            case SettingsSubMenu.GestioneAccount:
                return <GestioneAccountScreen />
            case SettingsSubMenu.CasellaEmail:
                return <EmailConfigScreen />
            case SettingsSubMenu.Listino:
                return <ListinoScreen />
            case SettingsSubMenu.Backup:
                return <BackupScreen />
            default:
                return null
        }
    }

    return (
        <div>
            <TitlePage text="Impostazioni" />
            <div className="settingsSubMenu">{renderMenuVoices()}</div>
            {renderCurrentScreen()}
        </div>
    )
}

export default SettingsPage
