import React from 'react'
import { ReducerGraphic, Reducers } from '../types/reducers'
import { useSelector } from 'react-redux'

interface Props {
    title: string
    value: string
}

const StatCard: React.FC<Props> = (props) => {
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)

    return (
        <div
            style={{
                backgroundColor: '#ebebeb',
                padding: 20,
                borderRadius: 15,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <p style={{ color: '#68696d', fontSize: 22 }}>{props.title}</p>
            <p style={{ margin: 15, fontSize: 34, color: graphic.colorAccent, fontWeight: 700 }}>{props.value}</p>
        </div>
    )
}

export default StatCard
