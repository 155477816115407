import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ReducerData, ReducerGraphic, Reducers, ReducerUser } from '../types/reducers'
import { faSignOutAlt, faCog, faReceipt, faTag, faShoppingCart, faBars, faEnvelope, faChartArea } from '@fortawesome/free-solid-svg-icons'
import { NavLink, useLocation } from 'react-router-dom'
import { logoutUser } from '../store/actions/user'
import HeaderButton from './HeaderButton'
import { LogoutUser } from '../resources/requests/requests'
import { LOGO_DEFAULT } from '../resources/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Sidebar from './sidebar/Sidebar'
import moment from 'moment'

const Header: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [showMobileMenu, setShowMobileMenu] = useState(false)
    const dispatch = useDispatch()
    const location = useLocation()

    const renderLeftHeader = () => {
        switch (location.pathname) {
            case '/':
                return (
                    <div>
                        <p className="userCount">
                            Nome utente:{' '}
                            <b>
                                {user.user?.DenAg} - {user.company?.RagioneSociale}
                            </b>
                        </p>
                        <p className="userCount">Tipo account: {user.user?.TipoAccount}</p>
                        {(user.user?.TipoAccount == 'Amministratore' || user.user?.TipoAccount == 'Supervisore') && (
                            <p className="userCount">Utenti connessi al momento: {graphic.currentUserOnline}</p>
                        )}
                        <p className="userCount">
                            Listino: {graphic.listino?.DescListino} (Codice: {graphic.listino?.CodListino}) - aggiornato al{' '}
                            {moment(graphic.listino?.DataListino).format('DD - MM - YYYY')}
                        </p>
                        {data.messages.length > 0 ? (
                            <p className="userCount">
                                {' '}
                                Ultimo messaggio: <b>{data.messages[0].Titolo}</b>
                            </p>
                        ) : null}
                    </div>
                )
            case '/products':
                if (!graphic.categorySelected) {
                    const findAll = data.categories.find((c) => c.Nome.toLowerCase().includes('tutt'))
                    return <img src={findAll?.IconaSimbolo} className="imgIcon" />
                }
                return <img src={graphic.categorySelected?.IconaSimbolo} className="imgIcon" />
            case '/quote':
                return <img src={graphic.categorySelected?.IconaSimbolo} className="imgIcon" />
            default:
                return null
        }
    }

    const renderMenu = () => {
        return (
            <div className="overlayMenu" style={{ display: showMobileMenu ? 'block' : 'none' }}>
                <div className="overlay" onClick={() => setShowMobileMenu(false)} />
                <Sidebar onMobileClick={() => setShowMobileMenu(false)} />
            </div>
        )
    }

    return (
        <div className="header">
            <div className="logoMobile">
                {renderMenu()}
                <FontAwesomeIcon
                    icon={faBars}
                    style={{ fontSize: 30, color: graphic.colorAccent, cursor: 'pointer' }}
                    onClick={() => setShowMobileMenu(true)}
                />
                <NavLink
                    to={'/'}
                    //@ts-ignore
                    exact
                    className="main navigateLink"
                >
                    <img src={user.company && user.company?.Logo && user.company.Logo !== '' ? user.company.Logo : LOGO_DEFAULT} style={{ maxWidth: 130 }} />
                </NavLink>
            </div>
            <div className="introHeader">{renderLeftHeader()}</div>
            <div className="headerButtonContainer">
                <HeaderButton
                    icon={faShoppingCart}
                    slug={'/current-offer'}
                    name="Offerta corrente"
                    onClick={() => {
                        return
                    }}
                    isCurrentOffer
                />
                <HeaderButton
                    icon={faTag}
                    slug={'/offers'}
                    name="Offerte"
                    onClick={() => {
                        return
                    }}
                />
                <HeaderButton
                    icon={faReceipt}
                    slug={'/orders'}
                    name="Ordini"
                    onClick={() => {
                        return
                    }}
                />
                <HeaderButton
                    icon={faCog}
                    slug={'/settings'}
                    name="Impostazioni"
                    onClick={() => {
                        return
                    }}
                />
                <HeaderButton
                    slug={'/messages'}
                    icon={faEnvelope}
                    name="Bacheca"
                    isMessages
                    onClick={async () => {
                        return
                    }}
                />
                {user.user?.TipoAccount == 'Amministratore' ? (
                    <HeaderButton
                        slug={'/stats'}
                        icon={faChartArea}
                        name="Statistiche"
                        onClick={async () => {
                            return
                        }}
                    />
                ) : null}
                <HeaderButton
                    slug={'/'}
                    icon={faSignOutAlt}
                    name="Logout"
                    isLogout={true}
                    onClick={async () => {
                        await LogoutUser()
                        dispatch(logoutUser())
                    }}
                />
            </div>
        </div>
    )
}

export default Header
