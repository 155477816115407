import { faCheck, faCopy, faEdit, faInfoCircle, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useMemo, useState } from 'react'
import { ItemInQuote, ItemToEdit, VariantItem } from '../types/order'
import { convertSale, transformToCurrency } from '../utilities/functions'
import Button from './Button'
import VariantListItem from '../components/VariantListItem'
import SelectShapeModal from '../components/modals/SelectShapeModal'
import NotesModal from '../components/modals/NotesModal'
import SaleModal from '../components/modals/SaleModal'
import {
    calculateTotalPrices,
    deleteItemFromQuote,
    duplicateItemInQuote,
    editItemPreventivo,
    getAdditionalVariantsItem,
    getSingleItemQuote,
    recalculateItems,
    saveCurrentOffer
} from '../resources/requests/requests'
import { ReducerData, ReducerGraphic, ReducerOrder, Reducers } from '../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentOffer, setItemsInQuote, setPricesTotalOffer } from '../store/actions/order'
import { setOfferLoadedRedux } from '../store/actions/thunk_actions'
import { setError, setIsLoading } from '../store/actions/graphic'
import DeleteModal from './modals/DeleteModal'
import DuplicateModal from './modals/DuplicateModal'

interface Props {
    itemInQuote: ItemInQuote
    idRecord: string
    goPrevPhase: () => void
    isAutoRecalc?: boolean
}

enum VARIANT_TAB {
    DEFAULT,
    ADDITIONAL
}

const TypologyListElement: React.FC<Props> = (props) => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [currentItemInQuote, setCurrentItemInQuote] = useState(props.itemInQuote)
    const [additionalVariants, setAdditionalVariants] = useState<VariantItem[]>([])
    const [currentVariantTab, setCurrentVariantTab] = useState(VARIANT_TAB.DEFAULT)
    const [visibleModalGeometria, setVisibleModalGeometria] = useState(false)
    const [visibleModalNotes, setVisibleModalNotes] = useState(false)
    const [visibleModalSale, setVisibleModalSale] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false)
    const dispatch = useDispatch()

    const renderFirstColumn = () => {
        return (
            <div
                style={{
                    borderRight: '1px solid #D3D3D3',
                    paddingRight: 20,
                    flex: 2,
                    textAlign: 'center',
                    color: '#68696D',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}
            >
                <p style={{ fontSize: 16, fontWeight: 500 }}>N° Ord.: {currentItemInQuote.DettaglioItem.Ordinamento}</p>
                <h2 style={{ color: '#000', fontSize: 22, fontWeight: 900 }}>{currentItemInQuote.DettaglioItem.Etichetta}</h2>
                <p style={{ fontSize: 18 }}>{currentItemInQuote.DettaglioItem.DescrizioneInterneDB}</p>
                {currentItemInQuote.DettaglioItem.Trasmittanza && currentItemInQuote.DettaglioItem.Trasmittanza > 0 ? (
                    <div style={{ display: 'flex', flexDirection: 'column', fontSize: 14 }}>
                        <span>trasmittanza termica da normativa:</span>
                        <span style={{ fontWeight: 900, color: '#68696D' }}>{currentItemInQuote.DettaglioItem.Trasmittanza} W/m2K</span>
                    </div>
                ) : null}
                {currentItemInQuote.DettaglioItem.Peso && currentItemInQuote.DettaglioItem.Peso > 0 ? (
                    <p style={{ fontWeight: 900, color: '#68696D' }}>{currentItemInQuote.DettaglioItem.Peso} Kg</p>
                ) : null}
                <div style={{ margin: '20px 0', position: 'relative' }}>
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        onClick={() => setVisibleModalGeometria(true)}
                        style={{
                            top: -10,
                            right: 0,
                            position: 'absolute',
                            fontSize: 24,
                            color: graphic.colorAccent,
                            cursor: 'pointer',
                            backgroundColor: '#fff',
                            borderRadius: '50%'
                        }}
                    />
                    <img
                        src={currentItemInQuote.DettaglioItem.FiguraRiferimento}
                        style={{ maxWidth: '100%', borderRadius: 10, height: '25vh', width: '25vh', backgroundColor: '#fff', objectFit: 'contain', padding: 5 }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 20, justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span style={{ color: '#000', fontSize: 14 }}>L (mm.)</span>
                        <span>{currentItemInQuote.DettaglioItem.Ltip}</span>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <span style={{ color: '#000', fontSize: 14 }}>H (mm.)</span>
                        <span>{currentItemInQuote.DettaglioItem.Htip}</span>
                    </div>
                    {currentItemInQuote.DettaglioItem.HManiglia && (
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <span style={{ color: '#000', fontSize: 14 }}>Hman.</span>
                            <span>{currentItemInQuote.DettaglioItem.HManiglia}</span>
                        </div>
                    )}
                </div>
            </div>
        )
    }

    const renderSecondColumn = () => {
        return (
            <div style={{ padding: '0 20px', flex: 6 }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 20, textTransform: 'uppercase', color: '#68696D' }}>
                    <span
                        onClick={() => setCurrentVariantTab(VARIANT_TAB.DEFAULT)}
                        style={{
                            cursor: 'pointer',
                            color: currentVariantTab == VARIANT_TAB.DEFAULT ? graphic.colorAccent : '#68696D',
                            borderBottom: currentVariantTab == VARIANT_TAB.DEFAULT ? `2px solid ${graphic.colorAccent}` : 'none',
                            paddingBottom: 5
                        }}
                    >
                        Varianti applicate
                    </span>
                    <span
                        onClick={() => setCurrentVariantTab(VARIANT_TAB.ADDITIONAL)}
                        style={{
                            cursor: 'pointer',
                            color: currentVariantTab == VARIANT_TAB.ADDITIONAL ? graphic.colorAccent : '#68696D',
                            borderBottom: currentVariantTab == VARIANT_TAB.ADDITIONAL ? `2px solid ${graphic.colorAccent}` : 'none',
                            paddingBottom: 5
                        }}
                    >
                        Varianti aggiuntive
                    </span>
                </div>
                <div
                    className="variantList"
                    style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 10, padding: '10px 5px 10px 0', overflowY: 'scroll', maxHeight: '45vh' }}
                >
                    {renderVariantsList()}
                </div>
            </div>
        )
    }

    const fetchAdditionalVariants = async () => {
        const variants = await getAdditionalVariantsItem(parseInt(props.idRecord), dispatch)
        setAdditionalVariants(variants)
    }

    const groupedAdditionalVariants = useMemo(() => {
        const groups: {
            id: string
            variants: VariantItem[]
        }[] = []
        additionalVariants
            .filter((variant) => variant.NascondiInDoc !== '1')
            .forEach((v) => {
                const foundIndex = groups.findIndex((g) => g.id == v.IdCatVar)
                if (foundIndex !== -1) {
                    groups[foundIndex].variants.push(v)
                } else {
                    groups.push({
                        id: v.IdCatVar,
                        variants: [v]
                    })
                }
            })
        return groups
    }, [additionalVariants])

    useEffect(() => {
        if (props.isAutoRecalc) {
            handleRecalculateItem()
        }
    }, [currentItemInQuote])

    useEffect(() => {
        if (currentVariantTab == VARIANT_TAB.ADDITIONAL) {
            fetchAdditionalVariants()
        }
    }, [currentVariantTab])

    useEffect(() => {
        setCurrentItemInQuote(props.itemInQuote)
    }, [props.itemInQuote])

    const renderAdditionalVariants = () => {
        if (additionalVariants.length == 0) {
            return <p>Non sono presenti varianti aggiuntive</p>
        }
        return groupedAdditionalVariants.map((v, i) => {
            return (
                <VariantListItem
                    updatedItem={(item) => {
                        setCurrentItemInQuote({
                            ...currentItemInQuote,
                            DettaglioItem: item
                        })
                        setCurrentVariantTab(VARIANT_TAB.DEFAULT)
                    }}
                    key={i}
                    variant={v.variants[0]}
                    variants={v.variants}
                    isAdditional
                    idRecord={currentItemInQuote.idRecord}
                    clickEX={() => setVisibleModalGeometria(true)}
                />
            )
        })
    }

    const renderVariantsList = () => {
        switch (currentVariantTab) {
            case VARIANT_TAB.DEFAULT:
                return currentItemInQuote.DettaglioItem.elencoVarianti
                    .filter((variant) => variant.NascondiInDoc !== '1')
                    .map((v, i) => {
                        return (
                            <VariantListItem
                                updatedItem={(item) =>
                                    setCurrentItemInQuote({
                                        ...currentItemInQuote,
                                        DettaglioItem: item
                                    })
                                }
                                key={v.CodVar + i}
                                variant={v}
                                idRecord={currentItemInQuote.idRecord}
                                clickEX={() => setVisibleModalGeometria(true)}
                            />
                        )
                    })
            case VARIANT_TAB.ADDITIONAL:
                return renderAdditionalVariants()
            default:
                return null
        }
    }

    const handleUpdateQuantity = async () => {
        dispatch(setIsLoading(true))
        try {
            console.log(currentItemInQuote)
            const body: ItemToEdit = {
                idRecord: currentItemInQuote.idRecord,
                CodicePreventivo: order.newQuoteFromUser?.CodicePreventivo,
                CodiceSerie: currentItemInQuote.DettaglioItem.CodiceSerie,
                CodTipoItem: currentItemInQuote.DettaglioItem.CodTipoItem,
                Htip: currentItemInQuote.DettaglioItem.Htip,
                Ltip: currentItemInQuote.DettaglioItem.Ltip,
                Quantita: currentItemInQuote.DettaglioItem.Quantita,
                Etichetta: currentItemInQuote.DettaglioItem.Etichetta,
                Ordinamento: currentItemInQuote.DettaglioItem.Ordinamento,
                HManiglia: currentItemInQuote.DettaglioItem.HManiglia ? currentItemInQuote.DettaglioItem.HManiglia : null,
                HTraverso1: currentItemInQuote.DettaglioItem.HTraverso1 ? currentItemInQuote.DettaglioItem.HTraverso1 : null,
                HTraverso2: currentItemInQuote.DettaglioItem.HTraverso2 ? currentItemInQuote.DettaglioItem.HTraverso2 : null,
                HTraverso3: currentItemInQuote.DettaglioItem.HTraverso3 ? currentItemInQuote.DettaglioItem.HTraverso3 : null,
                HTraverso4: currentItemInQuote.DettaglioItem.HTraverso4 ? currentItemInQuote.DettaglioItem.HTraverso4 : null,
                PRM: currentItemInQuote.DettaglioItem.PRM ? currentItemInQuote.DettaglioItem.PRM.toString() : null,
                Prezzo: currentItemInQuote.DettaglioItem.Prezzo.toString(),
                Sconto: currentItemInQuote.DettaglioItem.Sconto.toString(),
                Annotazioniutente: currentItemInQuote.DettaglioItem.Annotazioniutente
            }
            await editItemPreventivo(body, dispatch)
            const updatedItem = await getSingleItemQuote(currentItemInQuote.idRecord, order.newQuoteFromUser?.CodicePreventivo, dispatch)
            dispatch(
                setItemsInQuote(
                    order.itemsInQuote.map((item) => {
                        if (item.idRecord == currentItemInQuote.idRecord) {
                            return currentItemInQuote
                        } else {
                            return item
                        }
                    })
                )
            )
            const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
            const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
            dispatch(setPricesTotalOffer(prices))
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    const handleRecalculateItem = async () => {
        if (currentItemInQuote.DettaglioItem.Modificato) {
            dispatch(setIsLoading(true))
            try {
                await recalculateItems(
                    order.newQuoteFromUser?.CodicePreventivo,
                    order.newQuoteFromUser?.CodiceOfferta,
                    [{ idItem: currentItemInQuote.idRecord }],
                    dispatch
                )
                const updatedItem = await getSingleItemQuote(currentItemInQuote.idRecord, order.newQuoteFromUser?.CodicePreventivo, dispatch)
                dispatch(
                    setItemsInQuote(
                        order.itemsInQuote.map((item) => {
                            if (item.idRecord == currentItemInQuote.idRecord) {
                                return {
                                    ...item,
                                    DettaglioItem: updatedItem
                                }
                            } else {
                                return item
                            }
                        })
                    )
                )
                const foundTax = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)?.Codice
                const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, foundTax ? foundTax : data.taxes[0].Codice, dispatch)
                dispatch(setPricesTotalOffer(prices))
                if (order.currentOffer) {
                    const offer = await saveCurrentOffer(order.newQuoteFromUser?.CodiceOfferta, dispatch)
                    if (offer) {
                        dispatch(setCurrentOffer(offer))
                    }
                    // dispatch(setOfferLoadedRedux(order.newQuoteFromUser?.CodiceOfferta, true))
                }
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    const renderThirdColumn = () => {
        return (
            <div
                style={{
                    borderLeft: '1px solid #D3D3D3',
                    paddingLeft: 20,
                    flex: 1,
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: '#68696D'
                }}
            >
                <div style={{ paddingBottom: 20, borderBottom: '1px solid #D3D3D3', display: 'flex', flexDirection: 'column' }}>
                    <span>Prezzo</span>
                    <span style={{ color: '#000', fontWeight: 900, fontSize: 22 }}>
                        {transformToCurrency(currentItemInQuote.DettaglioItem.Prezzo * currentItemInQuote.DettaglioItem.Quantita)}
                    </span>
                    <span style={{ fontSize: 12, margin: '15px 0' }}>
                        Sconto applicato
                        <br />
                        {currentItemInQuote.DettaglioItem.Sconto
                            ? convertSale(currentItemInQuote.DettaglioItem.Sconto.toFixed(2).toString())[1] !== '0'
                                ? `${convertSale(currentItemInQuote.DettaglioItem.Sconto.toFixed(2).toString())[0]}% + ${
                                      convertSale(currentItemInQuote.DettaglioItem.Sconto.toFixed(2).toString())[1]
                                  }%`
                                : currentItemInQuote.DettaglioItem.Sconto.toFixed(2) + '%'
                            : '0%'}
                    </span>
                    <span style={{ fontSize: 12 }}>
                        {transformToCurrency(currentItemInQuote.DettaglioItem.Prezzo)} x {currentItemInQuote.DettaglioItem.Quantita}
                    </span>
                    <span style={{ fontSize: 12, padding: '5px 0' }}>IVA esclusa</span>
                </div>
                <div
                    style={{
                        paddingBottom: 20,
                        borderBottom: '1px solid #D3D3D3',
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        padding: '20px 0',
                        alignItems: 'flex-end',
                        justifyContent: 'center',
                        gap: 20
                    }}
                >
                    <div className="quantityBox" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <span>Quantità</span>
                        <input
                            min={0}
                            value={currentItemInQuote.DettaglioItem.Quantita}
                            onChange={(e) => {
                                setCurrentItemInQuote({
                                    ...currentItemInQuote,
                                    DettaglioItem: {
                                        ...currentItemInQuote.DettaglioItem,
                                        Quantita: parseInt(e.target.value)
                                    }
                                })
                            }}
                            defaultValue={0}
                            type="number"
                        />
                    </div>
                    {currentItemInQuote.DettaglioItem.Quantita !== props.itemInQuote.DettaglioItem.Quantita && (
                        <FontAwesomeIcon
                            icon={faCheck}
                            onClick={() => {
                                handleUpdateQuantity()
                            }}
                            style={{ backgroundColor: graphic.colorAccent, fontSize: 15, color: '#fff', cursor: 'pointer', padding: 8, borderRadius: 5 }}
                        />
                    )}
                </div>
                <div style={{ paddingBottom: 20, display: 'flex', flexDirection: 'column', width: '100%', padding: '20px 0', gap: 10 }}>
                    {/* <Button
                        isSmall
                        text="Ricalcola"
                        hasIcon={true}
                        icon={faSyncAlt}
                        onClick={() => {
                            handleRecalculateItem()
                        }}
                        opacity={currentItemInQuote.DettaglioItem.Modificato ? 1 : 0.5}
                    /> */}
                    {/*<Button
                        isSmall
                        text="Modifica prezzo"
                        onClick={() => {
                            setVisibleModalSale(true)
                        }}
                    />*/}
                    <Button
                        isSmall
                        text="Nota"
                        hasIcon={true}
                        icon={faEdit}
                        onClick={() => {
                            setVisibleModalNotes(true)
                        }}
                    />
                    <Button
                        isSmall
                        text="Duplica"
                        hasIcon={true}
                        buttonColor="#52565A"
                        icon={faCopy}
                        onClick={() => {
                            setIsDuplicateModalVisible(true)
                        }}
                    />
                    <Button
                        isSmall
                        text="Elimina"
                        hasIcon={true}
                        buttonColor="#FF0000"
                        icon={faTrash}
                        onClick={() => {
                            setIsDeleteModalVisible(true)
                        }}
                    />
                </div>
            </div>
        )
    }

    const handleAddItemToPreventivo = async () => {
        dispatch(setIsLoading(true))
        try {
            if (order.newQuoteFromUser) {
                const item: any = await duplicateItemInQuote(order.newQuoteFromUser?.CodiceOfferta, currentItemInQuote.idRecord, dispatch)
                const newItem: ItemInQuote = {
                    idRecord: item.idItem.toString,
                    Message: '',
                    DettaglioItem: item
                }
                dispatch(setItemsInQuote([...order.itemsInQuote, newItem]))
                const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
                const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
                dispatch(setPricesTotalOffer(prices))
                if (order.currentOffer) {
                    const offer = await saveCurrentOffer(order.newQuoteFromUser?.CodiceOfferta, dispatch)
                    if (offer) {
                        dispatch(setCurrentOffer(offer))
                    }
                    // dispatch(setOfferLoadedRedux(order.newQuoteFromUser?.CodiceOfferta, true))
                }
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handleDeleteItemToPreventivo = async () => {
        dispatch(setIsLoading(true))
        try {
            await deleteItemFromQuote(currentItemInQuote.idRecord, dispatch)
            const filteredArray = order.itemsInQuote.filter((i) => i.idRecord !== currentItemInQuote.idRecord)
            const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
            const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
            dispatch(setPricesTotalOffer(prices))
            if (filteredArray.length > 0) {
                dispatch(setItemsInQuote(filteredArray))
            } else {
                dispatch(setItemsInQuote([]))
                props.goPrevPhase()
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    return (
        <div className="cardCustomTypology">
            <SelectShapeModal
                updatedItem={(item) => {
                    setCurrentItemInQuote({
                        ...currentItemInQuote,
                        DettaglioItem: item
                    })
                }}
                isVisible={visibleModalGeometria}
                hideModal={() => setVisibleModalGeometria(false)}
                itemInQuote={currentItemInQuote}
            />
            <NotesModal
                isVisible={visibleModalNotes}
                item={currentItemInQuote}
                hideModal={() => setVisibleModalNotes(false)}
                updatedItem={(item) => {
                    setCurrentItemInQuote({
                        ...currentItemInQuote,
                        DettaglioItem: item
                    })
                }}
            />
            <SaleModal
                isVisible={visibleModalSale}
                item={currentItemInQuote}
                hideModal={() => setVisibleModalSale(false)}
                updatedItem={(item) => {
                    setCurrentItemInQuote({
                        ...currentItemInQuote,
                        DettaglioItem: item
                    })
                }}
            />
            <DeleteModal
                isVisible={isDeleteModalVisible}
                hideModal={() => setIsDeleteModalVisible(false)}
                onDelete={() => {
                    handleDeleteItemToPreventivo()
                    setIsDeleteModalVisible(false)
                }}
                text={"Cancella l'elemento (ID " + currentItemInQuote.idRecord + ')'}
                title={'Elimina elemento'}
            />
            <DuplicateModal
                isVisible={isDuplicateModalVisible}
                hideModal={() => setIsDuplicateModalVisible(false)}
                onDuplicate={() => {
                    handleAddItemToPreventivo()
                    setIsDuplicateModalVisible(false)
                }}
                text={"Duplica l'elemento (ID " + currentItemInQuote.idRecord + ')'}
                title={'Duplicazione elemento'}
            />
            {renderFirstColumn()}
            {renderSecondColumn()}
            {renderThirdColumn()}
        </div>
    )
}

export default TypologyListElement
