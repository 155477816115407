import { USER_ACTIONS } from '../actions/user'
import { ReducerUser, ReduxActionData } from './../../types/reducers'

const defaultValue: ReducerUser = {
    user: null,
    token: '',
    idOfferPending: '',
    lastProduct: null,
    lastOfferSeen: null,
    printFile: null,
    company: null
}

const userState: (state: ReducerUser, action: ReduxActionData<any>) => ReducerUser = (state = defaultValue, action: ReduxActionData<any>) => {
    switch (action.type) {
        case USER_ACTIONS.SET_USER:
            return {
                ...state,
                user: action.payload
            }
        case USER_ACTIONS.SET_TOKEN:
            return {
                ...state,
                token: action.payload
            }
        case USER_ACTIONS.SET_ID_OFFER_PENDING:
            return {
                ...state,
                idOfferPending: action.payload
            }
        case USER_ACTIONS.SET_LAST_PRODUCT:
            return {
                ...state,
                lastProduct: action.payload
            }
        case USER_ACTIONS.SET_LAST_OFFER_SEEN:
            return {
                ...state,
                lastOfferSeen: action.payload
            }
        case USER_ACTIONS.SET_PRINT_FILE:
            return {
                ...state,
                printFile: action.payload
            }
        case USER_ACTIONS.SET_COMPANY:
            return {
                ...state,
                company: action.payload
            }
        case USER_ACTIONS.LOGOUT_USER:
            return defaultValue
        default:
            return state
    }
}

export default userState
