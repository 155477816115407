import React, { useEffect, useMemo, useState } from 'react'
import { ComposedItem, ItemInQuote, ItemToEdit, VariantItem } from '../../types/order'
import { getAdditionalVariantsItem } from '../../resources/requests/requests'
import VariantListItem from '../VariantListItem'
import { useDispatch, useSelector } from 'react-redux'
import { setItemsInQuote } from '../../store/actions/order'
import { ReducerGraphic, ReducerOrder, Reducers } from '../../types/reducers'
import Button from '../Button'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import OtherInfoWindowItem from './OtherInfoWindowModal'

interface Props {
    variants: VariantItem[]
    idRecord: string
}

enum VARIANT_TAB {
    DEFAULT,
    ADDITIONAL
}

const VariantListComposed: React.FC<Props> = (props) => {
    const [currentVariantTab, setCurrentVariantTab] = useState(VARIANT_TAB.DEFAULT)
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [additionalVariants, setAdditionalVariants] = useState<VariantItem[]>([])
    const [showOtherInfoModal, setShowOtherInfoModal] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (currentVariantTab == VARIANT_TAB.ADDITIONAL) {
            fetchAdditionalVariants()
        }
    }, [currentVariantTab])

    const renderVariantsList = () => {
        switch (currentVariantTab) {
            case VARIANT_TAB.DEFAULT:
                return props.variants
                    .filter((variant) => variant.NascondiInDoc !== '1')
                    .map((v, i) => {
                        return (
                            <VariantListItem
                                updatedItem={(item) => {
                                    dispatch(
                                        setItemsInQuote(
                                            order.itemsInQuote.map((iq) => {
                                                const findItem = iq.idRecord == props.idRecord
                                                if (findItem) {
                                                    return {
                                                        ...iq,
                                                        DettaglioItem: item
                                                    }
                                                } else {
                                                    return iq
                                                }
                                            })
                                        )
                                    )
                                }}
                                key={v.CodVar + i}
                                variant={v}
                                idRecord={props.idRecord}
                            />
                        )
                    })
            case VARIANT_TAB.ADDITIONAL:
                return renderAdditionalVariants()
            default:
                return null
        }
    }

    const renderAdditionalVariants = () => {
        if (additionalVariants.length == 0) {
            return <p>Non sono presenti varianti aggiuntive</p>
        }
        return groupedAdditionalVariants.map((v, i) => {
            return (
                <VariantListItem
                    updatedItem={(item) => {
                        dispatch(
                            setItemsInQuote(
                                order.itemsInQuote.map((iq) => {
                                    const findItem = iq.idRecord == props.idRecord
                                    if (findItem) {
                                        return {
                                            ...iq,
                                            DettaglioItem: item
                                        }
                                    } else {
                                        return iq
                                    }
                                })
                            )
                        )
                        setCurrentVariantTab(VARIANT_TAB.DEFAULT)
                    }}
                    key={i}
                    variant={v.variants[0]}
                    variants={v.variants}
                    isAdditional
                    idRecord={props.idRecord}
                />
            )
        })
    }

    const fetchAdditionalVariants = async () => {
        const variants = await getAdditionalVariantsItem(parseInt(props.idRecord), dispatch)
        setAdditionalVariants(variants)
    }

    const groupedAdditionalVariants = useMemo(() => {
        const groups: {
            id: string
            variants: VariantItem[]
        }[] = []
        additionalVariants
            .filter((variant) => variant.NascondiInDoc !== '1')
            .forEach((v) => {
                const foundIndex = groups.findIndex((g) => g.id == v.IdCatVar)
                if (foundIndex !== -1) {
                    groups[foundIndex].variants.push(v)
                } else {
                    groups.push({
                        id: v.IdCatVar,
                        variants: [v]
                    })
                }
            })
        return groups
    }, [additionalVariants])

    if (props.variants.length > 0) {
        return (
            <div>
                <div style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between', marginBottom: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 20, textTransform: 'uppercase', color: '#68696D' }}>
                        <span
                            onClick={() => setCurrentVariantTab(VARIANT_TAB.DEFAULT)}
                            style={{
                                cursor: 'pointer',
                                color: currentVariantTab == VARIANT_TAB.DEFAULT ? graphic.colorAccent : '#68696D',
                                borderBottom: currentVariantTab == VARIANT_TAB.DEFAULT ? `2px solid ${graphic.colorAccent}` : 'none',
                                paddingBottom: 5
                            }}
                        >
                            Varianti applicate
                        </span>
                        <span
                            onClick={() => setCurrentVariantTab(VARIANT_TAB.ADDITIONAL)}
                            style={{
                                cursor: 'pointer',
                                color: currentVariantTab == VARIANT_TAB.ADDITIONAL ? graphic.colorAccent : '#68696D',
                                borderBottom: currentVariantTab == VARIANT_TAB.ADDITIONAL ? `2px solid ${graphic.colorAccent}` : 'none',
                                paddingBottom: 5
                            }}
                        >
                            Varianti aggiuntive
                        </span>
                    </div>
                    <div>
                        <Button
                            text="Altre info"
                            onClick={() => {
                                setShowOtherInfoModal(true)
                            }}
                            isSmall
                            hasIcon
                            icon={faInfoCircle}
                        />
                    </div>
                </div>

                <div
                    className="variantList"
                    style={{ display: 'flex', flex: 1, flexDirection: 'column', gap: 10, padding: '10px 5px 10px 0', overflowY: 'scroll', maxHeight: '34vh' }}
                >
                    {renderVariantsList()}
                </div>
                <OtherInfoWindowItem
                    isVisible={showOtherInfoModal}
                    hideModal={() => setShowOtherInfoModal(false)}
                    itemInQuote={order.itemsInQuote.find((i) => i.idRecord == props.idRecord)}
                />
            </div>
        )
    }

    return null
}

export default VariantListComposed
