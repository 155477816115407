import React from 'react'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { LOGO_DEFAULT, LOGO_PRODUCT } from '../../resources/constants'
import { resetPasswordRequest } from '../../store/actions/thunk_actions' // WL
const ForgotPasswordPage: React.FC = () => {
    const [username, setUsername] = useState("")
    const [email, setEmail] = useState("")
    const [sended, setEmailSend] = useState(false)
    const dispatch = useDispatch() 
    
    const resetHandle = () => {
       
        // invia email  link  per il reset password
         if (username !== '' && email !== '') {
             try {
                 dispatch(resetPasswordRequest(username, email))
                 setEmailSend (true) 
             } catch (error) {
                 console.log(error)
             }
         }       
        return
    }

    return (
        <div>
            <div className="loginContainer">
                <img src={LOGO_DEFAULT} />
                <h2 className="titleLogin">Richiedi password </h2>
                <p className="paragraphLogin">Inserisci i tuoi dati per ricevere una mail di reset password</p>

                <form
                    className="form"
                    onSubmit={(event) => {
                        event.preventDefault()
                        resetHandle()
                    }}
                >
                    <div className="form-group">
                        <input type="string" onChange={(event) => setUsername(event.target.value)} placeholder="Username" />
                    </div>
                    <div className="form-group">
                        <input type="email" onChange={(event) => setEmail(event.target.value)} placeholder="Email" />
                    </div>
                    <div className="actionsForm">
                        <button type="submit" className="submitLogin">
                            Invia
                        </button>
                        <NavLink
                            to="/"
                            //@ts-ignore
                            exact
                            className="loginLink"
                            activeClassName="active"
                        >
                            Indietro
                        </NavLink>
                    </div>
                    <div>
                        {sended === true && (
                            <p className="paragraphLogin" style={{ textAlign: 'center', color: '#0000FF' }}>Le informazioni di reset password sono state inviate alla tua email segui le istruzioni, per effettuare il reset</p>
                        )}

                    </div>
                    <img src={LOGO_PRODUCT} className="logoBG" />
                    {/* <p
                        style={{
                            padding: 18,
                            fontSize: 20,
                            color: '#fff',
                            textAlign: 'center',
                            opacity: requestState == LoginStatus.FAILED ? 1 : 0
                        }}
                    >
                        Login fallito, riprova
                    </p> */}
                </form>
            </div>
        </div>
    )
}

export default ForgotPasswordPage