import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { ReducerUser, Reducers } from '../types/reducers'
import { useSelector } from 'react-redux'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface Props {
    onClick: () => void
    tooltip: string
    color: 'green' | 'red' | 'yellow'
    icon: IconProp
}

const StatusIconButton: React.FC<Props> = (props) => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)

    const renderColor = () => {
        switch (props.color) {
            case 'green':
                return '#00FF58'
            case 'red':
                return '#E00000'
            case 'yellow':
                return '#EAE100'
            default:
                return '#EAE100'
        }
    }

    return (
        <div
            onClick={() => {
                if (user.user?.TipoAccount == 'Amministratore' || user.user?.TipoAccount == 'Supervisore') {
                    props.onClick()
                }
            }}
            className="tooltip"
            style={{
                width: 50,
                height: 50,
                backgroundColor: renderColor(),
                borderRadius: '50%',
                cursor: user.user?.TipoAccount == 'Amministratore' || user.user?.TipoAccount == 'Supervisore' ? 'pointer' : 'inherit'
            }}
        >
            <FontAwesomeIcon icon={props.icon} style={{ color: '#fff', fontSize: 25 }} />
            <span className="tooltiptext">{props.tooltip}</span>
        </div>
    )
}

export default StatusIconButton
