import React, { useEffect, useState } from 'react'
import TitlePage from '../../components/TitlePage'
import { ReducerData, Reducers } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { faCalendar, faChevronLeft, faChevronRight, faFilter, faSearch } from '@fortawesome/free-solid-svg-icons'
import OrdersListItem, { OrderStatus } from '../../components/OrdersListItem'
import { OrderArchive } from '../../types/data'
import moment from 'moment'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { useDispatch } from 'react-redux'
import CustomAxios from '../../customCompontents/CustomAxios'
import { headersJson } from '../../resources/requests/requests'
import Button from '../../components/Button'
import Input from '../../components/formComponents/Input'
import { BASE_URL } from '../../resources/constants'
import SelectInput, { Option } from '../../components/formComponents/SelectInput'

const StatusFilterOptions = [
    {
        id: OrderStatus.InCorso,
        value: OrderStatus.InCorso,
        label: OrderStatus.InCorso
    },
    {
        id: OrderStatus.InUso,
        value: OrderStatus.InUso,
        label: OrderStatus.InUso
    },
    {
        id: OrderStatus.ConfermatoCliente,
        value: OrderStatus.ConfermatoCliente,
        label: OrderStatus.ConfermatoCliente
    },
    {
        id: OrderStatus.RicevutoBiemme,
        value: OrderStatus.RicevutoBiemme,
        label: OrderStatus.RicevutoBiemme
    },
    {
        id: OrderStatus.Confermato,
        value: OrderStatus.Confermato,
        label: OrderStatus.Confermato
    },
    {
        id: OrderStatus.Rifiutato,
        value: OrderStatus.Rifiutato,
        label: OrderStatus.Rifiutato
    }
]

const OrdersList: React.FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const startDate = moment().subtract(1, 'months').format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')
    const [filterSearch, setFilterSearch] = useState('')
    const [filteredStartDate, setFilteredStartDate] = useState<string>(startDate)
    const [filteredEndDate, setFilteredEndDate] = useState<string>(endDate)
    const [statusFilter, setStatusFilter] = useState('')
    const [allOrders, setAllOrders] = useState<OrderArchive[]>([])
    const [renderedOrders, setRenderedOrders] = useState<OrderArchive[]>([])
    const dispatch = useDispatch()
    const pageDimension = 10
    const [currentPage, setCurrentPage] = useState(1)

    const renderList = () => {
        return renderedOrders.map((o) => {
            return <OrdersListItem key={o.ID} item={o} onDelete={() => fetchOrders()} updateOrders={() => fetchOrders()} />
        })
    }

    const renderStatusFilterValue = () => {
        if (statusFilter) {
            const statusFind = StatusFilterOptions.find((s) => s.id == statusFilter)
            if (statusFind) {
                return statusFind
            }
        }
        return null
    }

    useEffect(() => {
        fetchOrders()
    }, [])

    useEffect(() => {
        const newArray = allOrders.slice((currentPage - 1) * pageDimension, pageDimension * currentPage)
        setRenderedOrders(newArray)
    }, [allOrders, currentPage])

    const fetchOrders = async () => {
        dispatch(setIsLoading(true))
        try {
            const body = {
                filter: {
                    DataStart: filteredStartDate,
                    DataEnd: filteredEndDate,
                    Query: filterSearch.length > 3 ? filterSearch : '',
                    Stato: statusFilter ? statusFilter : ''
                }
            }
            const orders = await CustomAxios.post(BASE_URL + 'bmapi/Contratto/ElencoOrdini', body, headersJson).then((data) => {
                if (data.data.Data) {
                    return data.data.Data
                } else {
                    return []
                }
            })
            setAllOrders(orders)
            setCurrentPage(1)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <div>
            <TitlePage text="Ordini" />
            <div style={{ display: 'flex', gap: 20, flexDirection: 'row', marginBottom: 20 }}>
                <Input
                    value={filterSearch}
                    onChange={(e) => setFilterSearch(e)}
                    placeholder="Ricerca ordine..."
                    label="Ricerca ordine..."
                    icon={faSearch}
                    labelStyle={{ fontWeight: 400, color: '#68696d' }}
                    isFilterInput
                />
                <Input
                    value={filteredStartDate}
                    type="date"
                    onChange={(e) => setFilteredStartDate(e)}
                    placeholder="Data inizio periodo riferimento..."
                    label="Data inizio periodo riferimento"
                    style={{ padding: '12.5px 20px 12.5px 40px' }}
                    icon={faCalendar}
                    labelStyle={{ fontWeight: 400, color: '#68696d' }}
                    isFilterInput
                />
                <Input
                    value={filteredEndDate}
                    type="date"
                    onChange={(e) => setFilteredEndDate(e)}
                    placeholder="Data fine periodo riferimento..."
                    label="Data fine periodo riferimento"
                    style={{ padding: '12.5px 20px 12.5px 40px' }}
                    icon={faCalendar}
                    labelStyle={{ fontWeight: 400, color: '#68696d' }}
                    isFilterInput
                />
                <SelectInput
                    options={StatusFilterOptions}
                    value={renderStatusFilterValue()}
                    onChange={(v) => {
                        const val = v as Option
                        setStatusFilter(val.id as string)
                    }}
                    label="Stato dell'ordine"
                    labelStyle={{ fontWeight: 400, color: '#68696d' }}
                    placeholder="Stato ordine"
                />
                <div style={{ marginTop: 28 }}>
                    <Button text="Filtra" hasIcon icon={faFilter} onClick={() => fetchOrders()} />
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginBottom: 20 }}>
                <p style={{ fontWeight: 400, color: '#68696d', fontSize: 18, fontStyle: 'italic', flex: 1 }}>
                    Stai visualizzando: {allOrders.length} ordini - Pagina {currentPage} di {Math.ceil(allOrders.length / pageDimension)}
                </p>
                <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', gap: 10 }}>
                    <Button
                        text=""
                        onClick={() => {
                            if (currentPage > 1) {
                                setCurrentPage(currentPage - 1)
                            }
                        }}
                        opacity={currentPage > 1 ? 1 : 0.5}
                        hasIcon
                        isSmall
                        icon={faChevronLeft}
                    />
                    Pagina {currentPage}
                    <Button
                        text=""
                        onClick={() => {
                            if (currentPage !== Math.ceil(allOrders.length / pageDimension)) {
                                setCurrentPage(currentPage + 1)
                            }
                        }}
                        opacity={currentPage !== Math.ceil(allOrders.length / pageDimension) ? 1 : 0.5}
                        hasIcon
                        isSmall
                        icon={faChevronRight}
                    />
                </div>
            </div>
            <div className="scrollableDiv" style={{ maxHeight: '68vh', display: 'flex', flexDirection: 'column', gap: 20 }}>
                {renderList()}
            </div>
        </div>
    )
}

export default OrdersList
