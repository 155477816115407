import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { AddItemsToPreventivoBody, Typology } from '../../types/data'
import { Dialog, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'
import {
    ComposedDividerGraphic,
    ComposedItem,
    ComposedItemItem,
    ComposedItemStructure,
    ComposedItemStructureGraphic,
    DefaultDataTypology,
    ItemInQuote,
    ItemToEdit
} from '../../types/order'
import AddDivisorItem, { Direction } from './AddDivisorItem'
import { ALPHABET } from '../../resources/constants'
import {
    addItemToPreventivo,
    calculateTotalPrices,
    createDefaultVariants,
    deleteItemFromQuote,
    editItemPreventivo,
    getComposedImage,
    getComposedItem,
    saveCurrentOffer,
    setComposedItem
} from '../../resources/requests/requests'
import { useDispatch, useSelector } from 'react-redux'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { ReducerData, ReducerOrder, Reducers } from '../../types/reducers'
import { setCurrentOffer, setItemsInQuote, setPricesTotalOffer } from '../../store/actions/order'
import { setOfferLoadedRedux } from '../../store/actions/thunk_actions'
import SelectComposedTypologyModal from './SelectComposedTypologyModal'
import DivisorItemRow from './DivisorItemRow'
import Loader from '../Loader'

interface Props {
    isVisible: boolean
    composedItem: ComposedItem | null
    itemInQuote: ItemInQuote
    hideModal: () => void
    updateItem: (i: ComposedItem) => void
}

const SelectVariantModal: React.FC<Props> = (props) => {
    const currentItemInQuote = props.itemInQuote
    const [currentItem, setCurrentItem] = useState(props.composedItem)
    const [gridStructureGraphic, setGridStructureGraphic] = useState<ComposedItemStructureGraphic>()
    const [arrayParents, setArrayParents] = useState<ComposedItemStructureGraphic[]>([])
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [hasChanged, setHasChanged] = useState(false)
    const [orderNumber, setOrderNumber] = useState(parseInt(props.itemInQuote.DettaglioItem.Ordinamento))
    const [isUpdated, setIsUpdated] = useState(false)
    const [imgGrid, setImgGrid] = useState('')
    const [selectedParent, setSelectedParent] = useState<ComposedItemStructureGraphic | null>(null)
    const [showSelectTypologyModal, setShowSelectTypologyModal] = useState(false)
    const [removing, setRemoving] = useState<ComposedItemStructureGraphic[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const counter = useRef<number>(0)

    useEffect(() => {
        if (props.isVisible === false) {
            resetModalData()
        } else {
            setCurrentItem(props.composedItem)
            if (props.composedItem !== null) {
                readStructure(props.composedItem)
            }
        }
    }, [props.isVisible])

    const resetModalData = useCallback(() => {
        setGridStructureGraphic(undefined)
        setArrayParents([])
        counter.current = 0
        setCurrentItem(null)
    }, [])

    const readStructure = ({ Struttura }: ComposedItemStructure) => {
        if (Struttura === null || Struttura.length <= 0) {
            return
        }
        counter.current = counter.current + 1
        for (let i = 0; i < Struttura.length; i++) {
            readStructure(Struttura[i])
        }
    }

    const convertInnerStructure = useCallback((structure: ComposedItemStructure): ComposedItemStructureGraphic => {
        return {
            height: structure.BOX_H,
            width: structure.BOX_L,
            offset: {
                width: structure.OFFSET_L,
                height: structure.OFFSET_H
            },
            idItem: structure.idItem,
            idName: structure.Etichetta,
            children: structure.Struttura
                ? structure.Struttura.map((s) => {
                      return convertInnerStructure(s)
                  })
                : []
        }
    }, [])

    useEffect(() => {
        if (currentItem) {
            setGridStructureGraphic(convertInnerStructure({ ...currentItem, Etichetta: 'A0' }))
            getGridImage()
        }
    }, [currentItem])

    const getGridImage = useCallback(async () => {
        const img = await getComposedImage(currentItem, dispatch)
        setImgGrid(img)
    }, [currentItem])

    const checkEmptyParents = useCallback((structure: ComposedItemStructureGraphic): ComposedItemStructureGraphic[] => {
        const filteredElements = []
        if (structure.children.length !== 0) {
            for (let i = 0; i < structure.children.length; i++) {
                filteredElements.push(...checkEmptyParents(structure.children[i]))
            }
        } else {
            filteredElements.push(structure)
        }
        return filteredElements
    }, [])

    const checkFilledParents = useCallback((structure: ComposedItemStructureGraphic): ComposedItemStructureGraphic[] => {
        const filteredElements = []
        if (structure.children.length > 0) {
            filteredElements.push(structure)
            for (let i = 0; i < structure.children.length; i++) {
                filteredElements.push(...checkFilledParents(structure.children[i]))
            }
        }
        return filteredElements
    }, [])

    const checkAndRemoveDivisor = useCallback((structure: ComposedItemStructureGraphic, target: ComposedItemStructureGraphic): ComposedItemStructureGraphic => {
        const clonedStructure: ComposedItemStructureGraphic = JSON.parse(JSON.stringify(structure))
        let children = clonedStructure.children

        if (clonedStructure.idName === target.idName) {
            counter.current = counter.current - 1
            children = []
        } else {
            children = clonedStructure.children.map((c) => checkAndRemoveDivisor(c, target))
        }

        return {
            ...clonedStructure,
            children
        }
    }, [])

    const removeItemQuoteDivisor = async () => {
        if (removing && removing.length > 0 && currentItem) {
            setIsLoading(true)
            try {
                const itemsInQuoteCopy = JSON.parse(JSON.stringify(order.itemsInQuote))
                for (let i = 0; i < removing.length; i++) {
                    const foundedInQuote = order.itemsInQuote.findIndex((iq) => iq.DettaglioItem.Etichetta == removing[i].idName)
                    if (foundedInQuote !== -1) {
                        await deleteItemFromQuote(order.itemsInQuote[foundedInQuote].idRecord, dispatch)
                        itemsInQuoteCopy.splice(foundedInQuote, 1)
                    }
                }
                dispatch(setItemsInQuote(itemsInQuoteCopy))
                await setComposedItem(currentItem, dispatch)
                const offer = await saveCurrentOffer(order.newQuoteFromUser?.CodiceOfferta, dispatch)
                if (offer) {
                    dispatch(setCurrentOffer(offer))
                }
                // await dispatch(setOfferLoadedRedux(order.newQuoteFromUser?.CodiceOfferta, true))
                const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
                const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
                dispatch(setPricesTotalOffer(prices))
                const item = await getComposedItem(parseInt(currentItemInQuote.idRecord), dispatch)
                props.updateItem(item)
                setRemoving([])
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (removing.length > 0) {
            removeItemQuoteDivisor()
        }
    }, [removing])

    const getStructurePerItem = (
        structure: ComposedItemStructureGraphic,
        itemToGet: ComposedItemStructureGraphic
    ): ComposedItemStructureGraphic | undefined => {
        if (structure.idName == itemToGet.idName) {
            return structure
        }
        const foundChild = structure.children.find((c) => getStructurePerItem(c, itemToGet) !== undefined)
        return foundChild
    }

    useEffect(() => {
        if (gridStructureGraphic) {
            const emptyParents = checkEmptyParents(gridStructureGraphic)
            setArrayParents(emptyParents)
            const arrayItemsEmpty = emptyParents.filter((p) => getStructurePerItem(gridStructureGraphic, p)!.idItem !== null)
            if (isUpdated) {
                if (currentItem) {
                    if (gridStructureGraphic && gridStructureGraphic.children && gridStructureGraphic.children.length > 0) {
                        setCurrentItem({
                            ...currentItem,
                            Struttura: updateStructureAPI(gridStructureGraphic.children),
                            Items: currentItem.Items.filter((i) => arrayItemsEmpty.find((e) => e.idName == i.Etichetta) !== undefined)
                        })
                    } else {
                        setCurrentItem({
                            ...currentItem,
                            Struttura: []
                        })
                    }
                }
                setIsUpdated(false)
            }
        }
    }, [gridStructureGraphic])

    const updateStructure = (
        structure: ComposedItemStructureGraphic,
        dividers: ComposedDividerGraphic[],
        parent: ComposedItemStructureGraphic,
        direction: Direction
    ): ComposedItemStructureGraphic => {
        const clonedStructure: ComposedItemStructureGraphic = JSON.parse(JSON.stringify(structure))
        let children = clonedStructure.children

        if (clonedStructure.idName === parent.idName) {
            counter.current = counter.current + 1

            children = Array.from(
                {
                    length: dividers.length + 1
                },
                (_, i) => {
                    // If the first element
                    if (i === 0) {
                        const divider = dividers[i]
                        return {
                            height: direction === Direction.Vertical ? clonedStructure.height : divider.offset,
                            width: direction === Direction.Horizontal ? clonedStructure.width : divider.offset,
                            offset: {
                                width: clonedStructure.offset.width,
                                height: clonedStructure.offset.height
                            },
                            idName: `${ALPHABET[counter.current]}${i}`,
                            children: [],
                            idItem: null
                        }
                    }

                    // If the last element
                    if (i === dividers.length) {
                        const divider = dividers[i - 1]

                        return {
                            height: direction === Direction.Vertical ? clonedStructure.height : clonedStructure.height - divider.offset,
                            width: direction === Direction.Horizontal ? clonedStructure.width : clonedStructure.width - divider.offset,
                            offset: {
                                width: direction === Direction.Vertical ? divider.offset + clonedStructure.offset.width : clonedStructure.offset.width,
                                height: direction === Direction.Horizontal ? divider.offset + clonedStructure.offset.height : clonedStructure.offset.height
                            },
                            idName: `${ALPHABET[counter.current]}${i}`,
                            children: [],
                            idItem: null
                        }
                    }

                    const lastDivider = dividers[i - 1]
                    const nextDivider = dividers[i]
                    return {
                        height: direction === Direction.Vertical ? clonedStructure.height : nextDivider.offset - lastDivider.offset,
                        width: direction === Direction.Horizontal ? clonedStructure.width : nextDivider.offset - lastDivider.offset,
                        offset: {
                            width: direction === Direction.Vertical ? lastDivider.offset + clonedStructure.offset.width : clonedStructure.offset.width,
                            height: direction === Direction.Horizontal ? lastDivider.offset + clonedStructure.offset.height : clonedStructure.offset.height
                        },
                        idName: `${ALPHABET[counter.current]}${i}`,
                        children: [],
                        idItem: null
                    }
                }
            )
        } else {
            children = clonedStructure.children.map((c: any) => updateStructure(c, dividers, parent, direction))
        }

        return {
            ...clonedStructure,
            children,
            idItem: children.length > 0 ? null : clonedStructure.idItem
        }
    }

    const updateGrid = (dividers: ComposedDividerGraphic[], parent: ComposedItemStructureGraphic, direction: Direction) => {
        if (gridStructureGraphic) {
            const newStructure = updateStructure(gridStructureGraphic, dividers, parent, direction)
            setGridStructureGraphic(newStructure)
        }
    }

    const updateStructureAPI = (structure: ComposedItemStructureGraphic[], idItem?: number): ComposedItemStructure[] => {
        const newArray: ComposedItemStructure[] = structure.map((s) => {
            let newIdItem = s.idItem !== undefined ? s.idItem : null
            if (idItem !== undefined && idItem !== null && selectedParent && s.idName == selectedParent.idName) {
                newIdItem = idItem
            }
            return {
                Etichetta: s.idName,
                BOX_L: s.width,
                BOX_H: s.height,
                OFFSET_H: s.offset.height,
                OFFSET_L: s.offset.width,
                idItem: newIdItem,
                Struttura: s.children.length > 0 ? updateStructureAPI(s.children, idItem) : null
            }
        })
        return newArray
    }

    const setItemToCurrentComposed = (typology: Typology, data: DefaultDataTypology, idItem: number | null) => {
        if (currentItem && selectedParent && gridStructureGraphic) {
            let currentId: number = idItem ? idItem : 0
            const newItemArray = currentItem?.Items.filter((i) => i.Etichetta !== selectedParent?.idName)
            if (!idItem && newItemArray.length > 0) {
                currentId = newItemArray.length > 0 ? newItemArray[newItemArray?.length - 1].idItem + 1 : 0
            }
            const itemToPush: ComposedItemItem = {
                idItem: currentId,
                Tipo: typology.Tipo,
                Etichetta: selectedParent?.idName,
                NANT: parseInt(data.Numero_ante),
                APcode: '',
                HManiglia: data.HManiglia ? parseInt(data.HManiglia) : null,
                HTraverso1: data.HTraverso1 ? parseInt(data.HTraverso1) : null,
                HTraverso2: data.HTraverso2 ? parseInt(data.HTraverso2) : null,
                HTraverso3: data.HTraverso3 ? parseInt(data.HTraverso3) : null,
                HTraverso4: data.HTraverso4 ? parseInt(data.HTraverso4) : null,
                BOX_L: selectedParent.width,
                BOX_H: selectedParent.height,
                CodTip: typology.CodTip,
                DescrizTip: typology.DescrizTip
            }
            const newStructure = updateStructureAPI(gridStructureGraphic.children, itemToPush.idItem)
            newItemArray.push(itemToPush)
            setCurrentItem({
                ...currentItem,
                Struttura: newStructure,
                Items: newItemArray
            })
        }
    }

    const editMainItemComposed = async () => {
        const body: ItemToEdit = {
            idRecord: currentItemInQuote.idRecord,
            CodicePreventivo: order.newQuoteFromUser?.CodicePreventivo,
            CodiceSerie: currentItemInQuote.DettaglioItem.CodiceSerie,
            CodTipoItem: currentItemInQuote.DettaglioItem.CodTipoItem,
            Htip: currentItem!.BOX_H.toString(),
            Ltip: currentItem!.BOX_L.toString(),
            Quantita: currentItemInQuote.DettaglioItem.Quantita,
            Etichetta: currentItem!.Etichetta,
            Ordinamento: orderNumber.toString(),
            HManiglia: currentItemInQuote.DettaglioItem.HManiglia ? currentItemInQuote.DettaglioItem.HManiglia : null,
            HTraverso1: currentItemInQuote.DettaglioItem.HTraverso1 ? currentItemInQuote.DettaglioItem.HTraverso1 : null,
            HTraverso2: currentItemInQuote.DettaglioItem.HTraverso2 ? currentItemInQuote.DettaglioItem.HTraverso2 : null,
            HTraverso3: currentItemInQuote.DettaglioItem.HTraverso3 ? currentItemInQuote.DettaglioItem.HTraverso3 : null,
            HTraverso4: currentItemInQuote.DettaglioItem.HTraverso4 ? currentItemInQuote.DettaglioItem.HTraverso4 : null,
            PRM: currentItemInQuote.DettaglioItem.PRM ? currentItemInQuote.DettaglioItem.PRM.toString() : null,
            Prezzo: currentItemInQuote.DettaglioItem.Prezzo.toString(),
            Sconto: currentItemInQuote.DettaglioItem.Sconto.toString(),
            Annotazioniutente: currentItemInQuote.DettaglioItem.Annotazioniutente
        }
        await editItemPreventivo(body, dispatch)
    }

    const checkAndUpdateItems = async (newArray: ItemInQuote[]) => {
        const arrayEdit: ComposedItemItem[] = []
        for (let i = 0; i < newArray.length; i++) {
            const foundItem: ComposedItemItem | undefined = currentItem?.Items.find((f) => f.Etichetta == newArray[i].DettaglioItem.Etichetta)
            if (foundItem && foundItem?.CodTip != newArray[i].DettaglioItem.CodTipoItem) {
                const body: ItemToEdit = {
                    idRecord: newArray[i].idRecord,
                    CodicePreventivo: order.newQuoteFromUser?.CodicePreventivo,
                    CodiceSerie: newArray[i].DettaglioItem.CodiceSerie,
                    CodTipoItem: foundItem.CodTip,
                    Htip: newArray[i].DettaglioItem.Htip,
                    Ltip: newArray[i].DettaglioItem.Ltip,
                    Quantita: newArray[i].DettaglioItem.Quantita,
                    Etichetta: newArray[i].DettaglioItem.Etichetta,
                    Ordinamento: newArray[i].DettaglioItem.Ordinamento,
                    HManiglia: newArray[i].DettaglioItem.HManiglia ? newArray[i].DettaglioItem.HManiglia : null,
                    HTraverso1: newArray[i].DettaglioItem.HTraverso1 ? newArray[i].DettaglioItem.HTraverso1 : null,
                    HTraverso2: newArray[i].DettaglioItem.HTraverso2 ? newArray[i].DettaglioItem.HTraverso2 : null,
                    HTraverso3: newArray[i].DettaglioItem.HTraverso3 ? newArray[i].DettaglioItem.HTraverso3 : null,
                    HTraverso4: newArray[i].DettaglioItem.HTraverso4 ? newArray[i].DettaglioItem.HTraverso4 : null,
                    PRM: newArray[i].DettaglioItem.PRM ? newArray[i].DettaglioItem.PRM!.toString() : null,
                    Prezzo: newArray[i].DettaglioItem.Prezzo.toString(),
                    Sconto: newArray[i].DettaglioItem.Sconto.toString(),
                    Annotazioniutente: newArray[i].DettaglioItem.Annotazioniutente,
                    DescrizioneInterneDB: foundItem.DescrizTip
                }
                await editItemPreventivo(body, dispatch)
                arrayEdit.push(foundItem)
            }
        }
        return arrayEdit
    }

    const deleteNoMoreItems = async () => {
        const arrayItemsToDelete = order.itemsInQuote.filter(
            (i) =>
                i.DettaglioItem.IDComposta == currentItemInQuote.idRecord &&
                currentItem!.Items.find((f) => f.Etichetta == i.DettaglioItem.Etichetta) == undefined
        )
        if (arrayItemsToDelete.length > 0) {
            for (let i = 0; i < arrayItemsToDelete.length; i++) {
                await deleteItemFromQuote(arrayItemsToDelete[i].idRecord, dispatch)
            }
        }
        const newItemsInQuote = order.itemsInQuote.filter(
            (i) =>
                i.DettaglioItem.IDComposta == currentItemInQuote.idRecord &&
                currentItem!.Items.find((f) => f.Etichetta == i.DettaglioItem.Etichetta) !== undefined
        )
        return newItemsInQuote
    }

    const saveCurrentItem = async () => {
        if (currentItem) {
            setIsLoading(true)
            try {
                await editMainItemComposed()
                const newItemsInQuote = await deleteNoMoreItems()
                const itemsEdit: ComposedItemItem[] = await checkAndUpdateItems(newItemsInQuote)
                const bodyAddItems: AddItemsToPreventivoBody = createBodyAddPreventivo(itemsEdit, newItemsInQuote)
                const idArray: any = await addItemToPreventivo(bodyAddItems, dispatch)
                const newIdArray = [
                    ...idArray,
                    ...itemsEdit.map((i) => {
                        return { Idrecord: i.idItem.toString() }
                    })
                ]
                const defaultVariants: ItemInQuote[] = await createDefaultVariants({ Items: newIdArray }, dispatch)
                const copyCurrentItem = JSON.parse(JSON.stringify(currentItem))
                copyCurrentItem.Items = copyCurrentItem.Items.map((i: any) => {
                    const foundVariant = defaultVariants.find((v) => v.DettaglioItem.Etichetta == i.Etichetta)
                    if (foundVariant) {
                        return {
                            ...i,
                            idItem: parseInt(foundVariant.idRecord)
                        }
                    } else {
                        return i
                    }
                })
                const newArrayItems = [...defaultVariants, ...newItemsInQuote.filter((i) => defaultVariants.find((f) => f.idRecord == i.idRecord) == undefined)]
                copyCurrentItem.Struttura = updateStructureBeforeSet(currentItem.Struttura!, newArrayItems)
                await setComposedItem(copyCurrentItem, dispatch)
                await dispatch(
                    setItemsInQuote([...newArrayItems, ...order.itemsInQuote.filter((a) => a.DettaglioItem.IDComposta !== currentItemInQuote.idRecord)])
                )
                const offer = await saveCurrentOffer(order.newQuoteFromUser?.CodiceOfferta, dispatch)
                if (offer) {
                    dispatch(setCurrentOffer(offer))
                }
                // await dispatch(setOfferLoadedRedux(order.newQuoteFromUser?.CodiceOfferta, true))
                const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
                const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
                await dispatch(setPricesTotalOffer(prices))
                const item = await getComposedItem(parseInt(currentItemInQuote.idRecord), dispatch)
                props.hideModal()
                props.updateItem(item)
            } catch (error: any) {
                console.error(error)
                dispatch(setError(error.toString()))
            }
            setIsLoading(false)
        }
    }

    const updateStructureBeforeSet = useCallback((structure: ComposedItemStructure[], items: ItemInQuote[]): ComposedItemStructure[] | null => {
        if (!structure) {
            return null
        }
        const newArray = structure.map((s) => {
            const newId = items.find((i) => {
                return i.DettaglioItem.Etichetta == s.Etichetta
            })
            return {
                ...s,
                idItem: newId ? parseInt(newId?.idRecord) : null,
                Struttura: s.Struttura && s.Struttura.length > 0 ? updateStructureBeforeSet(s.Struttura, items) : null
            }
        })
        return newArray
    }, [])

    const assignTypologiesButton = useMemo(() => {
        return arrayParents.map((p) => {
            return (
                <Button
                    key={p.idName}
                    text={`${p.idName} (L. ${p.width} x H. ${p.height} mm.)`}
                    opacity={1}
                    onClick={() => {
                        setSelectedParent(p)
                        setShowSelectTypologyModal(true)
                    }}
                />
            )
        })
    }, [arrayParents])

    const createBodyAddPreventivo = (arrayEdited: ComposedItemItem[], itemsInQuote: ItemInQuote[]) => {
        const object: AddItemsToPreventivoBody = {
            CodicePreventivo: order.newQuoteFromUser?.CodicePreventivo,
            CodiceSerie: currentItemInQuote.DettaglioItem.CodiceSerie,
            UniformaMisure: 0,
            Tipologia: []
        }
        if (currentItem) {
            currentItem.Items.forEach((i) => {
                const foundInEdited = arrayEdited.find((e) => e.idItem == i.idItem)
                const foundInQuote = itemsInQuote.find((e) => e.DettaglioItem.Etichetta == i.Etichetta)
                if (!foundInEdited && !foundInQuote) {
                    const foundIndex = object.Tipologia.findIndex((t) => t.CodTipoItem == i.CodTip)
                    if (foundIndex == -1) {
                        object.Tipologia.push({
                            CodTipoItem: i.CodTip,
                            Items: [
                                {
                                    Htip: i.BOX_H,
                                    Ltip: i.BOX_L,
                                    HManiglia: i.HManiglia,
                                    HTraverso1: i.HTraverso1,
                                    HTraverso2: i.HTraverso2,
                                    HTraverso3: i.HTraverso3,
                                    HTraverso4: i.HTraverso4,
                                    Quantita: currentItemInQuote.DettaglioItem.Quantita,
                                    Ordinamento: 0,
                                    Etichetta: i.Etichetta,
                                    PRM: null,
                                    Prezzo: null,
                                    Sconto: null,
                                    Annotazioniutente: '',
                                    DescrizioneInterneDB: i.DescrizTip
                                }
                            ]
                        })
                    } else {
                        object.Tipologia[foundIndex].Items.push({
                            Htip: i.BOX_H,
                            Ltip: i.BOX_L,
                            HManiglia: i.HManiglia,
                            HTraverso1: i.HTraverso1,
                            HTraverso2: i.HTraverso2,
                            HTraverso3: i.HTraverso3,
                            HTraverso4: i.HTraverso4,
                            Quantita: currentItemInQuote.DettaglioItem.Quantita,
                            Ordinamento: 0,
                            Etichetta: i.Etichetta,
                            PRM: null,
                            Prezzo: null,
                            Sconto: null,
                            Annotazioniutente: '',
                            DescrizioneInterneDB: i.DescrizTip
                        })
                    }
                }
            })
        }
        return object
    }

    const renderDivisorsList = () => {
        if (gridStructureGraphic && gridStructureGraphic.children.length > 0) {
            return (
                <div style={{ backgroundColor: '#efefef', padding: 5, borderRadius: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                        <p style={{ fontSize: 20, color: '#68696D', margin: '10px 10px' }}>Lista divisori</p>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                        {checkFilledParents(gridStructureGraphic).map((d, i) => {
                            return (
                                <DivisorItemRow
                                    key={'d' + i}
                                    item={d}
                                    index={i}
                                    composedItem={currentItem}
                                    onRemove={async (founded) => {
                                        setIsUpdated(true)
                                        const newStructure = await checkAndRemoveDivisor(gridStructureGraphic, d)
                                        setGridStructureGraphic(newStructure)
                                        setRemoving(founded)
                                    }}
                                />
                            )
                        })}
                    </div>
                </div>
            )
        }
        return null
    }

    if (currentItem) {
        return (
            <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="xl" onBackdropClick={() => props.hideModal()}>
                <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                    <Loader isVisible={isLoading} />
                    <FontAwesomeIcon
                        icon={faTimes}
                        style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                        onClick={() => props.hideModal()}
                    />
                    <TitlePage text={'Finestra composta: ' + currentItem.Etichetta + ' (ID: ' + currentItem.CodiceSerie + ')'} style={{ margin: 0 }} />
                    <SelectComposedTypologyModal
                        isVisible={showSelectTypologyModal}
                        hideModal={() => {
                            setShowSelectTypologyModal(false)
                        }}
                        selectedParent={selectedParent}
                        serie={props.composedItem?.CodiceSerie}
                        setItem={(t, d) => {
                            setItemToCurrentComposed(t, d, selectedParent!.idItem)
                        }}
                        composedItem={currentItem}
                    />
                    <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Dettaglio finestra composta</p>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            gap: 20,
                            margin: '20px 0',
                            height: '70vh',
                            overflowY: 'scroll'
                        }}
                        className="variantList"
                    >
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '40vw' }}>
                            <img
                                src={imgGrid}
                                style={{ maxWidth: '100%', height: '60vh', backgroundColor: '#fff', objectFit: 'contain', borderRadius: 10, padding: 5 }}
                            />
                        </div>
                        <div style={{ flex: 2.4, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end', gap: 10 }}>
                            <div
                                style={{
                                    backgroundColor: '#fff',
                                    borderRadius: 10,
                                    padding: 15,
                                    flex: 1,
                                    gap: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: 'calc(100% - 30px)'
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                        <label>Etichetta</label>
                                        <input
                                            type="text"
                                            value={currentItem.Etichetta}
                                            placeholder="Etichetta"
                                            style={{
                                                flex: 4
                                            }}
                                            onChange={(e) => {
                                                setCurrentItem({
                                                    ...currentItem,
                                                    Etichetta: e.target.value
                                                })
                                            }}
                                        />
                                    </div>
                                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                        <label>Numero ordinamento</label>
                                        <input
                                            type="number"
                                            value={orderNumber}
                                            placeholder="N° Ord."
                                            style={{
                                                flex: 0.5
                                            }}
                                            onChange={(e) => {
                                                setOrderNumber(parseInt(e.target.value))
                                            }}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 20, marginTop: 20 }}>
                                    <div className={currentItem.BOX_L >= 0 && currentItem.BOX_L <= 9999 ? 'specInput' : 'specInput colorRed'}>
                                        <span>L (mm.)</span>
                                        <input
                                            disabled={gridStructureGraphic?.children && gridStructureGraphic.children.length > 0 ? true : false}
                                            min={0}
                                            max={9999}
                                            defaultValue={currentItem.BOX_L}
                                            value={currentItem.BOX_L}
                                            type="number"
                                            onChange={(e) => {
                                                setCurrentItem({
                                                    ...currentItem,
                                                    BOX_L: parseInt(e.target.value)
                                                })
                                                setHasChanged(true)
                                            }}
                                        />
                                        <span className="suggestion">(min. 0 mm.)</span>
                                        <span className="suggestion">(max. 9999 mm.)</span>
                                    </div>
                                    <div className={currentItem.BOX_H >= 0 && currentItem.BOX_H <= 9999 ? 'specInput' : 'specInput colorRed'}>
                                        <span>H (mm.)</span>
                                        <input
                                            disabled={gridStructureGraphic?.children && gridStructureGraphic.children.length > 0 ? true : false}
                                            min={0}
                                            max={9999}
                                            defaultValue={currentItem.BOX_H}
                                            value={currentItem.BOX_H}
                                            type="number"
                                            onChange={(e) => {
                                                setCurrentItem({
                                                    ...currentItem,
                                                    BOX_H: parseInt(e.target.value)
                                                })
                                                setHasChanged(true)
                                            }}
                                        />
                                        <span className="suggestion">(min. 0 mm.)</span>
                                        <span className="suggestion">(max. 9999 mm.)</span>
                                    </div>
                                </div>
                                {arrayParents.length > 0 && (
                                    <div style={{ backgroundColor: '#efefef', padding: 5, borderRadius: 10 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
                                            <p style={{ fontSize: 20, color: '#68696D', margin: '10px 10px' }}>Aggiungi divisore</p>
                                        </div>
                                        <AddDivisorItem
                                            parents={arrayParents}
                                            updateItem={(dividers, parent, direction) => {
                                                setIsUpdated(true)
                                                updateGrid(dividers, parent, direction)
                                            }}
                                        />
                                    </div>
                                )}
                                {currentItem.Struttura && currentItem.Struttura.length > 0 && (
                                    <div style={{ backgroundColor: '#efefef', padding: 5, borderRadius: 10 }}>
                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 }}>
                                            <p style={{ fontSize: 20, color: '#68696D', margin: '10px 10px' }}>Assegna tipologie</p>
                                        </div>
                                        <div style={{ display: 'flex', gap: 10, padding: '0 10px', marginBottom: 10 }}>{assignTypologiesButton}</div>
                                    </div>
                                )}
                                {renderDivisorsList()}
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: '100%', flexWrap: 'wrap' }}>
                        <Button
                            isSmall
                            text="Salva"
                            hasIcon={true}
                            icon={faCopy}
                            opacity={currentItem.Struttura && currentItem.Struttura.length > 0 && currentItem.Items.length > 0 ? 1 : 0.5}
                            onClick={() => {
                                if (currentItem.Struttura && currentItem.Struttura.length > 0 && currentItem.Items.length > 0) {
                                    saveCurrentItem()
                                }
                            }}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    return null
}

export default SelectVariantModal
