import React from 'react'
import { NavLink } from 'react-router-dom';
import { Categoria } from '../types/data'
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedCategory } from '../store/actions/graphic';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ReducerGraphic, Reducers } from '../types/reducers';

interface Props {
    category: Categoria
    onClick: () => void
    isEdit?: boolean
}

const CategoryCard: React.FC<Props> = (props) => {
    const dispatch = useDispatch()
    const category = props.category;
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)

    if (props.isEdit) {
        return (
            <div
                style={{
                    backgroundColor: category.ColoreFrame !== "" ? category.ColoreFrame : graphic.colorAccent,
                    cursor: 'inherit'
                }}
                className="categoryCard"
            >
                <div style={{ position: 'relative' }}>
                    <FontAwesomeIcon
                        icon={faPencilAlt}
                        onClick={() => props.onClick()}
                        style={{ fontSize: 20, position: 'absolute', backgroundColor: graphic.colorAccent, top: -20, right: -20, color: '#fff', padding: 15, borderRadius: "50%", boxShadow: '0 3px 6px rgba(0,0,0,0.3)', cursor: 'pointer' }}
                    />
                    <img src={category.ImgCategoria} />
                </div>
                <h3>{category.Nome}</h3>
            </div>
        )
    }


    return (
        <NavLink
            to='/products'
            style={{
                backgroundColor: category.ColoreFrame !== "" ? category.ColoreFrame : graphic.colorAccent
            }}
            className="categoryCard"
            onClick={() => dispatch(setSelectedCategory(category))}
        >
            <img src={category.ImgCategoria} />
            <h3>{category.Nome}</h3>
        </NavLink>
    )
}

export default CategoryCard