import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { ItemInQuote, VariantItem } from '../types/order';
import { ReducerGraphic, Reducers } from '../types/reducers';
import { transformToCurrency } from '../utilities/functions';
import { useSelector } from 'react-redux'

interface Props {
    typology: ItemInQuote
    isSelected: boolean
    onClick: () => void
}

const SelectTypologyCard: React.FC<Props> = (props) => {
    const typology = props.typology
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)

    return (
        <div className="typologyCard" style={{ backgroundColor: '#fff' }} onClick={() => props.onClick()}>
            <div style={{ border: "1px solid #ebebeb", backgroundColor: '#e9e9e9', position: 'absolute', top: 5, right: 5, width: 30, height: 30, borderRadius: "50%", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {props.isSelected && (
                    <FontAwesomeIcon icon={faCheckCircle} style={{ position: 'relative', top: 0, right: 0, color: graphic.colorAccent, fontSize: 24 }} />
                )}
            </div>
            <img src={typology.DettaglioItem.FiguraRiferimento !== "" ? typology.DettaglioItem.FiguraRiferimento : ""} style={{ width: "5vw", height: "5vw" }} />
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, justifyContent: 'space-between' }}>
                <p>{typology.DettaglioItem.Etichetta} - N° Ord. {typology.DettaglioItem.Ordinamento}</p>
                <p>{typology.DettaglioItem.DescrizioneInterneDB}</p>
                <p>({typology.DettaglioItem.Ltip} x {typology.DettaglioItem.Htip} mm.)</p>
            </div>
        </div>
    )
}

export default SelectTypologyCard