import React, { useState } from 'react'
import { DettaglioItemInQuote, VariantItem } from '../types/order'
import { ReducerGraphic, Reducers } from '../types/reducers'
import { transformToCurrency } from '../utilities/functions'
import SelectVariantModal from './modals/SelectVariantModal'
import { useSelector } from 'react-redux'

interface Props {
    variant: VariantItem
    idRecord: string
    updatedItem: (item: DettaglioItemInQuote) => void
    isAdditional?: boolean
    variants?: VariantItem[]
    clickEX?: () => void
}

const VariantListItem: React.FC<Props> = (props) => {
    const variant = props.variant
    const [isVisibleModal, setIsVisibleModal] = useState(false)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)

    if (variant.NascondiInDoc == "1") {
        return null
    }

    if (props.isAdditional && props.variants) {
        const values = props.variants.filter((v) => v.inserita !== 0).reduce((acc, value, index) => {
            if (index == 0) {
                return acc + value.DescrizVar
            }
            return acc + ', ' + value.DescrizVar
        }, '')
        const priceSum = props.variants.filter((v) => v.inserita !== 0).reduce((acc, value, index) => {
            return acc + value.PrezzoVariante
        }, 0)
        return (
            <div>
                <SelectVariantModal
                    isVisible={isVisibleModal}
                    variants={props.variants}
                    idRecord={props.idRecord}
                    variantSelected={variant}
                    hideModal={() => setIsVisibleModal(false)}
                    updatedItem={(item) => props.updatedItem(item)}
                />
                <div
                    style={{ cursor: 'pointer', background: "#fff", padding: "10px 15px", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderRadius: 7 }}
                    onClick={() => {
                        if (variant.IdGenereVar == "EX" && props.clickEX) {
                            props.clickEX()
                            return
                        }
                        setIsVisibleModal(true)
                    }}
                >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span className="limitTextOneRow" style={{ color: "#68696D" }}><b>{variant.DecrizioneCategoria}</b></span>
                        <span style={{ color: "#68696D" }}>{values ? (': ' + values) : ''}</span>
                    </div>

                    {priceSum > 0 && (
                        <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>{transformToCurrency(priceSum)}</span>
                    )}
                </div>
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', flex: 1, flexWrap: 'nowrap' }}>
            <SelectVariantModal
                isVisible={isVisibleModal}
                idRecord={props.idRecord}
                variantSelected={variant}
                hideModal={() => setIsVisibleModal(false)}
                updatedItem={(item) => props.updatedItem(item)}
            />
            <div
                style={{ cursor: 'pointer', background: "#fff", padding: "10px 15px", display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderRadius: 7, flex: 1, flexWrap: 'nowrap' }}
                onClick={() => {
                    if (variant.IdGenereVar == "EX" && props.clickEX) {
                        props.clickEX()
                        return
                    }
                    setIsVisibleModal(true)
                }}
            >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <span className="limitTextOneRow" style={{ color: "#68696D" }}><b>{variant.DecrizioneCategoria}</b>: </span>
                    <span style={{ color: "#68696D" }}>{variant.DescrizVar}</span>
                </div>
                {variant.PrezzoVariante > 0 && (
                    <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>{transformToCurrency(variant.PrezzoVariante)}</span>
                )}
            </div>
        </div>

    )
}

export default VariantListItem