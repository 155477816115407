import { DATA_ACTIONS } from './../actions/data'
import { ReducerData, ReduxActionData } from './../../types/reducers'

const defaultValue: ReducerData = {
    series: [],
    categories: [],
    provinces: [],
    cities: [],
    customers: [],
    offers: [],
    orders: [],
    taxes: [],
    additionalCosts: [],
    accounts: [],
    agents: [],
    levelsAgent: [],
    accountGroups: [],
    companies: [],
    saleCategories: [],
    messagesToRead: [],
    messages: []
}

const dataState: (state: ReducerData, action: ReduxActionData<any>) => ReducerData = (state = defaultValue, action: ReduxActionData<any>) => {
    switch (action.type) {
        case DATA_ACTIONS.SET_SERIES:
            return {
                ...state,
                series: action.payload
            }
        case DATA_ACTIONS.SET_CATEGORIES:
            return {
                ...state,
                categories: action.payload
            }
        case DATA_ACTIONS.SET_PROVINCES:
            return {
                ...state,
                provinces: action.payload
            }
        case DATA_ACTIONS.SET_CITIES:
            return {
                ...state,
                cities: action.payload
            }
        case DATA_ACTIONS.SET_CUSTOMERS:
            return {
                ...state,
                customers: action.payload
            }
        case DATA_ACTIONS.SET_OFFERS:
            return {
                ...state,
                offers: action.payload
            }
        case DATA_ACTIONS.SET_ORDERS:
            return {
                ...state,
                orders: action.payload
            }
        case DATA_ACTIONS.SET_TAXES:
            return {
                ...state,
                taxes: action.payload
            }
        case DATA_ACTIONS.SET_ADDITIONAL_COSTS:
            return {
                ...state,
                additionalCosts: action.payload
            }
        case DATA_ACTIONS.SET_ACCOUNTS:
            return {
                ...state,
                accounts: action.payload
            }
        case DATA_ACTIONS.SET_AGENTS:
            return {
                ...state,
                agents: action.payload
            }
        case DATA_ACTIONS.SET_LEVELS_AGENT:
            return {
                ...state,
                levelsAgent: action.payload
            }
        case DATA_ACTIONS.SET_ACCOUNT_GROUPS:
            return {
                ...state,
                accountGroups: action.payload
            }
        case DATA_ACTIONS.SET_COMPANIES:
            return {
                ...state,
                companies: action.payload
            }
        case DATA_ACTIONS.SET_SALE_CATEGORIES:
            return {
                ...state,
                saleCategories: action.payload
            }
        case DATA_ACTIONS.SET_MESSAGES_TO_READ:
            return {
                ...state,
                messagesToRead: action.payload
            }
        case DATA_ACTIONS.SET_MESSAGES:
            return {
                ...state,
                messages: action.payload
            }
        case DATA_ACTIONS.RESET_DATA:
            return defaultValue
        default:
            return state
    }
}

export default dataState
