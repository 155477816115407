import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import {
    ComposedItem,
    ComposedItemItem,
    ComposedItemStructureGraphic,
} from '../../types/order'
import DeleteModal from '../modals/DeleteModal'

interface Props {
    item: ComposedItemStructureGraphic
    index: number
    composedItem: ComposedItem | null
    onRemove: (founded: ComposedItemStructureGraphic[]) => void
}

const DivisorItemRow: React.FC<Props> = (props) => {
    const item = props.item
    const index = props.index
    const composedItem = props.composedItem
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [assignedItems, setAssignedItems] = useState<ComposedItemStructureGraphic[]>([])

    useEffect(() => {
        if (composedItem) {
            const foundItems = props.item.children.filter((c) => (props.composedItem?.Items.find((i) => c.idName == i.Etichetta.toString())) !== undefined)
            if (foundItems) {
                setAssignedItems(foundItems)
            } else {
                setAssignedItems([])
            }
        }
    }, [props.composedItem])

    return (
        <div style={{ display: 'flex', backgroundColor: '#fff', borderRadius: 20, justifyContent: 'space-between', padding: "10px 20px", alignItems: 'center' }}>
            <DeleteModal
                isVisible={isDeleteModalVisible}
                hideModal={() => setIsDeleteModalVisible(false)}
                onDelete={() => {
                    props.onRemove(assignedItems)
                    setIsDeleteModalVisible(false)
                }}
                text={`Cancella il divisore e le tipologie assegnate (ID: ${assignedItems.map((i) => { return i.idName }).toString()})`}
                title={"Elimina divisore"}
            />
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <label style={{ fontSize: 14 }}>ID</label>
                <span>{index}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', }}>
                <label style={{ fontSize: 14 }}>Divisore</label>
                <span>{item.children.length}</span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', }}>
                <label style={{ fontSize: 14 }}>Etichetta</label>
                <span>{item.idName}</span>
            </div>
            {item.children.every((child) => child.children.length === 0) ? (
                <div
                    onClick={() => {
                        setIsDeleteModalVisible(true)
                    }}
                >
                    <FontAwesomeIcon icon={faTrash} style={{ fontSize: 20, color: '#ff0000', cursor: 'pointer' }} />
                </div>
            ) : <div style={{ width: 20 }}></div>}
        </div>
    )
}

export default DivisorItemRow