import React, { useEffect, useMemo } from 'react'
import SubtitleSection from '../../../components/SubtitleSection'
import Button from '../../../components/Button'
import { faSearch, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { ReducerData, Reducers, ReducerUser } from '../../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { setError, setIsLoading } from '../../../store/actions/graphic'
import { User } from '../../../types/user'
import { deleteAccount, editAccount } from '../../../resources/requests/requests'
import { setAccountsRedux } from '../../../store/actions/thunk_actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MaterialTable from 'material-table'
import { locale_itIT } from '../../../utilities/tableLocale'
import DeleteModal from '../../../components/modals/DeleteModal'
import EditAccountModal from '../../../components/modals/EditAccountModal'
import ConfirmModal from '../../../components/modals/ConfirmModal'
import moment from 'moment'

const GestioneAccountScreen: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [filterSearch, setFilterSearch] = useState('')
    const [filteredAccount, setFilteredAccount] = useState<User[]>([])
    const [selectedAccount, setSelectedAccount] = useState<User | null>(null)
    const [isVisibleEditModal, setIsVisibleEditModal] = useState(false)
    const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false)
    const [isVisibleActivateModal, setIsVisibleActivateModal] = useState(false)
    const dispatch = useDispatch()

    const renderDatas = useMemo(() => {
        if (filterSearch) {
            return data.accounts.filter((a) => a.DenAg.toLowerCase().includes(filterSearch.toLowerCase()))
        } else {
            return data.accounts
        }
    }, [data.accounts, filterSearch])

    const renderActions = useMemo(() => {
        const actions = [
            () => ({
                icon: 'edit',
                iconProps: {
                    fontSize: 'medium'
                },
                tooltip: 'Modifica licenza',
                onClick: (event: any, rowData: User) => {
                    setSelectedAccount(rowData)
                    setIsVisibleEditModal(true)
                }
            }),
            (rowData: User) => {
                if (rowData.attivo == '1') {
                    return {
                        icon: 'checkbox',
                        iconProps: {
                            fontSize: 'medium'
                        },
                        tooltip: 'Disattiva',
                        onClick: (event: any, rowData: User) => {
                            setSelectedAccount(rowData)
                            setIsVisibleActivateModal(true)
                        }
                    }
                } else {
                    return {
                        icon: 'close',
                        iconProps: {
                            color: 'error',
                            fontSize: 'medium'
                        },
                        tooltip: 'Attiva',
                        onClick: (event: any, rowData: User) => {
                            setSelectedAccount(rowData)
                            setIsVisibleActivateModal(true)
                        }
                    }
                }
            },
            () => ({
                icon: 'delete',
                iconProps: {
                    color: 'error',
                    fontSize: 'medium'
                },
                tooltip: 'Elimina licenza',
                onClick: (event: any, rowData: User) => {
                    setIsVisibleDeleteModal(true)
                    setSelectedAccount(rowData)
                }
            })
        ]

        return actions
    }, [])

    const columns = [
        {
            title: 'ID',
            field: 'CodAg'
        },
        {
            title: 'Username',
            field: 'username'
        },
        {
            title: 'Nominativo',
            field: 'DenAg'
        },
        {
            title: 'Email',
            field: 'mail'
        },
        {
            title: 'Gruppo',
            field: 'TipoAccount'
        },
        {
            title: 'Scadenza licenza',
            field: 'passExpire',
            render: (rowData: User) => {
                const today = moment()
                const expire = moment(rowData.passExpire)
                return (
                    <p>
                        Rinnovo tra {expire.diff(today, 'days')} giorni - data: {expire.format('DD/MM/YYYY')}
                    </p>
                )
            }
        }
    ]

    const handleDelete = async () => {
        if (selectedAccount) {
            dispatch(setIsLoading(true))
            try {
                await deleteAccount(selectedAccount.CodAg, dispatch)
                await dispatch(setAccountsRedux())
                setIsVisibleDeleteModal(false)
                setSelectedAccount(null)
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    const handleActivateDeactivateAccount = async () => {
        if (selectedAccount) {
            dispatch(setIsLoading(true))
            try {
                const foundGroup = data.accountGroups.find((a) => a.Descrizione == selectedAccount.TipoAccount)
                const body = {
                    ID: parseInt(selectedAccount.ID),
                    CodAg: selectedAccount.CodAg,
                    username: selectedAccount.username,
                    ABL: 0,
                    password: null,
                    mail: selectedAccount.mail,
                    idgruppo: foundGroup ? parseInt(foundGroup.ID) : 1,
                    attivo: selectedAccount.attivo == '1' ? false : true
                }
                await editAccount(body, dispatch)
                await dispatch(setAccountsRedux())
                setIsVisibleActivateModal(false)
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    return (
        <div>
            <div className="introProductsPage">
                <SubtitleSection text="Lista account" />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 20 }}>
                    <div className="filterInput">
                        <FontAwesomeIcon icon={faSearch} className="icon" />
                        <input value={filterSearch} onChange={(e) => setFilterSearch(e.target.value)} placeholder="Ricerca licenza..." />
                    </div>
                    <Button
                        text="Aggiungi licenza"
                        hasIcon={true}
                        icon={faUserPlus}
                        onClick={() => {
                            setIsVisibleEditModal(true)
                            setSelectedAccount(null)
                        }}
                    />
                </div>
            </div>
            <div>
                <MaterialTable
                    title=""
                    columns={columns}
                    data={renderDatas}
                    localization={locale_itIT}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        search: false,
                        headerStyle: {
                            backgroundColor: '#ebebeb',
                            borderRadius: 20
                        }
                    }}
                    //@ts-ignore
                    actions={renderActions}
                />
            </div>
            <ConfirmModal
                isVisible={isVisibleActivateModal}
                hideModal={() => setIsVisibleActivateModal(false)}
                title={selectedAccount?.attivo == '1' ? 'Disattiva account' : 'Attiva account'}
                text={
                    selectedAccount?.attivo == '1'
                        ? `Vuoi disattivare l'account ${selectedAccount.username}?`
                        : `Vuoi attivare l'account ${selectedAccount?.username}?`
                }
                onConfirm={() => handleActivateDeactivateAccount()}
            />
            <EditAccountModal account={selectedAccount} isVisible={isVisibleEditModal} hideModal={() => setIsVisibleEditModal(false)} />
            <DeleteModal
                isVisible={isVisibleDeleteModal}
                hideModal={() => setIsVisibleDeleteModal(false)}
                onDelete={() => handleDelete()}
                title={'Eliminazione cliente'}
                text={'Vuoi davvero eliminare il cliente ' + selectedAccount?.DenAg + '?'}
            />
        </div>
    )
}

export default GestioneAccountScreen
