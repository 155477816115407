import { ReduxAction } from '../../types/reducers'
import { Serie, Categoria } from '../../types/data'
import { Listino } from '../../types/user'

export enum GRAPHIC_ACTIONS {
    SET_SELECTED_CATEGORY = 'graphicActions/setSelectedCategory',
    SET_CURRENT_USER_ONLINE = 'graphicActions/setCurrentUserOnline',
    SET_IS_LOADING = 'graphicActions/setIsLoading',
    SET_COLOR = 'graphicActions/setColor',
    SET_ERROR = 'graphicActions/setError',
    SET_LISTINO = 'graphicActions/setListino',
    SET_ERROR_VARIANTS = 'graphicActions/SetErrorVariants',
    SET_SHOW_CONTACTS = 'graphicActions/setShowContacts'
}

export const setSelectedCategory: ReduxAction<Categoria | null> = (category: Categoria | null) => {
    return {
        type: GRAPHIC_ACTIONS.SET_SELECTED_CATEGORY,
        payload: category
    }
}

export const setCurrentUserOnline: ReduxAction<string> = (num: string) => {
    return {
        type: GRAPHIC_ACTIONS.SET_CURRENT_USER_ONLINE,
        payload: num
    }
}

export const setIsLoading: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: GRAPHIC_ACTIONS.SET_IS_LOADING,
        payload: status
    }
}

export const setColor: ReduxAction<string> = (color: string) => {
    return {
        type: GRAPHIC_ACTIONS.SET_COLOR,
        payload: color
    }
}

export const setListino: ReduxAction<Listino> = (listino: Listino) => {
    return {
        type: GRAPHIC_ACTIONS.SET_LISTINO,
        payload: listino
    }
}

export const setError: ReduxAction<string | null> = (error: string | null) => {
    return {
        type: GRAPHIC_ACTIONS.SET_ERROR,
        payload: error
    }
}



export const setErrorVariants: ReduxAction<string[]> = (errors: string[]) => {
    return {
        type: GRAPHIC_ACTIONS.SET_ERROR_VARIANTS,
        payload: errors
    }
}

export const setShowContacts: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: GRAPHIC_ACTIONS.SET_SHOW_CONTACTS,
        payload: status
    }
}
