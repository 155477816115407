import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { ReducerData, ReducerGraphic, ReducerOrder, Reducers } from '../types/reducers'
import { useSelector } from 'react-redux'

interface Props {
    name: string
    icon: IconProp
    styleText?: any
    styleIcon?: any
    onClick: () => void
    isLogout?: boolean
    isCurrentOffer?: boolean
    isMessages?: boolean
    slug: string
}

const HeaderButton: React.FC<Props> = (props) => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)

    if (props.isLogout) {
        return (
            <NavLink to={props.slug} exact className="headerButton" activeClassName="active" onClick={() => props.onClick()}>
                <FontAwesomeIcon
                    icon={props.icon}
                    className="iconHeaderButton"
                    style={{
                        color: '#E00000',
                        ...props.styleIcon
                    }}
                />
                <span className="textHeaderButton" style={{ color: '#E00000', ...props.styleText }}>
                    {props.name}
                </span>
            </NavLink>
        )
    }

    if (props?.isCurrentOffer) {
        return (
            <NavLink to={props.slug} exact className="headerButton" activeClassName="active" style={{ position: 'relative' }}>
                <div style={{ position: 'relative' }}>
                    {order.currentOffer && order.currentOffer.Preventivi && order.currentOffer.Preventivi.length > 0 && (
                        <span
                            style={{
                                backgroundColor: graphic.colorAccent,
                                color: '#fff',
                                fontSize: 12,
                                width: 22,
                                height: 22,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '50%',
                                top: -10,
                                right: -10,
                                position: 'absolute',
                                fontWeight: 900,
                                boxShadow: '0 3px 6px rgba(0,0,0,0.3)'
                            }}
                        >
                            {order.currentOffer.Preventivi.length}
                        </span>
                    )}
                    <FontAwesomeIcon
                        icon={props.icon}
                        className="iconHeaderButton"
                        style={{
                            ...props.styleIcon
                        }}
                    />
                </div>

                <span className="textHeaderButton" style={{ ...props.styleText }}>
                    {props.name}
                </span>
            </NavLink>
        )
    }

    if (props.isMessages) {
        return (
            <NavLink to={props.slug} exact className="headerButton" activeClassName="active" style={{ position: 'relative' }}>
                <div style={{ position: 'relative' }}>
                    {data.messages.length > 0 && data.messages.filter((m) => m.Letto == '0').length > 0 && (
                        <span
                            style={{
                                backgroundColor: graphic.colorAccent,
                                color: '#fff',
                                fontSize: 12,
                                width: 22,
                                height: 22,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                borderRadius: '50%',
                                top: -10,
                                right: -10,
                                position: 'absolute',
                                fontWeight: 900,
                                boxShadow: '0 3px 6px rgba(0,0,0,0.3)'
                            }}
                        >
                            {data.messages.filter((m) => m.Letto == '0').length}
                        </span>
                    )}
                    <FontAwesomeIcon
                        icon={props.icon}
                        className="iconHeaderButton"
                        style={{
                            ...props.styleIcon
                        }}
                    />
                </div>

                <span className="textHeaderButton" style={{ ...props.styleText }}>
                    {props.name}
                </span>
            </NavLink>
        )
    }

    return (
        <NavLink to={props.slug} exact className="headerButton" activeClassName="active">
            <FontAwesomeIcon
                icon={props.icon}
                className="iconHeaderButton"
                style={{
                    ...props.styleIcon
                }}
            />
            <span className="textHeaderButton" style={{ ...props.styleText }}>
                {props.name}
            </span>
        </NavLink>
    )
}

export default HeaderButton
