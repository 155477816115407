import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faTimes } from '@fortawesome/free-solid-svg-icons'
import TitlePage from '../TitlePage'
import Button from '../Button'
import { ReducerData, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { customersPerSelect, provincePerSelect, citiesPerSelect } from '../../utilities/functions'
import SelectInput, { Option } from '../formComponents/SelectInput'
import Input from '../formComponents/Input'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { ClientNew, switchContatto } from '../../resources/requests/quote'
import { setOfferInProgress, setOfferLoadedRedux } from '../../store/actions/thunk_actions'
import { saveCurrentOffer } from '../../resources/requests/requests'
import { setCurrentOffer } from '../../store/actions/order'

interface Props {
    isVisible: boolean
    codPreventivo: string
    codCurrentCliente: string
    hideModal: () => void
    confirmChange: () => void
}

const SwitchCustomerModal: React.FC<Props> = (props) => {
    const voidCustomer: ClientNew = {
        CodiceOfferta: props.codPreventivo,
        idContatto: null,
        nominativo: null,
        cf: null,
        piva: null,
        email: null,
        idTipoPagamento: null,
        stato: null,
        provincia: null,
        cap: null,
        indirizzo: null,
        zona: null,
        CodAg: null,
        sdi: null
    }
    const [selectedCustomer, setSelectedCustomer] = useState(voidCustomer)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const dispatch = useDispatch()

    const customersOptions = () => {
        const arrClients = [{ id: 'new', value: 'new', label: '---INSERISCI NUOVO CLIENTE---' }]
        customersPerSelect(data.customers).forEach((c) => {
            arrClients.push(c)
        })
        return arrClients
    }

    useEffect(() => {
        if (props.isVisible) {
            const findCustomer = data.customers.find((c) => c.idContatto == props.codCurrentCliente)
            if (findCustomer) {
                setSelectedCustomer({
                    CodiceOfferta: props.codPreventivo,
                    idContatto: findCustomer.idContatto,
                    nominativo: findCustomer.nominativo,
                    cf: findCustomer.piva,
                    piva: findCustomer.piva,
                    email: findCustomer.email,
                    idTipoPagamento: findCustomer.idTipoPagamento,
                    stato: findCustomer.stato,
                    provincia: findCustomer.provincia,
                    cap: findCustomer.cap,
                    indirizzo: findCustomer.indirizzo,
                    zona: findCustomer.zona,
                    CodAg: findCustomer.CodAg,
                    sdi: findCustomer.sdi
                })
            } else {
                setSelectedCustomer(voidCustomer)
            }
        }
    }, [props.isVisible])

    const clienteValue = () => {
        if (selectedCustomer.idContatto) {
            return {
                id: selectedCustomer.idContatto,
                value: selectedCustomer.idContatto,
                label: selectedCustomer.nominativo ? selectedCustomer.nominativo : ''
            }
        } else {
            return {
                id: 'new',
                value: 'new',
                label: '---INSERISCI NUOVO CLIENTE---'
            }
        }
    }

    const provinciaValue = () => {
        if (selectedCustomer.provincia) {
            const provinceFound = data.provinces.find((p) => p.Sigla == selectedCustomer.provincia)
            return {
                id: selectedCustomer.provincia,
                value: selectedCustomer.provincia,
                label: provinceFound ? provinceFound.Provincia : ''
            }
        } else {
            return null
        }
    }

    const comuneValue = () => {
        if (selectedCustomer.cap) {
            const cityFound = data.cities.find((c) => c.CAP == selectedCustomer.cap)
            return {
                id: selectedCustomer.cap,
                value: selectedCustomer.cap,
                label: cityFound ? cityFound.Comune : ''
            }
        } else {
            return null
        }
    }

    const changeCustomer = async () => {
        dispatch(setIsLoading(true))
        try {
            let bodyToPass
            if (selectedCustomer.idContatto) {
                bodyToPass = {
                    CodiceOfferta: props.codPreventivo,
                    idContatto: parseInt(selectedCustomer.idContatto as string)
                }
            } else {
                bodyToPass = {
                    ...selectedCustomer
                }
            }
            await switchContatto(bodyToPass, dispatch)
            const offer = await saveCurrentOffer(props.codPreventivo, dispatch)
            if (offer) {
                dispatch(setCurrentOffer(offer))
            }
            //await saveCurrentOffer(props.codPreventivo, dispatch)
            // await dispatch(setOfferLoadedRedux(props.codPreventivo, true))
            props.confirmChange()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <Dialog
            open={props.isVisible}
            className="overflowVisible"
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10, overflow: 'visible' }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={'Cambio cliente preventivo' + props.codPreventivo} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Seleziona un nuovo cliente dall{"'"}elenco</p>
                <div style={{ margin: '20px 0' }}>
                    <SelectInput
                        value={clienteValue()}
                        options={customersOptions()}
                        onChange={(e) => {
                            const val = e as Option
                            if ((val.id as string) !== 'new') {
                                const findCustomer = data.customers.find((c) => c.idContatto == (val.id as string))
                                if (findCustomer) {
                                    setSelectedCustomer({
                                        CodiceOfferta: props.codPreventivo,
                                        idContatto: findCustomer.idContatto,
                                        nominativo: findCustomer.nominativo,
                                        cf: findCustomer.piva,
                                        piva: findCustomer.piva,
                                        email: findCustomer.email,
                                        idTipoPagamento: findCustomer.idTipoPagamento,
                                        stato: findCustomer.stato,
                                        provincia: findCustomer.provincia,
                                        cap: findCustomer.cap,
                                        indirizzo: findCustomer.indirizzo,
                                        zona: findCustomer.zona,
                                        CodAg: findCustomer.CodAg,
                                        sdi: findCustomer.sdi
                                    })
                                } else {
                                    setSelectedCustomer(voidCustomer)
                                }
                            } else {
                                setSelectedCustomer(voidCustomer)
                            }
                        }}
                        placeholder="Elenco clienti..."
                    />
                </div>
                {!selectedCustomer.idContatto ? (
                    <div className="gridInputOffer">
                        <Input
                            value={selectedCustomer.nominativo ? selectedCustomer.nominativo : ''}
                            onChange={(e) => {
                                setSelectedCustomer({
                                    ...selectedCustomer,
                                    nominativo: e
                                })
                            }}
                            placeholder="Nome e cognome / Ragione sociale*"
                            style={{ width: 'calc(100% - 40px)' }}
                        />
                        <Input
                            value={selectedCustomer.indirizzo ? selectedCustomer.indirizzo : ''}
                            onChange={(e) => {
                                setSelectedCustomer({
                                    ...selectedCustomer,
                                    indirizzo: e
                                })
                            }}
                            placeholder="Indirizzo"
                            style={{ width: 'calc(100% - 40px)' }}
                        />
                        <SelectInput
                            placeholder="Provincia"
                            options={provincePerSelect(data.provinces)}
                            onChange={(e) => {
                                const val = e as Option
                                if (e) {
                                    setSelectedCustomer({
                                        ...selectedCustomer,
                                        provincia: val.id as string
                                    })
                                }
                            }}
                            value={provinciaValue()}
                        />
                        <SelectInput
                            placeholder="Comune"
                            options={citiesPerSelect(
                                selectedCustomer.provincia !== '' ? data.cities.filter((c) => c.Sigla == selectedCustomer.provincia) : data.cities
                            )}
                            onChange={(e) => {
                                const val = e as Option
                                if (e) {
                                    if (e) {
                                        setSelectedCustomer({
                                            ...selectedCustomer,
                                            cap: val.id as string
                                        })
                                    }
                                }
                            }}
                            value={comuneValue()}
                        />
                        <Input
                            value={selectedCustomer.email ? selectedCustomer.email : ''}
                            type="email"
                            onChange={(e) => {
                                setSelectedCustomer({
                                    ...selectedCustomer,
                                    email: e
                                })
                            }}
                            placeholder="Indirizzo email*"
                            style={{ width: 'calc(100% - 40px)' }}
                        />
                        <Input
                            value={selectedCustomer.stato ? selectedCustomer.stato : ''}
                            onChange={(e) => {
                                setSelectedCustomer({
                                    ...selectedCustomer,
                                    stato: e
                                })
                            }}
                            placeholder="Stato"
                            style={{ width: 'calc(100% - 40px)' }}
                        />
                    </div>
                ) : null}
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Annulla"
                    buttonColor="#52565A"
                    onClick={() => {
                        props.hideModal()
                    }}
                />
                <Button
                    isSmall
                    text="Salva"
                    hasIcon={true}
                    icon={faCopy}
                    onClick={() => {
                        changeCustomer()
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default SwitchCustomerModal
