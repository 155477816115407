import { ClienteScontoItem } from './../../types/data'
import { CurrentOffer, ItemInQuote, NewQuoteFromUser, OfferData, TotaliOfferta } from './../../types/order'
import { ReduxAction } from '../../types/reducers'
import { Serie, Categoria } from '../../types/data'

export enum ORDER_ACTIONS {
    SET_NEW_ORDER_FROM_USER = 'orderActions/setNewOrderFromUser',
    SET_ITEMS_IN_QUOTE = 'orderActions/setItemsInQuote',
    SET_OFFER_DATA = 'orderActions/setOfferData',
    SET_ID_USER_KNOWN = 'orderActions/setIdUserKnown',
    SET_PRICES_TOTAL_OFFER = 'orderActions/setPricesTotalOffer',
    SET_CURRENT_OFFER = 'orderActions/setCurrentOffer',
    SET_IS_PENDING_OFFER = 'orderActions/setIsPendingOffer',
    SET_CURRENT_CUSTOMER_SALE = 'orderActions/setCurrentCustomerSale'
}

export const setNewOrderFromUser: ReduxAction<NewQuoteFromUser> = (data: NewQuoteFromUser) => {
    return {
        type: ORDER_ACTIONS.SET_NEW_ORDER_FROM_USER,
        payload: data
    }
}

export const setItemsInQuote: ReduxAction<ItemInQuote[]> = (items: ItemInQuote[]) => {
    return {
        type: ORDER_ACTIONS.SET_ITEMS_IN_QUOTE,
        payload: items
    }
}

export const setOfferData: ReduxAction<OfferData | null> = (offerData: OfferData | null) => {
    return {
        type: ORDER_ACTIONS.SET_OFFER_DATA,
        payload: offerData
    }
}

export const setIdUserKnown: ReduxAction<string> = (id: string) => {
    return {
        type: ORDER_ACTIONS.SET_ID_USER_KNOWN,
        payload: id
    }
}

export const setPricesTotalOffer: ReduxAction<TotaliOfferta | null> = (totals: TotaliOfferta | null) => {
    return {
        type: ORDER_ACTIONS.SET_PRICES_TOTAL_OFFER,
        payload: totals
    }
}

export const setCurrentOffer: ReduxAction<CurrentOffer | null> = (offer: CurrentOffer | null) => {
    return {
        type: ORDER_ACTIONS.SET_CURRENT_OFFER,
        payload: offer
    }
}

export const setIsPendingOffer: ReduxAction<boolean> = (status: boolean) => {
    return {
        type: ORDER_ACTIONS.SET_IS_PENDING_OFFER,
        payload: status
    }
}

export const setcurrentCustomerSale: ReduxAction<ClienteScontoItem | null> = (sale: ClienteScontoItem | null) => {
    return {
        type: ORDER_ACTIONS.SET_CURRENT_CUSTOMER_SALE,
        payload: sale
    }
}
