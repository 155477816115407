import React, { useState } from 'react'
import { OfferArchive } from '../types/data'
import { ReducerData, ReducerGraphic, ReducerOrder, Reducers, ReducerUser } from '../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faCheck,
    faCheckDouble,
    faCheckSquare,
    faEnvelope,
    faExclamation,
    faEye,
    faFileExport,
    faHourglassHalf,
    faLock,
    faPrint,
    faRedo,
    faStop,
    faTimes,
    faTrash,
    faUserCheck
} from '@fortawesome/free-solid-svg-icons'
import { transformToCurrency } from '../utilities/functions'
import {
    convertOfferToOrder,
    createPDFToPrint,
    createXMLToDownload,
    deleteAllQuotes,
    deleteOffer,
    duplicateOffer,
    getOfferData,
    getOrders,
    getPendingOffers,
    saveCurrentOffer
} from '../resources/requests/requests'
import { useHistory } from 'react-router'
import { setLastOfferSeen, setPrintFile } from '../store/actions/user'
import { setOfferInProgress, setOfferLoadedRedux, setOffersRedux, setOrdersRedux } from '../store/actions/thunk_actions'
import { setError, setIsLoading } from '../store/actions/graphic'
import DeleteModal from './modals/DeleteModal'
import DuplicateModal from './modals/DuplicateModal'
import { setCurrentOffer } from '../store/actions/order'
import PrintSettingsModal from './modals/PrintSettingsModal'
import { PrintType } from '../utilities/PrintType'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from './TitlePage'
import Button from './Button'
import ConfirmModal from './modals/ConfirmModal'
import { BASE_URL, BASE_URL_FRONTEND, SUB_PATH_URL_FRONTEND } from '../resources/constants'
import StatusIconButton from './StatusIconButton'
import { checkOfferStatus } from '../resources/requests/quote'

interface Props {
    item: OfferArchive
    backgroundColor?: string
    index: number
    onDelete?: () => void
}

export enum OfferStatus {
    InCorso = 'In corso',
    InUso = 'in uso',
    ConfermatoCliente = 'confermato dal cliente',
    RicevutoBiemme = 'Ricevuto da Biemme Finestre',
    Confermato = 'Accettato da Biemme Finestre',
    Rifiutato = 'Rifiutato da Biemme Finestre'
}

const OffersListItem: React.FC<Props> = (props) => {
    const offer = props.item
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isDuplicateModalVisible, setIsDuplicateModalVisible] = useState(false)
    const [visiblePrintSettingsModal, setVisiblePrintSettingsModal] = useState(false)
    const [modalCurrentOfferVisible, setModalCurrentOfferVisible] = useState(false)
    const [isConfirmVisible, setIsConfirmVisible] = useState('')
    const [duplicatedOffer, setDuplicatedOffer] = useState('')
    const [deletedOffer, setDeletedOffer] = useState('')
    const history = useHistory()
    const dispatch = useDispatch()

    const renderFirstColumn = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <h3 style={{ fontWeight: 400, color: '#68696D', fontSize: 20, marginBottom: 20 }}>
                    {data.customers.find((c) => c.idContatto == offer.IdContattoTemporaneo)?.nominativo}
                    {offer.Descrizione && ` - ${offer.Descrizione}`}
                </h3>
                <div style={{ display: 'flex', flexDirection: 'column', color: '#68696D' }}>
                    <span>Codice: {offer.CodiceOfferta}</span>
                    <span>Data creazione: {moment(offer.DataCreazione).format('DD/MM/YYYY')}</span>
                    <span>Data ultima modifica: {moment(offer.DataUltimaModifica).format('DD/MM/YYYY')}</span>
                </div>
            </div>
        )
    }

    const renderSecondColumn = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'flex-end' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: 40, justifyContent: 'flex-end' }}>
                    {offer.statoPreventivo !== OfferStatus.InUso && (
                        <div className="tooltip">
                            <FontAwesomeIcon
                                icon={faEye}
                                style={{ color: graphic.colorAccent, fontSize: 22, cursor: 'pointer' }}
                                onClick={() => {
                                    if (order.currentOffer) {
                                        setModalCurrentOfferVisible(true)
                                    } else {
                                        handleShowDetail()
                                    }
                                }}
                            />
                            <span className="tooltiptext">Visualizza dettagli</span>
                        </div>
                    )}
                    {offer.statoPreventivo == OfferStatus.InCorso && (
                        <div className="tooltip">
                            <FontAwesomeIcon
                                icon={faCheckSquare}
                                style={{ color: graphic.colorAccent, fontSize: 22, cursor: 'pointer' }}
                                onClick={() => {
                                    handleConvertToOrder()
                                }}
                            />
                            <span className="tooltiptext">Converti in ordine</span>
                        </div>
                    )}
                    {user.user?.TipoAccount == 'Amministratore' || user.user?.TipoAccount == 'Supervisore' ? (
                        <div className="tooltip">
                            <FontAwesomeIcon
                                icon={faFileExport}
                                style={{ color: '#68696D', fontSize: 20, cursor: 'pointer' }}
                                onClick={() => {
                                    handleDownloadXML()
                                }}
                            />
                            <span className="tooltiptext">Scarica file specifiche</span>
                        </div>
                    ) : null}

                    <div className="tooltip">
                        <FontAwesomeIcon
                            icon={faRedo}
                            style={{ color: '#68696D', fontSize: 20, cursor: 'pointer' }}
                            onClick={() => {
                                setIsDuplicateModalVisible(true)
                            }}
                        />
                        <span className="tooltiptext">Duplica</span>
                    </div>
                    <div className="tooltip">
                        <FontAwesomeIcon
                            icon={faPrint}
                            style={{ color: '#68696D', fontSize: 20, cursor: 'pointer' }}
                            onClick={() => {
                                setVisiblePrintSettingsModal(true)
                            }}
                        />
                        <span className="tooltiptext">Stampa PDF</span>
                    </div>
                    <div className="tooltip">
                        <FontAwesomeIcon
                            icon={faTrash}
                            style={{ color: '#E00000', fontSize: 20, cursor: 'pointer' }}
                            onClick={() => {
                                setIsDeleteModalVisible(true)
                            }}
                        />
                        <span className="tooltiptext">Elimina</span>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 15, gap: 10 }}>
                    {renderStatus()}
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5, justifyContent: 'flex-end' }}>
                        <p style={{ color: '#68696D', fontWeight: 900, fontSize: 24 }}>
                            Totale <span style={{ color: graphic.colorAccent }}>{transformToCurrency(offer.Totale)}</span>
                        </p>
                        <p style={{ color: '#68696D', textAlign: 'right' }}>IVA esclusa</p>
                    </div>
                </div>
            </div>
        )
    }

    const renderStatus = () => {
        switch (offer.statoPreventivo) {
            case OfferStatus.InUso:
                return (
                    <StatusIconButton
                        onClick={() => {
                            return
                        }}
                        tooltip={`${offer.statoPreventivo} da ${offer.lockCodAg} il ${moment(offer.LockDataAperturaDoc).format('DD/MM/YYYY HH:mm')}`}
                        color="yellow"
                        icon={faLock}
                    />
                )
            case OfferStatus.InCorso:
                return (
                    <StatusIconButton
                        onClick={() => {
                            return
                        }}
                        tooltip={offer.statoPreventivo}
                        color="yellow"
                        icon={faHourglassHalf}
                    />
                )
            case OfferStatus.RicevutoBiemme:
                return (
                    <StatusIconButton
                        onClick={() => {
                            return
                        }}
                        tooltip={offer.statoPreventivo}
                        color="yellow"
                        icon={faEnvelope}
                    />
                )
            case OfferStatus.ConfermatoCliente:
                return (
                    <StatusIconButton
                        onClick={() => {
                            return
                        }}
                        tooltip={offer.statoPreventivo}
                        color="green"
                        icon={faCheck}
                    />
                )
            case OfferStatus.Confermato:
                return (
                    <StatusIconButton
                        onClick={() => {
                            return
                        }}
                        tooltip={offer.statoPreventivo}
                        color="green"
                        icon={faCheckDouble}
                    />
                )
            case OfferStatus.Rifiutato:
                return (
                    <StatusIconButton
                        onClick={() => {
                            return
                        }}
                        tooltip={offer.statoPreventivo}
                        color="red"
                        icon={faTimes}
                    />
                )
            default:
                return (
                    <StatusIconButton
                        onClick={() => {
                            return
                        }}
                        tooltip={offer.statoPreventivo}
                        color="yellow"
                        icon={faHourglassHalf}
                    />
                )
        }
    }

    const handleDownloadXML = async () => {
        dispatch(setIsLoading(true))
        try {
            const fileXML = await createXMLToDownload(offer.CodiceOfferta, dispatch)
            if (fileXML) {
                saveAs(fileXML, offer.CodiceOfferta + '.zip')
                setIsConfirmVisible(offer.CodiceOfferta)
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handleShowDetail = async () => {
        dispatch(setIsLoading(true))
        try {
            const lockedOffer = await checkOfferStatus(offer.CodiceOfferta, dispatch)
            if (!lockedOffer) {
                
    dispatch(setIsLoading(true)) 

                const offerData = await getOfferData(offer.CodiceOfferta, dispatch)
                await dispatch(setLastOfferSeen(offerData))
                const pendingOffer = await getPendingOffers(dispatch)
                dispatch(setOfferLoadedRedux(pendingOffer))
    
    dispatch(setIsLoading(false)) 

                history.push('/offers/detail')
            } else {
                dispatch(setError("Impossibile aprire l'offerta perchè già in uso da un altro operatore."))
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handleConvertToOrder = async () => {
        dispatch(setIsLoading(true))
        try {
            await convertOfferToOrder(offer.CodiceOfferta, dispatch)
            //await dispatch(setOffersRedux())
            await dispatch(setOrdersRedux())
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handlePrintOffer = async (type: PrintType) => {
        dispatch(setIsLoading(true))
        try {
            const filePdf = await createPDFToPrint(offer.CodiceOfferta, dispatch)
            dispatch(setPrintFile({ ...filePdf, printType: type }))
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                window.open('/print', '_blank')
            }
             
            else {
                /*
                window.open(
                    `${process.env.REACT_APP_RELEASE_ENV == 'production' ? BASE_URL_FRONTEND : BASE_URL_FRONTEND + 'biemme_genera/panel'}/print`,
                    '_blank'
                )*/
                 window.open(
                     `${(process.env.REACT_APP_RELEASE_ENV == 'production' ) ? BASE_URL_FRONTEND : BASE_URL_FRONTEND + SUB_PATH_URL_FRONTEND}print`,
                     '_blank'
                 )
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handleDuplicate = async () => {
        dispatch(setIsLoading(true))
        try {
            const lockedOffer = await checkOfferStatus(offer.CodiceOfferta, dispatch)
            if (!lockedOffer) {
                const duplicated = await duplicateOffer(offer.CodiceOfferta, dispatch)
                if (duplicated) {
                    setDuplicatedOffer(duplicated)
                }
                //console.log(duplicated)
                //dispatch(setOffersRedux())
            } else {
                dispatch(setError("Impossibile aprire l'offerta perchè già in uso da un altro operatore."))
            }
            setIsDuplicateModalVisible(false)
            if (props.onDelete) {
                props.onDelete()
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handleDelete = async () => {
        dispatch(setIsLoading(true))
        try {
            if (order.currentOffer?.CodiceOfferta == offer.CodiceOfferta) {
                dispatch(setCurrentOffer(null))
                await deleteAllQuotes(dispatch)
            }
            const lockedOffer = await checkOfferStatus(offer.CodiceOfferta, dispatch)
            if (!lockedOffer) {
                await deleteOffer(offer.CodiceOfferta, dispatch)
                setDeletedOffer(offer.CodiceOfferta)
                //dispatch(setOffersRedux())
                if (props.onDelete) {
                    props.onDelete()
                }
            } else {
                dispatch(setError("Impossibile aprire l'offerta perchè già in uso da un altro operatore."))
            }
            setIsDeleteModalVisible(false)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const renderModalCurrentOfferVisible = () => {
        return (
            <Dialog open={modalCurrentOfferVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md">
                <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                    <TitlePage text={'Offerta aperta'} style={{ margin: 0 }} />
                    <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>
                        Hai un{"'"}offerta in sospeso.
                        <br />
                        Chiudi l{"'"}offerta corrente per consultare questa offerta. Cosa vorresti fare?
                    </p>
                </DialogContent>
                <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                    <Button
                        isSmall
                        text="Annulla operazione"
                        buttonColor="#52565A"
                        onClick={() => {
                            setModalCurrentOfferVisible(false)
                        }}
                    />
                    <Button
                        isSmall
                        text="Chiudi offerta corrente"
                        onClick={async () => {
                            dispatch(setIsLoading(true))
                            try {
                                await saveCurrentOffer(order.currentOffer?.CodiceOfferta, dispatch)
                                dispatch(setCurrentOffer(null))
                                await deleteAllQuotes(dispatch)
                                handleShowDetail()
                                //await dispatch(setOffersRedux())
                            } catch (error: any) {
                                dispatch(setError(error.toString()))
                            }
                            dispatch(setIsLoading(false))
                        }}
                    />
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <div
            className="cardCustomTypology"
            style={{ gap: 20, alignItems: 'center', backgroundColor: props.backgroundColor ? props.backgroundColor : '#ebebeb' }}
        >
            {renderFirstColumn()}
            {renderSecondColumn()}
            {renderModalCurrentOfferVisible()}
            <DeleteModal
                isVisible={isDeleteModalVisible}
                hideModal={() => setIsDeleteModalVisible(false)}
                onDelete={() => {
                    handleDelete()
                    setIsDeleteModalVisible(false)
                }}
                text={"Cancella l'offerta (ID " + offer.CodiceOfferta + ')'}
                title={'Elimina offerta'}
            />
            <DuplicateModal
                isVisible={isDuplicateModalVisible}
                hideModal={() => setIsDuplicateModalVisible(false)}
                onDuplicate={() => handleDuplicate()}
                text={"Duplica l'offerta (ID " + offer.CodiceOfferta + ')'}
                title={'Duplicazione offerta'}
            />
            <PrintSettingsModal
                isVisible={visiblePrintSettingsModal}
                hideModal={() => setVisiblePrintSettingsModal(false)}
                onConfirm={(type, order) => {
                    handlePrintOffer(type)
                }}
            />
            <ConfirmModal
                isVisible={isConfirmVisible !== ''}
                hideModal={() => setIsConfirmVisible('')}
                isOnlyConfirm
                title="Sto scaricando il file specifiche"
                text={
                    isConfirmVisible && isConfirmVisible.charAt(0) == 'P'
                        ? `Sto scaricando il file specifiche per l'offerta ${isConfirmVisible}`
                        : `Sto scaricando il file specifiche per l'ordine ${isConfirmVisible}`
                }
                onConfirm={() => setIsConfirmVisible('')}
            />
            {duplicatedOffer ? (
                <ConfirmModal
                    isVisible={duplicatedOffer !== ''}
                    hideModal={() => setDuplicatedOffer('')}
                    isOnlyConfirm
                    title={`Offerta duplicata! La nuova offerta ha il codice ${duplicatedOffer}`}
                    text={`Se non vedi la nuova offerta correttamente ricarica la pagina.`}
                    onConfirm={() => setDuplicatedOffer('')}
                />
            ) : null}
            {deletedOffer ? (
                <ConfirmModal
                    isVisible={deletedOffer !== ''}
                    hideModal={() => setDeletedOffer('')}
                    isOnlyConfirm
                    title={`Offerta ${deletedOffer} eliminata.`}
                    text={`Se vedi ancora l'offerta eliminata ricarica la pagina.`}
                    onConfirm={() => setDeletedOffer('')}
                />
            ) : null}
        </div>
    )
}

export default OffersListItem
