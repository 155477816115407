import { VoidDoc } from '../../components/modals/AddDocModal'
import CustomAxios from '../../customCompontents/CustomAxios'
import { logoutUser } from '../../store/actions/user'
import { BASE_URL, TOKEN_ERROR_STRING } from '../constants'
import { headersJson } from './requests'

export const getMessagesToAccept = async (dispatch: (action: any) => void) => {
    const messages = await CustomAxios.get(BASE_URL + 'bmapi/bacheca/ListConfirm').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return messages
}

export const getMessages = async (dispatch: (action: any) => void) => {
    const messages = await CustomAxios.get(BASE_URL + 'bmapi/bacheca').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return messages
}

/* WL  Messaggi inviati  via email */
export const SendNewMessageEmail = async (
    newMessageEmail: {
        EmailAddress: string
        AddressName: string
        EmailAddressSecond: string
        EmailAddressCC: string
        EmailAddressBCC: string
        Subject: string
        Body: string
        AltBody: string
        Attachments0: string
        Attachments1: string
        ReplyMe: boolean
    },
    dispatch: (action: any) => void
) => {
    await CustomAxios.post(BASE_URL + 'bmapi/sendMail', newMessageEmail, headersJson).then((res) => {
        if (res.data.Message && res.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}
export const SendNewMessageEmailCommessa = async (
    newMessageEmail: {
        CodAz: string,
        Descrizione: string,
        Commessa: string,
        DataIng: string,
        Operatore: string,
        Stato: string,
        DataConf: string,
        Agente: string,
        Cliente: string,
        Riferimento: string,
        Serie: string,
        ColEx: string,
        ColInt: string,
        Pezzi: string,
        Imponibile: string,
        DataRiSpe: string,
        DatPrePianificazione: string,
    },
    dispatch: (action: any) => void
) => {
    await CustomAxios.post(BASE_URL + 'bmapi/sendMailCommessa', newMessageEmail, headersJson).then((res) => {
        if (res.data.Message && res.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}
/* WL  fine  Messaggi inviati  via email */

export const createMessage = async (
    newMessage: {
        Titolo: string
        Descrizione: string
        Link: string
        Visibile: number
        RichestaAccettazione: number
        Gruppo: number
        Colore: string
    },
    dispatch: (action: any) => void
) => {
    await CustomAxios.post(BASE_URL + 'bmapi/bacheca', newMessage, headersJson).then((res) => {
        if (res.data.Message && res.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const acceptMessage = async (idMessage: string, dispatch: (action: any) => void) => {
    await CustomAxios.put(BASE_URL + 'bmapi/bacheca/' + idMessage).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const deleteMessage = async (id: string, dispatch: (action: any) => void) => {
    await CustomAxios.delete(BASE_URL + 'bmapi/bacheca/' + id).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const getDocs = async (dispatch: (action: any) => void) => {
    const messages = await CustomAxios.get(BASE_URL + 'bmapi/DocumentazioneEsterna').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return messages
}

export const deleteDoc = async (id: string, dispatch: (action: any) => void) => {
    await CustomAxios.delete(BASE_URL + 'bmapi/DocumentazioneEsterna/' + id).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const createDoc = async (doc: VoidDoc, dispatch: (action: any) => void) => {
    await CustomAxios.post(BASE_URL + 'bmapi/DocumentazioneEsterna', doc, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const getXMLDocs = async (dispatch: (action: any) => void) => {
    const messages = await CustomAxios.get(BASE_URL + 'bmapi/ListXML').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return messages
}
// WL Aggiunta 16/12/2022
export const getCommesseDocs = async (dispatch: (action: any) => void) => {
    const messages = await CustomAxios.get(BASE_URL + 'bmapi/Statuscommesse').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return messages
}
// WL Aggiunta 17/2/2023
export const getJSONDocs = async (dispatch: (action: any) => void) => {
    const messages = await CustomAxios.get(BASE_URL + 'bmapi/ListJSON').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return messages
}
export const resetOfferFromJSON = async (codOffer: string, versione: string, dataCreazione: string, dispatch: (action: any) => void) => {
    const body = {
        DataCreazione: dataCreazione,
        Versione: versione,
        CodiceOfferta: codOffer
    }
    const status = await CustomAxios.post(`${BASE_URL}bmapi/Service/OffertaFromJson`, body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        return data.data.Result
    })
    return status
}
export const getDatabases = async (dispatch: (action: any) => void) => {
    const databases = await CustomAxios.post(`${BASE_URL}bmapi/Service/BackupArchiviList`).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return databases
}

export const getServerStatus = async (dispatch: (action: any) => void) => {
    const status = await CustomAxios.post(`${BASE_URL}bmapi/Status`).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        return data.data.Data
    })
    return status
}
