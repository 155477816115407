import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { ReducerData, ReducerOrder, Reducers, ReducerUser } from '../../../types/reducers'
import { agentsPerSelect, citiesPerSelect, customersPerSelect, provincePerSelect, validateEmail, validatePhone } from '../../../utilities/functions'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../../components/Button'
import { faArrowRight, faRetweet } from '@fortawesome/free-solid-svg-icons'
import { Serie } from '../../../types/data'
import { addQuoteToOffer, createQuoteFromCustomer, setAdditionalDatasOffer } from '../../../resources/requests/requests'
import { setcurrentCustomerSale, setIdUserKnown, setNewOrderFromUser, setOfferData } from '../../../store/actions/order'
import { setIdOfferPending } from '../../../store/actions/user'
import { NewQuoteFromUser, OfferData } from '../../../types/order'
import { setError, setIsLoading } from '../../../store/actions/graphic'
import moment from 'moment'
import SelectInput, { Option } from '../../../components/formComponents/SelectInput'
import Input from '../../../components/formComponents/Input'
import SwitchCustomerModal from '../../../components/modals/SwitchCustomerModal'

interface Props {
    nextPhase: () => void
    currentClientOffer: OfferData
    setCurrentClientOffer: (data: OfferData) => void
    serieSelected: Serie
}

const CreaOffertaView: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const voidCustomer = {
        name: '',
        address: '',
        province: '',
        city: '',
        email: '',
        number: '',
        category: '',
        agent: user.user ? user.user.CodAg : ''
    }
    const dispatch = useDispatch()
    const [showSwitchClient, setShowSwitchClient] = useState(false)
    const [switchedClient, setSwitchedClient] = useState(false)

    useEffect(() => {
        console.log('qui')
        setSwitchedClient(false)
    }, [])

    const handleCreatePreventivo = async () => {
        dispatch(setIsLoading(true))
        try {
            if (order.currentOffer && user.lastProduct) {
                const seriesFound = order.currentOffer?.Preventivi.find((p) => p.ItemsList[0].CodiceSerie == user.lastProduct?.CodSerie)
                if (seriesFound) {
                    dispatch(
                        setNewOrderFromUser({
                            IdRecord: '',
                            CodiceOfferta: order.currentOffer.CodiceOfferta,
                            CodicePreventivo: seriesFound.CodicePreventivo,
                            IdContattoTemporaneo: order.currentOffer.Contatto[0].ID
                        })
                    )
                    await handleSetAdditionalDatas(order.currentOffer.CodiceOfferta)
                } else {
                    const newOrder = await addQuoteToOffer(
                        parseInt(order.currentOffer.Contatto[0].idContatto),
                        user.lastProduct?.CodSerie,
                        order.currentOffer.CodiceOfferta,
                        dispatch
                    )
                    dispatch(setNewOrderFromUser(newOrder))
                    await handleSetAdditionalDatas(newOrder.CodiceOfferta)
                }
                dispatch(setIsLoading(false))
                props.nextPhase()
                return
            }
            let body
            if (order.idUserKnown) {
                body = {
                    idContatto: parseInt(order.idUserKnown),
                    CodSerie: props.serieSelected.CodSerie,
                    codOfferta: null
                }
            } else {
                body = {
                    idContatto: null,
                    CodSerie: props.serieSelected.CodSerie,
                    nominativo: props.currentClientOffer.customer.name,
                    cf: null,
                    piva: null,
                    email: props.currentClientOffer.customer.email,
                    idTipoPagamento: null,
                    stato: 'Italia',
                    provincia: props.currentClientOffer.customer.province,
                    cap: props.currentClientOffer.customer.city,
                    indirizzo: props.currentClientOffer.customer.address,
                    zona: null,
                    codAg: props.currentClientOffer.customer.agent,
                    sdi: null,
                    titoloOfferta: props.currentClientOffer.offerTitle,
                    cellulare: props.currentClientOffer.customer.number
                }
            }
            body = {
                ...body,
                titoloOfferta: props.currentClientOffer.offerTitle,
                cantiereInstallazione: {
                    descrizione: props.currentClientOffer.worksite.description,
                    indirizzo: props.currentClientOffer.worksite.address,
                    provincia: props.currentClientOffer.worksite.province,
                    citta: props.currentClientOffer.worksite.city
                }
            }
            const offerReturn = await createQuoteFromCustomer(body, dispatch)
            dispatch(setNewOrderFromUser(offerReturn))
            dispatch(setIdOfferPending(offerReturn.CodiceOfferta))
            dispatch(setOfferData(props.currentClientOffer))
            await handleSetAdditionalDatas(offerReturn.CodiceOfferta)
            props.nextPhase()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handleSwitchClient = async () => {
        if (order.currentOffer) {
            dispatch(setIsLoading(true))
            try {
                await handleSetAdditionalDatas(order.currentOffer?.CodiceOfferta)
                setSwitchedClient(false)
                props.nextPhase()
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    const handleSetAdditionalDatas = async (offerCode: string) => {
        const cityFound = data.cities.find((c) => c.IDComune == props.currentClientOffer.worksite.city)
        const bodyAdditional = {
            CodiceOfferta: offerCode,
            Descrizione: props.currentClientOffer.offerTitle,
            CantiereInstallazione: props.currentClientOffer.worksite.description,
            IndirizzoCantiere: props.currentClientOffer.worksite.address,
            ProvinciaCantiere: props.currentClientOffer.worksite.province,
            ComuneCantiere: cityFound ? cityFound.Comune : '',
            CAPCantiere: cityFound ? cityFound.CAP : '',
            TestoIniziale: '',
            TestoFinale: '',
            DataConsegna: moment().format('YYYY-MM-DD')
        }
        await setAdditionalDatasOffer(bodyAdditional, dispatch)
    }

    const currentAgent = () => {
        if (props.currentClientOffer.customer.agent) {
            const foundAgent = data.agents.find((a) => a.CodAg == props.currentClientOffer.customer.agent)
            if (foundAgent) {
                return foundAgent.DenAg
            } else {
                return props.currentClientOffer.customer.agent
            }
        } else {
            if (user.user) {
                return user.user.DenAg
            } else {
                return ''
            }
        }
    }

    const clienteValue = () => {
        if (order.idUserKnown) {
            const foundCliente = data.customers.find((c) => c.idContatto == order.idUserKnown)
            return {
                id: order.idUserKnown,
                value: order.idUserKnown,
                label: foundCliente ? foundCliente.nominativo : ''
            }
        } else {
            return null
        }
    }

    const provinciaValue = () => {
        if (props.currentClientOffer.customer.province) {
            const provinceFound = data.provinces.find((p) => p.Sigla == props.currentClientOffer.customer.province)
            return {
                id: props.currentClientOffer.customer.province,
                value: props.currentClientOffer.customer.province,
                label: provinceFound ? provinceFound.Provincia : ''
            }
        } else {
            return null
        }
    }

    const provinciaWorksiteValue = () => {
        if (props.currentClientOffer.worksite.province) {
            const provinceFound = data.provinces.find((p) => p.Sigla == props.currentClientOffer.worksite.province)
            return {
                id: props.currentClientOffer.customer.province,
                value: props.currentClientOffer.customer.province,
                label: provinceFound ? provinceFound.Provincia : ''
            }
        } else {
            return null
        }
    }

    const comuneValue = () => {
        if (props.currentClientOffer.customer.city) {
            const cityFound = data.cities.find((c) => c.IDComune == props.currentClientOffer.customer.city)
            return {
                id: cityFound ? cityFound.CAP : '',
                value: props.currentClientOffer.customer.city,
                label: cityFound ? cityFound.Comune : ''
            }
        } else {
            return null
        }
    }

    const comuneWorksiteValue = () => {
        if (props.currentClientOffer.worksite.city) {
            const cityFound = data.cities.find((c) => c.IDComune == props.currentClientOffer.worksite.city)
            return {
                id: cityFound ? cityFound.CAP : '',
                value: props.currentClientOffer.worksite.city,
                label: cityFound ? cityFound.Comune : ''
            }
        } else {
            return null
        }
    }

    return (
        <div className="formCreateOffer">
            <Input
                label="Titolo offerta"
                value={props.currentClientOffer.offerTitle}
                onChange={(e) => {
                    props.setCurrentClientOffer({
                        ...props.currentClientOffer,
                        offerTitle: e
                    })
                }}
                divStyle={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}
                placeholder="Descrizione offerta"
            />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'flex-end', gap: 20 }}>
                <div style={{ flex: 2 }}>
                    <SelectInput
                        disabled={order.currentOffer !== null}
                        value={clienteValue()}
                        options={customersPerSelect(data.customers)}
                        onChange={(e) => {
                            if (!e) {
                                dispatch(setIdUserKnown(''))
                                props.setCurrentClientOffer({
                                    ...props.currentClientOffer,
                                    customer: voidCustomer
                                })
                                return
                            }
                            const val = e as Option
                            const customerFound = data.customers.find((c) => c.idContatto == val.id)
                            dispatch(setIdUserKnown(val.id as string))
                            if (customerFound) {
                                props.setCurrentClientOffer({
                                    ...props.currentClientOffer,
                                    customer: {
                                        name: customerFound.nominativo,
                                        address: customerFound.indirizzo,
                                        province: customerFound.provincia,
                                        city: customerFound.cap,
                                        email: customerFound.email,
                                        number: customerFound.cellulare,
                                        category: customerFound.idCategoria,
                                        agent: customerFound.CodAg
                                    }
                                })
                            }
                        }}
                        placeholder="Elenco clienti..."
                        label="Seleziona un cliente dall'elenco (se esistente)..."
                    />
                </div>
                {order.idUserKnown ? (
                    <div style={{ flex: 1 }}>
                        <Button
                            text="Cambia cliente"
                            hasIcon={true}
                            icon={faRetweet}
                            opacity={order.idUserKnown ? 1 : 0.5}
                            onClick={() => {
                                setShowSwitchClient(true)
                            }}
                        />
                    </div>
                ) : null}
            </div>

            <label style={{ marginTop: 15 }}>Dati cliente</label>
            <div className="gridInputOffer">
                <Input
                    value={props.currentClientOffer.customer.name}
                    onChange={(e) => {
                        props.setCurrentClientOffer({
                            ...props.currentClientOffer,
                            customer: {
                                ...props.currentClientOffer.customer,
                                name: e
                            }
                        })
                    }}
                    placeholder="Nome e cognome / Ragione sociale*"
                    style={{ width: 'calc(100% - 40px)' }}
                    disabled={order.idUserKnown !== ''}
                />
                <Input
                    value={props.currentClientOffer.customer.address}
                    onChange={(e) => {
                        props.setCurrentClientOffer({
                            ...props.currentClientOffer,
                            customer: {
                                ...props.currentClientOffer.customer,
                                address: e
                            }
                        })
                    }}
                    placeholder="Indirizzo"
                    style={{ width: 'calc(100% - 40px)' }}
                    disabled={order.idUserKnown !== ''}
                />
                <SelectInput
                    placeholder="Provincia"
                    options={provincePerSelect(data.provinces)}
                    onChange={(e) => {
                        const val = e as Option
                        if (e) {
                            props.setCurrentClientOffer({
                                ...props.currentClientOffer,
                                customer: {
                                    ...props.currentClientOffer.customer,
                                    province: val.id as string
                                }
                            })
                        }
                    }}
                    value={provinciaValue()}
                    disabled={order.idUserKnown !== ''}
                />
                <SelectInput
                    placeholder="Comune"
                    options={citiesPerSelect(
                        props.currentClientOffer.customer.province !== ''
                            ? data.cities.filter((c) => c.Sigla == props.currentClientOffer.customer.province)
                            : data.cities
                    )}
                    onChange={(e) => {
                        const val = e as Option
                        if (e) {
                            if (e) {
                                props.setCurrentClientOffer({
                                    ...props.currentClientOffer,
                                    customer: {
                                        ...props.currentClientOffer.customer,
                                        city: val.value as string
                                    }
                                })
                            }
                        }
                    }}
                    value={comuneValue()}
                    disabled={order.idUserKnown !== ''}
                />
                <Input
                    value={props.currentClientOffer.customer.email}
                    type="email"
                    onChange={(e) => {
                        props.setCurrentClientOffer({
                            ...props.currentClientOffer,
                            customer: {
                                ...props.currentClientOffer.customer,
                                email: e
                            }
                        })
                    }}
                    placeholder="Indirizzo email*"
                    style={{ width: 'calc(100% - 40px)' }}
                    disabled={order.idUserKnown !== ''}
                />
                <Input
                    value={props.currentClientOffer.customer.number}
                    type="tel"
                    onChange={(e) => {
                        props.setCurrentClientOffer({
                            ...props.currentClientOffer,
                            customer: {
                                ...props.currentClientOffer.customer,
                                number: e
                            }
                        })
                    }}
                    placeholder="Telefono*"
                    style={{ width: 'calc(100% - 40px)' }}
                    disabled={order.idUserKnown !== ''}
                />
                <Input
                    placeholder="Categoria sconto"
                    value={
                        order.currentCustomerSale
                            ? `${order.currentCustomerSale.categoria} (${order.currentCustomerSale.predefinito}% + ${order.currentCustomerSale.aggiuntivo}%)`
                            : ''
                    }
                    onChange={() => {
                        return
                    }}
                    disabled={true}
                    style={{ width: 'calc(100% - 40px)' }}
                />
                <Input
                    placeholder="Agente"
                    value={currentAgent()}
                    onChange={() => {
                        return
                    }}
                    disabled={true}
                    style={{ width: 'calc(100% - 40px)' }}
                />
            </div>
            <label style={{ marginTop: 30 }}>Cantiere di installazione</label>
            <div className="gridInputOffer">
                <Input
                    placeholder="Descrizione"
                    value={props.currentClientOffer.worksite.description}
                    onChange={(e) => {
                        props.setCurrentClientOffer({
                            ...props.currentClientOffer,
                            worksite: {
                                ...props.currentClientOffer.worksite,
                                description: e
                            }
                        })
                    }}
                    style={{ width: 'calc(100% - 40px)' }}
                />
                <Input
                    placeholder="Indirizzo"
                    value={props.currentClientOffer.worksite.address}
                    onChange={(e) => {
                        props.setCurrentClientOffer({
                            ...props.currentClientOffer,
                            worksite: {
                                ...props.currentClientOffer.worksite,
                                address: e
                            }
                        })
                    }}
                    style={{ width: 'calc(100% - 40px)' }}
                />
                <SelectInput
                    placeholder="Provincia"
                    options={provincePerSelect(data.provinces)}
                    onChange={(e) => {
                        const val = e as Option
                        if (e) {
                            props.setCurrentClientOffer({
                                ...props.currentClientOffer,
                                worksite: {
                                    ...props.currentClientOffer.worksite,
                                    province: val.id as string
                                }
                            })
                        }
                    }}
                    value={provinciaWorksiteValue()}
                />
                <SelectInput
                    placeholder="Comune"
                    options={citiesPerSelect(
                        props.currentClientOffer.worksite.province !== ''
                            ? data.cities.filter((c) => c.Sigla == props.currentClientOffer.worksite.province)
                            : data.cities
                    )}
                    onChange={(e) => {
                        const val = e as Option
                        if (e) {
                            props.setCurrentClientOffer({
                                ...props.currentClientOffer,
                                worksite: {
                                    ...props.currentClientOffer.worksite,
                                    city: val.value as string
                                }
                            })
                        }
                    }}
                    value={comuneWorksiteValue()}
                />
            </div>
            <div className="nextPhaseButtonBar">
                <Button
                    text="Prossimo step"
                    hasIcon={true}
                    icon={faArrowRight}
                    opacity={
                        switchedClient ||
                        (props.currentClientOffer.customer.name &&
                            validateEmail(props.currentClientOffer.customer.email) &&
                            props.currentClientOffer.customer.number)
                            ? 1
                            : 0.5
                    }
                    onClick={() => {
                        if (switchedClient) {
                            handleSwitchClient()
                            return
                        }
                        if (
                            props.currentClientOffer.customer.name &&
                            validateEmail(props.currentClientOffer.customer.email) &&
                            props.currentClientOffer.customer.number
                        ) {
                            handleCreatePreventivo()
                        }
                    }}
                />
            </div>
            {order.currentOffer ? (
                <SwitchCustomerModal
                    isVisible={showSwitchClient}
                    hideModal={() => setShowSwitchClient(false)}
                    codPreventivo={order.currentOffer?.CodiceOfferta}
                    codCurrentCliente={order.idUserKnown}
                    confirmChange={() => {
                        setShowSwitchClient(false)
                        setSwitchedClient(true)
                    }}
                />
            ) : null}
        </div>
    )
}

export default CreaOffertaView
