import CustomAxios from '../../customCompontents/CustomAxios'
import { logoutUser } from '../../store/actions/user'
import { BASE_URL, TOKEN_ERROR_STRING } from '../constants'
import { headersJson } from './requests'

export interface FilterStats {
    Tipo: string
    DataStart: string | null
    DataEnd: string | null
    CodAg: string | null
    CodListino: string | null
    CodiceSerie: string | null
    CodAz: number | null
    Andamento?: string
    DBSourceName: string | null
}

export const getStats = async (body: FilterStats, dispatch: (action: any) => void) => {
    const filter = {
        filter: { ...body }
    }
    const data = await CustomAxios.post(BASE_URL + 'bmapi/statistiche', filter, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data) {
            return data.data
        }
    })
    return data
}

export const getStatsAndamento = async (body: FilterStats, dispatch: (action: any) => void) => {
    const filter = {
        filter: { ...body }
    }
    const data = await CustomAxios.post(BASE_URL + 'bmapi/statistiche/Andamento', filter, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        }
    })
    return data
}

export const getStatsFatturatoAziende = async (body: FilterStats, dispatch: (action: any) => void) => {
    const filter = {
        filter: { ...body, CodAz: null, Andamento: 'F', CodAg: null, CodiceSerie: null }
    }
    const data = await CustomAxios.post(BASE_URL + 'bmapi/statistiche/Andamento', filter, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        }
    })
    return data
}
