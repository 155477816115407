/* WL Aggiunta 16 Dicembre 2022 
  PAGINA elenco Commesse
*/
import React, { useEffect, useMemo, useState } from 'react'
import TitlePage from '../../components/TitlePage'
import { useDispatch, useSelector } from 'react-redux'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { faFile, faSearch } from '@fortawesome/free-solid-svg-icons'
import { ReducerUser, Reducers, ReducerGraphic } from '../../types/reducers'
import Button from '../../components/Button'
import { deleteDoc, getDocs, getCommesseDocs, SendNewMessageEmailCommessa } from '../../resources/requests/others'
import { Doc, DocCommesse } from '../../types/others'
import MaterialTable from 'material-table'
import moment from 'moment'
import { locale_itIT } from '../../utilities/tableLocale'
import DeleteModal from '../../components/modals/DeleteModal'
import AddDocModal from '../../components/modals/AddDocModal'
import Input from '../../components/formComponents/Input'

const DocumentsCommesse : React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [documents, setDocuments] = useState<DocCommesse[]>([])
    const [search, setSearch] = useState('')
    const dispatch = useDispatch()

/*
    const voidNewMessageEmail = {  
        EmailAddress: '',
        AddressName: '',
        EmailAddressSecond: '',
        EmailAddressCC: '',
        EmailAddressBCC: '',
        Subject: '',
        Body: '',
        AltBody: '',
        Attachments0: '',
        Attachments1: '',
        ReplyMe: false

    }
    const [newMessageEmail, setnewMessageEmail] = useState(voidNewMessageEmail)
*/
const handleSendMessageEmail = async (rowData: DocCommesse) => {
    
        dispatch(setIsLoading(true))
        try {
             await SendNewMessageEmailCommessa(rowData, dispatch)
            //rowData.Commessa
            //rowData.DataIng
            //rowData.Operatore
            //rowData.Stato
            //newMessageEmail.AddressName

            //await SendNewMessageEmail(newMessageEmail, dispatch)
            
            //dispatch(setMessagesToAccept())
           
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    
}

    const fetchDocuments = async () => {
        dispatch(setIsLoading(true))
        try {
            const docs = await getCommesseDocs(dispatch)
            setDocuments(docs)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    useEffect(() => {
        if (user.user) {
            fetchDocuments()
        }
    }, [user.user])

    const renderColumns = useMemo(() => {
        const columns = [
            {
                title: 'Commessa',
                field: 'Commessa',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.Commessa}</p>
                }
            },
            {
                title: 'DataIng',
                field: 'DataIng',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.DataIng}</p>
                }
            },
            {
                title: 'Operatore',
                field: 'Operatore',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.Operatore}</p>
                }
            },
            {
                title: 'Stato',
                field: 'Stato',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.Stato}</p>
                }
            },
            {
                title: 'DataConf',
                field: 'DataConf',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.DataConf}</p>
                }
            },
            {
                title: 'Agente',
                field: 'Agente',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.Agente}</p>
                }
            },
            {
                title: 'Cliente',
                field: 'Cliente',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.Cliente}</p>
                }
            },
            {
                title: 'Riferimento',
                field: 'Riferimento',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.Riferimento}</p>
                }
            },
            {
                title: 'Serie',
                field: 'Serie',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.Serie}</p>
                }
            },
            {
                title: 'ColEx',
                field: 'ColEx',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.ColEx}</p>
                }
            },
            {
                title: 'ColInt',
                field: 'ColInt',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.ColInt}</p>
                }
            },
            {
                title: 'Pezzi',
                field: 'Pezzi',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.Pezzi}</p>
                }
            },
            {
                title: 'Imponibile',
                field: 'Imponibile',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.Imponibile}</p>
                }
            },
            {
                title: 'DataRiSpe',
                field: 'DataRiSpe',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.DataRiSpe}</p>
                }
            },
            {
                title: 'DatPrePianificazione',
                field: 'DatPrePianificazione',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.DatPrePianificazione}</p>
                }
            }
        ]
        if (user.user?.TipoAccount == 'Amministratore' || user.user?.TipoAccount == 'Supervisore') {
            columns.push({
                title: 'Codice azienda',
                field: 'CodAz',
                render: (rowData: DocCommesse) => {
                    return <p>{rowData.CodAz}</p>
                }
            })
        }
        return columns
    }, [])

    const renderDatas = useMemo(() => {
        if (search) {
            return documents.filter(
                (d) => d.Commessa.toLowerCase().includes(search.toLowerCase()) || 
                       d.Riferimento.toLowerCase().includes(search.toLowerCase())|| 
                       d.Operatore.toLowerCase().includes(search.toLowerCase())
                       
            )
        }
        return documents
    }, [documents, search])

    const renderActions = useMemo(() => {
        const actions = 
            [
            (rowData: DocCommesse) => ({
                icon: 'download',
                iconProps: {
                    fontSize: 'medium'
                },
                tooltip: 'Invia email',
                onClick: () => {
                   handleSendMessageEmail(rowData)
                }
            })]
            
    

        return actions
    }, [])

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <TitlePage text="Stato Commesse" />
                <Input
                    icon={faSearch}
                    isFilterInput
                    placeholder="Ricerca documento"
                    value={search}
                    onChange={(e) => {
                        if (e) {
                            setSearch(e)
                        } else {
                            setSearch('')
                        }
                    }}
                />
            </div>
            <MaterialTable
                title=""
                columns={renderColumns}
                data={renderDatas}
                localization={locale_itIT}
                options={{
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 50],
                    search: false,
                    headerStyle: {
                        backgroundColor: '#ebebeb',
                        borderRadius: 20
                    }
                }}
                //@ts-ignore
               // actions={renderActions}
            />
        </div>
    )
}

export default DocumentsCommesse
