import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'
import { useDispatch } from 'react-redux'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { getOrderStatusList, setStatusOrder } from '../../resources/requests/requests'
import Select from 'react-select'
import { setOrdersRedux } from '../../store/actions/thunk_actions'

interface Props {
    isVisible: boolean
    hideModal: () => void
    codOffer: string
    selectedStatus: string
}

const StatusOrderChangeModal: React.FC<Props> = (props) => {
    const [listStatus, setListStatus] = useState<string[]>([])
    const [selectedStatus, setSelectedStatus] = useState(props.selectedStatus)
    const dispatch = useDispatch()

    const fetchListStatus = async () => {
        dispatch(setIsLoading(true))
        try {
            const list = await getOrderStatusList(dispatch)
            setListStatus(list)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    useEffect(() => {
        if (props.isVisible) {
            setSelectedStatus(props.selectedStatus)
            fetchListStatus()
        }
    }, [props.isVisible])

    const handleUpdateStatus = async () => {
        dispatch(setIsLoading(true))
        try {
            await setStatusOrder(props.codOffer, selectedStatus, dispatch)
            await dispatch(setOrdersRedux())
            props.hideModal()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            className="overflowVisible"
            maxWidth="md"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <TitlePage text={'Cambia stato ordine'} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', marginBottom: 20 }}>Seleziona lo status dell{"'"}ordine da applicare.</p>
                <Select
                    value={selectedStatus ? { id: selectedStatus, value: selectedStatus, label: selectedStatus } : null}
                    options={listStatus.map((s) => {
                        return { id: s, value: s, label: s }
                    })}
                    placeholder="Seleziona spesa"
                    onChange={(e) => {
                        if (e) {
                            setSelectedStatus(e.id)
                        }
                    }}
                    styles={{
                        valueContainer: (provided) => ({
                            ...provided,
                            backgroundColor: '#f4f4f4'
                        }),
                        container: (provided) => ({
                            ...provided,
                            backgroundColor: '#f4f4f4',
                            borderRadius: 7,
                            width: '100%'
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            fontSize: 18
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            fontSize: 18
                        }),
                        control: (provided) => ({
                            ...provided,
                            backgroundColor: '#f4f4f4',
                            border: 0,
                            padding: '5px 10px',
                            borderRadius: 7
                        })
                    }}
                />
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Annulla"
                    buttonColor="#52565A"
                    onClick={() => {
                        props.hideModal()
                    }}
                />
                <Button
                    isSmall
                    text="Salva"
                    onClick={() => {
                        handleUpdateStatus()
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default StatusOrderChangeModal
