import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faTimes } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'
import { DefaultDataTypology, ItemInQuote, ItemToEdit, VariantItem } from '../../types/order'
import { ReducerData, ReducerOrder, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { calculateTotalPrices, editItemPreventivo, getSingleItemQuote, recalculateItems } from '../../resources/requests/requests'
import { setItemsInQuote, setPricesTotalOffer } from '../../store/actions/order'

interface Props {
    isVisible: boolean
    itemInQuote: ItemInQuote | undefined
    hideModal: () => void
}

const OtherInfoWindowModal: React.FC<Props> = (props) => {
    const [currentItem, setCurrentItem] = useState(props.itemInQuote)
    const [shapesAvailable, setShapeAvailable] = useState<VariantItem[]>([])
    const [defaultData, setDefaultData] = useState<DefaultDataTypology>()
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [hasChanged, setHasChanged] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        setCurrentItem(props.itemInQuote)
    }, [props.isVisible])

    const handleSave = async () => {
        if (currentItem) {
            dispatch(setIsLoading(true))
            try {
                if (JSON.stringify(props.itemInQuote) !== JSON.stringify(currentItem)) {
                    console.log(currentItem.DettaglioItem.Ordinamento)
                    const body: ItemToEdit = {
                        idRecord: currentItem.idRecord,
                        CodicePreventivo: order.newQuoteFromUser?.CodicePreventivo,
                        CodiceSerie: currentItem.DettaglioItem.CodiceSerie,
                        CodTipoItem: currentItem.DettaglioItem.CodTipoItem,
                        Htip: currentItem.DettaglioItem.Htip,
                        Ltip: currentItem.DettaglioItem.Ltip,
                        Quantita: currentItem.DettaglioItem.Quantita,
                        Etichetta: currentItem.DettaglioItem.Etichetta,
                        Ordinamento: currentItem.DettaglioItem.Ordinamento,
                        HManiglia: currentItem.DettaglioItem.HManiglia ? currentItem.DettaglioItem.HManiglia : null,
                        HTraverso1: currentItem.DettaglioItem?.HTraverso1 ? currentItem.DettaglioItem.HTraverso1 : null,
                        HTraverso2: currentItem.DettaglioItem?.HTraverso2 ? currentItem.DettaglioItem.HTraverso2 : null,
                        HTraverso3: currentItem.DettaglioItem?.HTraverso3 ? currentItem.DettaglioItem.HTraverso3 : null,
                        HTraverso4: currentItem.DettaglioItem?.HTraverso4 ? currentItem.DettaglioItem.HTraverso4 : null,
                        PRM: currentItem.DettaglioItem.PRM ? currentItem.DettaglioItem.PRM : null,
                        Prezzo: currentItem.DettaglioItem.Prezzo.toString(),
                        Sconto: currentItem.DettaglioItem.Sconto.toString(),
                        Annotazioniutente: currentItem.DettaglioItem.Annotazioniutente
                    }
                    await editItemPreventivo(body, dispatch)
                    await recalculateItems(
                        order.newQuoteFromUser?.CodicePreventivo,
                        order.newQuoteFromUser?.CodiceOfferta,
                        [{ idItem: currentItem.idRecord }],
                        dispatch
                    )
                    const updatedItem = await getSingleItemQuote(currentItem.idRecord, order.newQuoteFromUser?.CodicePreventivo, dispatch)
                    dispatch(
                        setItemsInQuote(
                            order.itemsInQuote.map((iq) => {
                                const findItem = iq.idRecord == currentItem.idRecord
                                if (findItem) {
                                    return {
                                        ...iq,
                                        DettaglioItem: updatedItem
                                    }
                                } else {
                                    return iq
                                }
                            })
                        )
                    )
                    const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
                    const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
                    dispatch(setPricesTotalOffer(prices))
                    props.hideModal()
                }
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    if (currentItem && props.itemInQuote) {
        return (
            <Dialog
                open={props.isVisible}
                fullWidth={true}
                aria-labelledby="form-dialog-title"
                maxWidth="lg"
                className="overflowVisible"
                onBackdropClick={() => props.hideModal()}
            >
                <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                    <FontAwesomeIcon
                        icon={faTimes}
                        style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                        onClick={() => props.hideModal()}
                    />
                    <TitlePage text={props.itemInQuote.DettaglioItem.DescrizioneInterneDB} style={{ margin: 0 }} />
                    <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Dettagli geometria</p>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 20, margin: '20px 0' }}>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                            <img
                                src={currentItem.DettaglioItem.FiguraRiferimento}
                                style={{ width: '100%', height: '40vh', backgroundColor: '#fff', objectFit: 'contain', borderRadius: 10, padding: 5 }}
                            />
                        </div>
                        <div style={{ flex: 2.4, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end', gap: 10 }}>
                            <div
                                style={{
                                    backgroundColor: '#fff',
                                    borderRadius: 10,
                                    padding: 15,
                                    flex: 1,
                                    gap: 10,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: 'calc(100% - 30px)'
                                }}
                            >
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 20 }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label>Etichetta</label>
                                        <input
                                            type="text"
                                            disabled={true}
                                            value={currentItem.DettaglioItem.Etichetta}
                                            placeholder="Etichetta"
                                            style={{
                                                flex: 4
                                            }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label>Peso</label>
                                        <input
                                            type="text"
                                            disabled={true}
                                            value={currentItem.DettaglioItem.Peso}
                                            placeholder="Peso"
                                            style={{
                                                flex: 4
                                            }}
                                        />
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label>Trasmittanza</label>
                                        <input
                                            type="text"
                                            disabled={true}
                                            value={currentItem.DettaglioItem.Trasmittanza}
                                            placeholder="Peso"
                                            style={{
                                                flex: 4
                                            }}
                                        />
                                    </div>
                                    {/* <input
                                    type="number"
                                    value={currentItem.DettaglioItem.Ordinamento}
                                    placeholder="N° Ord."
                                    style={{
                                        flex: 0.5
                                    }}
                                    onChange={(e) => {
                                        setCurrentItem({
                                            ...currentItem,
                                            DettaglioItem: {
                                                ...currentItem.DettaglioItem,
                                                Ordinamento: e.target.value
                                            }
                                        })
                                    }}
                                /> */}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 20, marginTop: 20 }}>
                                    <div className="specInput">
                                        <span>L (mm.)</span>
                                        <input value={currentItem.DettaglioItem.Ltip} type="number" disabled={true} />
                                    </div>
                                    <div className="specInput">
                                        <span>H (mm.)</span>
                                        <input value={currentItem.DettaglioItem.Htip} type="number" disabled={true} />
                                    </div>
                                    {currentItem.DettaglioItem.HManiglia && (
                                        <div className="specInput">
                                            <span>HMan.</span>
                                            <input
                                                defaultValue={currentItem.DettaglioItem.HManiglia}
                                                value={currentItem.DettaglioItem.HManiglia ? currentItem.DettaglioItem.HManiglia : 0}
                                                type="number"
                                                onChange={(e) => {
                                                    setCurrentItem({
                                                        ...currentItem,
                                                        DettaglioItem: {
                                                            ...currentItem.DettaglioItem,
                                                            HManiglia: e.target.value ? e.target.value : '0'
                                                        }
                                                    })
                                                    setHasChanged(true)
                                                }}
                                            />
                                        </div>
                                    )}
                                    {currentItem.DettaglioItem.HTraverso1 && (
                                        <div className="specInput">
                                            <span>HTrav. 1</span>
                                            <input
                                                min={0}
                                                defaultValue={currentItem.DettaglioItem.HTraverso1}
                                                value={currentItem.DettaglioItem.HTraverso1 ? currentItem.DettaglioItem.HTraverso1 : 0}
                                                type="number"
                                                onChange={(e) => {
                                                    setCurrentItem({
                                                        ...currentItem,
                                                        DettaglioItem: {
                                                            ...currentItem.DettaglioItem,
                                                            HTraverso1: e.target.value ? e.target.value : '0'
                                                        }
                                                    })
                                                    setHasChanged(true)
                                                }}
                                            />
                                        </div>
                                    )}
                                    {currentItem.DettaglioItem.HTraverso2 && (
                                        <div className="specInput">
                                            <span>HTrav. 2</span>
                                            <input
                                                min={0}
                                                defaultValue={currentItem.DettaglioItem.HTraverso2}
                                                value={currentItem.DettaglioItem.HTraverso2 ? currentItem.DettaglioItem.HTraverso2 : 0}
                                                type="number"
                                                onChange={(e) => {
                                                    setCurrentItem({
                                                        ...currentItem,
                                                        DettaglioItem: {
                                                            ...currentItem.DettaglioItem,
                                                            HTraverso2: e.target.value ? e.target.value : '0'
                                                        }
                                                    })
                                                    setHasChanged(true)
                                                }}
                                            />
                                        </div>
                                    )}
                                    {currentItem.DettaglioItem.HTraverso3 && (
                                        <div className="specInput">
                                            <span>HTrav. 3</span>
                                            <input
                                                min={0}
                                                defaultValue={currentItem.DettaglioItem.HTraverso3}
                                                value={currentItem.DettaglioItem.HTraverso3 ? currentItem.DettaglioItem.HTraverso3 : 0}
                                                type="number"
                                                onChange={(e) => {
                                                    setCurrentItem({
                                                        ...currentItem,
                                                        DettaglioItem: {
                                                            ...currentItem.DettaglioItem,
                                                            HTraverso3: e.target.value ? e.target.value : '0'
                                                        }
                                                    })
                                                    setHasChanged(true)
                                                }}
                                            />
                                        </div>
                                    )}
                                    {currentItem.DettaglioItem.HTraverso4 && (
                                        <div className="specInput">
                                            <span>HTrav. 4</span>
                                            <input
                                                min={0}
                                                defaultValue={currentItem.DettaglioItem.HTraverso4}
                                                value={currentItem.DettaglioItem.HTraverso4 ? currentItem.DettaglioItem.HTraverso4 : 0}
                                                type="number"
                                                onChange={(e) => {
                                                    setCurrentItem({
                                                        ...currentItem,
                                                        DettaglioItem: {
                                                            ...currentItem.DettaglioItem,
                                                            HTraverso4: e.target.value ? e.target.value : '0'
                                                        }
                                                    })
                                                    setHasChanged(true)
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: '100%' }}>
                        <Button
                            isSmall
                            text="Salva"
                            hasIcon={true}
                            icon={faCopy}
                            onClick={() => {
                                handleSave()
                            }}
                        />
                    </div>
                </DialogContent>
            </Dialog>
        )
    }

    return null
}

export default OtherInfoWindowModal
