import { NewCustomAxios } from './../../customCompontents/CustomAxios'
import { CurrentOffer } from './../../types/order'
import {
    setAccountGroups,
    setAccounts,
    setAdditionalCosts,
    setAgents,
    setCategories,
    setCities,
    setCompanies,
    setCustomers,
    setLevelsAgent,
    setMessages,
    setMessagesToRead,
    setOffersArchive,
    setOrdersArchive,
    setProvinces,
    setSaleCategories,
    setSeries,
    setTaxes
} from './data'
import { logoutUser, setCompany, setToken, setUser } from './user'
import {
    deleteAllQuotes,
    getAccountGroups,
    getAccounts,
    getAgents,
    getCategories,
    getCities,
    getCompaniesList,
    getCustomers,
    getLevelAgent,
    getListino,
    getMyData,
    getOfferData,
    getOffers,
    getOrders,
    getPendingOffers,
    getProvinces,
    getSaleCategories,
    getSeries,
    getTaxes,
    getToken,
    getTotalPricesOffer,
    getUserOnline,
    tokenAccessDev,
    tokenAccessProd,
    viewDatiAzienda,
    getResetPasswordRequest
} from '../../resources/requests/requests'
import { getMessages, getMessagesToAccept } from '../../resources/requests/others'
import CustomAxios from '../../customCompontents/CustomAxios'
import { Cliente, Comune, Provincia } from '../../types/data'
import { Message } from '../../types/others'
import { sortArrayPerKey } from '../../utilities/functions'
import { setCurrentOffer, setIsPendingOffer, setPricesTotalOffer } from './order'
import { Credentials, Listino } from '../../types/user'
import { setCurrentUserOnline, setError, setIsLoading, setListino } from './graphic'
import { useHistory } from 'react-router-dom'


//WL Add
export const resetPasswordRequest: any = (username: string, email: string) => async (dispatch: (any: any) => void) => {
    //const request =
     await getResetPasswordRequest(username, email)
   /* dispatch(setCurrentOffer(request))

    if (!request) {
        //await deleteAllQuotes(dispatch)
        //dispatch(setIsPendingOffer(false))
    }*/
}
//

export const getUserToken: any = (credentials: Credentials) => async (dispatch: (any: any) => void) => {
    const token: any = await getToken(credentials)
    console.log(token)
    if (token) {
        dispatch(setTokenRedux(token))
    }
}

export const setTokenRedux: any = (userToken: string) => async (dispatch: (any: any) => void) => {
    dispatch(setToken(userToken))
    CustomAxios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`
    NewCustomAxios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`
}

export const setCategoriesRedux: any = () => async (dispatch: (any: any) => void) => {
    const categories: any = await getCategories(dispatch)
    dispatch(setCategories(categories))
}

export const setSeriesRedux: any = () => async (dispatch: (any: any) => void) => {
    const series: any = await getSeries(dispatch)
    dispatch(setSeries(series))
}

export const setTaxesRedux: any = () => async (dispatch: (any: any) => void) => {
    const taxes = await getTaxes(dispatch)
    dispatch(setTaxes(taxes))
}

export const setProvincesRedux: any = () => async (dispatch: (any: any) => void) => {
    const provinces: Provincia[] = await getProvinces(dispatch)
    dispatch(setProvinces(sortArrayPerKey(provinces, 'Provincia')))
}

export const setCitiesRedux: any = () => async (dispatch: (any: any) => void) => {
    const cities: Comune[] = await getCities(dispatch)
    dispatch(setCities(sortArrayPerKey(cities, 'Comune')))
}

export const setCustomersRedux: any = () => async (dispatch: (any: any) => void) => {
    const customers: Cliente[] = await getCustomers(dispatch)
    dispatch(setCustomers(customers))
}

// export const setAdditionalCostsListRedux: any = () => async (dispatch: (any: any) => void) => {
//     const costs = await getAdditionalCostList()
//     dispatch(setAdditionalCosts(costs))
// }

export const setOfferLoadedRedux: any = (idOffer: string | undefined, notRecalc?: boolean) => async (dispatch: (any: any) => void) => {
    const offer: CurrentOffer = await getOfferData(idOffer, dispatch)
    dispatch(setCurrentOffer(offer))
    if (!notRecalc && offer) {
        dispatch(setPricesRedux(offer.CodiceOfferta))
    }
    if (!offer) {
        await deleteAllQuotes(dispatch)
        dispatch(setIsPendingOffer(false))
    }
}

export const setCurrentUserOnlineRedux: any = () => async (dispatch: (any: any) => void) => {
    const number: string = await getUserOnline(dispatch)
    dispatch(setCurrentUserOnline(number))
}

export const setListinoRedux: any = () => async (dispatch: (any: any) => void) => {
    const listino: Listino = await getListino(dispatch)
    dispatch(setListino(listino))
}

export const setPricesRedux: any = (codOfferta: string) => async (dispatch: (any: any) => void) => {
    const prices = await getTotalPricesOffer(codOfferta, dispatch)
    dispatch(setPricesTotalOffer(prices))
}

export const setOffersRedux: any = () => async (dispatch: (any: any) => void) => {
    const offers = await getOffers(dispatch)
    dispatch(setOffersArchive(offers))
}

export const setOrdersRedux: any = () => async (dispatch: (any: any) => void) => {
    const orders = await getOrders(dispatch)
    dispatch(setOrdersArchive(orders))
}

export const setOfferInProgress: any = () => async (dispatch: (any: any) => void) => {
    const pendingOffer = await getPendingOffers(dispatch)
    if (pendingOffer) {
        dispatch(setIsPendingOffer(true))
        dispatch(setOfferLoadedRedux(pendingOffer))
    } else {
        dispatch(setIsPendingOffer(false))
    }
}

export const setUserRedux: any = () => async (dispatch: (any: any) => void) => {
    const userData = await getMyData(dispatch)
    if (userData) {
        dispatch(setUser(userData))
        dispatch(setStartingData())
    } else {
        dispatch(logoutUser())
    }
}

export const setAccountsRedux: any = () => async (dispatch: (any: any) => void) => {
    const accounts = await getAccounts(dispatch)
    dispatch(setAccounts(accounts))
}

export const setAgentsRedux: any = () => async (dispatch: (any: any) => void) => {
    const agents = await getAgents(dispatch)
    dispatch(setAgents(agents))
}

export const setLevelsAgentRedux: any = () => async (dispatch: (any: any) => void) => {
    const levels = await getLevelAgent(dispatch)
    dispatch(setLevelsAgent(levels))
}

export const setAccountGroupsRedux: any = () => async (dispatch: (any: any) => void) => {
    const groups = await getAccountGroups(dispatch)
    dispatch(setAccountGroups(groups))
}

export const setMyCompany: any = () => async (dispatch: (any: any) => void) => {
    const company = await viewDatiAzienda(dispatch)
    dispatch(setCompany(company))
}

export const getCompaniesListRedux: any = () => async (dispatch: (any: any) => void) => {
    const companies = await getCompaniesList(dispatch)
    dispatch(setCompanies(companies))
}

export const setSaleCategoriesRedux: any = () => async (dispatch: (any: any) => void) => {
    const sales = await getSaleCategories(dispatch)
    dispatch(setSaleCategories(sales))
}

export const setMessagesToAccept: any = () => async (dispatch: (any: any) => void) => {
    const messagesToRead: Message[] = await getMessagesToAccept(dispatch)
    dispatch(setMessagesToRead(messagesToRead))
    if (messagesToRead.length == 0) {
        const messages = await getMessages(dispatch)
        dispatch(setMessages(messages))
    }
}

export const setStartingData: any = () => async (dispatch: (any: any) => void) => {
    dispatch(setIsLoading(true))
    try {
        await getAccountGroups(dispatch)
        await dispatch(setAgentsRedux())
        await dispatch(setAccountsRedux())
        await dispatch(setCategoriesRedux())
        await dispatch(setSeriesRedux())
        await dispatch(setTaxesRedux())
        await dispatch(setProvincesRedux())
        await dispatch(setCitiesRedux())
        await dispatch(setCustomersRedux())
        await dispatch(setOfferInProgress())
        await dispatch(setCurrentUserOnlineRedux())
        await dispatch(setListinoRedux())
        //await dispatch(setOffersRedux())
        //await dispatch(setOrdersRedux())
        await dispatch(setMessagesToAccept())
        //await dispatch(setAdditionalCostsListRedux())
        await dispatch(setLevelsAgentRedux())
        await dispatch(setAccountGroupsRedux())
        await dispatch(setMyCompany())
        await dispatch(getCompaniesListRedux())
        await dispatch(setSaleCategoriesRedux())
    } catch (error: any) {
        dispatch(setError(error.toString()))
    }
    dispatch(setIsLoading(false))
}
