import React, { useState } from 'react'
import './Sidebar.css'
import { ReducerData, ReducerGraphic, Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setSelectedCategory } from '../../store/actions/graphic'
import { Categoria } from '../../types/data'
import { BLUE_DEFAULT, LOGO_DEFAULT } from '../../resources/constants'

interface Props {
    onMobileClick?: () => void
}


const Sidebar: React.FC<Props> = (props) => {
    const dispatch = useDispatch()
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const location = useLocation()

    const singleItem = (menuItem: Categoria) => {
        const isSelected = graphic.categorySelected?.Nome == menuItem.Nome && location.pathname == '/products'
        return (
            <li className="nav-item" key={menuItem.IdCategorSerie}>
                <NavLink
                    to={'/products'}
                    //@ts-ignore
                    exact
                    className={
                        isSelected ?
                            ("main navigateLink isCurrent") :
                            ("main navigateLink")
                    }
                    onClick={() => {
                        dispatch(setSelectedCategory(menuItem))
                        if (props.onMobileClick) {
                            props.onMobileClick()
                        }
                    }}
                >
                    <span className="item">{menuItem.Nome}</span>
                </NavLink>
            </li>
        )
    }


    return (
        <div className="app-sidebar" style={{ backgroundColor: graphic.colorAccent }}>
            <div className="logo">
                <NavLink
                    to={'/'}
                    //@ts-ignore
                    exact
                    className="main navigateLink"
                    onClick={() => {
                        if (props.onMobileClick) {
                            props.onMobileClick()
                        }
                    }}
                >
                    <img src={user.company?.Logo && user.company.Logo !== '' ? user.company.Logo : LOGO_DEFAULT} />
                </NavLink>
                <div className="menuNav">
                    {data.categories.map((category) => { return (singleItem(category)) })}
                </div>
            </div>
        </div>
    )
}

export default Sidebar