import React, { useEffect, useState } from 'react'
import { ReducerGraphic, Reducers } from '../../../../types/reducers'
import { useSelector } from 'react-redux'
import { Serie } from '../../../../types/data'
import UploadCard from '../../../../components/UploadCard'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faCog } from '@fortawesome/free-solid-svg-icons'
import Button from '../../../../components/Button'
import { useDispatch } from 'react-redux'
import { setError, setIsLoading } from '../../../../store/actions/graphic'
import { editSerie, uploadImage } from '../../../../resources/requests/requests'
import { setSeriesRedux } from '../../../../store/actions/thunk_actions'
import { Ambito } from '../../../../utilities/Ambito'
import SubtitleSection from '../../../../components/SubtitleSection'
import Input from '../../../../components/formComponents/Input'
import TextArea from '../../../../components/formComponents/TextArea'

interface Props {
    selectedDetail: Serie
    onBack: () => void
}

const SeriesDetailScreen: React.FC<Props> = (props) => {
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [currentSerie, setCurrentSerie] = useState<Serie>(props.selectedDetail)
    const dispatch = useDispatch()

    useEffect(() => {
        setCurrentSerie(props.selectedDetail)
    }, [props.selectedDetail])

    const handleSave = async () => {
        dispatch(setIsLoading(true))
        try {
            let imgMain = currentSerie.ImgMain
            let img1 = props.selectedDetail.ImageGallery1
            let img2 = props.selectedDetail.ImageGallery2
            let img3 = props.selectedDetail.ImageGallery3
            let img4 = props.selectedDetail.ImageGallery4
            let img5 = props.selectedDetail.ImageGallery5
            let imgBanner = props.selectedDetail.ImgBanner
            if (currentSerie.ImageGallery1.name) {
                img1 = await uploadImage(currentSerie.ImageGallery1, Ambito.SERIE_GALLERY1, dispatch, currentSerie.CodSerie)
                if (currentSerie.ImageGallery1 == currentSerie.ImgMain) {
                    imgMain = img1
                }
            }
            if (currentSerie.ImageGallery2.name) {
                img2 = await uploadImage(currentSerie.ImageGallery2, Ambito.SERIE_GALLERY2, dispatch, currentSerie.CodSerie)
                if (currentSerie.ImageGallery2 == currentSerie.ImgMain) {
                    imgMain = img2
                }
            }
            if (currentSerie.ImageGallery3.name) {
                img3 = await uploadImage(currentSerie.ImageGallery3, Ambito.SERIE_GALLERY3, dispatch, currentSerie.CodSerie)
                if (currentSerie.ImageGallery3 == currentSerie.ImgMain) {
                    imgMain = img3
                }
            }
            if (currentSerie.ImageGallery4.name) {
                img4 = await uploadImage(currentSerie.ImageGallery4, Ambito.SERIE_GALLERY4, dispatch, currentSerie.CodSerie)
                if (currentSerie.ImageGallery4 == currentSerie.ImgMain) {
                    imgMain = img4
                }
            }
            if (currentSerie.ImageGallery5.name) {
                img5 = await uploadImage(currentSerie.ImageGallery5, Ambito.SERIE_GALLERY5, dispatch, currentSerie.CodSerie)
                if (currentSerie.ImageGallery5 == currentSerie.ImgMain) {
                    imgMain = img5
                }
            }
            if (currentSerie.ImgBanner.name) {
                imgBanner = await uploadImage(currentSerie.ImgBanner, Ambito.SERIE_BANNER, dispatch, currentSerie.CodSerie)
            }
            const body = {
                IdSerie: parseInt(currentSerie.ID),
                IdCategoriaSerie: parseInt(currentSerie.IdCategoriaSerie),
                CodSerie: currentSerie.CodSerie,
                NomeSerie: currentSerie.NomeSerie,
                FamigliaProdotto: currentSerie.FamigliaProdotto,
                Slogan: currentSerie.Slogan,
                AdditionalText: currentSerie.AdditionalText,
                ImgMain: imgMain,
                ImageGallery1: img1,
                ImageGallery2: img2,
                ImageGallery3: img3,
                ImageGallery4: img4,
                ImageGallery5: img5,
                ImgBanner: imgBanner,
                ImgAnimated: currentSerie.ImgAnimated,
                IdOrd: currentSerie.IdOrd,
                Visibile: currentSerie.Visibile
            }
            await editSerie(body, dispatch)
            await dispatch(setSeriesRedux())
            props.onBack()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10, cursor: 'pointer' }} onClick={() => props.onBack()}>
                        <FontAwesomeIcon icon={faChevronLeft} style={{ fontSize: 14, color: graphic.colorAccent }} />
                        <span style={{ fontSize: 14, color: '#68696D' }}>Indietro</span>
                    </div>
                    <h2 style={{ fontWeight: 300, marginTop: 0, color: '#68696D' }}>Serie: {props.selectedDetail.NomeSerie}</h2>
                </div>
                <Button
                    text="Salva serie"
                    hasIcon={true}
                    icon={faCog}
                    onClick={() => {
                        handleSave()
                    }}
                />
            </div>
            <div className="textToPrintContainer">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 20,
                        flex: 1
                    }}
                >
                    <Input
                        divStyle={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                        label="Nome"
                        placeholder="Nome"
                        value={currentSerie.NomeSerie}
                        onChange={(e) => {
                            setCurrentSerie({
                                ...currentSerie,
                                NomeSerie: e
                            })
                        }}
                    />
                    <Input
                        divStyle={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                        label="Slogan"
                        placeholder="Slogan"
                        value={currentSerie.Slogan}
                        onChange={(e) => {
                            setCurrentSerie({
                                ...currentSerie,
                                Slogan: e
                            })
                        }}
                    />
                </div>
                <TextArea
                    label="Descrizione serie"
                    placeholder="Descrizione serie"
                    value={currentSerie.AdditionalText}
                    onChange={(e) => {
                        setCurrentSerie({
                            ...currentSerie,
                            AdditionalText: e
                        })
                    }}
                />
            </div>
            <SubtitleSection text="Immagini galleria" />
            <div className="categoryListContainer" style={{ margin: '20px 0', gridTemplateColumns: 'repeat(6, 1fr)' }}>
                <UploadCard
                    title="Immagine galleria 1"
                    defaultImage={currentSerie.ImageGallery1}
                    toSetMain={currentSerie.ImgMain == props.selectedDetail.ImageGallery1 ? false : true}
                    setMain={() => {
                        setCurrentSerie({
                            ...currentSerie,
                            ImgMain: currentSerie.ImageGallery1
                        })
                    }}
                    editImage={(e) =>
                        setCurrentSerie({
                            ...currentSerie,
                            ImageGallery1: e
                        })
                    }
                />
                <UploadCard
                    title="Immagine galleria 2"
                    defaultImage={currentSerie.ImageGallery2}
                    toSetMain={currentSerie.ImgMain == props.selectedDetail.ImageGallery2 ? false : true}
                    setMain={() => {
                        setCurrentSerie({
                            ...currentSerie,
                            ImgMain: currentSerie.ImageGallery2
                        })
                    }}
                    editImage={(e) => {
                        setCurrentSerie({
                            ...currentSerie,
                            ImageGallery2: e
                        })
                    }}
                />
                <UploadCard
                    title="Immagine galleria 3"
                    defaultImage={currentSerie.ImageGallery3}
                    toSetMain={currentSerie.ImgMain == props.selectedDetail.ImageGallery3 ? false : true}
                    setMain={() => {
                        setCurrentSerie({
                            ...currentSerie,
                            ImgMain: currentSerie.ImageGallery3
                        })
                    }}
                    editImage={(e) =>
                        setCurrentSerie({
                            ...currentSerie,
                            ImageGallery3: e
                        })
                    }
                />
                <UploadCard
                    title="Immagine galleria 4"
                    defaultImage={currentSerie.ImageGallery4}
                    toSetMain={currentSerie.ImgMain == props.selectedDetail.ImageGallery4 ? false : true}
                    setMain={() => {
                        setCurrentSerie({
                            ...currentSerie,
                            ImgMain: currentSerie.ImageGallery4
                        })
                    }}
                    editImage={(e) =>
                        setCurrentSerie({
                            ...currentSerie,
                            ImageGallery4: e
                        })
                    }
                />
                <UploadCard
                    title="Immagine galleria 5"
                    defaultImage={currentSerie.ImageGallery5}
                    toSetMain={currentSerie.ImgMain == props.selectedDetail.ImageGallery5 ? false : true}
                    setMain={() => {
                        setCurrentSerie({
                            ...currentSerie,
                            ImgMain: currentSerie.ImageGallery5
                        })
                    }}
                    editImage={(e) =>
                        setCurrentSerie({
                            ...currentSerie,
                            ImageGallery5: e
                        })
                    }
                />
            </div>
            <UploadCard
                title="Immagine banner"
                defaultImage={currentSerie.ImgBanner}
                editImage={(e) =>
                    setCurrentSerie({
                        ...currentSerie,
                        ImgBanner: e
                    })
                }
            />
        </div>
    )
}

export default SeriesDetailScreen
