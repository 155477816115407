import React from 'react'
import { ReducerData, Reducers } from '../../../../types/reducers'
import { useSelector } from 'react-redux'
import CategoryCard from '../../../../components/CategoryCard'
import { Categoria, Serie } from '../../../../types/data'
import ProductCard from '../../../../components/ProductCard'

interface Props {
    selectDetail: (p: Serie) => void
}

const SeriesListScreen: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)

    const renderSeries = () => {
        return (
            data.series.map((p) => {
                return (
                    <ProductCard
                        key={p.ID}
                        product={p}
                        isEdit
                        onClick={() => {
                            props.selectDetail(p)
                        }}
                    />
                )
            })
        )
    }

    return (
        <div className="productsPageGrid" style={{ margin: "20px 0", gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))", }}>
            {renderSeries()}
        </div>
    )
}

export default SeriesListScreen