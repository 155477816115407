import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Cliente } from '../../types/data'
import { Dialog, DialogContent } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import TitlePage from '../TitlePage'
import { ReducerData, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { provincePerSelect, citiesPerSelect, agentsPerSelect } from '../../utilities/functions'
import Button from '../Button'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { createCliente, editCliente } from '../../resources/requests/requests'
import { setCustomersRedux } from '../../store/actions/thunk_actions'
import CheckboxInput from '../formComponents/CheckboxInput'

interface Props {
    customer: Cliente | null
    isVisible: boolean
    hideModal: () => void
}

const EditCustomerModal: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const voidCustomer: Cliente = {
        idContatto: '',
        idCategoria: '',
        nominativo: '',
        cf: '',
        piva: '',
        idTipoPagamento: '',
        email: '',
        cellulare: '',
        stato: '',
        provincia: '',
        citta: '',
        cap: '',
        indirizzo: '',
        zona: '',
        CodAg: '',
        pec: '',
        sdi: '',
        attivo: '',
        nascosto: '',
        Sconti: null,
        categoria: null,
        tipo: 'AZIENDA',
        ContattoDiretto: 1
    }
    const [currentCustomer, setCurrentCustomer] = useState<Cliente>(props.customer ? props.customer : voidCustomer)
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.isVisible) {
            setCurrentCustomer(props.customer ? props.customer : voidCustomer)
        }
    }, [props.isVisible])

    const provinceValue = () => {
        if (currentCustomer.provincia) {
            const cityFound = data.provinces.find((c) => c.Sigla == currentCustomer.provincia)
            if (cityFound) {
                return {
                    id: cityFound.Sigla,
                    value: cityFound.Sigla,
                    label: cityFound.Provincia
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const cityValue = () => {
        if (currentCustomer.cap) {
            const cityFound = data.cities.find((c) => c.CAP == currentCustomer.cap && c.Comune == currentCustomer.citta)
            if (cityFound) {
                return {
                    id: cityFound.CAP,
                    value: cityFound.IDComune,
                    label: cityFound.Comune
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const agentValue = () => {
        if (currentCustomer.CodAg) {
            const agentFound = data.agents.find((a) => a.CodAg == currentCustomer.CodAg)
            if (agentFound) {
                return {
                    id: agentFound.CodAg,
                    value: agentFound.CodAg,
                    label: agentFound.DenAg
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const handleSave = async () => {
        dispatch(setIsLoading(true))
        try {
            if (props.customer) {
                await editCliente(currentCustomer, dispatch)
            } else {
                await createCliente(currentCustomer, dispatch)
            }
            await dispatch(setCustomersRedux())
            props.hideModal()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={props.customer ? 'Dati cliente: ' + currentCustomer.nominativo : 'Aggiungi cliente'} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Aggiungi / modifica i dati del cliente</p>
                <div className="gridInputOffer" style={{ margin: '20px 0', gridTemplateColumns: 'repeat(3, 1fr)' }}>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Nominativo</label>
                        <input
                            type="text"
                            value={currentCustomer.nominativo}
                            onChange={(e) => {
                                setCurrentCustomer({
                                    ...currentCustomer,
                                    nominativo: e.target.value
                                })
                            }}
                            placeholder="Nominativo *"
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Codice Fiscale</label>
                        <input
                            type="text"
                            value={currentCustomer.cf}
                            onChange={(e) => {
                                setCurrentCustomer({
                                    ...currentCustomer,
                                    cf: e.target.value
                                })
                            }}
                            placeholder="Codice Fiscale"
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Partita IVA</label>
                        <input
                            type="text"
                            placeholder="Partita IVA"
                            value={currentCustomer.piva}
                            onChange={(e) => {
                                setCurrentCustomer({
                                    ...currentCustomer,
                                    piva: e.target.value
                                })
                            }}
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Email</label>
                        <input
                            type="email"
                            placeholder="Email"
                            value={currentCustomer.email}
                            onChange={(e) => {
                                setCurrentCustomer({
                                    ...currentCustomer,
                                    email: e.target.value
                                })
                            }}
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Telefono</label>
                        <input
                            type="tel"
                            placeholder="Telefono"
                            value={currentCustomer.cellulare}
                            onChange={(e) => {
                                setCurrentCustomer({
                                    ...currentCustomer,
                                    cellulare: e.target.value
                                })
                            }}
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Indirizzo</label>
                        <input
                            type="text"
                            placeholder="Indirizzo *"
                            value={currentCustomer.indirizzo}
                            onChange={(e) => {
                                setCurrentCustomer({
                                    ...currentCustomer,
                                    indirizzo: e.target.value
                                })
                            }}
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Zona</label>
                        <input
                            type="text"
                            placeholder="Zona"
                            value={currentCustomer.zona}
                            onChange={(e) => {
                                setCurrentCustomer({
                                    ...currentCustomer,
                                    zona: e.target.value
                                })
                            }}
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Provincia</label>
                        <Select
                            styles={{
                                valueContainer: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#f4f4f4'
                                }),
                                container: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#f4f4f4',
                                    borderRadius: 7
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#f4f4f4',
                                    border: 0,
                                    padding: '5px 10px',
                                    borderRadius: 7
                                })
                            }}
                            placeholder="Provincia"
                            value={provinceValue()}
                            onChange={(e: any) => {
                                if (e) {
                                    setCurrentCustomer({
                                        ...currentCustomer,
                                        provincia: e.id
                                    })
                                }
                            }}
                            options={provincePerSelect(data.provinces)}
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Città</label>
                        <Select
                            styles={{
                                singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                option: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#f4f4f4',
                                    border: 0,
                                    padding: '5px 10px',
                                    borderRadius: 7
                                })
                            }}
                            placeholder="Comune"
                            options={citiesPerSelect(
                                currentCustomer.provincia !== '' ? data.cities.filter((c) => c.Sigla == currentCustomer.provincia) : data.cities
                            )}
                            value={cityValue()}
                            onChange={(e: any) => {
                                if (e) {
                                    setCurrentCustomer({
                                        ...currentCustomer,
                                        cap: e.id,
                                        citta: e.label
                                    })
                                }
                            }}
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>PEC</label>
                        <input
                            type="text"
                            placeholder="PEC"
                            value={currentCustomer.pec}
                            onChange={(e) => {
                                setCurrentCustomer({
                                    ...currentCustomer,
                                    pec: e.target.value
                                })
                            }}
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>SDI</label>
                        <input
                            type="text"
                            placeholder="SDI"
                            value={currentCustomer.sdi}
                            onChange={(e) => {
                                setCurrentCustomer({
                                    ...currentCustomer,
                                    sdi: e.target.value
                                })
                            }}
                        />
                    </div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        <label>Agente</label>
                        <Select
                            styles={{
                                valueContainer: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#f4f4f4'
                                }),
                                container: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#f4f4f4',
                                    borderRadius: 7
                                }),
                                singleValue: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                placeholder: (provided) => ({
                                    ...provided,
                                    fontSize: 18
                                }),
                                control: (provided) => ({
                                    ...provided,
                                    backgroundColor: '#f4f4f4',
                                    border: 0,
                                    padding: '5px 10px',
                                    borderRadius: 7
                                })
                            }}
                            placeholder="Agente"
                            value={agentValue()}
                            onChange={(e: any) => {
                                if (e) {
                                    setCurrentCustomer({
                                        ...currentCustomer,
                                        CodAg: e.id
                                    })
                                }
                            }}
                            options={agentsPerSelect(data.agents)}
                        />
                    </div>
                    {props.customer && (
                        <CheckboxInput
                            values={[
                                {
                                    label: 'Attivo',
                                    status: currentCustomer.attivo == '1' ? true : false
                                }
                            ]}
                            onClick={() => {
                                if (currentCustomer.attivo == '1') {
                                    setCurrentCustomer({
                                        ...currentCustomer,
                                        attivo: '0'
                                    })
                                } else {
                                    setCurrentCustomer({
                                        ...currentCustomer,
                                        attivo: '1'
                                    })
                                }
                            }}
                        />
                    )}
                    {props.customer && (
                        <CheckboxInput
                            values={[
                                {
                                    label: 'Nascondi',
                                    status: currentCustomer.nascosto == '1' ? true : false
                                }
                            ]}
                            onClick={() => {
                                if (currentCustomer.nascosto == '1') {
                                    setCurrentCustomer({
                                        ...currentCustomer,
                                        nascosto: '0'
                                    })
                                } else {
                                    setCurrentCustomer({
                                        ...currentCustomer,
                                        nascosto: '1'
                                    })
                                }
                            }}
                        />
                    )}
                    <CheckboxInput
                        label="Tipologia cliente"
                        values={[
                            {
                                label: 'Privato',
                                status: currentCustomer.tipo == 'PRIVATO' ? true : false
                            },
                            {
                                label: 'Azienda',
                                status: currentCustomer.tipo == 'AZIENDA' ? true : false
                            }
                        ]}
                        onClick={(v) => {
                            if (v.label == 'Privato') {
                                setCurrentCustomer({ ...currentCustomer, tipo: 'PRIVATO' })
                            } else {
                                setCurrentCustomer({ ...currentCustomer, tipo: 'AZIENDA' })
                            }
                        }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: '100%' }}>
                    <Button
                        isSmall
                        text="Salva"
                        hasIcon={true}
                        icon={faEdit}
                        opacity={currentCustomer.nominativo && currentCustomer.indirizzo ? 1 : 0.5}
                        onClick={() => {
                            if (currentCustomer.nominativo && currentCustomer.indirizzo) {
                                handleSave()
                            }
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default EditCustomerModal
