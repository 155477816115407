import React from 'react'
import { View, Text, Image, Font, StyleSheet } from '@react-pdf/renderer'
import { useSelector } from 'react-redux'
import { Reducers } from '../../types/reducers'
import { PrintPage } from '../../types/user'
import moment from 'moment'
import { styles } from './styles'
import { BASE_URL } from '../../resources/constants'

interface Props {
    printFile: PrintPage | null
}

const HeaderPrint: React.FC<Props> = (props) => {
    const printFile = props.printFile

    Font.register({
        family: 'Roboto',
        fonts: [
            { src: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/Roboto-Regular.ttf' },
            { src: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/Roboto-Bold.ttf', fontStyle: 'bold', fontWeight: 700 }
        ]
    })

    if (printFile) {
        return (
            <View
                fixed
                style={{
                    height: 75,
                    marginVertical: 20,
                    borderColor: printFile?.color ? printFile.color : '#005AAB',
                    borderTopWidth: 1,
                    borderBottomWidth: 1,
                    borderStyle: 'solid',
                    flexDirection: 'row',
                    alignItems: 'center'
                }}
            >
                <View style={{ flex: 1, display: 'flex', flexDirection: 'column', paddingHorizontal: 20, paddingVertical: 10, height: 75 }}>
                    <Text style={styles.standardSmallText}>Data: {moment(printFile.header.date).format('DD/MM/YYYY')}</Text>
                    <Text style={styles.standardSmallText}>Offerta n. {printFile.header.offer}</Text>
                    <Text style={styles.standardSmallText}>
                        {printFile.header.customer.nominativo} - {printFile.offerDescription}
                    </Text>
                </View>
                <View style={{ paddingRight: 40, backgroundColor: printFile?.color ? printFile.color : '#005AAB' }}>
                    <Image
                        src={printFile.logo ? printFile.logo : `${BASE_URL}biemme_genera/UsersPesonal/AZI/logo.jpg`}
                        style={{ width: 120, height: 75, backgroundColor: '#fff', objectFit: 'contain' }}
                    />
                </View>
            </View>
        )
    }

    return null
}

export default HeaderPrint
