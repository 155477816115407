import { ItemInQuote } from './../types/order'
import { useEffect, useRef } from 'react'
import { Agente, Cliente, Comune, Provincia, SaleCategory } from '../types/data'
import { Company } from '../types/user'

/**
 * Mantiene lo stato precendente del valore passato
 * @param value any
 */
export const usePrevious = (value: any): any => {
    const ref = useRef()
    useEffect(() => {
        ref.current = value
    })
    return ref.current
}

export const useInterval = (callback: any, delay: number): void => {
    const savedCallback = useRef<any>(null)

    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    useEffect(() => {
        const tick = (): void => {
            savedCallback?.current && savedCallback?.current()
        }

        if (delay !== null) {
            const id = setInterval(tick, delay)
            return (): void => clearInterval(id)
        }

        return () => null
    }, [delay])
}

export const convertArrayToSelect = (array: string[]) => {
    return array.map((v, i) => {
        return {
            value: i,
            label: v
        }
    })
}

export const sortArrayPerKey = (array: any[], key: string) => {
    return array.sort(function (a, b) {
        if (a[key] < b[key]) {
            return -1
        }
        if (a[key] > b[key]) {
            return 1
        }
        return 0
    })
}

export const customersPerSelect = (customers: Cliente[]) => {
    return customers.map((c) => {
        return {
            id: c.idContatto,
            value: c.idContatto,
            label: c.nominativo
        }
    })
}

export const provincePerSelect = (provinces: Provincia[]) => {
    return provinces.map((p) => {
        return {
            id: p.Sigla,
            value: p.Sigla,
            label: p.Provincia
        }
    })
}

export const citiesPerSelect = (cities: Comune[]) => {
    return cities.map((c) => {
        return {
            id: c.CAP,
            value: c.IDComune,
            label: c.Comune
        }
    })
}

export const agentsPerSelect = (agents: Agente[]) => {
    return agents.map((a) => {
        return {
            id: a.CodAg,
            value: a.CodAg,
            label: a.DenAg
        }
    })
}

export const companiesPerSelect = (companies: Company[]) => {
    return companies.map((c) => {
        return {
            id: c.CodAZ,
            value: c.CodAZ,
            label: `${c.RagioneSociale} (Cod. ${c.CodAZ})`
        }
    })
}

export const salePerSelect = (sales: SaleCategory[]) => {
    return sales.map((s) => {
        return {
            id: s.ID,
            value: s.ID,
            label: s.categoria
        }
    })
}

export const transformToCurrency = (price: number) => {
    const formatter = new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        useGrouping: true,
        minimumFractionDigits: 2
    })
    return formatter.format(price)
}

export const orderArrayPerOrder = (items: ItemInQuote[]) => {
    const sortedArray = items.sort((a, b) => {
        return parseInt(a.DettaglioItem.Ordinamento) - parseInt(b.DettaglioItem.Ordinamento)
    })
    return sortedArray
}

export const orderArrayPerTypology = (items: ItemInQuote[]) => {
    const sortedArray = items.sort((a, b) => {
        const nameA = a.DettaglioItem.DescrizioneInterneDB.toLowerCase()
        const nameB = b.DettaglioItem.DescrizioneInterneDB.toLowerCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    })
    return sortedArray
}

export const orderArrayPerPrice = (items: ItemInQuote[], reverse?: boolean) => {
    const sortedArray = items.sort((a, b) => {
        return a.DettaglioItem.Prezzo * a.DettaglioItem.Quantita - b.DettaglioItem.Prezzo * b.DettaglioItem.Quantita
    })
    if (reverse) {
        sortedArray.reverse()
    }
    return sortedArray
}

export const orderClientiPerName = (items: Cliente[]) => {
    const sortedArray = items.sort((a, b) => {
        const nameA = a.nominativo.toLowerCase()
        const nameB = b.nominativo.toLowerCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    })
    return sortedArray
}

export const orderComposedPerLabel = (items: ItemInQuote[]) => {
    const sortedArray = items.sort((a, b) => {
        const nameA = a.DettaglioItem.Etichetta.toLowerCase()
        const nameB = b.DettaglioItem.Etichetta.toLowerCase()
        if (nameA < nameB) {
            return -1
        }
        if (nameA > nameB) {
            return 1
        }
        return 0
    })
    return sortedArray
}

export const convertSale = (sale: string) => {
    if (!sale) {
        return ['0', '0']
    }
    switch (sale) {
        case '47.75':
            return ['45', '5']
        case '50.5':
            return ['45', '10']
        case '50.50':
            return ['45', '10']
        case '53.25':
            return ['45', '15']
        case '56':
            return ['45', '20']
        case '56.0':
            return ['45', '20']
        case '56.00':
            return ['45', '20']
        case '58.75':
            return ['45', '25']
        case '61.5':
            return ['45', '30']
        case '61.50':
            return ['45', '30']
        case '64.25':
            return ['45', '35']
        case '67':
            return ['45', '40']
        case '67.0':
            return ['45', '40']
        case '67.00':
            return ['45', '40']
        case '64':
            return ['60', '10']
        case '64.0':
            return ['60', '10']
        case '64.00':
            return ['60', '10']
        default:
            return [sale, '0']
    }
}

export const validateEmail = (email: string) => {
    if (!email) {
        return false
    }
    const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
}

export const validatePhone = (phone: string) => {
    if (!phone) {
        return false
    }
    const regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
    return regex.test(phone)
}
