import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { CSSProperties } from 'react'

interface Props {
    value: string
    onChange: (e: string) => void
    label?: string
    placeholder?: string
    style?: CSSProperties
    labelStyle?: CSSProperties
    divStyle?: CSSProperties
    disabled?: boolean
}

const TextArea: React.FC<Props> = (props) => {
    return (
        <div className="textEditorColumn">
            <label>{props.label}</label>
            <textarea
                value={props.value}
                onChange={(e) => {
                    props.onChange(e.target.value)
                }}
                placeholder={props.placeholder}
            />
        </div>
    )
}

export default TextArea
