import React, { useEffect, useMemo } from 'react'
import SubtitleSection from '../../../components/SubtitleSection'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { ReducerData, Reducers, ReducerUser } from '../../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SaleCategory } from '../../../types/data'
import MaterialTable from 'material-table'
import { locale_itIT } from '../../../utilities/tableLocale'
import SalePerSeriesModal from '../../../components/modals/SalePerSeriesModal'

const ScontiScreen: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [filterSearch, setFilterSearch] = useState('')
    const [filteredSales, setFilteredSales] = useState<SaleCategory[]>([])
    const [selectedSale, setSelectedSale] = useState<SaleCategory | null>(null)
    const [isVisibleEditModal, setIsVisibleEditModal] = useState(false)
    const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        setFilteredSales(data.saleCategories.filter((c) => !c.CodSerie))
    }, [data.saleCategories])

    const renderDatas = useMemo(() => {
        return filteredSales
    }, [filteredSales])

    const renderActions = useMemo(() => {
        const actions: any[] = []
        if (user.user?.TipoAccount == 'Rivenditore') {
            return actions
        }
        actions.push(() => ({
            icon: 'percent',
            iconProps: {
                fontSize: 'medium'
            },
            tooltip: 'Modifica sconto',
            onClick: (event: any, rowData: SaleCategory) => {
                setSelectedSale(rowData)
                setIsVisibleEditModal(true)
            }
        }))

        return actions
    }, [])

    const columns = [
        {
            title: 'Categoria',
            field: 'categoria'
        },
        {
            title: 'Sconto principale',
            field: 'predefinito'
        },
        {
            title: 'Sconto aggiuntivo',
            field: 'aggiuntivo'
        }
    ]

    useEffect(() => {
        if (filterSearch) {
            setFilteredSales(data.saleCategories.filter((a) => a.categoria.toLowerCase().includes(filterSearch.toLowerCase()) && !a.CodSerie))
        } else {
            setFilteredSales(data.saleCategories.filter((c) => !c.CodSerie))
        }
    }, [data.saleCategories, filterSearch])

    return (
        <div>
            <div className="introProductsPage">
                <SubtitleSection text="Lista categorie di sconto" />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 20 }}>
                    <div className="filterInput">
                        <FontAwesomeIcon icon={faSearch} className="icon" />
                        <input value={filterSearch} onChange={(e) => setFilterSearch(e.target.value)} placeholder="Ricerca categoria..." />
                    </div>
                </div>
            </div>
            <div>
                <MaterialTable
                    title=""
                    columns={columns}
                    data={renderDatas}
                    localization={locale_itIT}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        search: false,
                        headerStyle: {
                            backgroundColor: '#ebebeb',
                            borderRadius: 20
                        }
                    }}
                    //@ts-ignore
                    actions={renderActions}
                />
            </div>
            <SalePerSeriesModal
                category={selectedSale}
                childCategory={data.saleCategories.filter((c) => c.categoria == selectedSale?.categoria && c.CodSerie !== null)}
                isVisible={isVisibleEditModal}
                hideModal={() => setIsVisibleEditModal(false)}
            />
            {/* <DeleteModal
                isVisible={isVisibleDeleteModal}
                hideModal={() => setIsVisibleDeleteModal(false)}
                onDelete={() => handleDelete()}
                title={"Eliminazione cliente"}
                text={"Vuoi davvero eliminare il cliente " + selectedSale?.categoria + "?"}
            /> */}
        </div>
    )
}

export default ScontiScreen
