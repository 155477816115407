import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogContent } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { editItemPreventivo, getSingleItemQuote } from '../../resources/requests/requests'
import { DettaglioItemInQuote, ItemInQuote, ItemToEdit } from '../../types/order'
import { ReducerOrder, Reducers } from '../../types/reducers'
import Button from '../Button'
import TitlePage from '../TitlePage'
import { useSelector, useDispatch } from 'react-redux'
import { setError, setIsLoading } from '../../store/actions/graphic'

interface Props {
    isVisible: boolean
    note: string
    codOrder: string
    hideModal: () => void
    onSave: (note: string) => void
}

const NotesModalEmail: React.FC<Props> = (props) => {
    const [currentNote, setCurrentNote] = useState(props.note)

    useEffect(() => {
        if (props.isVisible) {
            setCurrentNote(props.note)
        }
    }, [props.isVisible])

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={`Invia Email  ${props.codOrder}`} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Aggiungi una nota</p>
                <textarea
                    style={{
                        width: 'calc(100% - 40px)',
                        borderRadius: 20,
                        border: '0px',
                        resize: 'none',
                        height: 180,
                        padding: 20,
                        fontSize: 16,
                        margin: '20px 0',
                        fontFamily: 'Raleway',
                        outline: 'none'
                    }}
                    placeholder="Nota..."
                    value={currentNote}
                    onChange={(e) => {
                        setCurrentNote(e.target.value)
                    }}
                />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: '100%' }}>
                    <Button
                        isSmall
                        text="Annulla"
                        buttonColor="#68696d"
                        onClick={() => {
                            props.hideModal()
                        }}
                    />
                    <Button
                        isSmall
                        text="Invia Email"
                        hasIcon={true}
                        icon={faEdit}
                        onClick={() => {
                            props.onSave(currentNote)
                        }}
                    
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default NotesModalEmail
