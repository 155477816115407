import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'
import { ComposedItem, ComposedItemStructureGraphic, DefaultDataTypology } from '../../types/order'
import { faCheckCircle, faCopy, faTimes } from '@fortawesome/free-solid-svg-icons'
import { Serie, Typology } from '../../types/data'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getComposedTypologiesPerSeries, getDefaultDataTypology } from '../../resources/requests/requests'
import { ReducerGraphic, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'

interface Props {
    isVisible: boolean
    hideModal: () => void
    selectedParent: ComposedItemStructureGraphic | null
    serie: string | undefined
    composedItem: ComposedItem
    setItem: (item: Typology, defaultData: DefaultDataTypology) => void
}

const SelectComposedTypologyModal: React.FC<Props> = (props) => {
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [selectedTypology, setSelectedTypology] = useState<Typology | null>()
    const [typologiesAvailable, setTypologiesAvailable] = useState<Typology[]>([])
    const dispatch = useDispatch()

    const fetchTypologiesAvailable = async () => {
        if (props.serie && props.selectedParent) {
            const typologies = await getComposedTypologiesPerSeries(props.serie, props.selectedParent.width, props.selectedParent.height, dispatch)
            setTypologiesAvailable(typologies)
        }
    }

    useEffect(() => {
        if (props.isVisible) {
            fetchTypologiesAvailable()
        }
    }, [props.isVisible])

    useEffect(() => {
        if (typologiesAvailable.length > 0) {
            const foundEtichetta = props.composedItem.Items.find((i) => i.Etichetta == props.selectedParent?.idName)
            if (foundEtichetta) {
                const foundTypology = typologiesAvailable.find((t) => t.CodTip == foundEtichetta.CodTip)
                if (foundTypology) {
                    setSelectedTypology(foundTypology)
                } else {
                    setSelectedTypology(null)
                }
            } else {
                setSelectedTypology(null)
            }
        }
    }, [typologiesAvailable])

    const renderTypologyList = () => {
        return typologiesAvailable.map((t) => {
            return (
                <div
                    key={t.CodTip}
                    className="typologyCard"
                    style={{ backgroundColor: '#fff' }}
                    onClick={() => {
                        setSelectedTypology(t)
                    }}
                >
                    <div
                        style={{
                            border: '1px solid #ebebeb',
                            backgroundColor: '#e9e9e9',
                            position: 'absolute',
                            top: 5,
                            right: 5,
                            width: 30,
                            height: 30,
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        {selectedTypology && selectedTypology.CodTip == t.CodTip && (
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                style={{ position: 'relative', top: 0, right: 0, color: graphic.colorAccent, fontSize: 24 }}
                            />
                        )}
                    </div>
                    <img src={t.Figura !== '' ? t.Figura : ''} style={{ width: '5vw', height: '5vw' }} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', flex: 1, justifyContent: 'space-between' }}>
                        <p>{t.DescrizTip}</p>
                    </div>
                </div>
            )
        })
    }

    const fetchDefaultData = async () => {
        if (selectedTypology) {
            const defaultData = await getDefaultDataTypology(selectedTypology.CodTip, dispatch)
            props.setItem(selectedTypology, defaultData)
            props.hideModal()
        }
    }

    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md" onBackdropClick={() => props.hideModal()}>
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={'Seleziona la tipologia per il vano ' + props.selectedParent?.idName} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Seleziona la tipologia da assegnare al vano</p>
                <div className="variantGrid variantList">{renderTypologyList()}</div>
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    hasIcon
                    icon={faCopy}
                    text="Salva"
                    opacity={selectedTypology ? 1 : 0.5}
                    onClick={() => {
                        fetchDefaultData()
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default SelectComposedTypologyModal
