import React, { useEffect, useState } from 'react'
import { AdditionalCost, AdditionalCostItem } from '../types/data'
import { ReducerData, Reducers } from '../types/reducers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import Select from 'react-select'
import { useSelector } from 'react-redux'

interface Props {
    costSelected: AdditionalCostItem
    costList: AdditionalCost[]
    onDelete: () => void
    setCurrentAdditionalCost: (c: AdditionalCostItem) => void
}

const CostItem: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [currentCost, setCurrentCost] = useState<AdditionalCostItem>(props.costSelected)
    const [showInput, setShowInput] = useState(props.costSelected.TipoSpesa == "LIBERA" || currentCost.TipoSpesa == "LIBERA" ? true : false)

    useEffect(() => {
        props.setCurrentAdditionalCost(currentCost)
    }, [currentCost])

    useEffect(() => {
        const foundCost = props.costList.find((c) => c.DescrizSpesa == props.costSelected.Descrizione)
        setShowInput(!foundCost && currentCost.Descrizione !== '' ? true : false)
    }, [])

    if (currentCost.TipoSpesa == "INTERNA") {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: "1px solid #BCBCBC", paddingBottom: 10, marginTop: 20, alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 3, alignItems: 'center', gap: 15 }}>
                    <div style={{ width: 18 }}></div>
                    <Select
                        value={currentCost.TipoSpesa ? { id: currentCost.TipoSpesa, value: currentCost.TipoSpesa, label: currentCost.Descrizione } : null}
                        options={props.costList.map((c) => { return { id: c.CodSpesa, value: c.CodSpesa, label: c.DescrizSpesa } })}
                        placeholder="Seleziona spesa"
                        isDisabled={true}
                        onChange={(e) => {
                            if (e?.id == "LIBERA") {
                                setShowInput(true)
                                setCurrentCost({
                                    ...currentCost,
                                    TipoSpesa: 'LIBERA',
                                    Descrizione: ''
                                })
                            }
                            const foundCost = props.costList.find((a) => a.CodSpesa == e?.id);
                            if (foundCost) {
                                setCurrentCost({
                                    ...currentCost,
                                    TipoSpesa: foundCost.CodSpesa,
                                    Descrizione: foundCost.DescrizSpesa,
                                    Totale: foundCost.PrezzoFinale
                                })
                            }
                        }}
                        styles={{
                            valueContainer: (provided) => ({
                                ...provided,
                                backgroundColor: '#f4f4f4'
                            }),
                            container: (provided) => ({
                                ...provided,
                                backgroundColor: '#f4f4f4',
                                borderRadius: 7,
                                width: "100%"
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                fontSize: 18
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: 18
                            }),
                            control: (provided) => ({
                                ...provided,
                                backgroundColor: '#f4f4f4',
                                border: 0,
                                padding: "5px 10px",
                                borderRadius: 7
                            }),
                        }}
                    />
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <input
                        value={currentCost.Totale}
                        disabled={true}
                        placeholder="Totale spesa"
                        type="number"
                        style={{
                            backgroundColor: '#fff',
                            borderBottom: '1px solid #6e6e6e',
                            borderRadius: 0,
                            maxWidth: "20%",
                            textAlign: 'right'
                        }}
                        onChange={(e) => {
                            const value = e.target.value ? e.target.value : '0'
                            setCurrentCost({
                                ...currentCost,
                                Totale: value
                            })
                        }}
                    />
                </div>
            </div>
        )
    }

    if (showInput) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: "1px solid #BCBCBC", paddingBottom: 10, marginTop: 20, alignItems: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 3, alignItems: 'center', gap: 15 }}>
                    <FontAwesomeIcon
                        icon={faTrash}
                        style={{ color: "#E00000", fontSize: 18, cursor: 'pointer' }}
                        onClick={() => {
                            props.onDelete()
                        }}
                    />
                    <input
                        style={{
                            width: "100%"
                        }}
                        value={currentCost.Descrizione}
                        onChange={(e) => {
                            setCurrentCost({
                                ...currentCost,
                                Descrizione: e.target.value
                            })
                        }}
                    />
                </div>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <input
                        value={currentCost.Totale}
                        placeholder="Totale spesa"
                        type="number"
                        style={{
                            backgroundColor: '#fff',
                            borderBottom: '1px solid #6e6e6e',
                            borderRadius: 0,
                            maxWidth: "20%",
                            textAlign: 'right'
                        }}
                        onChange={(e) => {
                            const value = e.target.value ? e.target.value : '0'
                            setCurrentCost({
                                ...currentCost,
                                Totale: value
                            })
                        }}
                    />
                </div>
            </div>
        )
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', borderBottom: "1px solid #BCBCBC", paddingBottom: 10, marginTop: 20, alignItems: 'center' }}>
            <div style={{ display: 'flex', flexDirection: 'row', flex: 3, alignItems: 'center', gap: 15 }}>
                <FontAwesomeIcon
                    icon={faTrash}
                    style={{ color: "#E00000", fontSize: 18, cursor: 'pointer' }}
                    onClick={() => {
                        props.onDelete()
                    }}
                />
                <Select
                    value={currentCost.TipoSpesa ? { id: currentCost.TipoSpesa, value: currentCost.TipoSpesa, label: currentCost.Descrizione } : null}
                    options={props.costList.map((c) => { return { id: c.CodSpesa, value: c.CodSpesa, label: c.DescrizSpesa } })}
                    placeholder="Seleziona spesa"
                    onChange={(e) => {
                        if (e?.id == "LIBERA") {
                            setShowInput(true)
                            setCurrentCost({
                                ...currentCost,
                                TipoSpesa: 'LIBERA',
                                Descrizione: ''
                            })
                        }
                        const foundCost = props.costList.find((a) => a.CodSpesa == e?.id);
                        if (foundCost) {
                            setCurrentCost({
                                ...currentCost,
                                TipoSpesa: foundCost.CodSpesa,
                                Descrizione: foundCost.DescrizSpesa,
                                Totale: foundCost.PrezzoFinale
                            })
                        }
                    }}
                    styles={{
                        valueContainer: (provided) => ({
                            ...provided,
                            backgroundColor: '#f4f4f4'
                        }),
                        container: (provided) => ({
                            ...provided,
                            backgroundColor: '#f4f4f4',
                            borderRadius: 7,
                            width: "100%"
                        }),
                        singleValue: (provided) => ({
                            ...provided,
                            fontSize: 18
                        }),
                        placeholder: (provided) => ({
                            ...provided,
                            fontSize: 18
                        }),
                        control: (provided) => ({
                            ...provided,
                            backgroundColor: '#f4f4f4',
                            border: 0,
                            padding: "5px 10px",
                            borderRadius: 7
                        }),
                    }}
                />
            </div>
            <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                <input
                    value={currentCost.Totale}
                    disabled={true}
                    placeholder="Totale spesa"
                    type="number"
                    style={{
                        backgroundColor: '#fff',
                        borderBottom: '1px solid #6e6e6e',
                        borderRadius: 0,
                        maxWidth: "20%",
                        textAlign: 'right'
                    }}
                    onChange={(e) => {
                        const value = e.target.value ? e.target.value : '0'
                        setCurrentCost({
                            ...currentCost,
                            Totale: value
                        })
                    }}
                />
            </div>
        </div>
    )
}

export default CostItem