import CustomAxios from '../../customCompontents/CustomAxios'
import { DataVar } from '../../pages/Settings/screens/ListinoScreen'
import { logoutUser } from '../../store/actions/user'
import { BASE_URL, TOKEN_ERROR_STRING } from '../constants'
import { headersJson } from './requests'

export const getEmailConfig = async (dispatch: (action: any) => void) => {
    const messages = await CustomAxios.get(BASE_URL + 'bmapi/emailConfig').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data[0]
        } else {
            return null
        }
    })
    return messages
}

export const updateTabellaListino = async (name: string, content: any, dispatch: (action: any) => void) => {
    const data = await CustomAxios.post(BASE_URL + 'bmapi/Tabelle/' + name, content, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        return {
            message: data.data.Message,
            result: data.data.Data ? JSON.stringify(data.data.Data) : ''
        }
    })
    return data
}

export const updateDimensioniTipologie = async (content: any, dispatch: (action: any) => void) => {
    const data = await CustomAxios.post(BASE_URL + 'bmapi/FrontendCategorieSerie/UpdateMassiveDimensioniTipologie', content, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        return {
            message: data.data.Message,
            result: data.data.Data ? JSON.stringify(data.data.Data) : ''
        }
    })
    return data
}

export const getVariantsTypolgy = async (codTip: string, dispatch: (action: any) => void) => {
    const body = {
        CodTip: codTip,
        sort: {
            field: null,
            order: null
        },
        bound: {
            limit: null,
            offset: 0
        }
    }
    const data = await CustomAxios.post(BASE_URL + 'bmapi/Listino/VariantiXTipologiaSerie', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return data
}

export const EditVariantTypology = async (codTip: string, dataVars: DataVar[], dispatch: (action: any) => void) => {
    const body = dataVars.map((d) => {
        return {
            CodTip: codTip,
            CodVar: d.CodVar,
            PrioritaCalcoloPredefinita: d.PrioritaCalcoloPredefinita,
            PrioritaCalcoloAmmessa: d.PrioritaCalcoloAmmessa,
            FormulaValiditaPredefinita: d.FormulaValiditaPredefinita,
            FormulaValiditaAmmessa: d.FormulaValiditaAmmessa,
            FormulaCalcoloAmmessa: d.FormulaCalcoloAmmessa,
            Prezzo: d.Prezzo
        }
    })
    await CustomAxios.post(BASE_URL + 'bmapi/Listino/EditVarianteSuTipologia', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const deleteBackup = async (idBackup: string, dispatch: (action: any) => void) => {
    await CustomAxios.delete(`${BASE_URL}bmapi/DeleteBackupArchivi/${idBackup}`).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const resetOfferFromBackup = async (nomeBackup: string, codOffer: string, dispatch: (action: any) => void) => {
    const body = {
        CodiceOfferta: codOffer,
        ArchivioBackupName: nomeBackup
    }
    const status = await CustomAxios.post(`${BASE_URL}bmapi/Contratto/RipristinaOffertadaArchivioBackup`, body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        return data.data.Result
    })
    return status
}

export const resetOfferFromJSON = async ( codOffer: string, dispatch: (action: any) => void) => {
    const body = {
        CodiceOfferta: codOffer,
    }
    const status = await CustomAxios.post(`${BASE_URL}bmapi/Service/OffertaFromJson`, body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        return data.data.Result
    })
    return status
}