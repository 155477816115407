import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { Agente, Cliente, OfferArchive } from '../../types/data'
import { Dialog, DialogContent } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import TitlePage from '../TitlePage'
import { ReducerData, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import OffersListItem from '../OffersListItem'

interface Props {
    offers: OfferArchive[]
    customer?: Cliente | null
    agent?: Agente | null
    isVisible: boolean
    hideModal: () => void
}

const OffersListModal: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)

    const renderList = () => {
       
        return props.offers.map((o, i) => {
            return <OffersListItem key={o.ID} item={o} index={i} backgroundColor="#fff" />
        })
    }

    const renderTitle = () => {
        if (props.customer) {
            return (
                <div>
                    <TitlePage text={'Offerte di ' + props.customer?.nominativo} style={{ margin: 0 }} />
                    <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Le offerte relative al cliente {props.customer?.nominativo}</p>
                </div>
            )
        }
        if (props.agent) {
            return (
                <div>
                    <TitlePage text={'Offerte di ' + props.agent?.DenAg} style={{ margin: 0 }} />
                    <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Le offerte relative al cliente {props.agent?.DenAg}</p>
                </div>
            )
        }
        return null
    }

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#ebebeb', position: 'relative', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                {renderTitle()}
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '20px 0', maxHeight: '50vh' }}>
                    {props.offers.length > 0 ? (
                        renderList()
                    ) : (
                        <p style={{ fontSize: 20, fontStyle: 'italic', color: '#68696D' }}>
                            Non sono presenti offerte collegate a questo nominativo {
                            props.customer?.nominativo}
                        </p>
                    )}
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default OffersListModal
