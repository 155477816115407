import React from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'

interface Props {
    isVisible: boolean
    hideModal: () => void
    onConfirm: () => void
    saveText?: string
    notSaveText?: string
    isOnlyConfirm?: boolean
    text: string
    title: string
}

const ConfirmModal: React.FC<Props> = (props) => {
    if (props.isOnlyConfirm) {
        return (
            <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md" onBackdropClick={() => props.hideModal()}>
                <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                    <TitlePage text={props.title} style={{ margin: 0 }} />
                    <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>{props.text}</p>
                </DialogContent>
                <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                    <Button
                        isSmall
                        text="Ok"
                        onClick={() => {
                            props.hideModal()
                        }}
                    />
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md" onBackdropClick={() => props.hideModal()}>
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <TitlePage text={props.title} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>{props.text}</p>
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text={props.notSaveText ? props.notSaveText : 'Annulla'}
                    buttonColor="#52565A"
                    onClick={() => {
                        props.hideModal()
                    }}
                />
                <Button
                    isSmall
                    text={props.saveText ? props.saveText : 'Salva'}
                    onClick={() => {
                        props.onConfirm()
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default ConfirmModal
