import { Page, View, Image, StyleSheet, Text, Font } from '@react-pdf/renderer'
import React from 'react'
import { ItemsPrint, PrintPage, TipologyItemPrint } from '../../types/user'
import HeaderPrint from './HeaderPrint'
import FooterPrint from './FooterPrint'
import { styles } from './styles'
import { transformToCurrency } from '../../utilities/functions'
import { PrintType } from '../../utilities/PrintType'

interface Props {
    tipology: TipologyItemPrint
    printFile: PrintPage
    currentItem: ItemsPrint
    index: number
}

const FreeTipologyPage: React.FC<Props> = (props) => {
    const tipology = props.tipology
    const printFile = props.printFile

    Font.register({
        family: 'Roboto',
        fonts: [
            { src: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/Roboto-Regular.ttf' },
            { src: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/Roboto-Bold.ttf', fontStyle: 'bold', fontWeight: 700 }
        ]
    })

    if (props.tipology) {
        return (
            <Page size="A4" style={{ display: 'flex', flexDirection: 'column' }}>
                <HeaderPrint printFile={printFile} />
                <View style={{ width: '100%' }}>
                    <View style={{ paddingHorizontal: 20 }}>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <View style={{ flex: 0.8 }}>
                                <Image src={tipology.img} style={{ width: '100%', objectFit: 'contain' }} />
                                <View style={{ height: 20 }}>
                                    <Text style={styles.standardText}>N° progressivo: {props.index}</Text>
                                </View>
                                <View style={{ height: 20 }}>
                                    <Text style={styles.standardText}>Etichetta: {tipology.label}</Text>
                                </View>
                                <View style={{ height: 20 }}>
                                    <Text style={styles.standardText}>N° ordinamento: {tipology.order}</Text>
                                </View>
                            </View>
                            <View style={{ flex: 2.5, paddingLeft: 10 }}>
                                <Text style={styles.subtitle}>
                                    {tipology.description} ({tipology.dimensions.width} x {tipology.dimensions.height} mm.)
                                </Text>
                                {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', }}>
                                    <View style={{ flex: 1 }}>
                                        <Text style={styles.standardText}>Etichetta: {tipology.label}</Text>
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Text style={styles.standardText}>N° ordinamento: {tipology.order}</Text>
                                    </View>
                                </View> */}
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <View style={{ flex: 1 }}>
                                        <Text style={styles.standardText}>Quantità: {tipology.quantity} pz</Text>
                                    </View>
                                    {printFile.printType !== PrintType.ONLY_FINAL ? (
                                        <View style={{ flex: 1 }}>
                                            <Text style={styles.standardText}>Prezzo base: {transformToCurrency(tipology.priceRif)}</Text>
                                        </View>
                                    ) : null}
                                </View>
                            </View>
                        </View>
                        {props.tipology.note ? (
                            <Text style={{ ...styles.standardText, fontSize: 12, opacity: 0.8, marginTop: 10 }}>Note: {props.tipology.note}</Text>
                        ) : null}
                        <View
                            style={{
                                backgroundColor: printFile.color ? printFile.color : '#005AAB',
                                width: '100%',
                                padding: 5,
                                marginTop: 10,
                                flexDirection: 'row',
                                justifyContent: printFile.printType == PrintType.ONLY_FINAL ? 'flex-end' : 'space-between'
                            }}
                        >
                            {printFile.printType !== PrintType.ONLY_FINAL && printFile.printType !== PrintType.NO_PRICES ? (
                                <Text style={{ color: '#fff', fontSize: 12, fontFamily: 'Roboto' }}>Prezzo: {transformToCurrency(tipology.priceNoSale)}</Text>
                            ) : null}
                            {printFile.printType !== PrintType.ONLY_FINAL && printFile.printType !== PrintType.NO_PRICES ? (
                                <Text style={{ color: '#fff', fontSize: 12, fontFamily: 'Roboto' }}>
                                    Sconto ({tipology.saleFormula}%): {transformToCurrency(tipology.saleEuro)}
                                </Text>
                            ) : null}
                            {printFile.printType !== PrintType.NO_PRICES ? (
                                <Text style={{ color: '#fff', fontSize: 12, fontFamily: 'Roboto' }}>Prezzo finale: {transformToCurrency(tipology.price)}</Text>
                            ) : null}
                        </View>
                    </View>
                </View>
                <FooterPrint printFile={printFile} />
            </Page>
        )
    }

    return null
}

export default FreeTipologyPage
