import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faCopy, faRedo, faTimes } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'
import { DefaultDataTypology, DettaglioItemInQuote, ItemInQuote, ItemToEdit, VariantAdd, VariantItem, VariantRemove } from '../../types/order'
import {
    editItemPreventivo,
    getAllShapePerItem,
    getDefaultDataTypology,
    getSingleItemQuote,
    switchVariantsPerItem,
    calculateGeometry,
    calculateTotalPrices,
    recalculateItems
} from '../../resources/requests/requests'
import { ReducerData, ReducerOrder, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import Select from 'react-select'
import { usePrevious } from '../../utilities/functions'
import { setPricesTotalOffer } from '../../store/actions/order'
import { setError, setIsLoading } from '../../store/actions/graphic'

interface Props {
    isVisible: boolean
    itemInQuote: ItemInQuote
    hideModal: () => void
    updatedItem: (item: DettaglioItemInQuote) => void
}

const SelectShapeModal: React.FC<Props> = (props) => {
    const [currentItem, setCurrentItem] = useState(props.itemInQuote)
    const [shapesAvailable, setShapeAvailable] = useState<VariantItem[]>([])
    const [defaultData, setDefaultData] = useState<DefaultDataTypology>()
    const [shapeSelected, setShapeSelected] = useState<VariantItem>()
    const [currentShape, setCurrentShape] = useState<VariantItem>()
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [currentImage, setCurrentImage] = useState<string>(props.itemInQuote.DettaglioItem.FiguraRiferimento)
    const [hasChanged, setHasChanged] = useState(false)
    const [isDisabled, setIsDisabled] = useState(true)
    const [disableEdit, setDisableEdit] = useState(false)
    const [cantRecalc, setCantRecalc] = useState(false)
    const previousShape = usePrevious(currentShape)
    const previousShapesAvailable = usePrevious(previousShape)
    const dispatch = useDispatch()

    const getShapesList = async () => {
        dispatch(setIsLoading(true))
        try {
            const shapes = await getAllShapePerItem(
                props.itemInQuote.idRecord,
                parseInt(props.itemInQuote.DettaglioItem.Htip),
                parseInt(props.itemInQuote.DettaglioItem.Ltip),
                dispatch
            )
            setShapeAvailable(shapes)
            const shapeFound = shapes.find((s) => currentItem.DettaglioItem.elencoVarianti.find((v) => v.CodVar == s.CodVar))
            setShapeSelected(shapeFound ? shapeFound : shapes[0])
            setCurrentShape(shapeFound ? shapeFound : shapes[0])
            const defaultData = await getDefaultDataTypology(currentItem.DettaglioItem.CodTipoItem, dispatch)
            setDefaultData(defaultData)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    useEffect(() => {
        setHasChanged(false)
        setIsDisabled(true)
        setCurrentItem(props.itemInQuote)
        if (props.isVisible) {
            getShapesList()
        }
    }, [props.isVisible])

    const handleSave = async () => {
        dispatch(setIsLoading(true))
        try {
            if (JSON.stringify(props.itemInQuote) !== JSON.stringify(currentItem)) {
                //console.log(currentItem.DettaglioItem.Ordinamento)
                const body: ItemToEdit = {
                    idRecord: currentItem.idRecord,
                    CodicePreventivo: order.newQuoteFromUser?.CodicePreventivo,
                    CodiceSerie: currentItem.DettaglioItem.CodiceSerie,
                    CodTipoItem: currentItem.DettaglioItem.CodTipoItem,
                    Htip: currentItem.DettaglioItem.Htip,
                    Ltip: currentItem.DettaglioItem.Ltip,
                    Quantita: currentItem.DettaglioItem.Quantita,
                    Etichetta: currentItem.DettaglioItem.Etichetta,
                    Ordinamento: currentItem.DettaglioItem.Ordinamento,
                    HManiglia: currentItem.DettaglioItem.HManiglia ? currentItem.DettaglioItem.HManiglia : null,
                    HTraverso1: currentItem.DettaglioItem?.HTraverso1 ? currentItem.DettaglioItem.HTraverso1 : null,
                    HTraverso2: currentItem.DettaglioItem?.HTraverso2 ? currentItem.DettaglioItem.HTraverso2 : null,
                    HTraverso3: currentItem.DettaglioItem?.HTraverso3 ? currentItem.DettaglioItem.HTraverso3 : null,
                    HTraverso4: currentItem.DettaglioItem?.HTraverso4 ? currentItem.DettaglioItem.HTraverso4 : null,
                    PRM: currentItem.DettaglioItem.PRM ? currentItem.DettaglioItem.PRM : null,
                    Prezzo: currentItem.DettaglioItem.Prezzo.toString(),
                    Sconto: currentItem.DettaglioItem.Sconto.toString(),
                    Annotazioniutente: currentItem.DettaglioItem.Annotazioniutente
                }
                await editItemPreventivo(body, dispatch)
            }
            if (currentShape) {
                const shapeFound = shapesAvailable.find((s) => props.itemInQuote.DettaglioItem.elencoVarianti.find((v) => v.CodVar == s.CodVar))
                const shapeToAdd: VariantAdd = {
                    CodVar: currentShape.CodVar,
                    IdCatVar: currentShape.IdCatVar,
                    IdGenereVar: currentShape.IdGenereVar,
                    DescrizVar: currentShape.DescrizVar,
                    VALID_AMM_VAR: currentShape.VALIDAMMVAR,
                    CALC_VAR: currentShape.CALCVAR,
                    CALC_AMM_VAR: currentShape.CALCAMMVAR,
                    PRM: currentShape.PRM,
                    FiguraAp: currentShape.FiguraAp,
                    Priorita: currentShape.Priorita,
                    PrioritaCalcolo: currentShape.PrioritaCalcolo,
                    Um: currentShape.Um,
                    Prezzo: currentShape.Prezzo,
                    NascondiInDoc: currentShape.NascondiInDoc,
                    StampaPrezzi: currentShape.StampaPrezzi,
                    StampaQta: currentShape.StampaQta,
                    StampaPrev: currentShape.StampaPrev,
                    StampaOrd: currentShape.StampaOrd,
                    DecrizioneCategoria: currentShape.DecrizioneCategoria,
                    Univoco: currentShape.Univoco,
                    inserita: currentShape.inserita ? currentShape.inserita.toString() : '0',
                    Calcolo: currentShape.Calcolo,
                    PrezzoVariante: currentShape.PrezzoVariante ? currentShape.PrezzoVariante.toString() : '0',
                    Costo: currentShape.Costo ? currentShape.Costo.toString() : '0'
                }
                const shapeToRemove: VariantRemove[] = shapeFound
                    ? [{ CodVar: shapeFound.CodVar, IdCatVar: shapeFound.IdCatVar, IdGenereVar: shapeFound.IdGenereVar }]
                    : []
                await switchVariantsPerItem(currentItem.idRecord, order.newQuoteFromUser?.CodicePreventivo, [shapeToAdd], shapeToRemove, dispatch)
            }
            await recalculateItems(
                order.newQuoteFromUser?.CodicePreventivo,
                order.newQuoteFromUser?.CodiceOfferta,
                [{ idItem: currentItem.idRecord }],
                dispatch
            )
            const updatedItem = await getSingleItemQuote(currentItem.idRecord, order.newQuoteFromUser?.CodicePreventivo, dispatch)
            const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
            const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
            dispatch(setPricesTotalOffer(prices))
            props.updatedItem(updatedItem)
            props.hideModal()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    // useEffect(() => {
    //     if (shapeSelected?.CodVar !== currentShape?.CodVar) {
    //         setDisableEdit(true)
    //     } else {
    //         setDisableEdit(false)
    //     }
    // }, [shapeSelected])

    const renderShapeDimension = () => {
        const arrayRender = []
        console.log(currentShape)
        if (currentShape?.geometria) {
            if (currentShape.geometria.L1 !== undefined && currentShape.geometria.L1 !== null) {
                arrayRender.push(
                    <div key="L1" className={currentShape.geometria.L1 > parseInt(currentItem.DettaglioItem.Ltip) ? 'specInput colorRed' : 'specInput'}>
                        <span>L1 (mm.)</span>
                        <input
                            value={currentShape?.geometria.L1}
                            type="number"
                            min={1}
                            onChange={(e) => {
                                if (currentShape.geometria) {
                                    setCurrentShape({
                                        ...currentShape,
                                        geometria: {
                                            ...currentShape.geometria,
                                            L1: e.target.value ? parseInt(e.target.value) : 1
                                        }
                                    })
                                    setHasChanged(true)
                                }
                            }}
                            disabled={disableEdit}
                        />
                    </div>
                )
            }
            if (currentShape.geometria.L2 && currentShape.geometria.L2 !== null) {
                arrayRender.push(
                    <div key="L2" className={currentShape.geometria?.L2 > parseInt(currentItem.DettaglioItem.Ltip) ? 'specInput colorRed' : 'specInput'}>
                        <span>L2 (mm.)</span>
                        <input
                            value={currentShape?.geometria.L2}
                            type="number"
                            min={1}
                            onChange={(e) => {
                                if (currentShape.geometria) {
                                    setCurrentShape({
                                        ...currentShape,
                                        geometria: {
                                            ...currentShape.geometria,
                                            L2: e.target.value ? parseInt(e.target.value) : 1
                                        }
                                    })
                                    setHasChanged(true)
                                }
                            }}
                            disabled={disableEdit}
                        />
                    </div>
                )
            }
            if (currentShape.geometria.L3 && currentShape.geometria.L3 !== null) {
                arrayRender.push(
                    <div key="L3" className={currentShape.geometria?.L3 > parseInt(currentItem.DettaglioItem.Ltip) ? 'specInput colorRed' : 'specInput'}>
                        <span>L3 (mm.)</span>
                        <input
                            value={currentShape?.geometria.L3}
                            type="number"
                            min={1}
                            onChange={(e) => {
                                if (currentShape.geometria) {
                                    setCurrentShape({
                                        ...currentShape,
                                        geometria: {
                                            ...currentShape.geometria,
                                            L3: e.target.value ? parseInt(e.target.value) : 1
                                        }
                                    })
                                    setHasChanged(true)
                                }
                            }}
                            disabled={disableEdit}
                        />
                    </div>
                )
            }
            if (currentShape.geometria.L4 && currentShape.geometria.L4 !== null) {
                arrayRender.push(
                    <div key="L4" className={currentShape.geometria?.L4 > parseInt(currentItem.DettaglioItem.Ltip) ? 'specInput colorRed' : 'specInput'}>
                        <span>L4 (mm.)</span>
                        <input
                            value={currentShape?.geometria.L4}
                            type="number"
                            min={1}
                            onChange={(e) => {
                                if (currentShape.geometria) {
                                    setCurrentShape({
                                        ...currentShape,
                                        geometria: {
                                            ...currentShape.geometria,
                                            L4: e.target.value ? parseInt(e.target.value) : 1
                                        }
                                    })
                                    setHasChanged(true)
                                }
                            }}
                            disabled={disableEdit}
                        />
                    </div>
                )
            }
            if (currentShape.geometria.L5 && currentShape.geometria.L5 !== null) {
                arrayRender.push(
                    <div key="L5" className={currentShape.geometria?.L5 > parseInt(currentItem.DettaglioItem.Ltip) ? 'specInput colorRed' : 'specInput'}>
                        <span>L5 (mm.)</span>
                        <input
                            value={currentShape?.geometria.L5}
                            type="number"
                            min={1}
                            onChange={(e) => {
                                if (currentShape.geometria) {
                                    setCurrentShape({
                                        ...currentShape,
                                        geometria: {
                                            ...currentShape.geometria,
                                            L5: e.target.value ? parseInt(e.target.value) : 1
                                        }
                                    })
                                    setHasChanged(true)
                                }
                            }}
                            disabled={disableEdit}
                        />
                    </div>
                )
            }
            if (currentShape.geometria.L6 && currentShape.geometria.L6 !== null) {
                arrayRender.push(
                    <div key="L6" className={currentShape.geometria?.L6 > parseInt(currentItem.DettaglioItem.Ltip) ? 'specInput colorRed' : 'specInput'}>
                        <span>L6 (mm.)</span>
                        <input
                            value={currentShape?.geometria.L6}
                            type="number"
                            min={1}
                            onChange={(e) => {
                                if (currentShape.geometria) {
                                    setCurrentShape({
                                        ...currentShape,
                                        geometria: {
                                            ...currentShape.geometria,
                                            L6: e.target.value ? parseInt(e.target.value) : 1
                                        }
                                    })
                                    setHasChanged(true)
                                }
                            }}
                            disabled={disableEdit}
                        />
                    </div>
                )
            }
            if (currentShape.geometria.H1 && currentShape.geometria.H1 !== null) {
                arrayRender.push(
                    <div key="H1" className={currentShape.geometria.H1 > parseInt(currentItem.DettaglioItem.Htip) ? 'specInput colorRed' : 'specInput'}>
                        <span>H1 (mm.)</span>
                        <input
                            value={currentShape?.geometria.H1}
                            type="number"
                            min={1}
                            onChange={(e) => {
                                if (currentShape.geometria) {
                                    setCurrentShape({
                                        ...currentShape,
                                        geometria: {
                                            ...currentShape.geometria,
                                            H1: e.target.value ? parseInt(e.target.value) : 1
                                        }
                                    })
                                    setHasChanged(true)
                                }
                            }}
                            disabled={disableEdit}
                        />
                    </div>
                )
            }
            if (currentShape?.geometria.H2 && currentShape.geometria.H2 !== null) {
                arrayRender.push(
                    <div key="H2" className={currentShape.geometria?.H2 > parseInt(currentItem.DettaglioItem.Htip) ? 'specInput colorRed' : 'specInput'}>
                        <span>H2 (mm.)</span>
                        <input
                            value={currentShape?.geometria.H2}
                            type="number"
                            min={1}
                            onChange={(e) => {
                                if (currentShape.geometria) {
                                    setCurrentShape({
                                        ...currentShape,
                                        geometria: {
                                            ...currentShape.geometria,
                                            H2: e.target.value ? parseInt(e.target.value) : 1
                                        }
                                    })
                                    setHasChanged(true)
                                }
                            }}
                            disabled={disableEdit}
                        />
                    </div>
                )
            }
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 20 }}>
                {arrayRender.map((r) => {
                    return r
                })}
            </div>
        )
    }

    useEffect(() => {
        if (previousShape !== undefined) {
            return
        }
        if (shapesAvailable.length > 0) {
            const shapeInItemFound = currentItem.DettaglioItem.elencoVarianti.find((v) => v.IdGenereVar == 'EX')
            if (shapeInItemFound) {
                const shapeToSet = shapesAvailable.find((s) => s.CodVar == shapeInItemFound.CodVar)
                if (shapeToSet && shapeToSet.geometria) {
                    setCurrentShape(shapeToSet)
                    setShapeSelected(shapeToSet)
                    getGeometry()
                }
            } else {
                setCurrentShape(shapesAvailable.find((s) => s.CodVar == ''))
                setShapeSelected(shapesAvailable.find((s) => s.CodVar == ''))
            }
        }
    }, [shapesAvailable])

    useEffect(() => {
        if (previousShape == undefined || previousShape.geometria.Forma !== currentShape?.geometria?.Forma) {
            getGeometry()
            return
        }
        if (currentShape?.geometria) {
            if (
                (currentShape?.geometria.L1 && currentShape?.geometria.L1 > parseInt(currentItem.DettaglioItem.Ltip)) ||
                (currentShape.geometria.L2 && currentShape?.geometria?.L2 > parseInt(currentItem.DettaglioItem.Ltip)) ||
                (currentShape?.geometria.H1 && currentShape?.geometria.H1 > parseInt(currentItem.DettaglioItem.Htip)) ||
                (currentShape.geometria.H2 && currentShape?.geometria?.H2 > parseInt(currentItem.DettaglioItem.Htip))
            ) {
                setCantRecalc(true)
            } else {
                setCantRecalc(false)
                setIsDisabled(false)
            }
        }
    }, [currentShape, currentItem])

    const getGeometry = async () => {
        dispatch(setIsLoading(true))
        //const aperturaFound = currentItem.DettaglioItem.elencoVarianti.find((v) => v.CodVar)
        try {
            if (currentShape?.geometria) {
                setHasChanged(false)
                const body = {
                    IdItem: parseInt(currentItem.idRecord),
                    Etichetta: currentItem.DettaglioItem.Etichetta,
                    HManiglia: currentItem.DettaglioItem.HManiglia,
                    HTraverso1: currentItem.DettaglioItem.HTraverso1,
                    HTraverso2: currentItem.DettaglioItem.HTraverso2,
                    HTraverso3: currentItem.DettaglioItem.HTraverso3,
                    HTraverso4: currentItem.DettaglioItem.HTraverso4,
                    Forma: currentShape?.geometria?.Forma,
                    L1: currentShape?.geometria.L1 ? currentShape?.geometria.L1 : parseInt(currentItem.DettaglioItem.Ltip),
                    L2: currentShape?.geometria.L2 ? currentShape?.geometria.L2 : null,
                    L3: currentShape?.geometria.L3 ? currentShape?.geometria.L3 : null,
                    L4: currentShape?.geometria.L4 ? currentShape?.geometria.L4 : null,
                    L5: currentShape?.geometria.L5 ? currentShape?.geometria.L5 : null,
                    L6: currentShape?.geometria.L6 ? currentShape?.geometria.L6 : null,
                    H1: currentShape?.geometria.H1 ? currentShape?.geometria.H1 : parseInt(currentItem.DettaglioItem.Htip),
                    H2: currentShape?.geometria.H2 ? currentShape?.geometria.H2 : null,
                    BOX_L: parseInt(currentItem.DettaglioItem.Ltip),
                    BOX_H: parseInt(currentItem.DettaglioItem.Htip),
                    Icona: currentShape?.geometria.Icona,
                    Figura: currentShape?.geometria.Figura,
                    PRM_Formula: currentShape?.geometria.PRMFormula,
                    APcode: currentShape?.geometria.APcode,
                    Tipo: currentShape?.geometria.Tipo,
                    NANT: currentShape?.geometria.NANT,
                    CodVar: currentShape.CodVar
                }
                const geometria = await calculateGeometry(body, dispatch)
                setCurrentImage(geometria.FiguraQuotata)
                setCurrentItem({
                    ...currentItem,
                    DettaglioItem: {
                        ...currentItem.DettaglioItem,
                        FiguraRiferimento: geometria.Figura,
                        PRM: geometria.PRM
                    }
                })
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    useEffect(() => {
        if (defaultData) {
            if (
                parseInt(currentItem.DettaglioItem.Htip) < parseInt(defaultData?.Hmin) ||
                parseInt(currentItem.DettaglioItem.Htip) > parseInt(defaultData?.HMax) ||
                parseInt(currentItem.DettaglioItem.Ltip) < parseInt(defaultData?.Lmin) ||
                parseInt(currentItem.DettaglioItem.Ltip) > parseInt(defaultData?.LMax)
            ) {
                setIsDisabled(true)
            } else {
                setIsDisabled(false)
            }
        }
    }, [currentItem])

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="lg"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={props.itemInQuote.DettaglioItem.DescrizioneInterneDB} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Dettagli geometria</p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 20, margin: '20px 0' }}>
                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <img
                            src={currentImage}
                            style={{ maxWidth: '100%', height: '60vh', backgroundColor: '#fff', objectFit: 'contain', borderRadius: 10, padding: 5 }}
                        />
                    </div>
                    <div style={{ flex: 2.4, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end', gap: 10 }}>
                        <div
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: 10,
                                padding: 15,
                                flex: 1,
                                gap: 10,
                                display: 'flex',
                                flexDirection: 'column',
                                width: 'calc(100% - 30px)'
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                    <label>Etichetta</label>
                                    <input
                                        type="text"
                                        value={currentItem.DettaglioItem.Etichetta}
                                        placeholder="Etichetta"
                                        style={{
                                            flex: 4
                                        }}
                                        onChange={(e) => {
                                            setCurrentItem({
                                                ...currentItem,
                                                DettaglioItem: {
                                                    ...currentItem.DettaglioItem,
                                                    Etichetta: e.target.value
                                                }
                                            })
                                        }}
                                    />
                                </div>
                                <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                                    <label>Numero ordinamento</label>
                                    <input
                                        type="number"
                                        value={currentItem.DettaglioItem.Ordinamento}
                                        placeholder="N° Ord."
                                        style={{
                                            flex: 0.5
                                        }}
                                        onChange={(e) => {
                                            setCurrentItem({
                                                ...currentItem,
                                                DettaglioItem: {
                                                    ...currentItem.DettaglioItem,
                                                    Ordinamento: e.target.value
                                                }
                                            })
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                                <div style={{ flex: 3 }}>
                                    <label>Seleziona forma</label>
                                    <Select
                                        isDisabled={hasChanged}
                                        value={
                                            shapeSelected ? { id: shapeSelected.CodVar, value: shapeSelected.CodVar, label: shapeSelected.DescrizVar } : null
                                        }
                                        options={shapesAvailable.map((s) => {
                                            return { id: s.CodVar, value: s.CodVar, label: s.DescrizVar }
                                        })}
                                        placeholder="Seleziona geometria"
                                        onChange={(e) => {
                                            const foundShape = shapesAvailable.find((s) => s.CodVar == e?.id)
                                            setShapeSelected(foundShape)
                                        }}
                                        styles={{
                                            valueContainer: (provided) => ({
                                                ...provided,
                                                backgroundColor: '#f4f4f4'
                                            }),
                                            container: (provided) => ({
                                                ...provided,
                                                backgroundColor: '#f4f4f4',
                                                borderRadius: 7
                                            }),
                                            singleValue: (provided) => ({
                                                ...provided,
                                                fontSize: 18
                                            }),
                                            placeholder: (provided) => ({
                                                ...provided,
                                                fontSize: 18
                                            }),
                                            control: (provided) => ({
                                                ...provided,
                                                backgroundColor: '#f4f4f4',
                                                border: 0,
                                                padding: '5px 10px',
                                                borderRadius: 7
                                            })
                                        }}
                                    />
                                </div>
                                <div>
                                    <Button
                                        isSmall
                                        hasIcon
                                        icon={faCheck}
                                        opacity={currentShape && currentShape?.CodVar == shapeSelected?.CodVar ? 0.5 : 1}
                                        text="Conferma"
                                        onClick={() => {
                                            if (currentShape && currentShape?.CodVar !== shapeSelected?.CodVar) {
                                                setCurrentShape(shapeSelected)
                                                getGeometry()
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 20, marginTop: 20 }}>
                                <div
                                    className={
                                        defaultData &&
                                        parseInt(currentItem.DettaglioItem.Ltip) <= parseInt(defaultData?.LMax) &&
                                        parseInt(currentItem.DettaglioItem.Ltip) >= parseInt(defaultData?.Lmin)
                                            ? 'specInput'
                                            : 'specInput colorRed'
                                    }
                                >
                                    <span>L (mm.)</span>
                                    <input
                                        min={defaultData?.Lmin}
                                        max={defaultData?.LMax}
                                        defaultValue={currentItem.DettaglioItem.Ltip}
                                        value={currentItem.DettaglioItem.Ltip}
                                        type="number"
                                        onChange={(e) => {
                                            setCurrentItem({
                                                ...currentItem,
                                                DettaglioItem: {
                                                    ...currentItem.DettaglioItem,
                                                    Ltip: e.target.value ? e.target.value : '0'
                                                }
                                            })
                                            setHasChanged(true)
                                        }}
                                        disabled={disableEdit}
                                    />
                                    <span className="suggestion">(min. {defaultData?.Lmin} mm.)</span>
                                    <span className="suggestion">(max. {defaultData?.LMax} mm.)</span>
                                </div>
                                <div
                                    className={
                                        defaultData &&
                                        parseInt(currentItem.DettaglioItem.Htip) <= parseInt(defaultData?.HMax) &&
                                        parseInt(currentItem.DettaglioItem.Htip) >= parseInt(defaultData?.Hmin)
                                            ? 'specInput'
                                            : 'specInput colorRed'
                                    }
                                >
                                    <span>H (mm.)</span>
                                    <input
                                        min={defaultData?.Hmin}
                                        max={defaultData?.HMax}
                                        defaultValue={currentItem.DettaglioItem.Htip}
                                        value={currentItem.DettaglioItem.Htip}
                                        type="number"
                                        onChange={(e) => {
                                            setCurrentItem({
                                                ...currentItem,
                                                DettaglioItem: {
                                                    ...currentItem.DettaglioItem,
                                                    Htip: e.target.value ? e.target.value : '0'
                                                }
                                            })
                                            setHasChanged(true)
                                        }}
                                        disabled={disableEdit}
                                    />
                                    <span className="suggestion">(min. {defaultData?.Hmin} mm.)</span>
                                    <span className="suggestion">(max. {defaultData?.HMax} mm.)</span>
                                </div>
                                {currentItem.DettaglioItem.HManiglia && (
                                    <div className="specInput">
                                        <span>HMan.</span>
                                        <input
                                            defaultValue={currentItem.DettaglioItem.HManiglia}
                                            value={currentItem.DettaglioItem.HManiglia ? currentItem.DettaglioItem.HManiglia : 0}
                                            type="number"
                                            onChange={(e) => {
                                                setCurrentItem({
                                                    ...currentItem,
                                                    DettaglioItem: {
                                                        ...currentItem.DettaglioItem,
                                                        HManiglia: e.target.value ? e.target.value : '0'
                                                    }
                                                })
                                                setHasChanged(true)
                                            }}
                                            disabled={disableEdit}
                                        />
                                    </div>
                                )}
                                {currentItem.DettaglioItem.HTraverso1 && (
                                    <div className="specInput">
                                        <span>HTrav. 1</span>
                                        <input
                                            min={0}
                                            defaultValue={currentItem.DettaglioItem.HTraverso1}
                                            value={currentItem.DettaglioItem.HTraverso1 ? currentItem.DettaglioItem.HTraverso1 : 0}
                                            type="number"
                                            onChange={(e) => {
                                                setCurrentItem({
                                                    ...currentItem,
                                                    DettaglioItem: {
                                                        ...currentItem.DettaglioItem,
                                                        HTraverso1: e.target.value ? e.target.value : '0'
                                                    }
                                                })
                                                setHasChanged(true)
                                            }}
                                            disabled={disableEdit}
                                        />
                                    </div>
                                )}
                                {currentItem.DettaglioItem.HTraverso2 && (
                                    <div className="specInput">
                                        <span>HTrav. 2</span>
                                        <input
                                            min={0}
                                            defaultValue={currentItem.DettaglioItem.HTraverso2}
                                            value={currentItem.DettaglioItem.HTraverso2 ? currentItem.DettaglioItem.HTraverso2 : 0}
                                            type="number"
                                            onChange={(e) => {
                                                setCurrentItem({
                                                    ...currentItem,
                                                    DettaglioItem: {
                                                        ...currentItem.DettaglioItem,
                                                        HTraverso2: e.target.value ? e.target.value : '0'
                                                    }
                                                })
                                                setHasChanged(true)
                                            }}
                                            disabled={disableEdit}
                                        />
                                    </div>
                                )}
                                {currentItem.DettaglioItem.HTraverso3 && (
                                    <div className="specInput">
                                        <span>HTrav. 3</span>
                                        <input
                                            min={0}
                                            defaultValue={currentItem.DettaglioItem.HTraverso3}
                                            value={currentItem.DettaglioItem.HTraverso3 ? currentItem.DettaglioItem.HTraverso3 : 0}
                                            type="number"
                                            onChange={(e) => {
                                                setCurrentItem({
                                                    ...currentItem,
                                                    DettaglioItem: {
                                                        ...currentItem.DettaglioItem,
                                                        HTraverso3: e.target.value ? e.target.value : '0'
                                                    }
                                                })
                                                setHasChanged(true)
                                            }}
                                        />
                                    </div>
                                )}
                                {currentItem.DettaglioItem.HTraverso4 && (
                                    <div className="specInput">
                                        <span>HTrav. 4</span>
                                        <input
                                            min={0}
                                            defaultValue={currentItem.DettaglioItem.HTraverso4}
                                            value={currentItem.DettaglioItem.HTraverso4 ? currentItem.DettaglioItem.HTraverso4 : 0}
                                            type="number"
                                            onChange={(e) => {
                                                setCurrentItem({
                                                    ...currentItem,
                                                    DettaglioItem: {
                                                        ...currentItem.DettaglioItem,
                                                        HTraverso4: e.target.value ? e.target.value : '0'
                                                    }
                                                })
                                                setHasChanged(true)
                                            }}
                                            disabled={disableEdit}
                                        />
                                    </div>
                                )}
                            </div>
                            {currentShape &&
                                currentShape.CodVar &&
                                (currentShape.geometria?.H1 || currentShape.geometria?.H2 || currentShape.geometria?.L1 || currentShape.geometria?.L2) && (
                                    <div>
                                        <h3>Dati geometria</h3>
                                        {renderShapeDimension()}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                {defaultData && (
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: 1, width: '100%' }}>
                        {hasChanged && !cantRecalc ? (
                            <Button
                                isSmall
                                text="Ricalcola"
                                hasIcon={true}
                                icon={faRedo}
                                buttonColor="#52565A"
                                opacity={cantRecalc ? 0.5 : 1}
                                onClick={async () => {
                                    if (!cantRecalc) {
                                        const shapes = await getAllShapePerItem(
                                            props.itemInQuote.idRecord,
                                            parseInt(currentItem.DettaglioItem.Htip),
                                            parseInt(currentItem.DettaglioItem.Ltip),
                                            dispatch
                                        )
                                        const currentShapeFound = shapes.find((s) => s.CodVar == currentShape?.CodVar)
                                        if (!currentShapeFound) {
                                            setCurrentShape(shapes[0])
                                        }
                                        setShapeAvailable(shapes)
                                        setShapeSelected(currentShape)
                                        getGeometry()
                                        setHasChanged(false)
                                    }
                                }}
                            />
                        ) : (
                            <div></div>
                        )}
                        <Button
                            isSmall
                            text="Salva"
                            hasIcon={true}
                            icon={faCopy}
                            opacity={!hasChanged && !isDisabled ? 1 : 0.5}
                            onClick={() => {
                                if (!hasChanged && !isDisabled) {
                                    handleSave()
                                }
                            }}
                        />
                    </div>
                )}
            </DialogContent>
        </Dialog>
    )
}

export default SelectShapeModal
