import React, { useEffect, useState } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faTimes } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'
import { ItemInQuote, ItemToEdit } from '../../types/order'
import { editItemPreventivo, getSingleItemQuote, calculateTotalPrices, recalculateItems } from '../../resources/requests/requests'
import { ReducerData, ReducerGraphic, ReducerOrder, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { transformToCurrency } from '../../utilities/functions'
import { setItemsInQuote, setPricesTotalOffer } from '../../store/actions/order'
import { setError, setIsLoading } from '../../store/actions/graphic'

interface Props {
    isVisible: boolean
    itemInQuote: ItemInQuote
    hideModal: () => void
    updatedItem: (item: ItemInQuote) => void
}

const FreeEditDimensionModal: React.FC<Props> = (props) => {
    const [currentItem, setCurrentItem] = useState(props.itemInQuote)
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const dispatch = useDispatch()

    useEffect(() => {
        setCurrentItem(props.itemInQuote)
    }, [props.isVisible])

    const handleUpdateItem = async () => {
        dispatch(setIsLoading(true))
        try {
            const body: ItemToEdit = {
                idRecord: currentItem.idRecord,
                CodicePreventivo: order.newQuoteFromUser?.CodicePreventivo,
                CodiceSerie: currentItem.DettaglioItem.CodiceSerie,
                CodTipoItem: currentItem.DettaglioItem.CodTipoItem,
                Htip: currentItem.DettaglioItem.Htip,
                Ltip: currentItem.DettaglioItem.Ltip,
                Quantita: currentItem.DettaglioItem.Quantita,
                Etichetta: currentItem.DettaglioItem.Etichetta,
                Ordinamento: currentItem.DettaglioItem.Ordinamento,
                HManiglia: currentItem.DettaglioItem.HManiglia ? currentItem.DettaglioItem.HManiglia : null,
                HTraverso1: currentItem.DettaglioItem.HTraverso1 ? currentItem.DettaglioItem.HTraverso1 : null,
                HTraverso2: currentItem.DettaglioItem.HTraverso2 ? currentItem.DettaglioItem.HTraverso2 : null,
                HTraverso3: currentItem.DettaglioItem.HTraverso3 ? currentItem.DettaglioItem.HTraverso3 : null,
                HTraverso4: currentItem.DettaglioItem.HTraverso4 ? currentItem.DettaglioItem.HTraverso4 : null,
                PRM: currentItem.DettaglioItem.PRM ? currentItem.DettaglioItem.PRM.toString() : null,
                Prezzo: currentItem.DettaglioItem.Prezzo.toString(),
                Sconto: currentItem.DettaglioItem.Sconto.toString(),
                Annotazioniutente: currentItem.DettaglioItem.Annotazioniutente
            }
            await editItemPreventivo(body, dispatch)
            await recalculateItems(
                order.newQuoteFromUser?.CodicePreventivo,
                order.newQuoteFromUser?.CodiceOfferta,
                [{ idItem: currentItem.idRecord }],
                dispatch
            )
            const updatedItem = await getSingleItemQuote(currentItem.idRecord, order.newQuoteFromUser?.CodicePreventivo, dispatch)
            dispatch(
                setItemsInQuote(
                    order.itemsInQuote.map((item) => {
                        if (item.idRecord == currentItem.idRecord) {
                            return {
                                ...item,
                                DettaglioItem: updatedItem
                            }
                        } else {
                            return item
                        }
                    })
                )
            )
            const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
            const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
            dispatch(setPricesTotalOffer(prices))
            props.updatedItem(currentItem)
            props.hideModal()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={props.itemInQuote.DettaglioItem.DescrizioneInterneDB} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Dettagli dimensioni</p>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 20, margin: '20px 0' }}>
                    <div style={{ flex: 2.4, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-end', gap: 10 }}>
                        <div
                            style={{
                                backgroundColor: '#fff',
                                borderRadius: 10,
                                padding: 15,
                                flex: 1,
                                gap: 10,
                                display: 'flex',
                                flexDirection: 'column',
                                width: 'calc(100% - 30px)'
                            }}
                        >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', gap: 10 }}>
                                <label style={{ flex: 1 }}>Prezzo unitario:</label>
                                <input
                                    type="number"
                                    value={currentItem.DettaglioItem.Prezzo}
                                    placeholder="Prezzo"
                                    style={{
                                        flex: 2,
                                        textAlign: 'right'
                                    }}
                                    onChange={(e) => {
                                        setCurrentItem({
                                            ...currentItem,
                                            DettaglioItem: {
                                                ...currentItem.DettaglioItem,
                                                Prezzo: parseFloat(e.target.value)
                                            }
                                        })
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 20, alignItems: 'center' }}>
                                <label style={{ margin: 0 }}>Totale (prezzo x quantità):</label>
                                <span style={{ fontSize: 22, fontWeight: 900, color: graphic.colorAccent }}>
                                    {transformToCurrency(currentItem.DettaglioItem.Prezzo * currentItem.DettaglioItem.Quantita)}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: '100%' }}>
                    <Button
                        isSmall
                        text="Salva"
                        hasIcon={true}
                        icon={faCopy}
                        onClick={() => {
                            handleUpdateItem()
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default FreeEditDimensionModal
