import React, { useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'
import { PrintType } from '../../utilities/PrintType'
import { OrderItemPrint } from '../../utilities/OrderItemPrint'
import Select from 'react-select'
import SelectInput, { Option } from '../formComponents/SelectInput'

interface Props {
    isVisible: boolean
    hideModal: () => void
    onConfirm: (selectedType: PrintType, order: OrderItemPrint) => void
}

const PrintSettingsModal: React.FC<Props> = (props) => {
    const [type, setType] = useState(PrintType.EXTENDED)
    const [order, setOrder] = useState(OrderItemPrint.ALPHABETICAL)

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            className="overflowVisible"
            maxWidth="md"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <TitlePage text="Imposta i dati di stampa" style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Seleziona come impostare la stampa dei tuoi dati.</p>
                <SelectInput
                    options={[
                        { id: PrintType.EXTENDED, value: PrintType.EXTENDED, label: PrintType.EXTENDED },
                        { id: PrintType.NORMAL, value: PrintType.NORMAL, label: PrintType.NORMAL },
                        { id: PrintType.ONLY_FINAL, value: PrintType.ONLY_FINAL, label: PrintType.ONLY_FINAL },
                        { id: PrintType.NO_PRICES, value: PrintType.NO_PRICES, label: PrintType.NO_PRICES }
                    ]}
                    value={{ id: type, value: type, label: type }}
                    onChange={(e) => {
                        const val = e as Option
                        if (e) {
                            setType(val.id as PrintType)
                        }
                    }}
                    label="Tipo di stampa"
                    divStyle={{ marginTop: 20 }}
                />
                <SelectInput
                    options={[
                        { id: OrderItemPrint.ALPHABETICAL, value: OrderItemPrint.ALPHABETICAL, label: OrderItemPrint.ALPHABETICAL },
                        { id: OrderItemPrint.ORDER_NUMBER, value: OrderItemPrint.ORDER_NUMBER, label: OrderItemPrint.ORDER_NUMBER }
                    ]}
                    value={{ id: order, value: order, label: order }}
                    onChange={(e) => {
                        const val = e as Option
                        if (e) {
                            setOrder(val.id as OrderItemPrint)
                        }
                    }}
                    label="Ordinamento"
                    divStyle={{ marginTop: 20 }}
                />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: 20, gap: 10 }}>
                    <Button
                        isSmall
                        text="Annulla"
                        buttonColor="#52565A"
                        onClick={() => {
                            props.hideModal()
                        }}
                    />
                    <Button
                        isSmall
                        text="Continua"
                        onClick={() => {
                            props.onConfirm(type, order)
                            props.hideModal()
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default PrintSettingsModal
