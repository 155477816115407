import React, { useEffect, useState } from 'react'
import { Typology, TypologyItemQuote } from '../../types/data'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckSquare, faCopy, faPlusSquare, faSquare, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'
import { VariantItem } from '../../types/order'
import { ReducerData, ReducerOrder, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import SelectTypologyCard from '../SelectTypologyCard'
import { ItemInQuote } from '../../types/order'

interface Props {
    isVisible: boolean
    idRecord: string
    selectedVariants: VariantItem[]
    variantSelected: VariantItem
    onSave: (typologies: { idItem: string }[]) => void
    hideModal: () => void
}

const SelectTypologiesModal: React.FC<Props> = (props) => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [selectedTypologies, setSelectedTypologies] = useState<ItemInQuote[]>([])
    const [admittedTypologies, setAdmittedTypologies] = useState<ItemInQuote[]>([])
    const dispatch = useDispatch()

    useEffect(() => {
        //order.itemsInQuote.filter((i) => i.DettaglioItem.elencoVarianti.find((v) => v.IdGenereVar == props.variantSelected.IdGenereVar) !== undefined)
        setAdmittedTypologies(order.itemsInQuote)
        setSelectedTypologies(admittedTypologies.filter((t) => t.idRecord == props.idRecord))
    }, [props.isVisible])

    const renderCards = () => {
        return admittedTypologies.map((t) => {
            return (
                <SelectTypologyCard
                    key={t.idRecord}
                    typology={t}
                    isSelected={selectedTypologies.find((typology) => typology.idRecord == t.idRecord) !== undefined}
                    onClick={() => {
                        if (props.idRecord == t.idRecord) {
                            return
                        }
                        const itemFound = selectedTypologies.find((typology) => typology.idRecord == t.idRecord)
                        if (itemFound) {
                            setSelectedTypologies(selectedTypologies.filter((typology) => typology.idRecord !== t.idRecord))
                        } else {
                            setSelectedTypologies([...selectedTypologies, t])
                        }
                    }}
                />
            )
        })
    }

    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md" onBackdropClick={() => props.hideModal()}>
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={'Applica selezione di: ' + props.variantSelected.DecrizioneCategoria} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Seleziona le tipologie a cui vuoi applicare la selezione</p>
                <div className="variantGrid">{renderCards()}</div>
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text={admittedTypologies.length == selectedTypologies.length ? 'Deseleziona tutti' : 'Seleziona tutti'}
                    hasIcon={true}
                    buttonColor={'#52565a'}
                    icon={admittedTypologies.length == selectedTypologies.length ? faSquare : faCheckSquare}
                    onClick={() => {
                        if (admittedTypologies.length == selectedTypologies.length) {
                            setSelectedTypologies(selectedTypologies.filter((t) => t.idRecord == props.idRecord))
                        } else {
                            setSelectedTypologies(admittedTypologies)
                        }
                    }}
                />
                <Button
                    isSmall
                    text="Salva"
                    hasIcon={true}
                    icon={faCopy}
                    onClick={() => {
                        const selectedIdItems = selectedTypologies.map((t) => {
                            return {
                                idItem: t.idRecord
                            }
                        })
                        props.onSave(selectedIdItems)
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default SelectTypologiesModal
