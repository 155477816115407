import CustomAxios from '../../customCompontents/CustomAxios'
import { logoutUser } from '../../store/actions/user'
import { BASE_URL, TOKEN_ERROR_STRING } from '../constants'
import { headersJson } from './requests'

interface ClientFromArchive {
    CodiceOfferta: string
    idContatto: number
}

export interface ClientNew {
    CodiceOfferta: string
    idContatto: number | string | null
    nominativo: string | null
    cf: string | null
    piva: string | null
    email: string | null
    idTipoPagamento: string | null
    stato: string | null
    provincia: string | null
    cap: string | null
    indirizzo: string | null
    zona: string | null
    CodAg: string | null
    sdi: string | null
}

export const switchContatto = async (body: ClientFromArchive | ClientNew, dispatch: (action: any) => void) => {
    await CustomAxios.post(BASE_URL + 'bmapi/Contratto/SwitchContatto', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const tolleranceList = async (dispatch: (action: any) => void) => {
    const data = await CustomAxios.post(BASE_URL + 'bmapi/Preventivo/ElencoTolleranze', headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        }
    })
    return data
}

interface TolleranceBody {
    CodicePreventivo: string
    TolleranzaL: number
    TolleranzaH: number
}

export const applyTollerance = async (body: TolleranceBody, dispatch: (action: any) => void) => {
    await CustomAxios.post(BASE_URL + 'bmapi/Preventivo/ApplicaTolleranza', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const checkOfferStatus = async (codOffer: string, dispatch: (action: any) => void) => {
    const status = await CustomAxios.get(`${BASE_URL}bmapi/StatoOfferta/${codOffer}`).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data.locked
        } else {
            return true
        }
    })
    return status
}

export const getUpsellingElements = async (codPreventivo: string, dispatch: (action: any) => void) => {
    const body = {
        CodicePreventivo: codPreventivo
    }
    const status = await CustomAxios.post(`${BASE_URL}bmapi/Contratto/ElencoSerieProposte`, body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return []
        }
    })
    return status
}

export const saveUpsellingSelected = async (codOffer: string, codPreventivo: string, codSerie: string, dispatch: (action: any) => void) => {
    const body = {
        CodOfferta: codOffer,
        CodicePreventivo: codPreventivo,
        CodSerie: codSerie
    }
    const message = await CustomAxios.post(`${BASE_URL}bmapi/Contratto/AddSerieDaPreventivo`, body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return {
                message: data.data.Message,
                detailMessage: data.data.Data.MessageDettaglio
            }
        }
    })
    return message
}

export const setOrderNotes = async (codOrder: string, note: string, dispatch: (action: any) => void) => {
    const body = {
        CodiceOrdine: codOrder,
        Annotazioni: note
    }
    await CustomAxios.put(`${BASE_URL}bmapi/Contratto/SetAnnotazione`, body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}
