import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogContent } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { calculateTotalPrices, getAllItemsQuote, setPrezzoFinale } from '../../resources/requests/requests'
import { ItemInQuote } from '../../types/order'
import { ReducerData, ReducerOrder, Reducers } from '../../types/reducers'
import Button from '../Button'
import TitlePage from '../TitlePage'
import { useSelector, useDispatch } from 'react-redux'
import { transformToCurrency, usePrevious } from '../../utilities/functions'
import { setItemsInQuote, setPricesTotalOffer } from '../../store/actions/order'
import { setError, setIsLoading } from '../../store/actions/graphic'

enum SaleType {
    PERCENT,
    VALUE
}

interface Props {
    isVisible: boolean
    item: ItemInQuote
    assignedItems: ItemInQuote[]
    hideModal: () => void
}

interface SaleObject {
    salePercent: string
    salePercentAdd: string | null
    saleValue: string
}

const ComposedSaleModal: React.FC<Props> = (props) => {
    const [currentItem, setCurrentItem] = useState(props.item)
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const voidSale = {
        salePercent: '0',
        salePercentAdd: null,
        saleValue: '0'
    }
    const [totalPrice, setTotalPrice] = useState(0)
    const [finalPrice, setFinalPrice] = useState(0)
    const [sale, setSale] = useState<SaleObject>(voidSale)
    const [currentSaleType, setCurrentSaleType] = useState<SaleType>()
    const previousSale = usePrevious(sale)
    const dispatch = useDispatch()

    useEffect(() => {
        setCurrentItem(props.item)
        setSale(voidSale)
        const calculatedSale = calculateSale()
        if (calculatedSale) {
            setSale({
                ...sale,
                salePercent: calculatedSale.toFixed(2).toString()
            })
        }
        setTotalPrice(calculateTotalPrice())
        setFinalPrice(calculateFinalPrice())
    }, [props.isVisible])

    useEffect(() => {
        if (totalPrice) {
            setSale({
                ...sale,
                saleValue: ((totalPrice * parseFloat(sale.salePercent)) / 100).toFixed(2).toString()
            })
        }
    }, [totalPrice])

    const handleSave = async () => {
        if (sale.salePercent) {
            dispatch(setIsLoading(true))
            try {
                const singleItemSale = (parseFloat(sale.saleValue) / props.assignedItems.length).toFixed(2)
                for (let i = 0; i < props.assignedItems.length; i++) {
                    const calculatedSale = (parseFloat(singleItemSale) * 100) / parseFloat(props.assignedItems[i].DettaglioItem.PrezzoListino)
                    await setPrezzoFinale(props.assignedItems[i].idRecord, calculatedSale, dispatch)
                }
                const updatedItems = await getAllItemsQuote(order.newQuoteFromUser?.CodicePreventivo, dispatch)
                dispatch(
                    setItemsInQuote(
                        order.itemsInQuote.map((item) => {
                            const itemFound = updatedItems.find((i) => i.idRecord == item.idRecord)
                            if (itemFound) {
                                return {
                                    ...item,
                                    DettaglioItem: itemFound
                                }
                            } else {
                                return item
                            }
                        })
                    )
                )
                const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
                const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
                dispatch(setPricesTotalOffer(prices))
                props.hideModal()
            } catch (error: any) {
                dispatch(setError(error.toString))
            }
            dispatch(setIsLoading(false))
        }
    }

    useEffect(() => {
        if (previousSale !== undefined && JSON.stringify(sale) == JSON.stringify(previousSale)) {
            return
        }
        if (currentSaleType == SaleType.VALUE) {
            const salePercent = (parseFloat(sale.saleValue) * 100) / totalPrice
            setSale({
                ...sale,
                salePercent: parseFloat(salePercent.toFixed(2)).toString(),
                salePercentAdd: null
            })
        }
        if (currentSaleType == SaleType.PERCENT) {
            if (sale.salePercentAdd !== null && parseFloat(sale.salePercentAdd) > 0) {
                const firstSale = (totalPrice * parseFloat(sale.salePercent)) / 100
                const secondSale = ((totalPrice - firstSale) * parseFloat(sale.salePercentAdd)) / 100
                setSale({
                    ...sale,
                    saleValue: (firstSale + secondSale).toFixed(2).toString()
                })
            } else {
                const saleValue = (totalPrice * parseFloat(sale.salePercent)) / 100
                setSale({
                    ...sale,
                    saleValue: saleValue.toFixed(2).toString()
                })
            }
        }
    }, [sale])

    useEffect(() => {
        setFinalPrice(totalPrice - parseFloat(sale.saleValue))
    }, [sale.saleValue])

    const calculateSale = () => {
        let sale = 0
        let totalPrices = 0
        let finalPrices = 0
        props.assignedItems.forEach((p) => {
            totalPrices = totalPrices + parseFloat(p.DettaglioItem.PrezzoListino)
        })
        props.assignedItems.forEach((p) => {
            finalPrices = finalPrices + p.DettaglioItem.Prezzo
        })
        sale = ((totalPrices - finalPrices) * 100) / totalPrices
        return sale
    }

    const calculateTotalPrice = () => {
        let price = 0
        props.assignedItems.forEach((p) => {
            price = price + parseFloat(p.DettaglioItem.PrezzoListino)
        })
        return price
    }

    const calculateFinalPrice = () => {
        let price = 0
        props.assignedItems.forEach((p) => {
            price = price + p.DettaglioItem.Prezzo
        })
        return price
    }

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={currentItem.DettaglioItem.DescrizioneInterneDB} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Gestione sconti</p>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '20px 0' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <label>Sconto in valuta</label>
                        <input
                            placeholder="Sconto in valuta"
                            step=".01"
                            type="number"
                            value={sale.saleValue ? sale.saleValue : 0}
                            onFocus={() => setCurrentSaleType(SaleType.VALUE)}
                            onChange={(e) =>
                                setSale({
                                    ...sale,
                                    saleValue: e.target.value.replace(',', '.')
                                })
                            }
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '20px 0' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <label>Sconto in percentuale</label>
                        <input
                            placeholder="Sconto principale"
                            step=".01"
                            type="number"
                            value={sale.salePercent ? sale.salePercent : 0}
                            onFocus={() => setCurrentSaleType(SaleType.PERCENT)}
                            onChange={(e) =>
                                setSale({
                                    ...sale,
                                    salePercent: e.target.value.replace(',', '.')
                                })
                            }
                        />
                        <input
                            style={{ marginTop: 10 }}
                            placeholder="Sconto aggiuntivo"
                            step=".01"
                            type="number"
                            disabled={parseFloat(sale.salePercent) > 0 ? false : true}
                            value={sale.salePercentAdd ? sale.salePercentAdd : ''}
                            onFocus={() => setCurrentSaleType(SaleType.PERCENT)}
                            onChange={(e) =>
                                setSale({
                                    ...sale,
                                    salePercentAdd: e.target.value.replace(',', '.')
                                })
                            }
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
                    <p style={{ fontSize: 18 }}>
                        Prezzo di listino: <b>{transformToCurrency(totalPrice)}</b>
                    </p>
                    <p style={{ fontSize: 18 }}>
                        Prezzo finale: <b>{transformToCurrency(finalPrice)}</b>
                    </p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: '100%' }}>
                    <Button
                        isSmall
                        text="Salva"
                        hasIcon={true}
                        icon={faEdit}
                        onClick={() => {
                            handleSave()
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ComposedSaleModal
