import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faTimes } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'
import { useDispatch } from 'react-redux'
import { Serie } from '../../types/data'
import ProductCard from '../ProductCard'
import { seriesPerSwitch } from '../../resources/requests/requests'
import ConfirmModal from './ConfirmModal'

interface Props {
    isVisible: boolean
    codPreventivo: string
    codSerie: string | undefined
    onSave: (codSerie: string) => void
    hideModal: () => void
}

const SwitchSeriesModal: React.FC<Props> = (props) => {
    const [selectedSerie, setSelectedSerie] = useState<Serie>()
    const [admittedSeries, setAdmittedSerie] = useState<Serie[]>([])
    const [isVisibleConfirmModal, setIsVisibleConfirmModal] = useState(false)
    const dispatch = useDispatch()

    const fetchAdmittedSeries = async () => {
        if (props.codSerie) {
            const series: Serie[] = await seriesPerSwitch(props.codSerie, dispatch)
            setAdmittedSerie(series)
        }
    }

    useEffect(() => {
        if (props.isVisible) {
            fetchAdmittedSeries()
        }
    }, [props.isVisible])

    const renderSeries = () => {
        return admittedSeries.map((p) => {
            return (
                <ProductCard
                    key={p.ID}
                    isSelectable
                    isSelected={selectedSerie && selectedSerie.CodSerie == p.CodSerie}
                    product={p}
                    onClick={() => setSelectedSerie(p)}
                />
            )
        })
    }

    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="lg" onBackdropClick={() => props.hideModal()}>
            <ConfirmModal
                isVisible={isVisibleConfirmModal}
                hideModal={() => setIsVisibleConfirmModal(false)}
                onConfirm={() => {
                    if (selectedSerie) {
                        props.onSave(selectedSerie.CodSerie)
                        setIsVisibleConfirmModal(false)
                    }
                }}
                text="Confermi il passaggio dell'offerta alla serie selezionata?"
                title="Conferma l'operazione"
            />
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={'Cambio serie preventivo ' + props.codPreventivo} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Seleziona le tipologie a cui vuoi applicare la selezione</p>
                <div className="variantGrid" style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))' }}>
                    {renderSeries()}
                </div>
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Salva"
                    hasIcon={true}
                    icon={faCopy}
                    opacity={selectedSerie ? 1 : 0.5}
                    onClick={() => {
                        if (selectedSerie) {
                            setIsVisibleConfirmModal(true)
                        }
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default SwitchSeriesModal
