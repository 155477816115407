import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Button from '../Button'
import Input from '../formComponents/Input'
import TitlePage from '../TitlePage'
import { useDispatch, useSelector } from 'react-redux'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { createDoc } from '../../resources/requests/others'
import { ReducerData, Reducers } from '../../types/reducers'
import SelectInput, { Option } from '../formComponents/SelectInput'

interface Props {
    isVisible: boolean
    onSave: () => void
    hideModal: () => void
}

export interface VoidDoc {
    Titolo: string
    Descrizione: string
    Link: string
    Gruppo: number
    Visibile: number
}

const voidDoc = {
    Titolo: '',
    Descrizione: '',
    Link: '',
    Gruppo: -1,
    Visibile: 1,
    Colore: null,
    ID: null,
    DataCreazione: null
}

const AddDocModal: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [newDoc, setNewDoc] = useState<VoidDoc>(voidDoc)
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.isVisible) {
            setNewDoc(voidDoc)
        }
    }, [props.isVisible])

    const handleCreateDoc = async () => {
        dispatch(setIsLoading(true))
        try {
            await createDoc(newDoc, dispatch)
            props.onSave()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const groupValue = () => {
        if (newDoc.Gruppo !== -1) {
            const groupFind = data.accountGroups.find((g) => parseInt(g.ID) == newDoc.Gruppo)
            if (groupFind) {
                return {
                    id: parseInt(groupFind.ID),
                    value: parseInt(groupFind.ID),
                    label: groupFind.Descrizione
                }
            }
        }
        return null
    }

    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md" onBackdropClick={() => props.hideModal()}>
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <TitlePage text={'Aggiungi documento'} style={{ margin: 0 }} />
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20, marginTop: 20 }}>
                    <Input
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        value={newDoc.Titolo}
                        label={'Titolo'}
                        placeholder={'Titolo'}
                        onChange={(e) =>
                            setNewDoc({
                                ...newDoc,
                                Titolo: e
                            })
                        }
                    />
                    <Input
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        value={newDoc.Descrizione}
                        label={'Descrizione'}
                        placeholder={'Descrizione'}
                        onChange={(e) =>
                            setNewDoc({
                                ...newDoc,
                                Descrizione: e
                            })
                        }
                    />
                    <Input
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        value={newDoc.Link}
                        label={'Link'}
                        placeholder={'Link'}
                        onChange={(e) =>
                            setNewDoc({
                                ...newDoc,
                                Link: e
                            })
                        }
                    />
                    <SelectInput
                        placeholder="Gruppo account"
                        label="Gruppo account"
                        divStyle={{ flexDirection: 'column', display: 'flex' }}
                        options={data.accountGroups.map((g) => {
                            return { id: g.ID, value: g.ID, label: g.Descrizione }
                        })}
                        menuPlacement="top"
                        value={groupValue()}
                        onChange={(e) => {
                            const val = e as Option
                            if (e) {
                                setNewDoc({
                                    ...newDoc,
                                    Gruppo: val.id as number
                                })
                            }
                        }}
                    />
                </div>
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Annulla"
                    buttonColor="#52565A"
                    onClick={() => {
                        props.hideModal()
                    }}
                />
                <Button
                    isSmall
                    text="Salva"
                    onClick={() => {
                        handleCreateDoc()
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default AddDocModal
