import React, { useEffect, useState } from 'react'
import TitlePage from '../../components/TitlePage'
import { ReducerGraphic, ReducerOrder, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import Button from '../../components/Button'
import { faBuilding, faCalendar, faEdit, faFileExport, faPencilAlt, faPrint, faTag, faTrash } from '@fortawesome/free-solid-svg-icons'
import CurrentOfferListElement from '../../components/CurrentOfferListElement'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { convertSale, transformToCurrency } from '../../utilities/functions'
import { createPDFToPrint, createXMLToDownload, deleteAllQuotes, deleteOffer, getTotalPricesOffer, saveCurrentOffer } from '../../resources/requests/requests'
import { saveAs } from 'file-saver'
import { setCurrentOffer, setIsPendingOffer, setPricesTotalOffer } from '../../store/actions/order'
import { useHistory } from 'react-router'
import { setOffersRedux } from '../../store/actions/thunk_actions'
import { setPrintFile } from '../../store/actions/user'
import TextOffersModal from '../../components/modals/TextOffersModal'
import WorksiteAddressModal from '../../components/modals/WorksiteAddressModal'
import { setError, setIsLoading } from '../../store/actions/graphic'
import DeleteModal from '../../components/modals/DeleteModal'
import DeliveryDateSelectModal from '../../components/modals/DeliveryDateSelectModal'
import PrintSettingsModal from '../../components/modals/PrintSettingsModal'
import { PrintType } from '../../utilities/PrintType'
import ConfirmModal from '../../components/modals/ConfirmModal'
import { BASE_URL, BASE_URL_FRONTEND,SUB_PATH_URL_FRONTEND } from '../../resources/constants'
require('moment/min/locales')
moment.locale('it')

const CurrentOffer: React.FC = () => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const dispatch = useDispatch()
    const history = useHistory()
    const [isOffersTextModalVisible, setIsOffersTextModalVisible] = useState(false)
    const [isWorksiteModalVisible, setIsWorksiteModalVisible] = useState(false)
    const [isSelectDateVisible, setIsSelectDateVisible] = useState(false)
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false)
    const [isConfirmVisible, setIsConfirmVisible] = useState('')
    const [visiblePrintSettingsModal, setVisiblePrintSettingsModal] = useState(false)

    const handlePrintOffer = async (type: PrintType) => {
        dispatch(setIsLoading(true))
        try {
            const filePdf = await createPDFToPrint(order.currentOffer?.CodiceOfferta, dispatch)
            dispatch(setPrintFile({ ...filePdf, printType: type }))
           
           // console.log(' url print   process.env '+ process.env.REACT_APP_RELEASE_ENV )

           // console.log(' url print BASE_URL_FRONTEND' + BASE_URL_FRONTEND)

           // console.log(' url print  BASE_URL_FRONTEND +SUB_PATH_URL_FRONTEND '+BASE_URL_FRONTEND + SUB_PATH_URL_FRONTEND 

            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                window.open('/print', '_blank')
            }
            else {
                //window.open(`${BASE_URL} biemme_genera/panel/print`, '_blank')
                //window.open(`${BASE_URL}${SUB_PATH_URL_FRONTEND}print`, '_blank')
                window.open(`${(process.env.REACT_APP_RELEASE_ENV == 'production' )? BASE_URL_FRONTEND : BASE_URL_FRONTEND + SUB_PATH_URL_FRONTEND}print`, '_blank')
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    const getTotalPrices = async () => {
        dispatch(setIsLoading(true))
        try {
            const prices = await getTotalPricesOffer(order.currentOffer?.CodiceOfferta, dispatch)
            dispatch(setPricesTotalOffer(prices))
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const renderAddress = () => {
        if (order.currentOffer) {
            if (order.currentOffer.ComuneCantiere && order.currentOffer.IndirizzoCantiere && order.currentOffer.ProvinciaCantiere) {
                return (
                    <p style={{ color: '#68696D' }}>
                        Cantiere di installazione:{' '}
                        <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                            {order.currentOffer?.IndirizzoCantiere} - {order.currentOffer?.ComuneCantiere} ({order.currentOffer?.ProvinciaCantiere})
                        </span>
                    </p>
                )
            } else {
                return <p style={{ color: '#68696D' }}>Cantiere di installazione: </p>
            }
        }
        return <p style={{ color: '#68696D' }}>Cantiere di installazione: </p>
    }

    useEffect(() => {
        getTotalPrices()
    }, [])

    const handleDownloadXML = async () => {
        dispatch(setIsLoading(true))
        try {
            const fileXML = await createXMLToDownload(order.currentOffer?.CodiceOfferta, dispatch)
            if (fileXML && order.currentOffer) {
                saveAs(fileXML, order.currentOffer.CodiceOfferta + '.zip')
                setIsConfirmVisible(order.currentOffer.CodiceOfferta)
            }
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    const handleCloseOffer = async () => {
        dispatch(setIsLoading(true))
        try {
            await saveCurrentOffer(order.currentOffer?.CodiceOfferta, dispatch)
            dispatch(setCurrentOffer(null))
            await deleteAllQuotes(dispatch)
            //await dispatch(setOffersRedux())
            history.push('/offers')
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handleDeleteOffer = async () => {
        dispatch(setIsLoading(true))
        try {
            await deleteAllQuotes(dispatch)
            dispatch(setCurrentOffer(null))
            dispatch(setIsPendingOffer(false))
            if (order.currentOffer) {
                await deleteOffer(order.currentOffer?.CodiceOfferta, dispatch)
                //dispatch(setOffersRedux())
            }
            history.push('/')
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    const calculatedIva = () => {
        let tax = 0
        order.pricesTotalOffer?.DettaglioPreventivi.forEach((p) => {
            tax += parseFloat(p.Iva)
        })
        return transformToCurrency(tax)
    }

    return (
        <div>
            {order.currentOffer ? (
                <TitlePage
                    text="Offerta corrente "
                    dynamicText={
                        order.currentOffer?.Descrizione
                            ? order.currentOffer?.Descrizione + '(ID ' + order.currentOffer?.CodiceOfferta + ')'
                            : '(ID ' + order.currentOffer?.CodiceOfferta + ')'
                    }
                />
            ) : (
                <TitlePage text="Nessuna offerta presente" />
            )}
            {order.currentOffer && (
                <div className="scrollableDiv" style={{ maxHeight: '75vh' }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: 20 }}>
                        <Button
                            text="Chiudi offerta"
                            hasIcon={true}
                            icon={faTag}
                            onClick={async () => {
                                handleCloseOffer()
                            }}
                        />
                        <Button
                            text="Stampa offerta"
                            hasIcon={true}
                            icon={faPrint}
                            onClick={() => {
                                setVisiblePrintSettingsModal(true)
                            }}
                            buttonColor="#68696D"
                        />
                        <Button
                            text="Scarica file specifiche"
                            hasIcon={true}
                            icon={faFileExport}
                            onClick={() => {
                                handleDownloadXML()
                            }}
                            buttonColor="#68696D"
                        />
                        <Button
                            text="Elimina offerta"
                            hasIcon={true}
                            icon={faTrash}
                            onClick={async () => {
                                setIsDeleteModalVisible(true)
                            }}
                            buttonColor="#E00000"
                        />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '20px 0' }}>
                        {order.currentOffer.Preventivi.map((p) => {
                            return (
                                <CurrentOfferListElement
                                    offerTotals={order.pricesTotalOffer}
                                    codOffer={order.currentOffer?.CodiceOfferta}
                                    key={p.CodicePreventivo}
                                    parentOffer={order.currentOffer}
                                    isCurrentOffer={order.currentOffer?.CodiceOfferta.charAt(0) == 'O' ? false : true}
                                    quote={p}
                                    notEdit={false}
                                />
                            )
                        })}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', flex: 3, gap: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faCalendar} style={{ fontSize: 30, color: '#68696D' }} />
                                <p style={{ color: '#68696D' }}>
                                    Consegna richiesta entro:{' '}
                                    <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                        {order.currentOffer.DataConsegna
                                            ? moment(order.currentOffer.DataConsegna).format('DD MMMM YYYY')
                                            : moment().format('DD MMMM YYYY')}
                                    </span>
                                </p>
                                <FontAwesomeIcon
                                    icon={faPencilAlt}
                                    style={{ fontSize: 20, color: graphic.colorAccent, cursor: 'pointer' }}
                                    onClick={() => setIsSelectDateVisible(true)}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faEdit} style={{ fontSize: 30, color: '#68696D' }} />
                                <p style={{ color: '#68696D' }}>Testi iniziali e finali del documento</p>
                                <FontAwesomeIcon
                                    icon={faPencilAlt}
                                    style={{ fontSize: 20, color: graphic.colorAccent, cursor: 'pointer' }}
                                    onClick={() => setIsOffersTextModalVisible(true)}
                                />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', gap: 15, justifyContent: 'center', alignItems: 'center' }}>
                                <FontAwesomeIcon icon={faBuilding} style={{ fontSize: 30, color: '#68696D' }} />
                                {renderAddress()}
                                <FontAwesomeIcon
                                    icon={faPencilAlt}
                                    style={{ fontSize: 20, color: graphic.colorAccent, cursor: 'pointer' }}
                                    onClick={() => setIsWorksiteModalVisible(true)}
                                />
                            </div>
                        </div>
                        {order.pricesTotalOffer ? (
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 0.9, alignItems: 'flex-end', gap: 10 }}>
                                <p
                                    style={{
                                        color: '#68696D',
                                        fontWeight: 500,
                                        fontSize: 22,
                                        paddingBottom: 15,
                                        borderBottom: '1px solid #ccc',
                                        padding: '0 20px',
                                        alignSelf: 'stretch',
                                        textAlign: 'right'
                                    }}
                                >
                                    Totale imponibile:{' '}
                                    <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                        {order.pricesTotalOffer?.Totale &&
                                            transformToCurrency(
                                                parseFloat(order.pricesTotalOffer?.Totale) + parseFloat(order.pricesTotalOffer.ScontoApplicato)
                                            )}
                                    </span>
                                </p>
                                <p
                                    style={{
                                        color: '#68696D',
                                        fontWeight: 500,
                                        fontSize: 22,
                                        paddingBottom: 15,
                                        borderBottom: '1px solid #ccc',
                                        padding: '0 20px',
                                        alignSelf: 'stretch',
                                        textAlign: 'right'
                                    }}
                                >
                                    Sconto - ({' '}
                                    {order.pricesTotalOffer && convertSale(order.pricesTotalOffer!.Sconto)[1] !== '0'
                                        ? `${convertSale(order.pricesTotalOffer!.Sconto)[0]}+${convertSale(order.pricesTotalOffer!.Sconto)[1]}%`
                                        : `${order.pricesTotalOffer!.Sconto}%`}
                                    ):{' '}
                                    <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                        {order.pricesTotalOffer?.ScontoApplicato
                                            ? '- ' + transformToCurrency(parseFloat(order.pricesTotalOffer?.ScontoApplicato))
                                            : '- ' + transformToCurrency(0)}
                                    </span>
                                </p>
                                <p
                                    style={{
                                        color: '#68696D',
                                        fontWeight: 500,
                                        fontSize: 22,
                                        paddingBottom: 15,
                                        borderBottom: '1px solid #ccc',
                                        padding: '0 20px',
                                        alignSelf: 'stretch',
                                        textAlign: 'right'
                                    }}
                                >
                                    Spese interne:{' '}
                                    <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                        {order.pricesTotalOffer?.AltreSpese
                                            ? transformToCurrency(parseFloat(order.pricesTotalOffer?.AltreSpese))
                                            : transformToCurrency(0)}
                                    </span>
                                </p>
                                <p
                                    style={{
                                        color: '#68696D',
                                        fontWeight: 500,
                                        fontSize: 22,
                                        paddingBottom: 15,
                                        borderBottom: '1px solid #ccc',
                                        padding: '0 20px',
                                        alignSelf: 'stretch',
                                        textAlign: 'right'
                                    }}
                                >
                                    Spese accessorie:{' '}
                                    <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                        {order.pricesTotalOffer?.SpeseAcc
                                            ? transformToCurrency(parseFloat(order.pricesTotalOffer?.SpeseAcc))
                                            : transformToCurrency(0)}
                                    </span>
                                </p>
                                <p
                                    style={{
                                        color: '#68696D',
                                        fontWeight: 500,
                                        fontSize: 22,
                                        paddingBottom: 15,
                                        borderBottom: '1px solid #ccc',
                                        padding: '0 20px',
                                        alignSelf: 'stretch',
                                        textAlign: 'right'
                                    }}
                                >
                                    IVA: <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>{calculatedIva()}</span>
                                </p>
                                <p
                                    style={{
                                        color: '#68696D',
                                        fontWeight: 500,
                                        fontSize: 22,
                                        paddingBottom: 15,
                                        padding: '0 20px',
                                        alignSelf: 'stretch',
                                        textAlign: 'right'
                                    }}
                                >
                                    Totale offerta:{' '}
                                    <span style={{ color: graphic.colorAccent, fontWeight: 900 }}>
                                        {order.pricesTotalOffer?.ImportoTotale
                                            ? transformToCurrency(parseFloat(order.pricesTotalOffer?.ImportoTotale))
                                            : transformToCurrency(0)}
                                    </span>
                                </p>
                            </div>
                        ) : null}
                    </div>
                    <TextOffersModal isVisible={isOffersTextModalVisible} hideModal={() => setIsOffersTextModalVisible(false)} offerData={order.currentOffer} />
                    <WorksiteAddressModal
                        isVisible={isWorksiteModalVisible}
                        hideModal={() => setIsWorksiteModalVisible(false)}
                        offerData={order.currentOffer}
                    />
                    <DeliveryDateSelectModal isVisible={isSelectDateVisible} hideModal={() => setIsSelectDateVisible(false)} offerData={order.currentOffer} />
                    <DeleteModal
                        isVisible={isDeleteModalVisible}
                        hideModal={() => setIsDeleteModalVisible(false)}
                        onDelete={() => {
                            handleDeleteOffer()
                            setIsDeleteModalVisible(false)
                        }}
                        text={"Cancella l'offerta corrente (ID " + order.currentOffer?.CodiceOfferta + ')'}
                        title={'Elimina offerta corrente'}
                    />
                    <PrintSettingsModal
                        isVisible={visiblePrintSettingsModal}
                        hideModal={() => setVisiblePrintSettingsModal(false)}
                        onConfirm={(type, order) => {
                            handlePrintOffer(type)
                        }}
                    />
                    <ConfirmModal
                        isVisible={isConfirmVisible !== ''}
                        hideModal={() => setIsConfirmVisible('')}
                        isOnlyConfirm
                        title="Sto scaricando il file specifiche"
                        text={
                            isConfirmVisible && isConfirmVisible.charAt(0) == 'P'
                                ? `Sto scaricando il file specifiche per l'offerta ${isConfirmVisible}`
                                : `Sto scaricando il file specifiche per l'ordine ${isConfirmVisible}`
                        }
                        onConfirm={() => setIsConfirmVisible('')}
                    />
                </div>
            )}
        </div>
    )
}

export default CurrentOffer
