import React, { useEffect } from 'react'
import { useState } from 'react'
import { NavLink, useHistory, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setToken, setUser } from '../../store/actions/user'
import { getUserToken, setTokenRedux } from '../../store/actions/thunk_actions'
import { Credentials } from '../../types/user'
import { Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import { LOGO_DEFAULT, LOGO_PRODUCT, BASE_URL, BANNER_LOGIN_ENABLED, EMAIL_ADMIN_PRODUCT,EVIDENCE_NOTE } from '../../resources/constants'
import { setShowContacts } from '../../store/actions/graphic'
//import CustomAxios from '../../customCompontents/CustomAxios' 
 // wl aggiunta 
import { fetchReferences } from '../../resources/requests/requests'

const LoginPage: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const [credentials, setCredentials] = useState<Credentials>({
        grant_type: 'password',
        username: '',
        password: ''
    })

    const [references, setReferences] = useState('')

    const dispatch = useDispatch()
    const history = useHistory()
    const location = useLocation()

    useEffect(() => {
        dispatch(setToken(''))
        if (location.pathname !== '/') {
            history.push('/')
        }
    }, [])

     

    const loginHandle = () => {
        if (credentials.username !== '' && credentials.password !== '') {
            try {
                dispatch(getUserToken(credentials))
               /// const reference = getReferences() 
                console.log("-------------------reference") 
            } catch (error) {
                console.log(error)
            }
        }
    }
    //  gestione dati generali Prodotto

       const getReferences = async () => {
           //dispatch(setIsLoading(true))
           try {
               const references = await fetchReferences(dispatch)
               setReferences(references)
           } catch (error: any) {
               //dispatch(setError(error.toString()))
           }
           //dispatch(setIsLoading(false))
           //return references
       }
    //getReferences()
    //////////////
    return (
        <div>
            <div className="loginContainer">
                <img src={LOGO_DEFAULT} />
                <h2 className="titleLogin" style={{ visibility: 'hidden' }}>
                    Richiedi password{' '}
                </h2>
                <p className="paragraphLogin" style={{ visibility: 'hidden' }}>
                    Inserisci i tuoi dati per ricevere una mail di reset password
                </p>
                {user.token && user.token == 'ERROR' && <p style={{ textAlign: 'center', color: '#FF0000' }}>Username e/o password non corretti</p>}
                <form
                    className="form"
                    onSubmit={(event) => {
                        event.preventDefault()
                        loginHandle()
                    }}
                >
                    <div className="form-group">
                        <input type="string" onChange={(event) => setCredentials({ ...credentials, username: event.target.value })} placeholder="Username" />
                    </div>
                    <div className="form-group">
                        <input type="password" onChange={(event) => setCredentials({ ...credentials, password: event.target.value })} placeholder="Password" />
                    </div>
                    <div className="actionsForm">
                        <button type="submit" className="submitLogin" style={{ opacity: credentials.username && credentials.password ? 1 : 0.6 }}>
                            Accedi
                        </button>
                        <NavLink
                            to="/forgot-password"
                            //@ts-ignore
                            exact
                            className="loginLink"
                            activeClassName="active"
                        >
                            Richiedi nuova password
                        </NavLink>
                    </div>

                    <img src={LOGO_PRODUCT} className="logoBG" />
                    {/* <p
                        style={{
                            padding: 18,
                            fontSize: 20,
                            color: '#fff',
                            textAlign: 'center',
                            opacity: requestState == LoginStatus.FAILED ? 1 : 0
                        }}
                    >
                        Login fallito, riprova
                    </p> */}
                </form>
            </div>
            {
                <div
                    style={{
                        fontSize: 20,
                        color: '#000',

                        position: 'absolute',
                        top: 30,
                        left: '35%',
                        transform: 'transition(-50%, 0)'
                    }}
                >
                    {BANNER_LOGIN_ENABLED === true
                        ? EVIDENCE_NOTE
                        : ''}
                </div>
            }
            <p
                style={{
                    fontSize: 16,
                    color: '#000',
                    textDecoration: 'underline',
                    position: 'absolute',
                    bottom: 20,
                    left: '47%',
                    transform: 'transition(-50%, 0)'
                }}
                onClick={() => dispatch(setShowContacts(true))}
            >
                Contatti
            </p>
        </div>
    )
}

export default LoginPage
