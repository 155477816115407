import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import ProductCard from '../components/ProductCard'
import TitlePage from '../components/TitlePage'
import { setSelectedCategory } from '../store/actions/graphic'
import { setLastProduct } from '../store/actions/user'
import { ReducerData, ReducerGraphic, Reducers } from '../types/reducers'

const ProductsPage: React.FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const [arrayProducts, setArrayProducts] = useState(data.series)
    const [filterSearch, setFilterSearch] = useState("")
    const history = useHistory()
    const dispatch = useDispatch()

    useEffect(() => {
        setFilterSearch("")
        const filtered = data.series.filter((s) => s.IdCategoriaSerie == graphic.categorySelected?.IdCategorSerie);
        if (filtered.length > 0) {
            setArrayProducts(filtered)
        } else {
            setArrayProducts(data.series)
        }
    }, [graphic.categorySelected])

    useEffect(() => {
        if (filterSearch == "") {
            const filtered = data.series.filter((s) => s.IdCategoriaSerie == graphic.categorySelected?.IdCategorSerie);
            if (filtered.length > 0) {
                setArrayProducts(filtered)
            } else {
                setArrayProducts(data.series)
            }
            return
        }
        const filtered = arrayProducts.filter((s) => s.NomeSerie.toLowerCase().includes(filterSearch) || s.FamigliaProdotto.toLowerCase().includes(filterSearch))
        setArrayProducts(filtered)
    }, [filterSearch])


    const renderProducts = () => {
        if (filterSearch !== "" && arrayProducts.length == 0) {
            return (
                <p className="nothingFound">Nessun prodotto trovato</p>
            )
        }
        return (
            arrayProducts.map((s) => {
                return <ProductCard
                    key={s.ID}
                    product={s}
                    onClick={() => {
                        dispatch(setLastProduct(s))
                        history.push('/quote', { product: s })
                        const categoryProduct = data.categories.find((c) => c.IdCategorSerie == s.IdCategoriaSerie)
                        if (categoryProduct) {
                            dispatch(setSelectedCategory(categoryProduct))
                        }
                    }}
                />
            })
        )
    }

    return (
        <div>
            <div className="introProductsPage">
                <TitlePage text={graphic.categorySelected?.Nome ? graphic.categorySelected.Nome : "Tutti i prodotti"} />
                <div className="filterInput">
                    <FontAwesomeIcon icon={faSearch} className="icon" />
                    <input value={filterSearch} onChange={(e) => setFilterSearch(e.target.value)} placeholder="Filtra prodotti..." />
                </div>
            </div>
            <div className="productsPageGrid">
                {renderProducts()}
            </div>
        </div>
    )
}

export default ProductsPage