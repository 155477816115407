import React, { useEffect } from 'react'
import { useState } from 'react'
import TitlePage from '../../components/TitlePage'
import { ReducerGraphic, Reducers, ReducerUser } from '../../types/reducers'
import { useSelector } from 'react-redux'
import OrdiniScreen from './screens/OrdiniScreen'
import OfferteScreen from './screens/OfferteScreen'
import { Database, Stats } from '../../types/data'
import { FilterStats, getStats } from '../../resources/requests/stats'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { usePrevious } from '../../utilities/functions'
import { getDatabases } from '../../resources/requests/others'

enum StatsSubMenu {
    Ordini = 'Ordini',
    Offerte = 'Offerte'
}

const statsSubMenuVoices = [StatsSubMenu.Ordini, StatsSubMenu.Offerte]

const StatsPage: React.FC = () => {
    const [selectedVoice, setSelectedVoice] = useState(StatsSubMenu.Ordini)
    const graphic: ReducerGraphic = useSelector((store: Reducers) => store.graphic)
    const startDate = moment().subtract(2, 'weeks').format('YYYY-MM-DD')
    const endDate = moment().format('YYYY-MM-DD')
    const [orderStats, setOrderStats] = useState<Stats | null>(null)
    const [filterOrders, setFilterOrders] = useState<FilterStats>({
        Tipo: 'O',
        DataStart: startDate,
        DataEnd: endDate,
        CodAg: null,
        CodListino: null,
        CodiceSerie: null,
        CodAz: null,
        DBSourceName: null
    })
    const [noOrdersFound, setNoOrderFound] = useState(false)
    const [offerStats, setOfferStats] = useState<Stats | null>(null)
    const [filterOffers, setFilterOffers] = useState<FilterStats>({
        Tipo: 'P',
        DataStart: startDate,
        DataEnd: endDate,
        CodAg: null,
        CodListino: null,
        CodiceSerie: null,
        CodAz: null,
        DBSourceName: null
    })
    const [databases, setDatabases] = useState<Database[]>([])
    const [noOffersFound, setNoOffersFound] = useState(false)
    const [orderSubtitle, setOrderSubtitle] = useState('')
    const [orderTimeExec, setOrderTimeExec] = useState('')
    const [orderNotes, setOrderNotes] = useState('')
    const [offerSubtitle, setOfferSubtitle] = useState('')
    const [offerTimeExec, setOfferTimeExec] = useState('')
    const [offerNotes, setOfferNotes] = useState('')
    const dispatch = useDispatch()
    const previousFilterOrders = usePrevious(filterOrders)
    const previousFilterOffers = usePrevious(filterOffers)

    const renderMenuVoices = () => {
        return statsSubMenuVoices.map((m, i) => {
            return (
                <span
                    key={m}
                    className="menuVoice"
                    style={{
                        borderBottomWidth: selectedVoice == m ? 3 : 0,
                        color: selectedVoice == m ? graphic.colorAccent : '#68696D'
                    }}
                    onClick={() => setSelectedVoice(m)}
                >
                    {m}
                </span>
            )
        })
    }

    const fetchDatabases = async () => {
        dispatch(setIsLoading(true))
        try {
            const databases = await getDatabases(dispatch)
            setDatabases(databases)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const fetchOrderStats = async () => {
        dispatch(setIsLoading(true))
        try {
            const stats = await getStats(filterOrders, dispatch)
            if (!stats) {
                setNoOrderFound(true)
            } else {
                setNoOrderFound(false)
            }
            setOrderSubtitle(stats.Sottotitolo)
            setOrderTimeExec(stats.TimeExecute)
            setOrderNotes(stats.Note)
            setOrderStats(stats.Data)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const fetchOffersStats = async () => {
        dispatch(setIsLoading(true))
        try {
            const stats = await getStats(filterOffers, dispatch)
            if (!stats) {
                setNoOffersFound(true)
            } else {
                setNoOffersFound(false)
            }
            setOfferSubtitle(stats.Sottotitolo)
            setOfferTimeExec(stats.TimeExecute)
            setOfferNotes(stats.Note)
            setOfferStats(stats.Data)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    useEffect(() => {
        if (filterOrders && previousFilterOrders) {
            fetchOrderStats()
        }
    }, [filterOrders])

    useEffect(() => {
        if (filterOffers && previousFilterOffers) {
            fetchOffersStats()
        }
    }, [filterOffers])

    useEffect(() => {
        fetchDatabases()
    }, [])

    const renderCurrentScreen = () => {
        switch (selectedVoice) {
            case StatsSubMenu.Ordini:
                return (
                    <OrdiniScreen
                        currentStats={orderStats}
                        lastFilter={filterOrders}
                        onGetStats={(filter) => setFilterOrders(filter)}
                        databases={databases}
                        noOrders={noOrdersFound}
                        subtitle={orderSubtitle}
                        timeExec={orderTimeExec}
                        notes={orderNotes}
                    />
                )
            case StatsSubMenu.Offerte:
                return (
                    <OfferteScreen
                        currentStats={offerStats}
                        lastFilter={filterOffers}
                        onGetStats={(filter) => setFilterOffers(filter)}
                        databases={databases}
                        noOffers={noOffersFound}
                        subtitle={offerSubtitle}
                        timeExec={offerTimeExec}
                        notes={offerNotes}
                    />
                )
            default:
                return null
        }
    }

    return (
        <div>
            <TitlePage text="Statistiche" />
            <div className="settingsSubMenu" style={{ justifyContent: 'flex-start' }}>
                {renderMenuVoices()}
            </div>
            {renderCurrentScreen()}
        </div>
    )
}

export default StatsPage
