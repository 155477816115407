import React, { useEffect, useMemo } from 'react'
import SubtitleSection from '../../../components/SubtitleSection'
import Button from '../../../components/Button'
import { faReceipt, faSearch, faTag, faUserPlus } from '@fortawesome/free-solid-svg-icons'
import { useState } from 'react'
import { ReducerData, Reducers, ReducerUser } from '../../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { setError, setIsLoading } from '../../../store/actions/graphic'
import { setAccountsRedux } from '../../../store/actions/thunk_actions'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Agente } from '../../../types/data'
import MaterialTable from 'material-table'
import { locale_itIT } from '../../../utilities/tableLocale'
import OffersListModal from '../../../components/modals/OffersListModal'
import OrdersListModal from '../../../components/modals/OrdersListModal'
import DeleteModal from '../../../components/modals/DeleteModal'
import EditAgentModal from '../../../components/modals/EditAgentModal'

const GestioneAgentiScreen: React.FC = () => {
    const user: ReducerUser = useSelector((store: Reducers) => store.user)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [filterSearch, setFilterSearch] = useState('')
    const [selectedAgent, setSelectedAgent] = useState<Agente | null>(null)
    const [isVisibleEditModal, setIsVisibleEditModal] = useState(false)
    const [isVisibleOfferListModal, setIsVisibleOfferListModal] = useState(false)
    const [isVisibleOrderListModal, setIsVisibleOrderListModal] = useState(false)
    const [isVisibleDeleteModal, setIsVisibleDeleteModal] = useState(false)
    const dispatch = useDispatch()

    const renderDatas = useMemo(() => {
        if (filterSearch) {
            return data.agents.filter((a) => a.DenAg.toLowerCase().includes(filterSearch.toLowerCase()))
        } else {
            return data.agents
        }
    }, [data.agents, filterSearch])

    const renderActions = useMemo(() => {
        const actions = [
            () => ({
                icon: 'edit',
                iconProps: {
                    fontSize: 'medium'
                },
                tooltip: 'Modifica cliente',
                onClick: (event: any, rowData: Agente) => {
                    setSelectedAgent(rowData)
                    setIsVisibleEditModal(true)
                }
            }),
            () => ({
                // eslint-disable-next-line react/display-name
                icon: () => <FontAwesomeIcon icon={faTag} style={{ fontSize: 20, color: '#68696D' }} />,
                tooltip: 'Lista offerte',
                onClick: (event: any, rowData: Agente) => {
                    setSelectedAgent(rowData)
                    setIsVisibleOfferListModal(true)
                }
            }),
            () => ({
                // eslint-disable-next-line react/display-name
                icon: () => <FontAwesomeIcon icon={faReceipt} style={{ fontSize: 20, color: '#68696D' }} />,
                tooltip: 'Lista ordini',
                onClick: (event: any, rowData: Agente) => {
                    setSelectedAgent(rowData)
                    setIsVisibleOrderListModal(true)
                }
            })
        ]

        return actions
    }, [])

    const columns = [
        {
            title: 'ID',
            field: 'CodAg'
        },
        {
            title: 'Nominativo',
            field: 'DenAg'
        },
        {
            title: 'Tipo',
            field: 'Tipo'
        },
        {
            title: 'Livello',
            field: 'Agente',
            render: (rowData: Agente) => {
                return data.levelsAgent.find((a) => a.livello == rowData.Agente)?.categoria
            }
        }
    ]

    const handleDelete = async () => {
        if (selectedAgent) {
            dispatch(setIsLoading(true))
            try {
                //
                await dispatch(setAccountsRedux())
                setIsVisibleDeleteModal(false)
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    return (
        <div>
            <div className="introProductsPage">
                <SubtitleSection text="Lista operatori" />
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: 20 }}>
                    <div className="filterInput">
                        <FontAwesomeIcon icon={faSearch} className="icon" />
                        <input value={filterSearch} onChange={(e) => setFilterSearch(e.target.value)} placeholder="Ricerca operatore..." />
                    </div>
                    <Button
                        text="Aggiungi"
                        hasIcon={true}
                        icon={faUserPlus}
                        onClick={() => {
                            setIsVisibleEditModal(true)
                            setSelectedAgent(null)
                        }}
                    />
                </div>
            </div>
            <div>
                <MaterialTable
                    title=""
                    columns={columns}
                    data={renderDatas}
                    localization={locale_itIT}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 10,
                        pageSizeOptions: [10, 20, 50],
                        search: false,
                        headerStyle: {
                            backgroundColor: '#ebebeb',
                            borderRadius: 20
                        }
                    }}
                    //@ts-ignore
                    actions={renderActions}
                />
            </div>
            <EditAgentModal agent={selectedAgent} isVisible={isVisibleEditModal} hideModal={() => setIsVisibleEditModal(false)} />
            <DeleteModal
                isVisible={isVisibleDeleteModal}
                hideModal={() => setIsVisibleDeleteModal(false)}
                onDelete={() => handleDelete()}
                title={'Eliminazione cliente'}
                text={'Vuoi davvero eliminare il cliente ' + selectedAgent?.DenAg + '?'}
            />
            <OffersListModal
                offers={selectedAgent ? data.offers.filter((o) => o.CodAg == selectedAgent.CodAg) : []}
                agent={selectedAgent}
                isVisible={isVisibleOfferListModal}
                hideModal={() => setIsVisibleOfferListModal(false)}
            />
            <OrdersListModal
                orders={selectedAgent ? data.orders.filter((o) => o.CodAg == selectedAgent.CodAg) : []}
                agent={selectedAgent}
                isVisible={isVisibleOrderListModal}
                hideModal={() => setIsVisibleOrderListModal(false)}
            />
        </div>
    )
}

export default GestioneAgentiScreen
