import { setError } from '../../store/actions/graphic'
import { Ambito } from '../../utilities/Ambito'
import { NewCustomAxios } from '../../customCompontents/CustomAxios'
import { AdditionalCost, AdditionalCostOffer, Cliente, OfferArchive, OrderArchive, Tax } from '../../types/data'
import {
    ComposedItem,
    DefaultDataTypology,
    DettaglioItemInQuote,
    ItemInQuote,
    ItemToEdit,
    NewQuoteFromUser,
    TotaliOfferta,
    VariantAdd,
    VariantItem,
    VariantRemove
} from '../../types/order'
import CustomAxios from '../../customCompontents/CustomAxios'
import { AddItemsToPreventivoBody, Typology } from '../../types/data'
import { AgentUpdateData, Company, PrintPage, User } from '../../types/user'
import { logoutUser } from '../../store/actions/user'
import { BASE_URL, TOKEN_ERROR_STRING } from '../constants'

//export const BASE_URL = 'http://54.228.96.60:3001/'
//export const BASE_URL = 'http://54.228.96.60:3004/'

export const tokenAccessDev = {
    grant_type: 'password',
    username: 'r.morrone',
    password: 'rmorroneBMFZ0Z1@!'
}

export const tokenAccessProd = {
    grant_type: 'password',
    username: 'adminbiemmefinestre',
    password: 'biemmefinestreZ0Z1@!'
}

export const headersJson = {
    headers: {
        'Content-Type': 'application/json'
    }
}

const multipartHeaders = {
    headers: {
        'Content-Type': 'multipart/form-data'
    }
}

export const getToken = async (access: any) => {
    const token = await CustomAxios.post(BASE_URL + 'TokenBM', access).then((data) => {
        if (data.data.accessToken) {
            return data.data.accessToken
        } else {
            return 'ERROR'
        }
    })
    return token
}

export const getResetPasswordRequest = async (username: string, email: string) => {
    const body = {
            username: username,
            email: email
        }
    //const request = 

    console.log('Request', BASE_URL + 'bmapi/forgotpassword') 

    await CustomAxios.post(BASE_URL + 'bmapi/forgotpassword', body).then((data) => {
      console.log('Res', data) 
        if (data.data.accessToken) {
            return 'OK'//data.data.accessToken
        } else
         {
            return 'OK' 
        }
    })
    return 'OK' 
}
/*
export const getResetPasswordRequest = async (username: string, email: string, dispatch: (action: any) => void) => {
    const body = {
        username: username,
        email: email
    }
    const request = await CustomAxios.post(BASE_URL + 'bmapi/forgotpassword', body, headersJson).then((data) => {
        if (data.data.Message ) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return null
        }
    })
    return request
}
*/
export const getUserOnline = async (dispatch: (action: any) => void) => {
    const number = await CustomAxios.get(BASE_URL + 'bmapi/UtentiConnessi').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data[0].UtentiConnessi
        } else {
            return ''
        }
    })
    return number
}

export const getListino = async (dispatch: (action: any) => void) => {
    const listino = await CustomAxios.post(BASE_URL + 'bmapi/Listino').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data[0]
        } else {
            return null
        }
    })
    return listino
}

export const getSeries = async (dispatch: (action: any) => void) => {
    const series = await CustomAxios.post(BASE_URL + 'bmapi/FrontendSerie/ElencoSerieWEB').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return []
        }
    })
    return series
}

export const getCategories = async (dispatch: (action: any) => void) => {
    const categories = await CustomAxios.get(BASE_URL + 'bmapi/FrontendCategorieSerie/GetCategorieSerie').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return []
        }
    })
    return categories
}

export const getProvinces = async (dispatch: (action: any) => void) => {
    const provinces = await CustomAxios.get(BASE_URL + 'bmapi/Prov').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return []
        }
    })
    return provinces
}

export const getCities = async (dispatch: (action: any) => void) => {
    const cities = await CustomAxios.get(BASE_URL + 'bmapi/Comuni').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return []
        }
    })
    return cities
}

export const getCustomers = async (dispatch: (action: any) => void) => {
    const defaultBody = {
        filter: {
            tutti: true,
            codAg: null,
            nominativo: null,
            provincia: null
        },
        sort: {
            field: 'CodAg',
            order: 'ASC'
        },
        bound: {
            limit: 0,
            offset: 0
        }
    }
    const customers = await CustomAxios.post(BASE_URL + 'bmapi/Contatti/list', defaultBody, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return []
        }
    })
    return customers
}

export const getAgents = async (dispatch: (action: any) => void) => {
    const defaultBody = {
        filter: {
            codAg: null,
            nome: null,
            parent: null,
            provincia: null
        },
        sort: {
            field: null,
            order: null
        },
        bound: {
            limit: 0,
            offset: 0
        }
    }
    const agents = await CustomAxios.post(BASE_URL + 'bmapi/Utenti/Agenti', defaultBody, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return agents
}

export const getTypologies = async (idSerie: string, dispatch: (action: any) => void) => {
    const body = {
        filter: {
            CodSerie: idSerie
        },
        sort: {
            field: null,
            order: 'null'
        },
        bound: {
            limit: 50,
            offset: 0
        }
    }
    const typologies: Typology[] = await CustomAxios.post(BASE_URL + 'bmapi/Listino/Serie', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            console.log('QUA')
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return []
        }
    })
    return typologies
}

export const getAllVariantsPerItem = async (idRecord: string, idCategory: string, dispatch: (action: any) => void) => {
    const body = {
        idItem: idRecord,
        filter: {
            IdCatVar: idCategory,
            IdGenereVar: null
        },
        sort: {
            field: null,
            order: null
        },
        bound: {
            limit: null,
            offset: null
        }
    }
    const items: VariantItem[] = await CustomAxios.post(BASE_URL + 'bmapi/Varianti/ElencoVarAmmesseItemfilter', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return []
        }
    })
    return items
}

export const getAllShapePerItem = async (idRecord: string, Htip: number, Ltip: number, dispatch: (action: any) => void) => {
    const body = {
        idItem: idRecord,
        Htip: Htip,
        Ltip: Ltip,
        filter: {
            IdCatVar: null
        },
        sort: {
            field: null,
            order: null
        },
        bound: {
            limit: null,
            offset: null
        }
    }
    const items: VariantItem[] = await CustomAxios.post(BASE_URL + 'bmapi/Varianti/ElencoGeometrieVarAmmesseItemfilter', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data.map((d: VariantItem) => {
                if (!d.DescrizVar) {
                    return {
                        ...d,
                        DescrizVar: 'Predefinito'
                    }
                } else {
                    return d
                }
            })
        } else {
            return []
        }
    })
    return items
}

export const getSingleItemQuote = async (idRecord: string, idQuote: string | undefined, dispatch: (action: any) => void) => {
    const body = {
        idItem: idRecord,
        CodicePreventivo: idQuote
    }
    const item: DettaglioItemInQuote = await CustomAxios.post(BASE_URL + 'bmapi/Varianti/ViewItem', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return null
        }
    })
    return item
}

export const getAllItemsQuote = async (idQuote: string | undefined, dispatch: (action: any) => void) => {
    const body = {
        CodicePreventivo: idQuote,
        filter: {
            Etichetta: null,
            CodTipoItem: null,
            Htip: null,
            Ltip: null,
            Quantita: null
        },
        sort: {
            field: 'Etichetta',
            order: 'ASC'
        },
        bound: {
            limit: 10,
            offset: 0
        }
    }
    const items: DettaglioItemInQuote[] = await CustomAxios.post(BASE_URL + 'bmapi/Varianti/ViewItems', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return []
        }
    })
    return items
}

export const getDefaultDataTypology = async (idTypology: string, dispatch: (action: any) => void) => {
    const defaultData: DefaultDataTypology = await CustomAxios.get(BASE_URL + 'bmapi/MacroItemDefaultData/' + idTypology).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data[0]
        }
    })
    return defaultData
}

export const getVariantiPresentiItem = async (idRecord: string, dispatch: (action: any) => void) => {
    const body = {
        idItem: idRecord
    }
    const variants = await CustomAxios.post(BASE_URL + 'bmapi/Varianti/ElencoVarPresentiItem', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return []
        }
    })
    return variants
}

export const getAdditionalVariantsItem = async (idRecord: number, dispatch: (action: any) => void) => {
    const body = {
        idItem: idRecord,
        filter: {
            IdCatVar: null,
            IdGenereVar: null
        },
        sort: {
            field: null,
            order: null
        },
        bound: {
            limit: null,
            offset: null
        }
    }
    const variants: VariantItem[] = await CustomAxios.post(BASE_URL + 'bmapi/Varianti/ElencoVarAggiuntiveItemfilter', JSON.stringify(body), headersJson).then(
        (data) => {
            if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
                dispatch(logoutUser())
            }
            if (data.data.Data) {
                return data.data.Data
            } else {
                return []
            }
        }
    )
    return variants
}

export const getTotalPricesOffer = async (idOffer: string | undefined, dispatch: (action: any) => void) => {
    const prices: TotaliOfferta | null = await CustomAxios.get(BASE_URL + 'bmapi/TotaliOfferta/' + idOffer).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return null
        }
    })
    return prices
}

export const getOfferData = async (idOffer: string | undefined, dispatch: (action: any) => void) => {
    const body = {
        CodOfferta: idOffer
    }
    const offerData = await CustomAxios.post(BASE_URL + 'bmapi/Contratto/LoadOfferta', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return null
        }
    })
    return offerData
}

export const getOffers = async (dispatch: (action: any) => void) => {
    const offers: OfferArchive[] = await CustomAxios.post(BASE_URL + 'bmapi/Contratto/ElencoPreventivi').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return offers
}

export const getOrders = async (dispatch: (action: any) => void) => {
    const orders: OrderArchive[] = await CustomAxios.post(BASE_URL + 'bmapi/Contratto/ElencoOrdini').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return orders
}

export const getTaxes = async (dispatch: (action: any) => void) => {
    const taxes: Tax[] = await CustomAxios.get(BASE_URL + 'bmapi/AliquotaIVA').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return []
        }
    })
    return taxes
}

export const getAdditionalCostList = async (codPreventivo: string | undefined, dispatch: (action: any) => void) => {
    const costs: AdditionalCost[] = await CustomAxios.get(BASE_URL + 'bmapi/SpeseAccessorie/' + codPreventivo).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return []
        }
    })
    return costs
}

export const getPendingOffers = async (dispatch: (action: any) => void) => {
    const pendingOffer = await CustomAxios.get(BASE_URL + 'bmapi/Contratto/InCorso').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data[0].CodiceOfferta
        } else {
            return null
        }
    })
    return pendingOffer
}

export const getAdditionalCostsOffer = async (body: { CodiceOfferta: string | null; CodicePreventivo: string | null }, dispatch: (action: any) => void) => {
    const costs: AdditionalCostOffer = await CustomAxios.post(BASE_URL + 'bmapi/Contratto/GetSpese', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data[0]
        } else {
            return null
        }
    })
    return costs
}

export const getMyData = async (dispatch: (action: any) => void) => {
    const data: User = await CustomAxios.post(BASE_URL + 'bmapi/Utenti/Io').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data[0]
        } else {
            return null
        }
    })
    return data
}

export const getAccounts = async (dispatch: (action: any) => void) => {
    const body = {
        filter: {
            codAg: null,
            nome: null,
            parent: null,
            provincia: null
        },
        sort: {
            field: null,
            order: null
        },
        bound: {
            limit: null,
            offset: null
        }
    }
    const accounts = await CustomAxios.post(BASE_URL + 'bmapi/Utenti/Account', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return accounts
}

export const getSaleCategories = async (dispatch: (action: any) => void) => {
    const sales = await CustomAxios.get(BASE_URL + 'bmapi/Sconti/CatSconti').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return sales
}

export const getLevelAgent = async (dispatch: (action: any) => void) => {
    const levels = await CustomAxios.post(BASE_URL + 'bmapi/Utenti/LivelloList').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return levels
}

export const getAccountGroups = async (dispatch: (action: any) => void) => {
    const groups = await CustomAxios.post(BASE_URL + 'bmapi/Utenti/GruppiList').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return groups
}

export const getComposedItem = async (idItem: number, dispatch: (action: any) => void) => {
    const body = {
        IdItem: idItem
    }
    const item = await NewCustomAxios.post(BASE_URL + 'bmapi/CAD/GetFiguraCompostaItem', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        //console.log(data.data.Data)
        if (data.data.Data) {
            return data.data.Data
        } else {
            return null
        }
    })
    return item
}

export const getComposedImage = async (body: any, dispatch: (action: any) => void) => {
    const img = await CustomAxios.post(BASE_URL + 'bmapi/CAD/CalcolaFiguraGeometricaCompostaItem2', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.FiguraQuotata) {
            return data.data.Data.FiguraQuotata
        } else {
            return ''
        }
    })
    return img
}

export const getComposedTypologiesPerSeries = async (codSerie: string, width: number, height: number, dispatch: (action: any) => void) => {
    const body = {
        filter: {
            CodSerie: codSerie,
            BOX_L: width,
            BOX_H: height
        },
        sort: {
            field: null,
            order: null
        },
        bound: {
            limit: 0,
            offset: 0
        }
    }
    const typologies: Typology[] = await CustomAxios.post(BASE_URL + 'bmapi/Listino/ComposteXSerie', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return typologies
}

export const getOrderStatusList = async (dispatch: (action: any) => void) => {
    const statusArray = await CustomAxios.post(BASE_URL + 'bmapi/Contratto/ElencoStatusDoc').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return statusArray
}

export const viewDatiAzienda = async (dispatch: (action: any) => void) => {
    const data: Company = await CustomAxios.post(BASE_URL + 'bmapi/Utenti/ViewDatiAziendali').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data[0]
        } else {
            return null
        }
    })
    return data
}

export const getCompaniesList = async (dispatch: (action: any) => void) => {
    const companies: Company[] = await CustomAxios.post(BASE_URL + 'bmapi/Utenti/ListDatiAziendali').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return companies
}
export const createQuoteFromCustomer = async (body: any, dispatch: (action: any) => void) => {
    const quoteUser: NewQuoteFromUser = await CustomAxios.put(BASE_URL + 'bmapi/Contratto/CreatePreventivo', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data[0]
        } else {
            return []
        }
    })
    return quoteUser
}

export const addQuoteToOffer = async (idContatto: number, CodSerie: string, CodOfferta: string, dispatch: (action: any) => void) => {
    const body = {
        idContatto: idContatto,
        CodSerie: CodSerie,
        CodOfferta: CodOfferta
    }
    const quoteUser: NewQuoteFromUser = await CustomAxios.put(BASE_URL + 'bmapi/Contratto/CreatePreventivo', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data[0]
        } else {
            return null
        }
    })
    return quoteUser
}

export const addItemToPreventivo = async (body: AddItemsToPreventivoBody, dispatch: (action: any) => void) => {
    const itemAdded = await CustomAxios.put(BASE_URL + 'bmapi/Preventivo/AddItems', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data.map((n: any) => {
                return { Idrecord: n.Idrecord.toString() }
            })
        } else {
            return []
        }
    })
    return itemAdded
}

export const createDefaultVariants = async (body: any, dispatch: (action: any) => void) => {
    const items: ItemInQuote[] = await CustomAxios.post(BASE_URL + 'bmapi/Varianti/CreaVariantiDefault', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return []
        }
    })
    return items
}

export const deleteAllQuotes = async (dispatch: (action: any) => void) => {
    await CustomAxios.delete(BASE_URL + 'bmapi/Contratto/PulisciTemporanei').then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const switchVariantsPerItem = async (
    idRecord: string,
    idPreventivo: string | undefined,
    arrayVariantsAdd: VariantAdd[],
    arrayVariantsRemove: VariantRemove[],
    dispatch: (action: any) => void
) => {
    const body = {
        idItem: idRecord,
        CodicePreventivo: idPreventivo,
        AddVarianti: arrayVariantsAdd,
        RemoveVarianti: arrayVariantsRemove
    }
    await CustomAxios.post(BASE_URL + 'bmapi/Varianti/SwitchVarianti', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const switchVariantsSelectedItems = async (
    idRecords: { idItem: string }[],
    idPreventivo: string | undefined,
    arrayVariantsAdd: VariantAdd[],
    arrayVariantsRemove: VariantRemove[],
    dispatch: (action: any) => void
) => {
    const body = {
        items: idRecords,
        CodicePreventivo: idPreventivo,
        AddVarianti: arrayVariantsAdd,
        RemoveVarianti: arrayVariantsRemove
    }
    const data = await CustomAxios.post(BASE_URL + 'bmapi/Varianti/SwitchVariantiItems', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return null
        }
    })
    return data
}

export const editItemPreventivo = async (body: ItemToEdit, dispatch: (action: any) => void) => {
    await CustomAxios.post(BASE_URL + 'bmapi/Preventivo/EditItem', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const saveCurrentOffer = async (idOffer: string | undefined, dispatch: (action: any) => void) => {
    const body = {
        CodOfferta: idOffer
    }
    const offer = await CustomAxios.post(BASE_URL + 'bmapi/Contratto/ArchiviaOfferta', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        }
    })
    return offer
}

export const recalculateItems = async (
    codPreventivo: string | undefined,
    codOffer: string | undefined,
    idRecords: { idItem: string }[],
    dispatch: (action: any) => void
) => {
    const body = {
        CodicePreventivo: codPreventivo,
        CodiceOfferta: codOffer,
        DataItems: idRecords
    }
    await CustomAxios.post(BASE_URL + 'bmapi/Contratto/RicalcolaItems', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const createPDFToPrint = async (codOfferta: string | undefined, dispatch: (action: any) => void) => {
    const body = {
        CodiceOfferta: codOfferta,
        idPreventivo: null
    }
    const pdfLink: PrintPage = await CustomAxios.post(BASE_URL + 'bmapi/Contratto/CreaPDF', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data) {
            return data.data
        } else {
            return null
        }
    })
    return pdfLink
}

export const createXMLToDownload = async (codOfferta: string | undefined, dispatch: (action: any) => void) => {
    const body = {
        CodiceOfferta: codOfferta
    }
    const xmlLink = await CustomAxios.post(BASE_URL + 'bmapi/Contratto/CreaXML', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.XmlFile) {
            return data.data.XmlFile
        } else {
            return null
        }
    })
    return xmlLink
}

export const calculateGeometry = async (
    body: {
        IdItem: number
        Etichetta: string
        HManiglia: string | null
        HTraverso1: string | null
        HTraverso2: string | null
        HTraverso3: string | null
        HTraverso4: string | null
        Forma: string
        L1: number
        L2: number | null
        H1: number
        H2: number | null
        BOX_L: number
        BOX_H: number
        Icona: string
        Figura: string
        PRM_Formula: string
        CodVar: string
    },
    dispatch: (action: any) => void
) => {
    const geometry: { Figura: string; FiguraQuotata: string; PRM: string } = await CustomAxios.post(
        BASE_URL + 'bmapi/CAD/CalcolaFiguraGeometricaItem',
        body,
        headersJson
    ).then((data) => {
        if (data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return null
        }
    })
    return geometry
}

export const deleteItemFromQuote = async (idRecord: string, dispatch: (action: any) => void) => {
    await CustomAxios.delete(BASE_URL + 'bmapi/Preventivo/' + idRecord).then((res) => {
        if (res.data.Message && res.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const deleteQuoteFromOffer = async (idQuote: string, dispatch: (action: any) => void) => {
    await CustomAxios.delete(BASE_URL + 'bmapi/Contratto/' + idQuote).then((res) => {
        if (res.data.Message && res.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const LogoutUser = async () => {
    await CustomAxios.post(BASE_URL + 'bmapi/Utenti/Logout')
}

export const setPrezzoFinale = async (idItem: string, sconto: number, dispatch: (action: any) => void) => {
    const body = {
        idItem: idItem,
        sconto: sconto.toString(),
        prezzoFinale: null
    }
    const price = await CustomAxios.post(BASE_URL + 'bmapi/Varianti/SetPrezzoFinaleItem', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        return data.data.Data.PrezzoFinale
    })
    return price
}

export const convertOfferToOrder = async (codOfferta: string | undefined, dispatch: (action: any) => void) => {
    const body = {
        CodiceOfferta: codOfferta
    }
    await CustomAxios.post(BASE_URL + 'bmapi/Contratto/ConvertiOfferta', body, headersJson).then((res) => {
        if (res.data.Message && res.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const calculateTotalPrices = async (codOfferta: string | undefined, codIva: string | null, dispatch: (action: any) => void) => {
    const body = {
        CodiceOfferta: codOfferta,
        CodiceIva: codIva
    }
    const prices: TotaliOfferta = await CustomAxios.post(BASE_URL + 'bmapi/Contratto/RicalcolaTotaliOfferta', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return null
        }
    })
    return prices
}

export const setAdditionalDatasOffer = async (
    body: {
        CodiceOfferta: string
        Descrizione: string
        CantiereInstallazione: string
        IndirizzoCantiere: string
        ProvinciaCantiere: string
        ComuneCantiere: string
        CAPCantiere: string
        TestoIniziale: string
        TestoFinale: string
        DataConsegna: string
    },
    dispatch: (action: any) => void
) => {
    await CustomAxios.post(BASE_URL + 'bmapi/Contratto/SetDatiAggiuntiviOfferta', body, headersJson).then((res) => {
        if (res.data.Message && res.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const duplicateOffer = async (idOffer: string, dispatch: (action: any) => void) => {
    const body = {
        CodiceOfferta: idOffer
    }
    return await CustomAxios.post(BASE_URL + 'bmapi/Contratto/DuplicaOfferta', body, headersJson).then((res) => {
        if (res.data.Message && res.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (res.data.Data.length > 0) {
            return res.data.Data[0].CodiceOfferta
        }
    })
}

export const deleteOffer = async (idOffer: string, dispatch: (action: any) => void) => {
    await CustomAxios.delete(BASE_URL + 'bmapi/Contratto/' + idOffer).then((res) => {
        if (res.data.Message && res.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const switchCosts = async (
    body: {
        CodiceOfferta: string | null
        CodicePreventivo: string | null
        AddSpese: { Totale: number; Descrizione: string }[]
        RemoveSpese: { ID: number }[]
    },
    dispatch: (action: any) => void
) => {
    await CustomAxios.post(BASE_URL + 'bmapi/Contratto/SwitchSpese', body, headersJson).then((res) => {
        if (res.data.Message && res.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const duplicateItemInQuote = async (idOffer: string, idItem: string, dispatch: (action: any) => void) => {
    const body = {
        CodiceOfferta: idOffer,
        idItem: idItem
    }
    const item = await CustomAxios.post(BASE_URL + 'bmapi/Contratto/DuplicaItemPreventivo', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data
        } else {
            return null
        }
    })
    return item
}

export const createCliente = async (customer: Cliente, dispatch: (action: any) => void) => {
    const body = {
        idCategoria: parseInt(customer.idCategoria),
        nominativo: customer.nominativo,
        cf: customer.cf,
        piva: customer.piva,
        idTipoPagamento: parseInt(customer.idTipoPagamento),
        email: customer.email,
        cellulare: customer.cellulare,
        stato: 'Italia',
        provincia: customer.provincia,
        citta: customer.citta,
        cap: customer.cap,
        indirizzo: customer.indirizzo,
        zona: customer.zona,
        CodAg: customer.CodAg,
        pec: customer.pec,
        sdi: customer.sdi
    }
    await CustomAxios.put(BASE_URL + 'bmapi/Contatti', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const editCliente = async (customer: Cliente, dispatch: (action: any) => void) => {
    const body = {
        idContatto: parseInt(customer.idContatto),
        idCategoria: parseInt(customer.idCategoria),
        nominativo: customer.nominativo,
        cf: customer.cf,
        piva: customer.piva,
        idTipoPagamento: parseInt(customer.idTipoPagamento),
        email: customer.email,
        cellulare: customer.cellulare,
        stato: customer.stato,
        provincia: customer.provincia,
        citta: customer.citta,
        cap: customer.cap,
        indirizzo: customer.indirizzo,
        zona: customer.zona,
        CodAg: customer.CodAg,
        pec: customer.pec,
        sdi: customer.sdi,
        attivo: customer.attivo == '1' ? true : false,
        tipo: customer.tipo
    }
    await CustomAxios.post(BASE_URL + 'bmapi/Contatti', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const deleteCliente = async (idCustomer: string, dispatch: (action: any) => void) => {
    await CustomAxios.delete(BASE_URL + 'bmapi/Contatti/' + idCustomer).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const createAccount = async (
    body: { CodAg: string; username: string; password: string; mail: string; idgruppo: number },
    dispatch: (action: any) => void
) => {
    await CustomAxios.put(BASE_URL + 'bmapi/Utenti/CreateAccount', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const editAccount = async (
    body: {
        ID: number
        CodAg: string
        username: string
        ABL: number
        password: string | null
        mail: string
        idgruppo: number
        attivo: boolean
    },
    dispatch: (action: any) => void
) => {
    await CustomAxios.post(BASE_URL + 'bmapi/Utenti/EditAccount', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const createAgent = async (
    body: {
        CodAg: string
        DenAg: string
        CfiAg: string
        PivAg: string
        AliqProvv: number
        email: string
        TelAg: string
        FaxAg: string
        Agente: number
        Parent: string
        Tipo: string
        ProvAg: string
        CitAg: string
        CapAg: string
        IndAg: string
        IdQual: string
    },
    dispatch: (action: any) => void
) => {
    await CustomAxios.put(BASE_URL + 'bmapi/Utenti/CreateAgent', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const editAgent = async (body: AgentUpdateData, dispatch: (action: any) => void) => {
    await CustomAxios.post(BASE_URL + 'bmapi/Utenti/EditAgent', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const setQuotePrice = async (
    body: {
        CodiceOfferta: string | null
        CodicePreventivo: string | null
        Sconto: string | null
        PrezzoTotale: string | null
    },
    dispatch: (action: any) => void
) => {
    await CustomAxios.post(BASE_URL + 'bmapi/Varianti/SetPrezzoTotale', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const setComposedItem = async (body: ComposedItem, dispatch: (action: any) => void) => {
    await CustomAxios.post(BASE_URL + 'bmapi/CAD/SetFiguraCompostaItem', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const uploadImage = async (
    file: File,
    ambito: Ambito,
    dispatch: (any: any) => void,
    serie: string | undefined = undefined,
    codAzienda: string | undefined = undefined
) => {
    const formData = new FormData()
    formData.append('fileToUpload', file)
    formData.append('ambito', ambito)
    if (serie !== undefined) {
        formData.append('Serie', serie)
    }
    if (codAzienda !== undefined) {
        formData.append('CDDAZ', codAzienda)
    }
    const img = await CustomAxios.post(BASE_URL + 'bmapi/Listino/LoadImage', formData, multipartHeaders).then((data) => {
        if (data.data) {
            if (data.data.result == 'Error') {
                dispatch(setError(`Immagine già esistente in ${ambito}. Rinomina immagine.`))
            }
            return data.data.url
        } else {
            return 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/logoBiemme.png'
        }
    })
    return img
}

export const editCategory = async (body: any, dispatch: (action: any) => void) => {
    await CustomAxios.post(BASE_URL + 'bmapi/FrontendCategorieSerie/SetCategorieSerieWEB', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const editSerie = async (body: any, dispatch: (action: any) => void) => {
    await CustomAxios.post(BASE_URL + 'bmapi/FrontendSerie/SetSerieWEB', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const addEditAzienda = async (body: Company, dispatch: (action: any) => void) => {
    await CustomAxios.post(BASE_URL + 'bmapi/Utenti/AddAzienda', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const seriesPerSwitch = async (codSerie: string, dispatch: (action: any) => void) => {
    const body = {
        CodSerie: codSerie
    }
    const series = CustomAxios.post(BASE_URL + 'bmapi/Contratto/ElencoSeriePerCambio', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data && data.data.Data.length > 0) {
            return data.data.Data
        } else {
            return []
        }
    })
    return series
}

export const switchSeries = async (codOfferta: string, codPreventivo: string, codSerie: string, dispatch: (action: any) => void) => {
    const body = {
        CodiceOfferta: codOfferta,
        CodicePreventivo: codPreventivo,
        CodSerie: codSerie
    }
    const dataReturn = await CustomAxios.post(BASE_URL + 'bmapi/Contratto/CambiaSeriePreventivo', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
        if (data.data.Data) {
            return data.data.Data.CambioSerieApplicato
        }
    })
    return dataReturn
}

export const createSaleCategorySeries = async (
    body: {
        CodAg: string
        categoria: string
        NomeSerie: string
        CodSerie: string
        predefinito: number
        aggiuntivo: number
    },
    dispatch: (action: any) => void
) => {
    await CustomAxios.put(BASE_URL + 'bmapi/Sconti/ScontoUser', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const editSaleCategorySeries = async (
    body: {
        ID: number
        CodAg: string
        categoria: string
        NomeSerie: string
        CodSerie: string
        predefinito: number
        aggiuntivo: number
    },
    dispatch: (action: any) => void
) => {
    await CustomAxios.post(BASE_URL + 'bmapi/Sconti/EditScontoUser', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const deleteCategorySale = async (id: string, dispatch: (action: any) => void) => {
    await CustomAxios.delete(BASE_URL + 'bmapi/Sconti/' + id).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const setStatusOrder = async (codOfferta: string, status: string, dispatch: (action: any) => void) => {
    const body = {
        CodiceOfferta: codOfferta,
        Stato: status
    }
    await CustomAxios.post(BASE_URL + 'bmapi/Contratto/SetStatoOfferta', body, headersJson).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const deleteAccount = async (id: string, dispatch: (action: any) => void) => {
    await CustomAxios.delete(BASE_URL + 'bmapi/Utenti/' + id).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}

export const deleteCompany = async (id: string, dispatch: (action: any) => void) => {
    await CustomAxios.delete(BASE_URL + 'bmapi/Azienda/' + id).then((data) => {
        if (data.data.Message && data.data.Message.includes(TOKEN_ERROR_STRING)) {
            dispatch(logoutUser())
        }
    })
}
// WL aggiunta
export const fetchReferences = async (dispatch: (action: any) => void) => {
    const references = await CustomAxios.get(BASE_URL + 'bmapi/Reference').then((data) => {
        if (data.data.Data) {
            return data.data.Data
        } else {
            return null
        }
        //setReferences(references)
    })
    return references
}