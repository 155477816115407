import { Company } from './../../types/user'
import { AccountGroup, AdditionalCost, Agente, LevelAgent, OfferArchive, OrderArchive, SaleCategory, Tax } from './../../types/data'
import { Message } from './../../types/others'
import { ReduxAction } from '../../types/reducers'
import { Serie, Categoria, Provincia, Comune, Cliente } from '../../types/data'
import { User } from '../../types/user'

export enum DATA_ACTIONS {
    SET_SERIES = 'dataActions/setSeries',
    SET_CATEGORIES = 'dataActions/setCategories',
    SET_PROVINCES = 'dataActions/setProvinces',
    SET_CITIES = 'dataActions/setCities',
    SET_CUSTOMERS = 'dataActions/setCustomers',
    SET_OFFERS = 'dataActions/setOffers',
    SET_ORDERS = 'dataActions/setOrders',
    SET_TAXES = 'dataActions/setTaxes',
    SET_ADDITIONAL_COSTS = 'dataActions/setAdditionalCosts',
    SET_ACCOUNTS = 'dataActions/setAccounts',
    SET_AGENTS = 'dataActions/setAgents',
    SET_LEVELS_AGENT = 'dataActions/setLevelsAgent',
    SET_ACCOUNT_GROUPS = 'dataActions/setAccountGroups',
    SET_COMPANIES = 'dataActions/setCompanies',
    SET_SALE_CATEGORIES = 'dataActions/setSaleCategories',
    SET_MESSAGES_TO_READ = 'dataActions/setMessagesToRead',
    SET_MESSAGES = 'dataActions/setMessages',
    RESET_DATA = 'dataActions/resetDatas'
}

export const setSeries: ReduxAction<Serie[]> = (series: Serie[]) => {
    return {
        type: DATA_ACTIONS.SET_SERIES,
        payload: series
    }
}

export const setCategories: ReduxAction<Categoria[]> = (categories: Categoria[]) => {
    return {
        type: DATA_ACTIONS.SET_CATEGORIES,
        payload: categories
    }
}

export const setProvinces: ReduxAction<Provincia[]> = (provinces: Provincia[]) => {
    return {
        type: DATA_ACTIONS.SET_PROVINCES,
        payload: provinces
    }
}

export const setCities: ReduxAction<Comune[]> = (cities: Comune[]) => {
    return {
        type: DATA_ACTIONS.SET_CITIES,
        payload: cities
    }
}

export const setCustomers: ReduxAction<Cliente[]> = (customers: Cliente[]) => {
    return {
        type: DATA_ACTIONS.SET_CUSTOMERS,
        payload: customers
    }
}

export const setOffersArchive: ReduxAction<OfferArchive[]> = (offers: OfferArchive[]) => {
    return {
        type: DATA_ACTIONS.SET_OFFERS,
        payload: offers
    }
}

export const setOrdersArchive: ReduxAction<OrderArchive[]> = (orders: OrderArchive[]) => {
    return {
        type: DATA_ACTIONS.SET_ORDERS,
        payload: orders
    }
}

export const setTaxes: ReduxAction<Tax[]> = (taxes: Tax[]) => {
    return {
        type: DATA_ACTIONS.SET_TAXES,
        payload: taxes
    }
}

export const setAdditionalCosts: ReduxAction<AdditionalCost[]> = (costs: AdditionalCost[]) => {
    return {
        type: DATA_ACTIONS.SET_ADDITIONAL_COSTS,
        payload: costs
    }
}

export const setAccounts: ReduxAction<User[]> = (accounts: User[]) => {
    return {
        type: DATA_ACTIONS.SET_ACCOUNTS,
        payload: accounts
    }
}

export const setAgents: ReduxAction<Agente[]> = (agents: Agente[]) => {
    return {
        type: DATA_ACTIONS.SET_AGENTS,
        payload: agents
    }
}

export const setLevelsAgent: ReduxAction<LevelAgent[]> = (levels: LevelAgent[]) => {
    return {
        type: DATA_ACTIONS.SET_LEVELS_AGENT,
        payload: levels
    }
}

export const setAccountGroups: ReduxAction<AccountGroup[]> = (groups: AccountGroup[]) => {
    return {
        type: DATA_ACTIONS.SET_ACCOUNT_GROUPS,
        payload: groups
    }
}

export const setCompanies: ReduxAction<Company[]> = (companies: Company[]) => {
    return {
        type: DATA_ACTIONS.SET_COMPANIES,
        payload: companies
    }
}

export const setSaleCategories: ReduxAction<SaleCategory[]> = (categories: SaleCategory[]) => {
    return {
        type: DATA_ACTIONS.SET_SALE_CATEGORIES,
        payload: categories
    }
}

export const setMessagesToRead: ReduxAction<Message[]> = (messages: Message[]) => {
    return {
        type: DATA_ACTIONS.SET_MESSAGES_TO_READ,
        payload: messages
    }
}

export const setMessages: ReduxAction<Message[]> = (messages: Message[]) => {
    return {
        type: DATA_ACTIONS.SET_MESSAGES,
        payload: messages
    }
}

export const resetData: ReduxAction<null> = () => {
    return {
        type: DATA_ACTIONS.RESET_DATA
    }
}
