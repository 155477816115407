export const locale_itIT = {
    body: {
        emptyDataSourceMessage: 'Nessun contenuto trovato',
        addTooltip: 'Aggiungi',
        deleteTooltip: 'Rimuovi',
        editTooltip: 'Modifica',
        filterRow: {
            filterTooltip: 'Filtra'
        },
        editRow: {
            deleteText: 'Vuoi rimuovere questa riga?',
            cancelTooltip: 'Annulla',
            saveTooltip: 'Salva'
        }
    },
    grouping: {
        groupedBy: 'Raggruppato per:'
    },
    header: {
        actions: 'Azioni'
    },
    pagination: {
        labelDisplayedRows: '{from}-{to} di {count}',
        labelRowsSelect: 'righe',
        labelRowsPerPage: 'righe per pagina:',
        firstAriaLabel: 'Prima pagina',
        firstTooltip: 'Prima pagina',
        previousAriaLabel: 'Pagina precedente',
        previousTooltip: 'Pagina precedente',
        nextAriaLabel: 'Pagina successiva',
        nextTooltip: 'Pagina successiva',
        lastAriaLabel: 'Ultima pagina',
        lastTooltip: 'Ultima pagina'
    },
    toolbar: {
        addRemoveColumns: 'Aggiungi o rimuovi colonne',
        nRowsSelected: '{0} righe selezionate',
        showColumnsTitle: 'Vedi le colonne',
        showColumnsAriaLabel: 'Vedi le colonne',
        exportTitle: 'Esporta',
        exportAriaLabel: 'Esporta',
        exportCSVName: 'Esporta in CSV',
        exportPDFName: 'Esporta in PDF',
        searchTooltip: 'Cerca',
        searchPlaceholder: 'Cerca'
    }
}

export const locale_enEN = {
    body: {
        emptyDataSourceMessage: 'No content found',
        addTooltip: 'Add',
        deleteTooltip: 'Remove',
        editTooltip: 'Edit',
        filterRow: {
            filterTooltip: 'Filter'
        },
        editRow: {
            deleteText: 'Do you want to remove this row?',
            cancelTooltip: 'Cancel',
            saveTooltip: 'Save'
        }
    },
    grouping: {
        groupedBy: 'Grouped by:'
    },
    header: {
        actions: 'Actions'
    },
    pagination: {
        labelDisplayedRows: '{from}-{to} of {count}',
        labelRowsSelect: 'rows',
        labelRowsPerPage: 'rows per page:',
        firstAriaLabel: 'First page',
        firstTooltip: 'First page',
        previousAriaLabel: 'Previous page',
        previousTooltip: 'Previous page',
        nextAriaLabel: 'Next page',
        nextTooltip: 'Next page',
        lastAriaLabel: 'Last page',
        lastTooltip: 'Last page'
    },
    toolbar: {
        addRemoveColumns: 'Add or remove columns',
        nRowsSelected: '{0} selected rows',
        showColumnsTitle: 'Show columns',
        showColumnsAriaLabel: 'Show columns',
        exportTitle: 'Export',
        exportAriaLabel: 'Export',
        exportCSVName: 'Export to CSV',
        exportPDFName: 'Export to PDF',
        searchTooltip: 'Search',
        searchPlaceholder: 'Search'
    }
}
