import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogContent } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { calculateTotalPrices, getSingleItemQuote, setPrezzoFinale } from '../../resources/requests/requests'
import { DettaglioItemInQuote, ItemInQuote } from '../../types/order'
import { ReducerData, ReducerOrder, Reducers } from '../../types/reducers'
import Button from '../Button'
import TitlePage from '../TitlePage'
import { useSelector, useDispatch } from 'react-redux'
import { convertSale, transformToCurrency, usePrevious } from '../../utilities/functions'
import { setPricesTotalOffer } from '../../store/actions/order'
import { setError, setIsLoading } from '../../store/actions/graphic'

enum SaleType {
    PERCENT,
    VALUE
}

interface Props {
    isVisible: boolean
    item: ItemInQuote
    hideModal: () => void
    updatedItem: (item: DettaglioItemInQuote) => void
}

interface SaleObject {
    salePercent: string
    salePercentAdd: string | null
    saleValue: string
}

const SaleModal: React.FC<Props> = (props) => {
    const [currentItem, setCurrentItem] = useState(props.item)
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const voidSale = {
        salePercent: '0',
        salePercentAdd: null,
        saleValue: '0'
    }
    const [sale, setSale] = useState<SaleObject>(voidSale)
    const [currentSaleType, setCurrentSaleType] = useState<SaleType>()
    const [isOverSale, setIsOverSale] = useState(false)
    const previousSale = usePrevious(sale)
    const dispatch = useDispatch()

    useEffect(() => {
        setCurrentItem(props.item)
        setSale(voidSale)
        if (currentItem.DettaglioItem.Sconto !== 0) {
            const newSale = convertSale(currentItem.DettaglioItem.Sconto.toFixed(2).toString())
            setSale({
                salePercent: newSale[0],
                salePercentAdd: newSale[1],
                saleValue: (parseFloat(currentItem.DettaglioItem.PrezzoListino) - currentItem.DettaglioItem.Prezzo).toFixed(2).toString()
            })
        }
    }, [props.isVisible])

    const handleSave = async () => {
        if (sale.salePercent) {
            dispatch(setIsLoading(true))
            try {
                const calculatedSale = (parseFloat(sale.saleValue) * 100) / parseFloat(currentItem.DettaglioItem.PrezzoListino)
                await setPrezzoFinale(currentItem.idRecord, calculatedSale, dispatch)
                const updatedItem = await getSingleItemQuote(currentItem.idRecord, order.newQuoteFromUser?.CodicePreventivo, dispatch)
                const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
                const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
                dispatch(setPricesTotalOffer(prices))
                props.updatedItem(updatedItem)
                props.hideModal()
            } catch (error: any) {
                dispatch(setError(error.toString))
            }
            dispatch(setIsLoading(false))
        }
    }

    const calculatedFinalPrice = () => {
        const result = parseFloat(currentItem.DettaglioItem.PrezzoListino) - parseFloat(sale.saleValue)
        return transformToCurrency(result)
    }

    useEffect(() => {
        if (previousSale !== undefined && JSON.stringify(sale) == JSON.stringify(previousSale)) {
            return
        }
        if (currentSaleType == SaleType.VALUE) {
            const salePercent = (parseFloat(sale.saleValue) * 100) / parseFloat(currentItem.DettaglioItem.PrezzoListino)
            setSale({
                ...sale,
                salePercent: parseFloat(salePercent.toFixed(2)).toString(),
                salePercentAdd: null
            })
        }
        if (currentSaleType == SaleType.PERCENT) {
            if (sale.salePercentAdd !== null && parseFloat(sale.salePercentAdd) > 0) {
                const firstSale = (parseFloat(currentItem.DettaglioItem.PrezzoListino) * parseFloat(sale.salePercent)) / 100
                const secondSale = ((parseFloat(currentItem.DettaglioItem.PrezzoListino) - firstSale) * parseFloat(sale.salePercentAdd)) / 100
                setSale({
                    ...sale,
                    saleValue: (firstSale + secondSale).toFixed(2).toString()
                })
            } else {
                const saleValue = (parseFloat(currentItem.DettaglioItem.PrezzoListino) * parseFloat(sale.salePercent)) / 100
                setSale({
                    ...sale,
                    saleValue: saleValue.toFixed(2).toString()
                })
            }
        }
        if (
            order.currentCustomerSale &&
            (parseFloat(sale.salePercent) > parseFloat(order.currentCustomerSale.predefinito) ||
                (sale.salePercentAdd && parseFloat(sale.salePercentAdd) > parseFloat(order.currentCustomerSale.aggiuntivo)))
        ) {
            setIsOverSale(true)
        } else {
            setIsOverSale(false)
        }
    }, [sale])

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={currentItem.DettaglioItem.DescrizioneInterneDB} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Gestione sconti</p>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '20px 0' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <label>Sconto in valuta</label>
                        <input
                            style={{
                                color: isOverSale ? '#E00000' : '#68696d',
                                border: isOverSale ? '1px solid #e00000' : 'none'
                            }}
                            placeholder="Sconto in valuta"
                            step=".01"
                            type="number"
                            value={sale.saleValue ? sale.saleValue : 0}
                            onFocus={() => setCurrentSaleType(SaleType.VALUE)}
                            onChange={(e) =>
                                setSale({
                                    ...sale,
                                    saleValue: e.target.value.replace(',', '.')
                                })
                            }
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', gap: 20, margin: '20px 0' }}>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <label>Sconto in percentuale</label>
                        <input
                            style={{
                                color: isOverSale ? '#E00000' : '#68696d',
                                border: isOverSale ? '1px solid #e00000' : 'none'
                            }}
                            placeholder="Sconto principale"
                            step=".01"
                            type="number"
                            max={order.currentCustomerSale ? order.currentCustomerSale.predefinito : 100}
                            value={sale.salePercent ? sale.salePercent : 0}
                            onFocus={() => setCurrentSaleType(SaleType.PERCENT)}
                            onChange={(e) =>
                                setSale({
                                    ...sale,
                                    salePercent: e.target.value.replace(',', '.')
                                })
                            }
                        />
                        <input
                            style={{
                                marginTop: 10,
                                color: isOverSale ? '#E00000' : '#68696d',
                                border: isOverSale ? '1px solid #e00000' : 'none'
                            }}
                            placeholder="Sconto aggiuntivo"
                            step=".01"
                            type="number"
                            max={order.currentCustomerSale ? order.currentCustomerSale.predefinito : 100}
                            disabled={parseFloat(sale.salePercent) > 0 ? false : true}
                            value={sale.salePercentAdd ? sale.salePercentAdd : ''}
                            onFocus={() => setCurrentSaleType(SaleType.PERCENT)}
                            onChange={(e) =>
                                setSale({
                                    ...sale,
                                    salePercentAdd: e.target.value.replace(',', '.')
                                })
                            }
                        />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: 20 }}>
                    <p style={{ fontSize: 18 }}>
                        Prezzo unitario di listino: <b>{transformToCurrency(parseFloat(currentItem.DettaglioItem.PrezzoListino))}</b>
                    </p>
                    <p style={{ fontSize: 18 }}>
                        Prezzo unitario finale: <b>{calculatedFinalPrice()}</b>
                    </p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: '100%' }}>
                    <Button
                        isSmall
                        text="Salva"
                        hasIcon={true}
                        icon={faEdit}
                        opacity={isOverSale ? 0.5 : 1}
                        onClick={() => {
                            if (isOverSale) {
                                return
                            }
                            handleSave()
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default SaleModal
