import React, { useEffect, useState } from 'react'
import { Typology, TypologyItemQuote } from '../../types/data'
import { Dialog, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faPlusSquare, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'
import { ReducerOrder, Reducers } from '../../types/reducers'
import { useSelector } from 'react-redux'

interface Props {
    isVisible: boolean
    typology: Typology
    variants: TypologyItemQuote[]
    hideModal: () => void
    setSelectedVariants: (variants: TypologyItemQuote[]) => void
}

const TypologyFastVariantModal: React.FC<Props> = (props) => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const [currentVariants, setCurrentVariants] = useState<TypologyItemQuote[]>(props.variants)
    const [sizeWrong, setSizeWrong] = useState(false)
    const voidVariant = {
        Htip: parseInt(props.typology.HPred),
        Ltip: parseInt(props.typology.LPred),
        HManiglia: parseInt(props.typology.HManiglia),
        HTraverso1: parseInt(props.typology.HTraverso1),
        HTraverso2: parseInt(props.typology.HTraverso2),
        HTraverso3: parseInt(props.typology.HTraverso3),
        HTraverso4: parseInt(props.typology.HTraverso4),
        Quantita: 0,
        Ordinamento: 1,
        Etichetta: '',
        PRM: null,
        Prezzo: null,
        Sconto: null,
        Annotazioniutente: '',
        DescrizioneInterneDB: props.typology.DescrizTip,
        isNew: true
    }

    useEffect(() => {
        setCurrentVariants(props.variants)
    }, [props.isVisible])

    useEffect(() => {
        const check = currentVariants.some((v) => {
            return (
                v.Htip > parseInt(props.typology.HMax) ||
                v.Htip < parseInt(props.typology.Hmin) ||
                v.Ltip > parseInt(props.typology.LMax) ||
                v.Ltip < parseInt(props.typology.Lmin)
            )
        })
        setSizeWrong(check)
    }, [currentVariants])

    const renderVariant = () => {
        return currentVariants.map((v, i) => {
            return (
                <div key={'var' + i} className="variantItem">
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: 10 }}>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <label>Etichetta</label>
                            <input
                                type="text"
                                value={v.Etichetta}
                                placeholder="Etichetta"
                                style={{
                                    flex: 4
                                }}
                                onChange={(e) => {
                                    setCurrentVariants(
                                        currentVariants.map((variant, index) => {
                                            if (i == index) {
                                                return {
                                                    ...variant,
                                                    Etichetta: e.target.value
                                                }
                                            } else {
                                                return variant
                                            }
                                        })
                                    )
                                }}
                                disabled={v.Prezzo !== null}
                            />
                        </div>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <label>Numero ordinamento</label>
                            <input
                                type="number"
                                value={v.Ordinamento}
                                placeholder="N° Ord."
                                style={{
                                    flex: 0.5
                                }}
                                onChange={(e) => {
                                    setCurrentVariants(
                                        currentVariants.map((variant, index) => {
                                            if (i == index) {
                                                return {
                                                    ...variant,
                                                    Ordinamento: parseInt(e.target.value)
                                                }
                                            } else {
                                                return variant
                                            }
                                        })
                                    )
                                }}
                                disabled={v.Prezzo !== null}
                            />
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', gap: 20, marginTop: 20 }}>
                            <div
                                className={
                                    v.Ltip <= parseInt(props.typology.LMax) && v.Ltip >= parseInt(props.typology.Lmin) ? 'specInput' : 'specInput colorRed'
                                }
                            >
                                <span>L (mm.)</span>
                                <input
                                    min={props.typology.Lmin}
                                    max={props.typology.LMax}
                                    defaultValue={props.typology.LPred}
                                    value={v.Ltip}
                                    type="number"
                                    onChange={(e) => {
                                        const value = e.target.value ? parseInt(e.target.value) : 0
                                        setCurrentVariants(
                                            currentVariants.map((variant, index) => {
                                                if (i == index) {
                                                    return {
                                                        ...variant,
                                                        Ltip: value
                                                    }
                                                } else {
                                                    return variant
                                                }
                                            })
                                        )
                                    }}
                                    disabled={v.Prezzo !== null}
                                />
                                <span className="suggestion">(min. {props.typology.Lmin} mm.)</span>
                                <span className="suggestion">(max. {props.typology.LMax} mm.)</span>
                            </div>
                            <div
                                className={
                                    v.Htip <= parseInt(props.typology.HMax) && v.Htip >= parseInt(props.typology.Hmin) ? 'specInput' : 'specInput colorRed'
                                }
                            >
                                <span>H (mm.)</span>
                                <input
                                    defaultValue={props.typology.HPred}
                                    value={v.Htip}
                                    type="number"
                                    onChange={(e) => {
                                        const value = e.target.value ? parseInt(e.target.value) : 0
                                        setCurrentVariants(
                                            currentVariants.map((variant, index) => {
                                                if (i == index) {
                                                    return {
                                                        ...variant,
                                                        Htip: value
                                                    }
                                                } else {
                                                    return variant
                                                }
                                            })
                                        )
                                    }}
                                    disabled={v.Prezzo !== null}
                                />
                                <span className="suggestion">(min. {props.typology.Hmin} mm.)</span>
                                <span className="suggestion">(max. {props.typology.HMax} mm.)</span>
                            </div>
                            {props.typology.HManiglia && (
                                <div className="specInput">
                                    <span>HMan.</span>
                                    <input
                                        defaultValue={props.typology.HManiglia}
                                        value={v.HManiglia ? v.HManiglia : 0}
                                        type="number"
                                        onChange={(e) => {
                                            setCurrentVariants(
                                                currentVariants.map((variant, index) => {
                                                    if (i == index) {
                                                        return {
                                                            ...variant,
                                                            HManiglia: e.target.value ? parseInt(e.target.value) : 0
                                                        }
                                                    } else {
                                                        return variant
                                                    }
                                                })
                                            )
                                        }}
                                        disabled={v.Prezzo !== null}
                                    />
                                </div>
                            )}

                            {props.typology.HTraverso1 && (
                                <div className="specInput">
                                    <span>HTrav. 1</span>
                                    <input
                                        min={0}
                                        defaultValue={props.typology.HTraverso1}
                                        value={v.HTraverso1 ? v.HTraverso1 : 0}
                                        type="number"
                                        onChange={(e) => {
                                            setCurrentVariants(
                                                currentVariants.map((variant, index) => {
                                                    if (i == index) {
                                                        return {
                                                            ...variant,
                                                            HTraverso1: e.target.value ? parseInt(e.target.value) : 0
                                                        }
                                                    } else {
                                                        return variant
                                                    }
                                                })
                                            )
                                        }}
                                        disabled={v.Prezzo !== null}
                                    />
                                </div>
                            )}
                            {props.typology.HTraverso2 && (
                                <div className="specInput">
                                    <span>HTrav. 2</span>
                                    <input
                                        min={0}
                                        defaultValue={props.typology.HTraverso2}
                                        value={v.HTraverso2 ? v.HTraverso2 : 0}
                                        type="number"
                                        onChange={(e) => {
                                            setCurrentVariants(
                                                currentVariants.map((variant, index) => {
                                                    if (i == index) {
                                                        return {
                                                            ...variant,
                                                            HTraverso2: e.target.value ? parseInt(e.target.value) : 0
                                                        }
                                                    } else {
                                                        return variant
                                                    }
                                                })
                                            )
                                        }}
                                        disabled={v.Prezzo !== null}
                                    />
                                </div>
                            )}
                            {props.typology.HTraverso3 && (
                                <div className="specInput">
                                    <span>HTrav. 3</span>
                                    <input
                                        min={0}
                                        defaultValue={props.typology.HTraverso3}
                                        value={v.HTraverso3 ? v.HTraverso3 : 0}
                                        type="number"
                                        onChange={(e) => {
                                            setCurrentVariants(
                                                currentVariants.map((variant, index) => {
                                                    if (i == index) {
                                                        return {
                                                            ...variant,
                                                            HTraverso3: e.target.value ? parseInt(e.target.value) : 0
                                                        }
                                                    } else {
                                                        return variant
                                                    }
                                                })
                                            )
                                        }}
                                        disabled={v.Prezzo !== null}
                                    />
                                </div>
                            )}
                            {props.typology.HTraverso4 && (
                                <div className="specInput">
                                    <span>HTrav. 4</span>
                                    <input
                                        min={0}
                                        defaultValue={props.typology.HTraverso4}
                                        value={v.HTraverso4 ? v.HTraverso4 : 0}
                                        type="number"
                                        onChange={(e) => {
                                            setCurrentVariants(
                                                currentVariants.map((variant, index) => {
                                                    if (i == index) {
                                                        return {
                                                            ...variant,
                                                            HTraverso4: e.target.value ? parseInt(e.target.value) : 0
                                                        }
                                                    } else {
                                                        return variant
                                                    }
                                                })
                                            )
                                        }}
                                        disabled={v.Prezzo !== null}
                                    />
                                </div>
                            )}
                            <div className="specInput">
                                <span>Quantità</span>
                                <input
                                    min={0}
                                    value={v.Quantita}
                                    type="number"
                                    onChange={(e) => {
                                        setCurrentVariants(
                                            currentVariants.map((variant, index) => {
                                                if (i == index) {
                                                    return {
                                                        ...variant,
                                                        Quantita: e.target.value ? parseInt(e.target.value) : 0
                                                    }
                                                } else {
                                                    return variant
                                                }
                                            })
                                        )
                                    }}
                                    disabled={v.Prezzo !== null}
                                />
                            </div>
                        </div>
                        {currentVariants.length > 1 && v.Prezzo == null && (
                            <FontAwesomeIcon
                                icon={faTrash}
                                style={{ fontSize: 20, color: '#E00000', marginRight: 10, cursor: 'pointer' }}
                                onClick={() => setCurrentVariants(currentVariants.filter((variant, index) => index !== i))}
                            />
                        )}
                    </div>
                </div>
            )
        })
    }

    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md" onBackdropClick={() => props.hideModal()}>
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={props.typology.DescrizTip} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Dettaglio dimensioni e misure</p>
                <div className="variantsList">{renderVariant()}</div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    {currentVariants[currentVariants.length - 1].Quantita > 0 ? (
                        <Button
                            isSmall
                            text="Aggiungi"
                            buttonColor="#52565A"
                            hasIcon={true}
                            icon={faPlusSquare}
                            onClick={() => {
                                setCurrentVariants([...currentVariants, voidVariant])
                            }}
                        />
                    ) : (
                        <div></div>
                    )}
                    <Button
                        isSmall
                        text="Salva"
                        hasIcon={true}
                        icon={faCopy}
                        opacity={sizeWrong ? 0.5 : 1}
                        onClick={() => {
                            if (!sizeWrong) {
                                props.setSelectedVariants(currentVariants)
                                props.hideModal()
                            }
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default TypologyFastVariantModal
