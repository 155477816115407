import React from 'react'

interface Props {
    isVisible: boolean
}

const Loader: React.FC<Props> = (props) => {
    if (props.isVisible) {
        return (
            <div style={{ width: "100vw", height: "100vh", position: 'fixed', top: 0, left: 0, backgroundColor: 'rgba(0,0,0,0.6)', zIndex: 99999, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div>
                    <div className="loadingio-spinner-blocks-uu3o4oof88c"><div className="ldio-2p5aulscjif">
                        <div style={{ left: 38, top: 38, animationDelay: '0s' }}></div><div style={{ left: 80, top: 38, animationDelay: '0.125s' }}></div><div style={{ left: 122, top: 38, animationDelay: '0.25s' }}></div><div style={{ left: 38, top: 80, animationDelay: '0.875s' }}></div><div style={{ left: 122, top: 80, animationDelay: '0.375s' }}></div><div style={{ left: 38, top: 122, animationDelay: '0.75s' }}></div><div style={{ left: 80, top: 122, animationDelay: '0.625s' }}></div><div style={{ left: 122, top: 122, animationDelay: '0.5s' }}></div>
                    </div></div>
                </div>
                <p style={{ fontSize: 24, color: '#fff' }}>Attendi prego...</p>
            </div>
        )
    }
    return null
}

export default Loader