import { faEdit, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dialog, DialogContent } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { getOfferData, saveCurrentOffer, setAdditionalDatasOffer } from '../../resources/requests/requests'
import { CurrentOffer } from '../../types/order'
import { ReducerData, ReducerOrder, Reducers } from '../../types/reducers'
import Button from '../Button'
import TitlePage from '../TitlePage'
import { useSelector, useDispatch } from 'react-redux'
import { setOfferLoadedRedux } from '../../store/actions/thunk_actions'
import Select from 'react-select'
import { citiesPerSelect, provincePerSelect } from '../../utilities/functions'
import { setLastOfferSeen } from '../../store/actions/user'
import { setError, setIsLoading } from '../../store/actions/graphic'

interface Props {
    isVisible: boolean
    hideModal: () => void
    offerData: CurrentOffer
    isClosed?: boolean
}

const WorksiteAddressModal: React.FC<Props> = (props) => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const offerData = props.offerData
    const [currentOfferDatas, setCurrentOfferDatas] = useState({
        CantiereInstallazione: props.offerData.CantiereInstallazione,
        IndirizzoCantiere: props.offerData.IndirizzoCantiere,
        ProvinciaCantiere: props.offerData.ProvinciaCantiere,
        ComuneCantiere: props.offerData.ComuneCantiere,
        CAPCantiere: props.offerData.CAPCantiere
    })
    const dispatch = useDispatch()

    useEffect(() => {
        setCurrentOfferDatas({
            CantiereInstallazione: props.offerData.CantiereInstallazione,
            IndirizzoCantiere: props.offerData.IndirizzoCantiere,
            ProvinciaCantiere: props.offerData.ProvinciaCantiere,
            ComuneCantiere: props.offerData.ComuneCantiere,
            CAPCantiere: props.offerData.CAPCantiere
        })
    }, [props.isVisible])

    const handleSave = async () => {
        dispatch(setIsLoading(true))
        try {
            const bodyAdditional = {
                CodiceOfferta: offerData.CodiceOfferta,
                Descrizione: offerData.Descrizione,
                CantiereInstallazione: currentOfferDatas.CantiereInstallazione,
                IndirizzoCantiere: currentOfferDatas.IndirizzoCantiere,
                ProvinciaCantiere: currentOfferDatas.ProvinciaCantiere,
                ComuneCantiere: currentOfferDatas.ComuneCantiere,
                CAPCantiere: currentOfferDatas.CAPCantiere,
                TestoIniziale: offerData.TestoIniziale,
                TestoFinale: offerData.TestoFinale,
                DataConsegna: offerData.DataConsegna
            }
            await setAdditionalDatasOffer(bodyAdditional, dispatch)
            await saveCurrentOffer(offerData.CodiceOfferta, dispatch)
            if (!props.isClosed) {
                dispatch(setOfferLoadedRedux(offerData.CodiceOfferta))
            } else {
                const newOfferData = await getOfferData(offerData.CodiceOfferta, dispatch)
                dispatch(setLastOfferSeen(newOfferData))
            }
            props.hideModal()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const provinceValue = () => {
        if (currentOfferDatas.ProvinciaCantiere) {
            const cityFound = data.provinces.find((c) => c.Sigla == currentOfferDatas.ProvinciaCantiere)
            if (cityFound) {
                return {
                    id: cityFound.Sigla,
                    value: cityFound.Sigla,
                    label: cityFound.Provincia
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    const cityValue = () => {
        if (currentOfferDatas.CAPCantiere) {
            const cityFound = data.cities.find((c) => c.CAP == currentOfferDatas.CAPCantiere)
            if (cityFound) {
                return {
                    id: cityFound.CAP,
                    value: cityFound.IDComune,
                    label: cityFound.Comune
                }
            } else {
                return null
            }
        } else {
            return null
        }
    }

    return (
        <Dialog
            open={props.isVisible}
            fullWidth={true}
            aria-labelledby="form-dialog-title"
            maxWidth="md"
            className="overflowVisible"
            onBackdropClick={() => props.hideModal()}
        >
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', overflow: 'visible', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={'Cantiere di installazione'} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Aggiungi i dati del cantiere di installazione</p>
                <div className="gridInputOffer" style={{ margin: '20px 0' }}>
                    <input
                        placeholder="Descrizione"
                        type="text"
                        value={currentOfferDatas.CantiereInstallazione}
                        onChange={(e) => {
                            setCurrentOfferDatas({
                                ...currentOfferDatas,
                                CantiereInstallazione: e.target.value
                            })
                        }}
                        required
                    />
                    <input
                        placeholder="Indirizzo"
                        type="text"
                        value={currentOfferDatas.IndirizzoCantiere}
                        onChange={(e) => {
                            setCurrentOfferDatas({
                                ...currentOfferDatas,
                                IndirizzoCantiere: e.target.value
                            })
                        }}
                        required
                    />
                    <Select
                        styles={{
                            valueContainer: (provided) => ({
                                ...provided,
                                backgroundColor: '#f4f4f4'
                            }),
                            container: (provided) => ({
                                ...provided,
                                backgroundColor: '#f4f4f4',
                                borderRadius: 7
                            }),
                            singleValue: (provided) => ({
                                ...provided,
                                fontSize: 18
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: 18
                            }),
                            control: (provided) => ({
                                ...provided,
                                backgroundColor: '#f4f4f4',
                                border: 0,
                                padding: '5px 10px',
                                borderRadius: 7
                            })
                        }}
                        placeholder="Provincia"
                        value={provinceValue()}
                        onChange={(e: any) => {
                            if (e) {
                                setCurrentOfferDatas({
                                    ...currentOfferDatas,
                                    ProvinciaCantiere: e.id
                                })
                            }
                        }}
                        options={provincePerSelect(data.provinces)}
                    />
                    <Select
                        styles={{
                            singleValue: (provided) => ({
                                ...provided,
                                fontSize: 18
                            }),
                            option: (provided) => ({
                                ...provided,
                                fontSize: 18
                            }),
                            placeholder: (provided) => ({
                                ...provided,
                                fontSize: 18
                            }),
                            control: (provided) => ({
                                ...provided,
                                backgroundColor: '#f4f4f4',
                                border: 0,
                                padding: '5px 10px',
                                borderRadius: 7
                            })
                        }}
                        placeholder="Comune"
                        options={citiesPerSelect(
                            currentOfferDatas.ProvinciaCantiere !== '' ? data.cities.filter((c) => c.Sigla == currentOfferDatas.ProvinciaCantiere) : data.cities
                        )}
                        value={cityValue()}
                        onChange={(e: any) => {
                            if (e) {
                                setCurrentOfferDatas({
                                    ...currentOfferDatas,
                                    CAPCantiere: e.id,
                                    ComuneCantiere: e.label
                                })
                            }
                        }}
                    />
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', flex: 1, width: '100%' }}>
                    <Button
                        isSmall
                        text="Salva"
                        hasIcon={true}
                        icon={faEdit}
                        onClick={() => {
                            handleSave()
                        }}
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default WorksiteAddressModal
