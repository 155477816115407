import { faCog, faUpload } from '@fortawesome/free-solid-svg-icons'
import MaterialTable from 'material-table'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../components/Button'
import SelectInput, { Option } from '../../../components/formComponents/SelectInput'
import ConfirmModal from '../../../components/modals/ConfirmModal'
import EditDataVarModal from '../../../components/modals/EditDataVarModal'
import FinalMessageModal from '../../../components/modals/FinalMessageModal'
import SubtitleSection from '../../../components/SubtitleSection'
import { getTypologies } from '../../../resources/requests/requests'
import { EditVariantTypology, getVariantsTypolgy, updateDimensioniTipologie, updateTabellaListino } from '../../../resources/requests/settings'
import { setError, setIsLoading } from '../../../store/actions/graphic'
import { Serie, Typology } from '../../../types/data'
import { ReducerData, Reducers } from '../../../types/reducers'
import { locale_itIT } from '../../../utilities/tableLocale'

export interface DataVar {
    ID: number
    CodVar: string
    IdCatVar: string
    IdGenereVar: string
    DescrizVar: string
    PrioritaCalcoloPredefinita: string
    FormulaValiditaPredefinita: string
    FormulaValiditaAmmessa: string
    FormulaCalcoloAmmessa: string
    PrioritaCalcoloAmmessa: string
    PRM: string
    Figura: string
    FiguraAp: string
    Um: string
    Prezzo: string
    NascondiInDoc: string
    StampaPrezzi: string
    StampaQta: string
    StampaPrev: string
    StampaOrd: string
    DecrizioneCategoria: string
    Univoco: string
    OrdineStampa: string
}

const ListinoScreen: React.FC = () => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [tempFile, setTempFile] = useState<File | null>(null)
    const [tempTypologyFile, setTempTypologyFile] = useState<File | null>(null)
    const [confirmModal, setConfirmModal] = useState(false)
    const [serieSelected, setSerieSelected] = useState<Serie | null>(null)
    const [tipologySelected, setTipologySelected] = useState<Typology | null>(null)
    const [typologies, setTypologies] = useState<Typology[]>([])
    const [dataVars, setDataVars] = useState<DataVar[]>([])
    const [editedDataVars, setEditedDataVars] = useState<DataVar[]>([])
    const [selectedDataVar, setSelectedDataVar] = useState<DataVar | null>(null)
    const [finalMessage, setFinalMessage] = useState<{ result: string; message: string } | null>(null)
    const dispatch = useDispatch()

    const handleCSVFile = async (listino: File) => {
        if (listino) {
            dispatch(setIsLoading(true))
            try {
                const name = listino.name.split('.').slice(0, -1).join('.')
                const file = new FileReader()
                file.readAsBinaryString(listino)
                file.onload = async () => {
                    const csv: any = file.result
                    const lines = csv.split('\r' + '\n')
                    const headers = lines[0].split(';')
                    const result: any = []
                    for (let i = 1; i < lines.length; i++) {
                        if (lines[i]) {
                            const obj: any = {}
                            let currentLine = lines[i]
                            const re = /"/g
                            currentLine = re[Symbol.replace](currentLine, '')
                            currentLine = currentLine.split(';')
                            for (let j = 0; j < headers.length; j++) {
                                if (j == 0 || j == 1 || j == 2 || j == 3 || j == 4) {
                                    const head = headers[j].trim().replace(/"/g, '')
                                    const value = currentLine[j].trim()
                                    obj[head] = value
                                }
                            }
                            result.push(obj)
                        }
                    }
                    const data = await updateTabellaListino(name, result, dispatch)
                    setFinalMessage(data)
                    setTempFile(null)
                }
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    const handleTypologyCSVFile = async (csv: File) => {
        if (csv) {
            dispatch(setIsLoading(true))
            try {
                const file = new FileReader()
                file.readAsBinaryString(csv)
                file.onload = async () => {
                    const csv: any = file.result
                    const lines = csv.split('\r' + '\n')
                    const headers = lines[0].split(';')
                    const result: any = []
                    //console.log(' linee ' + lines.length)
                    for (let i = 1; i < lines.length; i++) {
                        if (lines[i]) {
                            const obj: any = {}
                            let currentLine = lines[i]
                            const re = /"/g
                            currentLine = re[Symbol.replace](currentLine, '')
                            //console.log(' linea '+`${currentLine}`)
                            currentLine = currentLine.split(';')
                            for (let j = 0; j < headers.length; j++) {
                                if (j == 0 || j == 1 || j == 2 || j == 3 || j == 4) {
                                    const head = headers[j].trim().replace(/"/g, '')
                                    const value = currentLine[j].trim()
                                    obj[head] = value
                                }
                            }
                            result.push(obj)
                        }
                    }
                    const data = await updateDimensioniTipologie(result, dispatch)
                    setFinalMessage(data)
                    setTempTypologyFile(null) 
                }
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    useEffect(() => {
        if (serieSelected) {
            setTipologySelected(null)
            fetchTypologies()
        }
    }, [serieSelected])

    const fetchTypologies = async () => {
        if (serieSelected) {
            dispatch(setIsLoading(true))
            try {
                const types = await getTypologies(serieSelected.CodSerie, dispatch)
                setTypologies(types)
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    const fetchDatas = async () => {
        if (serieSelected && tipologySelected) {
            dispatch(setIsLoading(true))
            try {
                const vars = await getVariantsTypolgy(tipologySelected.CodTip, dispatch)
                setEditedDataVars([])
                setDataVars(vars)
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    const serieValue = () => {
        if (serieSelected) {
            const serieFound = data.series.find((s) => s.ID == serieSelected.ID)
            if (serieFound) {
                return {
                    id: serieFound.ID,
                    value: serieFound.ID,
                    label: serieFound.NomeSerie
                }
            }
        }
        return null
    }

    const typologyValue = () => {
        if (tipologySelected && typologies.length > 0) {
            const typologyFound = typologies.find((t) => t.CodTip == tipologySelected.CodTip)
            if (typologyFound) {
                return {
                    id: typologyFound.CodTip,
                    value: typologyFound.CodTip,
                    label: typologyFound.DescrizTip
                }
            }
        }
        return null
    }

    const renderDatas = useMemo(() => {
        if (dataVars.length > 0) {
            return dataVars
        } else {
            return []
        }
    }, [dataVars])

    const columns = [
        {
            title: 'Codice variante',
            field: 'CodVar'
        },
        {
            title: 'Nome variante',
            field: 'DescrizVar'
        },
        {
            title: 'Categoria',
            field: 'DecrizioneCategoria'
        }
    ]

    const renderActions = useMemo(() => {
        return [
            () => ({
                icon: 'edit',
                iconProps: {
                    fontSize: 'medium'
                },
                tooltip: 'Modifica variazione',
                onClick: (event: any, rowData: DataVar) => {
                    setSelectedDataVar(rowData)
                }
            })
        ]
    }, [dataVars])

    const handleSaveVariants = async () => {
        if (editedDataVars.length > 0 && tipologySelected) {
            dispatch(setIsLoading(true))
            try {
                await EditVariantTypology(tipologySelected.CodTip, editedDataVars, dispatch)
                await fetchDatas()
                setConfirmModal(false)
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    const confirmModalText = () => {
        if (confirmModal) {
            return 'Proseguendo andrai a modificare le varianti selezionate. Vuoi proseguire?'
        }
        if (tempFile) {
            return `Se cliccherai su prosegui le modifiche sulla tabella ${tempFile?.name
                .split('.')
                .slice(0, -1)
                .join('.')} del database saranno irreversibili. Vuoi proseguire?`
        }
        if (tempTypologyFile) {
            return 'Proseguendo andrai a modificare le tipologie presenti nel CSV. Vuoi proseguire?'
        }
        return ''
    }

    return (
        <div>
            <div className="introProductsPage" style={{ borderBottom: '1px solid #cecece', paddingBottom: 10, marginBottom: 20 }}>
                <SubtitleSection text="Aggiornamento listino CSV" />
                <Button
                    hasUpload
                    hasIcon
                    text="Carica file CSV"
                    icon={faUpload}
                    accept=".csv"
                    onClick={() => {
                        return
                    }}
                    onChange={(e) => {
                        setTempFile(e)
                    }}
                />
            </div>
            <div className="introProductsPage" style={{ borderBottom: '1px solid #cecece', paddingBottom: 10, marginBottom: 20 }}>
                <SubtitleSection text="Aggiornamento dimensioni tipologie" />
                <Button
                    hasUpload
                    hasIcon
                    text="Carica file CSV"
                    icon={faUpload}
                    accept=".csv"
                    onClick={() => {
                        return
                    }}
                    onChange={(e) => {
                        setTempTypologyFile(e)
                    }}
                />
            </div>
            <div className="introProductsPage">
                <SubtitleSection text="Aggiornamento varianti tipologie" />
                <Button
                    disabled={editedDataVars.length == 0}
                    opacity={editedDataVars.length == 0 ? 0.5 : 1}
                    text="Salva modifiche"
                    hasIcon
                    icon={faCog}
                    onClick={() => {
                        if (editedDataVars.length > 0) {
                            setConfirmModal(true)
                        }
                    }}
                />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
                <SelectInput
                    label="Serie selezionata"
                    placeholder="Seleziona serie"
                    value={serieValue()}
                    options={data.series.map((s) => {
                        return {
                            id: s.ID,
                            value: s.ID,
                            label: s.NomeSerie
                        }
                    })}
                    onChange={(e) => {
                        const val = e as Option
                        const serieFound = data.series.find((s) => s.ID == (val.id as string))
                        if (serieFound) {
                            setSerieSelected(serieFound)
                        }
                    }}
                />
                <SelectInput
                    label="Tipologia selezionata"
                    disabled={typologies.length == 0}
                    placeholder={typologies.length > 0 ? 'Seleziona tipologia' : 'Seleziona una serie per proseguire'}
                    value={typologyValue()}
                    options={typologies.map((t) => {
                        return {
                            id: t.CodTip,
                            value: t.CodTip,
                            label: t.DescrizTip
                        }
                    })}
                    onChange={(e) => {
                        const val = e as Option
                        const typologyFound = typologies.find((t) => t.CodTip == (val.id as string))
                        if (typologyFound) {
                            setTipologySelected(typologyFound)
                        }
                    }}
                />
                <div>
                    <div style={{ marginTop: 18 }} />
                    <Button
                        disabled={!serieSelected || !tipologySelected}
                        opacity={!serieSelected || !tipologySelected ? 0.5 : 1}
                        text="Filtra tabella"
                        onClick={() => {
                            if (serieSelected && tipologySelected) {
                                fetchDatas()
                            }
                        }}
                    />
                </div>
            </div>
            <div style={{ margin: '30px 0' }}>
                {dataVars.length > 0 ? (
                    <MaterialTable
                        title=""
                        columns={columns}
                        data={renderDatas}
                        localization={locale_itIT}
                        options={{
                            actionsColumnIndex: -1,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50],
                            search: false,
                            headerStyle: {
                                backgroundColor: '#ebebeb',
                                borderRadius: 20
                            }
                        }}
                        //@ts-ignore
                        actions={renderActions}
                    />
                ) : (
                    <p style={{ textAlign: 'center', fontSize: 24, color: '#68696d', fontStyle: 'italic', opacity: 0.6, margin: '30px 0' }}>
                        Imposta i filtri e premi sul pulsante {'"'}Risultato{'"'} per modificare le varianti tipologie
                    </p>
                )}
            </div>
            <EditDataVarModal
                isVisible={selectedDataVar !== null}
                dataVar={selectedDataVar}
                hideModal={() => setSelectedDataVar(null)}
                onSave={(e) => {
                    setDataVars(
                        dataVars.map((v) => {
                            if (e.CodVar == v.CodVar) {
                                return e
                            } else {
                                return v
                            }
                        })
                    )
                    const foundInEdited = editedDataVars.find((v) => v.CodVar == e.CodVar)
                    if (foundInEdited) {
                        setEditedDataVars(
                            editedDataVars.map((v) => {
                                if (e.CodVar == v.CodVar) {
                                    return e
                                } else {
                                    return v
                                }
                            })
                        )
                    } else {
                        setEditedDataVars([...editedDataVars, e])
                    }
                    setSelectedDataVar(null)
                }}
            />
            <ConfirmModal
                isVisible={tempFile !== null || confirmModal || tempTypologyFile !== null}
                title={'Conferma modifiche'}
                text={confirmModalText()}
                saveText={'Prosegui'}
                notSaveText={'Non proseguire'}
                onConfirm={() => {
                    if (confirmModal) {
                        handleSaveVariants()
                        return
                    }
                    if (tempFile) {
                        handleCSVFile(tempFile as File)
                        return
                    }
                    if (tempTypologyFile) {
                        handleTypologyCSVFile(tempTypologyFile as File)
                        return
                    }
                }}
                hideModal={() => {
                    setTempFile(null)
                    setTempTypologyFile(null)
                    setConfirmModal(false)
                }}
            />
            {finalMessage ? (
                <FinalMessageModal
                    isVisible={finalMessage !== null}
                    message={finalMessage?.message}
                    hideModal={() => setFinalMessage(null)}
                    detail={finalMessage.result}
                />
            ) : null}
        </div>
    )
}

export default ListinoScreen
