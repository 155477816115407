import React from 'react'
import { Dialog, DialogActions, DialogContent, } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'

interface Props {
    isVisible: boolean
    hideModal: () => void
    onDuplicate: () => void
    text: string
    title: string
}

const DuplicateModal: React.FC<Props> = (props) => {

    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md" onBackdropClick={() => props.hideModal()} >
            <DialogContent style={{ padding: 20, backgroundColor: "#EBEBEB", position: 'relative', borderRadius: 10 }}>
                <TitlePage text={props.title} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: "#68696D", margin: 0 }}>{props.text}</p>
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: "#EBEBEB", }}>
                <Button
                    isSmall
                    text="Annulla"
                    buttonColor="#52565A"
                    onClick={() => {
                        props.hideModal()
                    }}
                />
                <Button
                    isSmall
                    text="Duplica"
                    onClick={() => {
                        props.onDuplicate()
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default DuplicateModal