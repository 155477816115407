import { Page, View, Image, StyleSheet, Text, Font } from '@react-pdf/renderer'
import React from 'react'
import { ItemsPrint, PrintPage, TipologyItemPrint } from '../../types/user'
import HeaderPrint from './HeaderPrint'
import FooterPrint from './FooterPrint'
import { styles } from './styles'
import { transformToCurrency } from '../../utilities/functions'
import { PrintType } from '../../utilities/PrintType'

interface Props {
    tipology: TipologyItemPrint
    printFile: PrintPage
    currentItem: ItemsPrint
    index: number
}

const TipologyPage: React.FC<Props> = (props) => {
    const tipology = props.tipology
    const printFile = props.printFile

    Font.register({
        family: 'Roboto',
        fonts: [
            { src: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/Roboto-Regular.ttf' },
            { src: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/Roboto-Bold.ttf', fontStyle: 'bold', fontWeight: 700 }
        ]
    })

    const renderVariants = () => {
        if (tipology) {
            return tipology.variants.map((v, i) => {
                return (
                    <View
                        key={v.name + v.price}
                        style={{
                            paddingTop: 5,
                            paddingHorizontal: 5,
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            backgroundColor: i % 2 == 0 ? '#fff' : '#ececec'
                        }}
                    >
                        <View style={{ flexDirection: 'row', flex: 1, overflow: 'hidden', flexWrap: 'wrap' }}>
                            <Text style={[styles.boldText, { fontSize: 10 }]}>{v.name}: </Text>
                            <Text style={[styles.standardText, { fontSize: 10 }]}>{v.value}</Text>
                        </View>
                        {printFile.printType !== PrintType.ONLY_FINAL && printFile.printType !== PrintType.NO_PRICES ? (
                            <View style={{ marginLeft: 10 }}>
                                <Text style={[styles.boldText, { fontSize: 10 }]}>{v.price !== 0 ? transformToCurrency(v.price) : ''}</Text>
                            </View>
                        ) : null}
                    </View>
                )
            })
        }
    }

    if (props.tipology) {
        return (
            <Page size="A4" style={{ display: 'flex', flexDirection: 'column' }}>
                <HeaderPrint printFile={printFile} />
                <View style={{ width: '100%' }}>
                    <View style={{ paddingHorizontal: 20 }}>
                        <View style={{ display: 'flex', flexDirection: 'row' }}>
                            <View style={{ flex: 1.2 }}>
                                <Image src={tipology.img} style={{ width: '100%', objectFit: 'contain' }} />
                                <View style={{ height: 20 }}>
                                    <Text style={styles.standardText}>N° progressivo: {props.index}</Text>
                                </View>
                                <View style={{ height: 20 }}>
                                    <Text style={styles.standardText}>Etichetta: {tipology.label}</Text>
                                </View>
                                <View style={{ height: 20 }}>
                                    <Text style={styles.standardText}>N° ordinamento: {tipology.order}</Text>
                                </View>
                            </View>
                            <View style={{ flex: 2.5, paddingLeft: 10 }}>
                                <Text style={styles.subtitle}>
                                    {tipology.description} ({tipology.dimensions.width} x {tipology.dimensions.height} mm.)
                                </Text>
                                {/* <View style={{ flexDirection: 'row', justifyContent: 'space-between', }}>
                                    <View style={{ flex: 1 }}>
                                        <Text style={styles.standardText}>Etichetta: {tipology.label}</Text>
                                    </View>
                                    <View style={{ flex: 1 }}>
                                        <Text style={styles.standardText}>N° ordinamento: {tipology.order}</Text>
                                    </View>
                                </View> */}
                                <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <View style={{ flex: 1 }}>
                                        <Text style={styles.standardText}>Quantità: {tipology.quantity} pz</Text>
                                    </View>
                                    {printFile.printType !== PrintType.ONLY_FINAL && printFile.printType !== PrintType.NO_PRICES ? (
                                        <View style={{ flex: 1 }}>
                                            <Text style={styles.standardText}>Prezzo base: {transformToCurrency(tipology.priceRif)}</Text>
                                        </View>
                                    ) : null}
                                </View>
                                <View>{renderVariants()}</View>
                            </View>
                        </View>
                        {props.tipology.note ? (
                            <Text style={{ ...styles.standardText, fontSize: 12, opacity: 0.8, marginTop: 10 }}>Note: {props.tipology.note}</Text>
                        ) : null}
                        {printFile.printType !== PrintType.NO_PRICES ? (
                            <View
                                style={{
                                    backgroundColor: printFile.color ? printFile.color : '#005AAB',
                                    width: '100%',
                                    padding: 5,
                                    marginTop: 10,
                                    flexDirection: 'row',
                                    justifyContent: printFile.printType == PrintType.ONLY_FINAL ? 'flex-end' : 'space-between'
                                }}
                            >
                                {printFile.printType !== PrintType.ONLY_FINAL ? (
                                    <Text style={{ color: '#fff', fontSize: 12, fontFamily: 'Roboto' }}>
                                        Prezzo: {transformToCurrency(tipology.priceNoSale)}
                                    </Text>
                                ) : null}
                                {printFile.printType !== PrintType.ONLY_FINAL ? (
                                    <Text style={{ color: '#fff', fontSize: 12, fontFamily: 'Roboto' }}>
                                        Sconto ({tipology.saleFormula}%): {transformToCurrency(tipology.saleEuro)}
                                    </Text>
                                ) : null}
                                <Text style={{ color: '#fff', fontSize: 12, fontFamily: 'Roboto' }}>Prezzo finale: {transformToCurrency(tipology.price)}</Text>
                            </View>
                        ) : null}
                    </View>
                    <View wrap={false} style={{ paddingHorizontal: 20, height: 230 }}>
                        <Text style={styles.subtitle}>Dati tecnici</Text>
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                            {tipology.dimensions.weight && tipology.dimensions.weight !== '0' ? (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.standardText, fontSize: 12 }}>Peso</Text>
                                    <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>
                                        {tipology.dimensions.weight} kg.
                                    </Text>
                                </View>
                            ) : null}
                            {tipology.dimensions.heightManiglia ? (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.standardText, fontSize: 12 }}>HMan.</Text>
                                    <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>
                                        {tipology.dimensions.heightManiglia} mm.
                                    </Text>
                                </View>
                            ) : null}
                            {tipology.dimensions.heightTraverso1 ? (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.standardText, fontSize: 12 }}>HTrav. 1</Text>
                                    <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>
                                        {tipology.dimensions.heightTraverso1} mm.
                                    </Text>
                                </View>
                            ) : null}
                            {tipology.dimensions.heightTraverso2 ? (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.standardText, fontSize: 12 }}>HTrav. 2</Text>
                                    <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>
                                        {tipology.dimensions.heightTraverso2} mm.
                                    </Text>
                                </View>
                            ) : null}
                            {tipology.dimensions.heightTraverso3 ? (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.standardText, fontSize: 12 }}>HTrav. 3</Text>
                                    <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>
                                        {tipology.dimensions.heightTraverso3} mm.
                                    </Text>
                                </View>
                            ) : null}
                            {tipology.dimensions.heightTraverso4 ? (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.standardText, fontSize: 12 }}>HTrav. 4</Text>
                                    <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>
                                        {tipology.dimensions.heightTraverso4} mm.
                                    </Text>
                                </View>
                            ) : null}
                            {tipology.dimensions.h1 ? (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.standardText, fontSize: 12 }}>H1</Text>
                                    <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>
                                        {tipology.dimensions.h1} mm.
                                    </Text>
                                </View>
                            ) : null}
                            {tipology.dimensions.h2 ? (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.standardText, fontSize: 12 }}>H2</Text>
                                    <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>
                                        {tipology.dimensions.h2} mm.
                                    </Text>
                                </View>
                            ) : null}
                            {tipology.dimensions.l1 ? (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.standardText, fontSize: 12 }}>L1</Text>
                                    <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>
                                        {tipology.dimensions.l1} mm.
                                    </Text>
                                </View>
                            ) : null}
                            {tipology.dimensions.l2 ? (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.standardText, fontSize: 12 }}>L2</Text>
                                    <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>
                                        {tipology.dimensions.l2} mm.
                                    </Text>
                                </View>
                            ) : null}
                            {tipology.dimensions.l3 ? (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.standardText, fontSize: 12 }}>L3</Text>
                                    <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>
                                        {tipology.dimensions.l3} mm.
                                    </Text>
                                </View>
                            ) : null}
                            {tipology.dimensions.l4 ? (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.standardText, fontSize: 12 }}>L4</Text>
                                    <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>
                                        {tipology.dimensions.l4} mm.
                                    </Text>
                                </View>
                            ) : null}
                            {tipology.dimensions.l5 ? (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.standardText, fontSize: 12 }}>L5</Text>
                                    <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>
                                        {tipology.dimensions.l5} mm.
                                    </Text>
                                </View>
                            ) : null}
                            {tipology.dimensions.l6 ? (
                                <View
                                    style={{
                                        flex: 1,
                                        justifyContent: 'center',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        textAlign: 'center',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Text style={{ ...styles.standardText, fontSize: 12 }}>L6</Text>
                                    <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>
                                        {tipology.dimensions.l6} mm.
                                    </Text>
                                </View>
                            ) : null}
                        </View>
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                            {tipology.features.map((f) => {
                                return (
                                    <View
                                        key={f.name}
                                        style={{
                                            flex: 1,
                                            justifyContent: 'center',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            textAlign: 'center',
                                            alignItems: 'center'
                                        }}
                                    >
                                        <Image src={f.icon} style={{ width: 30, height: 30, borderRadius: 20, objectFit: 'contain' }} />
                                        <Text style={{ ...styles.standardText, fontSize: 12 }}>{f.name}</Text>
                                        <Text style={{ ...styles.standardText, ...{ fontSize: 12, fontWeight: 700, color: '#000' } }}>{f.value}</Text>
                                    </View>
                                )
                            })}
                        </View>
                    </View>
                </View>
                <FooterPrint printFile={printFile} />
            </Page>
        )
    }

    return null
}

export default TipologyPage
