import { faSearch } from '@fortawesome/free-solid-svg-icons'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Button from '../../../components/Button'
import Input from '../../../components/formComponents/Input'
import DeleteModal from '../../../components/modals/DeleteModal'
import ResetOfferFromBackup from '../../../components/modals/ResetOfferFromBackup'
import SubtitleSection from '../../../components/SubtitleSection'
import BackupTable from '../../../components/tables/BackupTable'
import { getDatabases, getServerStatus } from '../../../resources/requests/others'
import { deleteBackup, resetOfferFromBackup } from '../../../resources/requests/settings'
import { setError, setIsLoading } from '../../../store/actions/graphic'
import { Database } from '../../../types/data'

interface ServerStatus {
    info: string
    DiskHead: string
    DiskValueGB: string
    MemoryHead: string
    MemoryValueKB: string
}

const BackupScreen: React.FC = () => {
    const [backups, setBackups] = useState<Database[]>([])
    const [selectedBackup, setSelectedBackup] = useState<Database | null>(null)
    const [backupToDelete, setBackupToDelete] = useState<Database | null>(null)
    const [status, setStatus] = useState<ServerStatus | null>(null)
    const [filterSearch, setFilterSearch] = useState('')
    const dispatch = useDispatch()

    const fetchServerDatas = async () => {
        dispatch(setIsLoading(true))
        try {
            const status = await getServerStatus(dispatch)
            setStatus(status)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const fetchDatabases = async () => {
        dispatch(setIsLoading(true))
        try {
            const databases = await getDatabases(dispatch)
            setBackups(databases)
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const handleDelete = async () => {
        if (backupToDelete) {
            dispatch(setIsLoading(true))
            try {
                await deleteBackup(backupToDelete.ID, dispatch)
                setBackupToDelete(null)
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    const handleResetOffer = async (codOffer: string) => {
        if (selectedBackup) {
            dispatch(setIsLoading(true))
            try {
                const status = await resetOfferFromBackup(selectedBackup.NomeBackup, codOffer, dispatch)
                if (status !== 'OK') {
                    dispatch(setError('Offerta non disponibile o corrotta'))
                } else {
                    setSelectedBackup(null)
                }
            } catch (error: any) {
                dispatch(setError(error.toString()))
            }
            dispatch(setIsLoading(false))
        }
    }

    useEffect(() => {
        fetchDatabases()
        fetchServerDatas()
    }, [])

    console.log(status)

    return (
        <div>
            <div className="introProductsPage" style={{ borderBottom: '1px solid #cecece', marginBottom: 20, paddingBottom: 10 }}>
                <div>
                    <SubtitleSection text="Status server" />
                    {status ? (
                        <div style={{ color: '#68696d' }}>
                            <p>{status.info}</p>
                            <p>Dati riassuntivi</p>
                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: 20, margin: '15px 0', fontSize: 18 }}>
                                <div>
                                    <p>{status.DiskHead}</p>
                                    <p>{status.DiskValueGB}</p>
                                </div>
                                <div>
                                    <p>{status.MemoryHead}</p>
                                    <p>{status.MemoryValueKB}</p>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
                <Button
                    text="Aggiorna"
                    onClick={() => {
                        fetchServerDatas()
                    }}
                />
            </div>
            <div className="introProductsPage">
                <SubtitleSection text="Lista backup" />
                <Input isFilterInput icon={faSearch} value={filterSearch} onChange={(e) => setFilterSearch(e)} placeholder={'Ricerca backup...'} />
            </div>
            <BackupTable
                backups={backups}
                search={filterSearch}
                onSelect={(b) => {
                    setSelectedBackup(b)
                }}
                onDelete={(b) => {
                    setBackupToDelete(b)
                }}
            />
            {backupToDelete ? (
                <DeleteModal
                    text={`Confermi di voler eliminare il backup ${backupToDelete.NomeBackup}`}
                    title={`Eliminazione backup ${backupToDelete.NomeBackup}`}
                    isVisible={backupToDelete !== null}
                    hideModal={() => setBackupToDelete(null)}
                    onDelete={() => handleDelete()}
                />
            ) : null}
            {selectedBackup ? (
                <ResetOfferFromBackup
                    backup={selectedBackup}
                    isVisible={selectedBackup !== null}
                    hideModal={() => setSelectedBackup(null)}
                    onReset={(codOffer) => {
                        handleResetOffer(codOffer)
                    }}
                />
            ) : null}
        </div>
    )
}

export default BackupScreen
