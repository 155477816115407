import React, { useEffect, useState } from 'react'
import { Tax, Typology, TypologyItemQuote } from '../../types/data'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy, faPlusSquare, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons'
import Button from '../Button'
import { DettaglioItemInQuote, VariantAdd, VariantItem, VariantRemove } from '../../types/order'
import {
    calculateTotalPrices,
    getAllItemsQuote,
    getAllVariantsPerItem,
    getSingleItemQuote,
    switchVariantsPerItem,
    switchVariantsSelectedItems
} from '../../resources/requests/requests'
import VariantCard from '../VariantCard'
import { ReducerData, ReducerOrder, Reducers } from '../../types/reducers'
import { useSelector, useDispatch } from 'react-redux'
import { setError, setErrorVariants, setIsLoading } from '../../store/actions/graphic'
import { setItemsInQuote, setPricesTotalOffer } from '../../store/actions/order'
import SelectTypologiesModal from './SelectTypologiesModal'

interface Props {
    isVisible: boolean
    variantSelected: VariantItem
    idRecord: string
    variants?: VariantItem[]
    hideModal: () => void
    updatedItem: (item: DettaglioItemInQuote) => void
}

const SelectVariantModal: React.FC<Props> = (props) => {
    const order: ReducerOrder = useSelector((store: Reducers) => store.order)
    const data: ReducerData = useSelector((store: Reducers) => store.data)
    const [hasImage, setHasImage] = useState(true)
    const [allVariants, setAllVariants] = useState<VariantItem[]>([])
    const [selectedVariants, setSelectedVariants] = useState<VariantItem[]>([props.variantSelected])
    const [showTypologySelection, setShowTypologySelection] = useState(false)
    const [showVariantErrorAddModal, setShowVariantErrorAddModal] = useState(false)
    const [variantAddErrors, setVariantAddErrors] = useState<string[]>([])
    const dispatch = useDispatch()

    const getVariantsList = async () => {
        dispatch(setIsLoading(true))
        try {
            const variants = await getAllVariantsPerItem(props.idRecord, props.variantSelected.IdCatVar, dispatch)
            setAllVariants(variants)
            setHasImage(variants.filter((v: VariantItem) => v.FiguraAp !== '').length > 0)
            setSelectedVariants(variants.filter((v) => v.inserita !== 0))
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    const setPropsVariants = () => {
        if (props.variants) {
            setAllVariants(props.variants)
            setHasImage(props.variants.filter((v: VariantItem) => v.FiguraAp !== '').length > 0)
            setSelectedVariants(props.variants.filter((v) => v.inserita !== 0))
        }
    }

    useEffect(() => {
        setSelectedVariants([])
        if (props.isVisible && !props.variants) {
            getVariantsList()
        }
        if (props.isVisible && props.variants) {
            setPropsVariants()
        }
    }, [props.isVisible])

    const renderAllVariants = () => {
        return allVariants.map((v, i) => {
            return (
                <VariantCard
                    key={v.CodVar + i}
                    onClick={() => {
                        addRemoveSelectedVariants(v)
                    }}
                    variant={v}
                    hasImage={hasImage}
                    isSelected={selectedVariants.find((variant) => variant.CodVar == v.CodVar) !== undefined}
                />
            )
        })
    }

    const addRemoveSelectedVariants = (v: VariantItem) => {
        if (allVariants[0].Univoco !== '1') {
            const itemFound = selectedVariants.find((variant) => variant.CodVar == v.CodVar)
            if (itemFound) {
                setSelectedVariants(selectedVariants.filter((variant) => variant.CodVar !== v.CodVar))
            } else {
                setSelectedVariants([...selectedVariants, v])
            }
        } else {
            setSelectedVariants([v])
        }
    }

    const handleSaveVariants = async () => {
        dispatch(setIsLoading(true))
        try {
            const arrayVariantAdd: VariantAdd[] = selectedVariants.map((v) => {
                return {
                    CodVar: v.CodVar,
                    IdCatVar: v.IdCatVar,
                    IdGenereVar: v.IdGenereVar,
                    DescrizVar: v.DescrizVar,
                    VALID_AMM_VAR: v.VALIDAMMVAR,
                    CALC_VAR: v.CALCVAR,
                    CALC_AMM_VAR: v.CALCAMMVAR,
                    PRM: v.PRM,
                    FiguraAp: v.FiguraAp,
                    Um: v.Um,
                    Priorita: v.Priorita,
                    PrioritaCalcolo: v.PrioritaCalcolo,
                    Prezzo: v.Prezzo,
                    NascondiInDoc: v.NascondiInDoc,
                    StampaPrezzi: v.StampaPrezzi,
                    StampaQta: v.StampaQta,
                    StampaPrev: v.StampaPrev,
                    StampaOrd: v.StampaOrd,
                    DecrizioneCategoria: v.DecrizioneCategoria,
                    Univoco: v.Univoco,
                    inserita: v.inserita.toString(),
                    Calcolo: v.Calcolo,
                    PrezzoVariante: v.PrezzoVariante.toString(),
                    Costo: v.Costo.toString()
                }
            })
            const arrayVariantRemove: VariantRemove[] = allVariants.filter((v) => v.inserita !== 0)
            await switchVariantsPerItem(props.idRecord, order.newQuoteFromUser?.CodicePreventivo, arrayVariantAdd, arrayVariantRemove, dispatch)
            const updatedItem = await getSingleItemQuote(props.idRecord, order.newQuoteFromUser?.CodicePreventivo, dispatch)
            const previousIVA = data.taxes.find((t) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
            const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
            dispatch(setPricesTotalOffer(prices))
            props.updatedItem(updatedItem)
            props.hideModal()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }

        dispatch(setIsLoading(false))
    }

    const handleSaveMultipleVariants = async (idItems: { idItem: string }[]) => {
        dispatch(setIsLoading(true))
        try {
            const arrayVariantAdd: VariantAdd[] = selectedVariants.map((v) => {
                return {
                    CodVar: v.CodVar,
                    IdCatVar: v.IdCatVar,
                    IdGenereVar: v.IdGenereVar,
                    DescrizVar: v.DescrizVar,
                    VALID_AMM_VAR: v.VALIDAMMVAR,
                    CALC_VAR: v.CALCVAR,
                    CALC_AMM_VAR: v.CALCAMMVAR,
                    PRM: v.PRM,
                    FiguraAp: v.FiguraAp,
                    Um: v.Um,
                    Priorita: v.Priorita,
                    PrioritaCalcolo: v.PrioritaCalcolo,
                    Prezzo: v.Prezzo,
                    NascondiInDoc: v.NascondiInDoc,
                    StampaPrezzi: v.StampaPrezzi,
                    StampaQta: v.StampaQta,
                    StampaPrev: v.StampaPrev,
                    StampaOrd: v.StampaOrd,
                    DecrizioneCategoria: v.DecrizioneCategoria,
                    Univoco: v.Univoco,
                    inserita: v.inserita.toString(),
                    Calcolo: v.Calcolo,
                    PrezzoVariante: v.PrezzoVariante.toString(),
                    Costo: v.Costo.toString()
                }
            })
            const arrayVariantRemove: VariantRemove[] = allVariants.filter((v) => v.inserita !== 0)
            const dataFetch = await switchVariantsSelectedItems(
                idItems,
                order.newQuoteFromUser?.CodicePreventivo,
                arrayVariantAdd,
                arrayVariantRemove,
                dispatch
            )
            if (dataFetch && dataFetch.WarningMessage && dataFetch.WarningMessage.length > 0) {
                dispatch(setErrorVariants(dataFetch.WarningMessage))
            }
            const items = await getAllItemsQuote(order.newQuoteFromUser?.CodicePreventivo, dispatch)
            const previousIVA = data.taxes.find((t: Tax) => t.descrizione == order.pricesTotalOffer?.DescrizioneIva)
            const prices = await calculateTotalPrices(order.newQuoteFromUser?.CodiceOfferta, previousIVA ? previousIVA.Codice : null, dispatch)
            await dispatch(setPricesTotalOffer(prices))
            await dispatch(
                setItemsInQuote(
                    order.itemsInQuote.map((item) => {
                        const itemFound = items.find((i) => i.idRecord == item.idRecord)
                        if (itemFound) {
                            return {
                                ...item,
                                DettaglioItem: itemFound
                            }
                        } else {
                            return item
                        }
                    })
                )
            )
            setShowTypologySelection(false)
            props.hideModal()
        } catch (error: any) {
            console.log(error)
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="md" onBackdropClick={() => props.hideModal()}>
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <FontAwesomeIcon
                    icon={faTimes}
                    style={{ position: 'absolute', top: 10, right: 10, color: '#68696D', fontSize: 17, cursor: 'pointer' }}
                    onClick={() => props.hideModal()}
                />
                <TitlePage text={props.variantSelected.DecrizioneCategoria} style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Altre varianti selezionabili</p>
                <div className="variantGrid">{renderAllVariants()}</div>
                <SelectTypologiesModal
                    isVisible={showTypologySelection}
                    selectedVariants={selectedVariants}
                    variantSelected={props.variantSelected}
                    hideModal={() => setShowTypologySelection(false)}
                    idRecord={props.idRecord}
                    onSave={(typologies) => {
                        handleSaveMultipleVariants(typologies)
                    }}
                />
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Applica selezione"
                    hasIcon={true}
                    icon={faCopy}
                    buttonColor="#52565a"
                    onClick={() => {
                        setShowTypologySelection(true)
                    }}
                />
                <Button
                    isSmall
                    text="Salva"
                    hasIcon={true}
                    icon={faCopy}
                    onClick={() => {
                        handleSaveVariants()
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default SelectVariantModal
