import React from 'react'
import { ReducerData, Reducers } from '../../../../types/reducers'
import { useSelector } from 'react-redux'
import CategoryCard from '../../../../components/CategoryCard'
import { Categoria } from '../../../../types/data'

interface Props {
    selectDetail: (c: Categoria) => void
}

const CategoriesListScreen: React.FC<Props> = (props) => {
    const data: ReducerData = useSelector((store: Reducers) => store.data)

    const renderCategories = () => {
        const newArray = data.categories.filter((c) => !c.Nome.includes("Tutt"))
        return (
            newArray.map((c) => {
                return (
                    <CategoryCard category={c} key={c.IdCategorSerie} onClick={() => { props.selectDetail(c) }} isEdit />
                )
            })
        )
    }

    return (
        <div className="categoryListContainer" style={{ margin: "20px 0", gridTemplateColumns: "repeat(6, 1fr)", height: "46vh" }}>
            {renderCategories()}
        </div>
    )
}

export default CategoriesListScreen