import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import TitlePage from '../TitlePage'
import Button from '../Button'
import { CurrentOffer } from '../../types/order'
import moment from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { useDispatch } from 'react-redux'
import { setError, setIsLoading } from '../../store/actions/graphic'
import { getOfferData, saveCurrentOffer, setAdditionalDatasOffer } from '../../resources/requests/requests'
import { setOfferLoadedRedux } from '../../store/actions/thunk_actions'
import { setLastOfferSeen } from '../../store/actions/user'

interface Props {
    isVisible: boolean
    hideModal: () => void
    offerData: CurrentOffer
    isClosed?: boolean
}

const DeliveryDateSelectModal: React.FC<Props> = (props) => {
    const offerData = props.offerData
    const [dateSelected, setDateSelected] = useState(
        props.offerData.DataConsegna ? moment(props.offerData.DataConsegna).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
    )
    const dispatch = useDispatch()

    useEffect(() => {
        if (props.isVisible) {
            setDateSelected(props.offerData.DataConsegna ? moment(props.offerData.DataConsegna).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))
        }
    }, [props.isVisible])

    const handleSave = async () => {
        dispatch(setIsLoading(true))
        try {
            const bodyAdditional = {
                CodiceOfferta: offerData.CodiceOfferta,
                Descrizione: offerData.Descrizione,
                CantiereInstallazione: offerData.CantiereInstallazione,
                IndirizzoCantiere: offerData.IndirizzoCantiere,
                ProvinciaCantiere: offerData.ProvinciaCantiere,
                ComuneCantiere: offerData.ComuneCantiere,
                CAPCantiere: offerData.CAPCantiere,
                TestoIniziale: offerData.TestoIniziale,
                TestoFinale: offerData.TestoFinale,
                DataConsegna: dateSelected
            }
            await setAdditionalDatasOffer(bodyAdditional, dispatch)
            await saveCurrentOffer(offerData.CodiceOfferta, dispatch)
            if (!props.isClosed) {
                dispatch(setOfferLoadedRedux(offerData.CodiceOfferta))
            } else {
                const newOfferData = await getOfferData(offerData.CodiceOfferta, dispatch)
                dispatch(setLastOfferSeen(newOfferData))
            }
            props.hideModal()
        } catch (error: any) {
            dispatch(setError(error.toString()))
        }
        dispatch(setIsLoading(false))
    }

    return (
        <Dialog open={props.isVisible} fullWidth={true} aria-labelledby="form-dialog-title" maxWidth="sm" onBackdropClick={() => props.hideModal()}>
            <DialogContent style={{ padding: 20, backgroundColor: '#EBEBEB', position: 'relative', borderRadius: 10 }}>
                <TitlePage text="Imposta data consegna" style={{ margin: 0 }} />
                <p style={{ fontSize: 24, color: '#68696D', margin: 0 }}>Inserisci la data di consegna</p>
                <div style={{ margin: '30px 0 0 0' }}>
                    <div className="filterInput">
                        <FontAwesomeIcon icon={faCalendar} className="icon" />
                        <input
                            style={{ padding: '12.5px 20px 12.5px 40px', width: '100%', maxWidth: 'calc(100% - 60px)' }}
                            type="date"
                            value={dateSelected}
                            onChange={(e) => {
                                setDateSelected(e.target.value)
                            }}
                            placeholder="Data inizio periodo riferimento..."
                        />
                    </div>
                </div>
            </DialogContent>
            <DialogActions style={{ padding: 20, backgroundColor: '#EBEBEB' }}>
                <Button
                    isSmall
                    text="Annulla"
                    buttonColor="#52565A"
                    onClick={() => {
                        props.hideModal()
                    }}
                />
                <Button
                    isSmall
                    text="Salva"
                    onClick={() => {
                        handleSave()
                    }}
                />
            </DialogActions>
        </Dialog>
    )
}

export default DeliveryDateSelectModal
