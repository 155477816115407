import React from 'react'

interface CheckboxOption {
    label: string
    status: boolean
}

interface Props {
    values: CheckboxOption[]
    onClick: (check: CheckboxOption) => void
    label?: string
    flex?: number
    disabled?: boolean
}

const CheckboxInput: React.FC<Props> = (props) => {
    return (
        <div
            style={{
                flex: props.flex ? props.flex : 1,
                flexDirection: props.values.length === 1 && !props.values[0].label ? 'row' : 'column',
                alignItems: props.values.length === 1 && !props.values[0].label ? 'center' : 'flex-start',
                display: 'flex',
                gap: 10
            }}
        >
            {props.label && <label>{props.label}</label>}
            <div style={{ display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center' }}>
                {props.values.map((v) => {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'row', gap: 20, alignItems: 'center' }} key={v.label}>
                            <input
                                type="checkbox"
                                checked={v.status}
                                onChange={() => props.onClick(v)}
                                disabled={props.disabled}
                                style={{ width: 25, height: 25, border: '0' }}
                            />
                            <span>{v.label}</span>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default CheckboxInput
