import React from 'react'
import { View, Text, Font, StyleSheet } from '@react-pdf/renderer'
import { useSelector } from 'react-redux'
import { ReducerGraphic, Reducers } from '../../types/reducers'
import { PrintPage } from '../../types/user'
import moment from 'moment'
import { styles } from './styles'

interface Props {
    printFile: PrintPage | null
}

const FooterPrint: React.FC<Props> = (props) => {
    const printFile = props.printFile

    Font.register({
        family: 'Roboto',
        fonts: [
            { src: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/Roboto-Regular.ttf' },
            { src: 'https://22hbgtest.s3.eu-west-2.amazonaws.com/biemme_finestre/Roboto-Bold.ttf', fontStyle: 'bold', fontWeight: 700 }
        ]
    })

    if (printFile) {
        return (
            <View
                fixed
                style={{
                    backgroundColor: '#ebebeb',
                    height: 80,
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    flexDirection: 'row',
                    alignItems: 'center',
                    paddingHorizontal: 20
                }}
            >
                <View style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 80 }}>
                    <View style={{ display: 'flex', flexDirection: 'row', height: 18 }}>
                        <Text style={styles.boldSmallText}>{printFile.footer.ragioneSociale} </Text>
                        <Text style={styles.standardSmallText}>
                            {' '}
                            - {printFile.footer.address} {printFile.footer.cap} {printFile.footer.city} {printFile.footer.province}
                        </Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', height: 18 }}>
                        <Text style={styles.boldSmallText}>Telefono: </Text>
                        <Text style={styles.standardSmallText}>{printFile.footer.phone} - </Text>
                        <Text style={styles.boldSmallText}>Partita IVA: </Text>
                        <Text style={styles.standardSmallText}>{printFile.footer.partitaIVA}</Text>
                    </View>
                    <View style={{ display: 'flex', flexDirection: 'row', height: 18 }}>
                        <Text style={styles.boldSmallText}>Sito Web: </Text>
                        <Text style={styles.standardSmallText}>{printFile.footer.website} - </Text>
                        <Text style={styles.boldSmallText}>Indirizzo mail: </Text>
                        <Text style={styles.standardSmallText}>{printFile.footer.email}</Text>
                    </View>
                </View>
                <View
                    style={{
                        backgroundColor: printFile?.color ? printFile.color : '#005AAB',
                        height: 80,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingHorizontal: 20
                    }}
                >
                    <Text
                        style={{
                            color: '#fff',
                            fontSize: 10,
                            fontWeight: 400
                        }}
                        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                    />
                </View>
            </View>
        )
    }

    return null
}

export default FooterPrint
