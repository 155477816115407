import React from 'react'

interface Props {
    text: string
}

const SubtitleSection: React.FC<Props> = (props) => {
    return (
        <h2 className="subtitleSection">{props.text}</h2>
    )
}

export default SubtitleSection