import { ORDER_ACTIONS } from './../actions/order'
import { ReducerOrder, ReduxActionData } from './../../types/reducers'

const defaultValue: ReducerOrder = {
    newQuoteFromUser: null,
    itemsInQuote: [],
    offerData: null,
    idUserKnown: '',
    pricesTotalOffer: null,
    currentOffer: null,
    pendingOffer: false,
    currentCustomerSale: null
}

const orderState: (state: ReducerOrder, action: ReduxActionData<any>) => ReducerOrder = (state = defaultValue, action: ReduxActionData<any>) => {
    switch (action.type) {
        case ORDER_ACTIONS.SET_NEW_ORDER_FROM_USER:
            return {
                ...state,
                newQuoteFromUser: action.payload
            }
        case ORDER_ACTIONS.SET_ITEMS_IN_QUOTE:
            return {
                ...state,
                itemsInQuote: action.payload
            }
        case ORDER_ACTIONS.SET_OFFER_DATA:
            return {
                ...state,
                offerData: action.payload
            }
        case ORDER_ACTIONS.SET_ID_USER_KNOWN:
            return {
                ...state,
                idUserKnown: action.payload
            }
        case ORDER_ACTIONS.SET_PRICES_TOTAL_OFFER:
            return {
                ...state,
                pricesTotalOffer: action.payload
            }
        case ORDER_ACTIONS.SET_CURRENT_OFFER:
            return {
                ...state,
                currentOffer: action.payload
            }
        case ORDER_ACTIONS.SET_IS_PENDING_OFFER:
            return {
                ...state,
                pendingOffer: action.payload
            }
        case ORDER_ACTIONS.SET_CURRENT_CUSTOMER_SALE:
            return {
                ...state,
                currentCustomerSale: action.payload
            }
        default:
            return state
    }
}

export default orderState
