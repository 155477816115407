import { IconProp } from '@fortawesome/fontawesome-svg-core'
import React, { CSSProperties } from 'react'
import Select, { GroupBase, OptionsOrGroups, PropsValue } from 'react-select'

export interface Option {
    id: number | string
    value: number | string
    label: string
}

interface Props {
    label?: string
    icon?: IconProp
    placeholder?: string
    labelStyle?: CSSProperties
    divStyle?: CSSProperties
    disabled?: boolean
    required?: boolean
    value: PropsValue<Option | Option[]> | null
    options: OptionsOrGroups<Option, GroupBase<Option>>
    isMulti?: boolean
    menuPlacement?: 'top' | 'bottom'
    onChange: (value: PropsValue<Option | Option[]>) => void
    width?: number | string
}

const SelectInput: React.FC<Props> = (props) => {
    return (
        <div style={{ ...props.divStyle }}>
            {props.label ? <label style={{ ...props.labelStyle }}>{props.label}</label> : null}
            <Select
                isDisabled={props.disabled}
                menuPlacement={props.menuPlacement ? props.menuPlacement : 'auto'}
                styles={{
                    valueContainer: (provided) => ({
                        ...provided,
                        backgroundColor: '#f4f4f4',
                        width: props.width ? props.width : ''
                    }),
                    container: (provided) => ({
                        ...provided,
                        backgroundColor: '#f4f4f4',
                        borderRadius: 7
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        fontSize: 18
                    }),
                    placeholder: (provided) => ({
                        ...provided,
                        fontSize: 18
                    }),
                    control: (provided) => ({
                        ...provided,
                        backgroundColor: '#f4f4f4',
                        border: 0,
                        padding: '5px 10px',
                        borderRadius: 7
                    }),
                    menu: (provided) => ({
                        ...provided,
                        zIndex: 99999999999
                    })
                }}
                placeholder={props.placeholder ? props.placeholder : ''}
                options={props.options}
                onChange={(value) => {
                    if (value) {
                        props.onChange(value)
                    }
                }}
                value={props.value}
            />
        </div>
    )
}

export default SelectInput
